export const INTERNAL_USER = 'internal';

export const DESIRED_ROLES = 'DESIRED_ROLES';
export const GAMES_PLAYED = 'GAMES_PLAYED';
export const GAMES_WORKED_ON = 'GAMES_WORKED_ON';
export const GENRES_BY_GAMES_PLAYED = 'GENRES_BY_GAMES_PLAYED';
export const GENRES_BY_GAMES_WORKED_ON = 'GENRES_BY_GAMES_WORKED_ON';
export const GENRES_EXPERIENCE_BY_YEAR = 'GENRES_EXPERIENCE_BY_YEAR';
export const IS_AVAILABLE = 'IS_AVAILABLE';
export const IS_RELOCATE_OK = 'IS_RELOCATE_OK';
export const IS_REMOTE_COVID_OK = 'IS_REMOTE_COVID_OK';
export const IS_REMOTE_OK = 'IS_REMOTE_OK';
export const LOCATION_PROXIMITY = 'LOCATION_PROXIMITY';
export const LONGEVITY_SCORE = 'LONGEVITY_SCORE';
export const PAY_ANNUAL_RANGE = 'PAY_ANNUAL_RANGE';
export const PAY_HOURLY_RANGE = 'PAY_HOURLY_RANGE';
export const PRIMARY_HIGHLIGHT = 'PRIMARY_HIGHLIGHT';
export const RECRUITER_NOTES = 'RECRUITER_NOTES';
export const RECRUITER_EXPLICIT_NOTES = 'RECRUITER_EXPLICIT_NOTES';
export const REPORT_CARD = 'REPORT_CARD';
export const SKILLS_EXPERIENCE_BY_YEAR = 'SKILLS_EXPERIENCE_BY_YEAR';
export const TOTAL_YEARS_OF_EXPERIENCE = 'TOTAL_YEARS_OF_EXPERIENCE';

export const DEFAULT_INSIGHTS = [
  DESIRED_ROLES,
  GAMES_PLAYED,
  GAMES_WORKED_ON,
  GENRES_BY_GAMES_PLAYED,
  GENRES_BY_GAMES_WORKED_ON,
  GENRES_EXPERIENCE_BY_YEAR,
  IS_AVAILABLE,
  IS_RELOCATE_OK,
  IS_REMOTE_COVID_OK,
  IS_REMOTE_OK,
  LOCATION_PROXIMITY,
  LONGEVITY_SCORE,
  PAY_ANNUAL_RANGE,
  PAY_HOURLY_RANGE,
  PRIMARY_HIGHLIGHT,
  RECRUITER_NOTES,
  RECRUITER_EXPLICIT_NOTES,
  REPORT_CARD,
  SKILLS_EXPERIENCE_BY_YEAR,
  TOTAL_YEARS_OF_EXPERIENCE
];
