import { normalize } from 'normalizr';

import { Credit, User } from 'schemas/index';
import { errorState, loadingState, successState } from 'utils/actionUtils';
import { RESEND_ENDPOINT, VERIFY_ENDPOINT } from 'constants/global';
import {
  RESEND_HASH_ERROR,
  RESEND_HASH_START,
  RESEND_HASH_SUCCESS,
  VERIFY_HASH_ERROR,
  VERIFY_HASH_START,
  VERIFY_HASH_SUCCESS
} from 'constants/verifyConstants';
import { ENTITY_TYPE_ENUM } from 'shared/constants/enumConstants';

const { CREDIT, USER } = ENTITY_TYPE_ENUM;

const ENTITY_TO_SCHEMA = {
  [CREDIT]: Credit,
  [USER]: User
};

export function validateHash(hash) {
  return {
    types: {
      loading: () => loadingState(VERIFY_HASH_START),
      success: hashData => {
        const { message, normalizeEntity, Type } = hashData;

        if (!normalizeEntity) return successState(VERIFY_HASH_SUCCESS, hashData);

        const { model, entity } = normalizeEntity;
        return successState(VERIFY_HASH_SUCCESS, {
          ...normalize(model, ENTITY_TO_SCHEMA[entity]),
          message,
          Type
        });
      },
      error: err => errorState(VERIFY_HASH_ERROR)
    },
    apiParams: {
      endpoint: `${VERIFY_ENDPOINT}?hash=${hash}`
    }
  };
}

export function resendHash(hash) {
  let queryUrl = RESEND_ENDPOINT;
  if (hash) {
    queryUrl += `?hash=${hash}`;
  }

  return {
    types: {
      loading: () => loadingState(RESEND_HASH_START),
      success: () => successState(RESEND_HASH_SUCCESS),
      error: err => errorState(RESEND_HASH_ERROR)
    },
    apiParams: {
      endpoint: queryUrl
    }
  };
}
