import appLocaleData from '@formatjs/intl-relativetimeformat/dist/locale-data/en.json';

import { antdEn, rupieEn } from './en_US';

require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/dist/locale-data/en');

const appLocale = {
  antd: antdEn,
  data: appLocaleData,
  locale: 'en-US',
  messages: {},
  moment: 'us',
  rupie: rupieEn
};

export default appLocale;
