export const CONVERSATION_ADD_REACTION_START = 'CONVERSATION_ADD_REACTION_START';
export const CONVERSATION_ADD_REACTION_SUCCESS = 'CONVERSATION_ADD_REACTION_SUCCESS';
export const CONVERSATION_ADD_REACTION_ERROR = 'CONVERSATION_ADD_REACTION_ERROR';

export const CONVERSATION_REMOVE_REACTION_START = 'CONVERSATION_REMOVE_REACTION_START';
export const CONVERSATION_REMOVE_REACTION_SUCCESS = 'CONVERSATION_REMOVE_REACTION_SUCCESS';
export const CONVERSATION_REMOVE_REACTION_ERROR = 'CONVERSATION_REMOVE_REACTION_ERROR';

export const CONVERSATIONS_FETCH_START = 'CONVERSATIONS_FETCH_START';
export const CONVERSATIONS_FETCH_SUCCESS = 'CONVERSATIONS_FETCH_SUCCESS';
export const CONVERSATIONS_FETCH_ERROR = 'CONVERSATIONS_FETCH_ERROR';

export const CONVERSATION_CREATE_START = 'CONVERSATION_CREATE_START';
export const CONVERSATION_CREATE_SUCCESS = 'CONVERSATION_CREATE_SUCCESS';
export const CONVERSATION_CREATE_ERROR = 'CONVERSATION_CREATE_ERROR';

export const CONVERSATION_FETCH_START = 'CONVERSATION_FETCH_START';
export const CONVERSATION_FETCH_SUCCESS = 'CONVERSATION_FETCH_SUCCESS';
export const CONVERSATION_FETCH_ERROR = 'CONVERSATION_FETCH_ERROR';

export const CONVERSATION_DELETE_START = 'CONVERSATION_DELETE_START';
export const CONVERSATION_DELETE_SUCCESS = 'CONVERSATION_DELETE_SUCCESS';
export const CONVERSATION_DELETE_ERROR = 'CONVERSATION_DELETE_ERROR';

export const CONVERSATION_UPDATE_START = 'CONVERSATION_UPDATE_START';
export const CONVERSATION_UPDATE_SUCCESS = 'CONVERSATION_UPDATE_SUCCESS';
export const CONVERSATION_UPDATE_ERROR = 'CONVERSATION_UPDATE_ERROR';

export const CONVERSATION_ADD_COMMENT_START = 'CONVERSATION_ADD_COMMENT_START';
export const CONVERSATION_ADD_COMMENT_SUCCESS = 'CONVERSATION_ADD_COMMENT_SUCCESS';
export const CONVERSATION_ADD_COMMENT_ERROR = 'CONVERSATION_ADD_COMMENT_ERROR';

export const CONVERSATION_REMOVE_COMMENT_START = 'CONVERSATION_REMOVE_COMMENT_START';
export const CONVERSATION_REMOVE_COMMENT_SUCCESS = 'CONVERSATION_REMOVE_COMMENT_SUCCESS';
export const CONVERSATION_REMOVE_COMMENT_ERROR = 'CONVERSATION_REMOVE_COMMENT_ERROR';

export const CONVERSATION_EDIT_COMMENT_START = 'CONVERSATION_EDIT_COMMENT_START';
export const CONVERSATION_EDIT_COMMENT_SUCCESS = 'CONVERSATION_EDIT_COMMENT_SUCCESS';
export const CONVERSATION_EDIT_COMMENT_ERROR = 'CONVERSATION_EDIT_COMMENT_ERROR';
