import { schema } from 'normalizr';

const Label = new schema.Entity(
  'Label',
  {},
  {
    idAttribute: 'ID'
  }
);

const LabelList = [Label];

const Tag = new schema.Entity(
  'Tag',
  {},
  {
    idAttribute: 'ID'
  }
);

const TagList = [Tag];

const Genre = new schema.Entity(
  'Genre',
  {},
  {
    idAttribute: 'ID'
  }
);

const GenreList = [Genre];

// eslint-disable-next-line simple-import-sort/exports
export {
  Label, LabelList, Tag, TagList, Genre, GenreList
};
