import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { TEAM_MEDIA_TYPE_ENUM } from 'shared/constants/enumConstants';
import { filterEntitiesById } from 'selectors/genericSelectors';
import {
  getFileEntities,
  getMediaEntities,
  getTeamByIDState,
  getTeamEntities,
  getTeamsByUserIDState,
  getTeamSkillEntities,
  getUserSkillEntities,
  getWorkPreferencesEntities
} from 'selectors/stateSelectors';
import { getUserId } from 'selectors/userSelectors';

const { AVATAR, BANNER } = TEAM_MEDIA_TYPE_ENUM;

export function getTeamFilesAndMedia(team, fileEntities, mediaEntities) {
  const teamMediaFiles = Object.values(fileEntities).filter(file => {
    const { TeamxFile, Media } = file;
    if (TeamxFile && TeamxFile.TeamID === team.ID && Media) {
      return file;
    }
  });

  const teamFiles = Object.values(fileEntities).filter(file => {
    const { TeamxFile, Media } = file;
    if (TeamxFile && TeamxFile.TeamID === team.ID && !Media) {
      return file;
    }
  }) || [];

  const teamMediaIDs = team.Media || [];
  const teamMedia = compact(teamMediaIDs.reduce((entities, item) => entities.concat(mediaEntities[item]), []));

  const teamAvatar = teamMedia.find(item => {
    const { TeamxMedia } = item;

    if (!TeamxMedia) {
      return false;
    }

    return TeamxMedia.Type === AVATAR;
  }) || teamMediaFiles.find(file => {
    const { MediaID } = file;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === AVATAR && media.ID === MediaID);
  }) || {};

  const teamBanner = teamMedia.find(item => {
    const { TeamxMedia } = item;

    if (!TeamxMedia) {
      return false;
    }

    return TeamxMedia.Type === BANNER;
  }) || teamMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === BANNER && media.ID === MediaID);
  }) || {};

  return {
    teamAvatar,
    teamBanner,
    teamFiles
  };
}

export const getCurrentUserTeamsWithMedia = createSelector(
  (state, props) => props.UserID || getUserId(state),
  getTeamsByUserIDState,
  getTeamEntities,
  getFileEntities,
  getMediaEntities,
  (userId, teamsByUserID, teamEntities, fileEntities, mediaEntities) => {
    const userTeamsState = teamsByUserID[userId] || {};
    const userTeamIDs = userTeamsState.items || [];
    const Teams = userTeamIDs.reduce((teams, id) => teams.concat(teamEntities[id]), []);
    const { error, isLoading } = userTeamsState;

    return {
      error,
      isLoading,
      Teams: Teams.map(team => ({
        ...team,
        ...getTeamFilesAndMedia(team, fileEntities, mediaEntities),
      })),
      userId
    };
  }
);

// if ProjectID is passed to the connected component, will extract and use this project in the selector
const getTeamByIDFilter = (state, props) => {
  const { TeamID, match } = props;
  if (props && TeamID) {
    return getTeamByIDState(state)[TeamID];
  } if (match && match.params.id) {
    return getTeamByIDState(state)[match.params.id];
  }
  return {};
};

export const getBaseTeam = createSelector(
  getTeamByIDFilter,
  getTeamEntities,
  getTeamSkillEntities,
  getUserSkillEntities,
  (teamByIDState = {}, teamEntities, teamSkillEntities, userSkillEntities) => {
    const Team = teamEntities[teamByIDState.teamId] || {
      Members: []
    };

    const UserSkills = (Team.Members || []).reduce((accum, member) => {
      ((member && member.Skills) || []).forEach(memberSkill => {
        const { QualityTime = 0, XP = 0, counter = 0 } = accum[memberSkill.Skill.ID] || {};
        memberSkill.ID = `User-${memberSkill.ID}`;
        memberSkill.XP += XP;
        memberSkill.counter = counter + 1;
        memberSkill.QualityTime += QualityTime;
        accum[memberSkill.SkillID] = memberSkill;
      });
      return accum;
    }, {});

    const TeamSkills = Object.values(teamSkillEntities)
      .filter(skill => skill.TeamID === Team.ID)
      .reduce((accum, teamSkill) => {
        if (UserSkills[teamSkill.SkillID]) {
          const { UserID, counter } = UserSkills[teamSkill.SkillID];
          teamSkill.counter = counter;
          teamSkill.UserID = UserID;
        }
        accum[teamSkill.SkillID] = teamSkill;
        return accum;
      }, {});

    // sort skills
    const Skills = Object.values({ ...UserSkills, ...TeamSkills })
      .sort((a, b) => b.XP - a.XP);

    return {
      error: teamByIDState.error,
      isLoading: teamByIDState.isLoading,
      Team: {
        ...Team,
        Skills
      }
    };
  }
);

export const getTeamWithMedia = createSelector(
  getBaseTeam,
  getFileEntities,
  getMediaEntities,
  (baseTeam, fileEntities, mediaEntities) => ({
    ...baseTeam,
    ...getTeamFilesAndMedia(baseTeam.Team, fileEntities, mediaEntities)
  })
);

export const getTeamWithMediaAndWorkPreferences = createSelector(
  getTeamWithMedia,
  getWorkPreferencesEntities,
  (teamWithMedia, workPreferencesEntities) => {
    const WorkPreferences = filterEntitiesById(teamWithMedia.Team.WorkPreferences, workPreferencesEntities);

    return {
      ...teamWithMedia,
      WorkPreferences
    };
  }
);
