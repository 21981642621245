import { merge, isEmpty } from 'lodash';
import * as scheduleEventConstants from 'constants/scheduleEventConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES, SCHEDULE_EVENT_TYPE_ENUM } from 'shared/constants/enumConstants';
import { getUserId } from 'selectors/userSelectors';

const { SCHEDULE_EVENT, JOB, JOB_APPLICATION } = ENTITY_TYPES;
const { GOOGLE_EVENT } = SCHEDULE_EVENT_TYPE_ENUM;

export default function entities(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    const {
      entityId, scheduleEventId
    } = action;
    switch (type) {
      case scheduleEventConstants.SCHEDULE_EVENT_REMOVE_BLOCK_TIME._SUCCESS: {
        const newState = {
          ...state
        };
        delete newState[SCHEDULE_EVENT][scheduleEventId];
        return newState;
      }
      case scheduleEventConstants.REQUEST_SCHEDULE_TIME._SUCCESS: {
        const {
          jobId,
          applicantId,
          scheduleEventId,
          prevScheduleEventId
        } = action;
        const newState = { ...state };
        const scheduleEvent = state[SCHEDULE_EVENT][scheduleEventId];

        if (!isEmpty(scheduleEvent)) {
          if (prevScheduleEventId) {
            delete newState[SCHEDULE_EVENT][prevScheduleEventId];
          }

          if (jobId) {
            const { Applications = [] } = newState[JOB][jobId];

            Applications.forEach(appId => {
              const { Applicant, UserID } = newState[JOB_APPLICATION][appId];

              if (!isEmpty(Applicant) && applicantId === UserID) {
                const { ScheduleEvents = [] } = Applicant;

                delete newState[SCHEDULE_EVENT][scheduleEventId];
                if (ScheduleEvents.length) {
                  newState[JOB_APPLICATION][appId].Applicant.ScheduleEvents = [
                    ...ScheduleEvents, scheduleEvent
                  ].filter(({ ID }) => ID === scheduleEventId);
                } else {
                  newState[JOB_APPLICATION][appId].Applicant.ScheduleEvents = [scheduleEvent];
                }
              }
            });
          }
        }

        return newState;
      }
      case scheduleEventConstants.CREATE_GOOGLE_EVENT._SUCCESS: {
        const { scheduleEventId, scheduleEvent, userId } = action;
        const newState = { ...state };

        if (scheduleEventId) {
          if (!isEmpty(newState[SCHEDULE_EVENT][scheduleEventId])) {
            newState[SCHEDULE_EVENT][scheduleEventId].Type = GOOGLE_EVENT;
          }

          const applications = newState[JOB_APPLICATION] || {};
          Object.values(applications).filter(application => {
            const {
              Applicant,
              UserID: applicantId,
              JobID
            } = application;
            const { UserID: providerId } = newState[JOB][JobID];

            return application && !isEmpty(Applicant) && (userId === applicantId || userId === providerId);
          })
            .forEach(application => {
              const {
                ID: applicationId,
                Applicant
              } = application;
              const { ScheduleEvents } = Applicant;

              newState[JOB_APPLICATION][applicationId].Applicant.ScheduleEvents = ScheduleEvents.map(stateScheduleEvent => {
                const { ID } = stateScheduleEvent;
                if (ID === scheduleEventId) {
                  return {
                    ...scheduleEvent,
                    Type: GOOGLE_EVENT
                  };
                }
                return stateScheduleEvent;
              });
            });
        }
        return newState;
      }
      case scheduleEventConstants.REMOVE_SCHEDULE_EVENT._SUCCESS: {
        const { scheduleEventId, userId } = action;
        const newState = { ...state };

        if (scheduleEventId) {
          delete newState[SCHEDULE_EVENT][scheduleEventId];

          const applications = newState[JOB_APPLICATION] || {};
          Object.values(applications).filter(application => {
            const {
              Applicant,
              UserID: applicantId,
              JobID
            } = application;
            const { UserID: providerId } = newState[JOB][JobID];

            return application && !isEmpty(Applicant) && (userId === applicantId || userId === providerId);
          })
            .forEach(application => {
              const {
                ID: appId,
                Applicant
              } = application;
              const { ScheduleEvents } = Applicant;

              newState[JOB_APPLICATION][appId].Applicant.ScheduleEvents = ScheduleEvents.filter(({ ID }) => ID !== scheduleEventId);
            });
        }
        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }

  return state;
}
