import { capitalize, compact, map } from 'lodash';

import { WORK_EXPERIENCE_MEDIA_TYPE_ENUM } from 'shared/constants/enumConstants';

const { ASSET, AVATAR, BANNER } = WORK_EXPERIENCE_MEDIA_TYPE_ENUM;

const titleCase = str => map(str.split(' '), capitalize).join(' ');

export function cleanRole(role = '', allowExtensions = false) {
  // only allow roles that match any valid characters (allow alphanumeric, /,++, #, and spaces; as we we can't be sure what the role will be if it has special characters)
  if (!/[^\w/++#\s]/g.test(role)) {
    let cleanedRole = role.trim();
    // Check if the string contains the word "at" or "on" and remove everything after it
    if (!allowExtensions && (cleanedRole.includes(' at') || cleanedRole.includes(' on'))) {
      // replace everything after the word " at" or " on" with nothing
      cleanedRole = cleanedRole.replace(/\s(at|on\b).*/g, '').trim();
    }

    // title case the role
    cleanedRole = titleCase(cleanedRole);

    // be sure no whitespace is left
    cleanedRole = cleanedRole.replace(/\s+/g, ' ').trim();

    return cleanedRole;
  }

  return false;
}

export function getWorkExperienceFilesAndMedia(workExperience = {}, fileEntities = {}, mediaEntities = {}) {
  const workExperienceMediaFiles = Object.values(fileEntities).filter(file => {
    const { WorkExperiencexFile, Media } = file;
    if (WorkExperiencexFile && WorkExperiencexFile.WorkExperienceID === workExperience.ID && Media) {
      return file;
    }
    return false;
  });

  const workExperienceFiles = Object.values(fileEntities).filter(file => {
    const { WorkExperiencexFile, Media } = file;
    if (WorkExperiencexFile && WorkExperiencexFile.WorkExperienceID === workExperience.ID && !Media) {
      return file;
    }
  }) || [];

  const workExperienceMediaIDs = workExperience.Media || [];
  const workExperienceMedia = compact(workExperienceMediaIDs.reduce((entities, item) => entities.concat(mediaEntities[item]), []));

  const workExperienceAvatar = workExperienceMedia.find(item => {
    const { WorkExperiencexMedia } = item;

    if (!WorkExperiencexMedia) {
      return false;
    }

    return WorkExperiencexMedia.Type === AVATAR;
  }) || workExperienceMediaFiles.find(file => {
    const { MediaID } = file;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === AVATAR && media.ID === MediaID);
  }) || {};

  const workExperienceBanner = workExperienceMedia.find(item => {
    const { WorkExperiencexMedia } = item;

    if (!WorkExperiencexMedia) {
      return false;
    }

    return WorkExperiencexMedia.Type === BANNER;
  }) || workExperienceMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === BANNER && media.ID === MediaID);
  }) || {};

  // assets refer to all files without media, and files with media that are typed as ASSET
  const workExperienceAssets = [
    ...(workExperienceMedia || []).filter(media => {
      const { WorkExperiencexMedia } = media;

      if (WorkExperiencexMedia && WorkExperiencexMedia.Type === ASSET) {
        return media;
      }
      return false;
    }),

    ...(workExperienceMediaFiles || []).filter(file => {
      const { MediaID } = file;
      return MediaID && Object.values(mediaEntities).find(media => media.Type === ASSET && media.ID === MediaID);
    }),

    ...workExperienceFiles
  ];

  return {
    workExperienceAssets,
    workExperienceAvatar,
    workExperienceBanner
  };
}
