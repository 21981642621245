import * as endpoints from 'shared/web/constants/endpointConstants';

// TODO remove these and fix all references
// backwards compat tech debt
export const BEACON = '/beacon/:id';
export const GIG = '/gig/:id';
export const GIG_CREATE = '/gig/create';
export const JOB = '/job/:id';
export const JOB_CLAIM = '/job/:id/claim';
export const INVOICE = '/invoice/:id';
export const NOTIFICATIONS_UNSUBSCRIBE = '/notifications/unsubscribe';
export const ROLODEX = '/rolodex';
export const TALENT = '/talent';
export const USER_SCHEDULE = `/user/:id/schedule`;
export const USER_VOUCH_REQUESTS = `/user/:id/vouches`;
export const USER_PROFILE = '/user/:id/profile';

export default {
  BEACON: `${endpoints.BEACON_ENDPOINT}/:id`,
  BEACON_CREATE: `${endpoints.BEACON_ENDPOINT}/`,
  BEACON_SCHEDULE: `${endpoints.BEACON_ENDPOINT}/:id/schedule`,
  BEACON_SETTINGS: `${endpoints.BEACON_ENDPOINT}/:id/settings`,
  BEACON_SETTINGS_BILLING: `${endpoints.BEACON_ENDPOINT}/:id/settings/billing`,
  BEACON_SETTINGS_EMAILS: `${endpoints.BEACON_ENDPOINT}/:id/settings/emails`,
  BEACON_SETTINGS_TRANSACTIONS: `${endpoints.BEACON_ENDPOINT}/:id/settings/transactions`,
  BEACON_TALENT: `${endpoints.BEACON_ENDPOINT}/:id/talent`,
  BEACONS: `${endpoints.BEACONS_ENDPOINT}`,
  CONVERT_CURRENCY_ENDPOINT: '/convert-currency',
  DASHBOARD: '/dashboard',
  ESTIMATE: '/estimate',
  EVENT: `${endpoints.EVENT_ENDPOINT}/:id`,
  EVENT_SUBMISSION: `${endpoints.EVENT_ENDPOINT}/:id/submissions/submission/:submissionId`,
  EVENT_SUBMISSIONS: `${endpoints.EVENT_ENDPOINT}/:id/submissions`,
  EVENT_UPDATE: `${endpoints.EVENT_ENDPOINT}/:id/updates/update/:updateId`,
  EVENT_UPDATES: `${endpoints.EVENT_ENDPOINT}/:id/updates`,
  EVENTS: endpoints.EVENTS_ENDPOINT,
  EVENTS_DISCOVER: `${endpoints.EVENTS_ENDPOINT}/discover`,
  EXTENSION: `${endpoints.EXTENSION_ENDPOINT}`,
  FORGOT: endpoints.FORGOT_ENDPOINT,
  GIG: `${endpoints.GIG_ENDPOINT}/:id`,
  GIG_CREATE: `${endpoints.GIG_ENDPOINT}/create`,
  GIG_OVERVIEW: `${endpoints.GIG_ENDPOINT}/:id/overview`,
  GIG_PROPOSAL_CREATE: `${endpoints.GIG_ENDPOINT}/:id/proposals/proposal`,
  GIG_PROPOSAL_RATING: `${endpoints.GIG_ENDPOINT}/:id/proposal/:proposalId/rating`,
  GIG_PROPOSALS: `${endpoints.GIG_ENDPOINT}/:id/proposals`,
  GIG_PROPOSALS_CONVERSATION: `${endpoints.GIG_ENDPOINT}/:id/proposals/conversation/:conversationId`,
  GIG_PROPOSALS_PROPOSAL: `${endpoints.GIG_ENDPOINT}/:id/proposals/proposal/:proposalId`,
  GIG_PROPOSALS_PROPOSAL_CHECKOUT: `${endpoints.GIG_ENDPOINT}/:id/proposals/proposal/:proposalId/checkout`,
  GIG_SETTINGS: `${endpoints.GIG_ENDPOINT}/:id/settings`,
  GIGS: endpoints.GIGS_ENDPOINT,
  HIRE_DASHBOARD: '/dashboard/hire',
  INTEGRATION: `${endpoints.INTEGRATION_ENDPOINT}/:id`,
  INTEGRATIONS: `${endpoints.INTEGRATIONS_ENDPOINT}`,
  INVOICE: `${endpoints.INVOICE_ENDPOINT}/:id`,
  JOB: `${endpoints.JOB_ENDPOINT}/:id`,
  JOB_CLAIM: `${endpoints.JOB_ENDPOINT}/:id/claim`,
  JOB_OVERVIEW: `${endpoints.JOB_ENDPOINT}/:id/overview`,
  LOGIN: endpoints.LOGIN_ENDPOINT,
  LOGOUT: endpoints.LOGOUT_ENDPOINT,
  NOT_FOUND: '/not-found',
  NOTIFICATIONS_UNSUBSCRIBE: '/notifications/unsubscribe',
  ONBOARDING: endpoints.ONBOARDING_ENDPOINT,
  ORGANIZATION: `${endpoints.ORGANIZATION_ENDPOINT}/:id`,
  ORGANIZATION_PROFILE: `${endpoints.ORGANIZATION_ENDPOINT}/:id/profile`,
  ORGANIZATION_SETTINGS_BILLING: `${endpoints.ORGANIZATION_ENDPOINT}/:id/settings/billing`,
  ORGANIZATION_SETTINGS_CREDITS: `${endpoints.ORGANIZATION_ENDPOINT}/:id/settings/credits`,
  ORGANIZATION_SETTINGS_ID: `${endpoints.ORGANIZATION_ENDPOINT}/:id/settings`,
  ORGANIZATION_SETTINGS_LINKS: `${endpoints.ORGANIZATION_ENDPOINT}/:id/settings/links`,
  ORGANIZATION_SETTINGS_PROFILE: `${endpoints.ORGANIZATION_ENDPOINT}/:id/settings/profile`,
  ORGANIZATIONS: endpoints.ORGANIZATIONS_ENDPOINT,
  PRISM: `${endpoints.PRISM_ENDPOINT}/:id`,
  PRISMS: `${endpoints.PRISMS_ENDPOINT}`,
  PROJECT: `${endpoints.PROJECT_ENDPOINT}/:id`,
  PROJECT_BUILD: `${endpoints.PROJECT_ENDPOINT}/:id/build`,
  PROJECT_COMMUNITY: `${endpoints.PROJECT_ENDPOINT}/:id/community`,
  PROJECT_CREATE: `${endpoints.PROJECT_ENDPOINT}/create`,
  PROJECT_OVERVIEW: `${endpoints.PROJECT_ENDPOINT}/:id/overview`,
  PROJECT_SETTINGS: `${endpoints.PROJECT_ENDPOINT}/:id/settings`,
  PROJECT_WORK: `${endpoints.PROJECT_ENDPOINT}/:id/work`,
  PROJECTS: endpoints.PROJECTS_ENDPOINT,
  QUEST: endpoints.QUEST_ENDPOINT,
  QUESTS: endpoints.QUESTS_ENDPOINT,
  RECEIPT: `${endpoints.RECEIPT_ENDPOINT}/:id`,
  REGISTER: endpoints.REGISTER_ENDPOINT,
  REGISTER_VIP: endpoints.REGISTER_VIP_ENDPOINT,
  RESET: endpoints.RESET_ENDPOINT,
  ROLODEX: endpoints.ROLODEX_ENDPOINT,
  SIGNAL: endpoints.SIGNAL_ENDPOINT,
  SIGNAL_TALENT: `${endpoints.SIGNAL_ENDPOINT}/:id/talent/:talentId`,
  SKILL: endpoints.SKILL_ENDPOINT,
  SKILLS: endpoints.SKILLS_ENDPOINT,
  TALENT: endpoints.TALENT_ENDPOINT,
  TALENT_DASHBOARD: '/dashboard/talent',
  TEAM: `${endpoints.TEAM_ENDPOINT}/:id`,
  TEAM_PROFILE: `${endpoints.TEAM_ENDPOINT}/:id/profile`,
  TEAM_SCHEDULE: `${endpoints.TEAM_ENDPOINT}/:id/schedule`,
  TEAM_SETTINGS_BILLING: `${endpoints.TEAM_ENDPOINT}/:id/settings/billing`,
  TEAM_SETTINGS_ID: `${endpoints.TEAM_ENDPOINT}/:id/settings`,
  TEAM_SETTINGS_PAYOUT_PROFILE: `${endpoints.TEAM_ENDPOINT}/:id/settings/payout`,
  TEAM_SETTINGS_PROFILE: `${endpoints.TEAM_ENDPOINT}/:id/settings/profile`,
  TEAMS: endpoints.TEAMS_ENDPOINT,
  USER: `${endpoints.USER_ENDPOINT}/:id`,
  USER_ANALYSIS: `${endpoints.USER_ENDPOINT}/:id/analysis`,
  USER_ASSESSMENTS: `${endpoints.USER_ENDPOINT}/:id/personality/assessments`,
  USER_ASSESSMENTS_QUIZ: `${endpoints.USER_ENDPOINT}/:id/personality/assessments/quiz/:quizId`,
  USER_CALENDAR: `${endpoints.USER_ENDPOINT}/:id/calendar`,
  USER_EXPERIENCE: `${endpoints.USER_ENDPOINT}/:id/experience/:workExperienceId`,
  USER_EXPERIENCES: `${endpoints.USER_ENDPOINT}/:id/experience`,
  USER_GAMING_PROFILE: `${endpoints.USER_ENDPOINT}/:id/personality/gaming-profile`,
  USER_PERSONALITY: `${endpoints.USER_ENDPOINT}/:id/personality`,
  USER_PROFILE: `${endpoints.USER_ENDPOINT}/:id${endpoints.PROFILE_ENDPOINT}`,
  USER_PROFILE_PREVIEW: `${endpoints.USER_ENDPOINT}/:id${endpoints.PROFILE_ENDPOINT}/preview`,
  USER_PROFILE_SHORTCUT: `${endpoints.USER_ENDPOINT}/profile`,
  USER_REFERRALS: `${endpoints.USER_ENDPOINT}/:id/referrals`,
  USER_SCHEDULE: `${endpoints.USER_ENDPOINT}/:id/schedule`,
  USER_SETTINGS: `${endpoints.USER_ENDPOINT}/settings`,
  USER_SETTINGS_CONNECTED_ACCOUNTS: `${endpoints.USER_ENDPOINT}/:id/settings/connected-accounts`,
  USER_SETTINGS_HIRE_PREFERENCES: `${endpoints.USER_ENDPOINT}/:id/settings/hire/preferences`,
  USER_SETTINGS_ID: `${endpoints.USER_ENDPOINT}/:id/settings`,
  USER_SETTINGS_IDENTITY_VERIFICATION: `${endpoints.USER_ENDPOINT}/:id/settings/identity-verification`,
  USER_SETTINGS_INVOICES: `${endpoints.USER_ENDPOINT}/:id/settings/invoices`,
  USER_SETTINGS_PAYMENTS: `${endpoints.USER_ENDPOINT}/:id/settings/payments`,
  USER_SETTINGS_PROFILE: `${endpoints.USER_ENDPOINT}/:id/settings/profile`,
  USER_SETTINGS_SUBSCRIPTION: `${endpoints.USER_ENDPOINT}/:id/settings/subscription`,
  USER_SETTINGS_TRANSACTIONS: `${endpoints.USER_ENDPOINT}/:id/settings/transactions`,
  USER_SETTINGS_WORK_ELIGIBILITY: `${endpoints.USER_ENDPOINT}/:id/settings/work/work-eligibility`,
  USER_SETTINGS_WORK_PAYOUT: `${endpoints.USER_ENDPOINT}/:id/settings/work/payout`,
  USER_SETTINGS_WORK_PREFERENCES: `${endpoints.USER_ENDPOINT}/:id/settings/work/preferences`,
  USER_STUB: `${endpoints.USER_STUB_ENDPOINT}/:id`,
  USER_VOUCH_REQUESTS: `${endpoints.USER_ENDPOINT}/:id/vouches`,
  USER_WORK: `${endpoints.USER_ENDPOINT}/:id/work`,
  USER_WORK_PREFERENCES: `${endpoints.USER_ENDPOINT}/:id/settings/work/preferences`,
  USER_WORK_STATS: `${endpoints.USER_ENDPOINT}/:id/work/stats`,
  VERIFY: endpoints.VERIFY_ENDPOINT,
  VERIFY_EMAIL_CHANGE: `${endpoints.VERIFY_ENDPOINT}/email-change/:code`,
  VERIFY_USER_REGISTRATION: `${endpoints.VERIFY_ENDPOINT}/registration/:code`,
  WALLET: endpoints.WALLET_ENDPOINT,
  WORK: endpoints.WORK_ENDPOINT,
  WORK_GIGS: `${endpoints.WORK_ENDPOINT}/gigs`,
  WORK_JOBS: `${endpoints.WORK_ENDPOINT}/jobs`,
  WORK_OFFERS: `${endpoints.WORK_ENDPOINT}/offers`,
  WORK_OVERVIEW: `${endpoints.WORK_ENDPOINT}/overview`
};
