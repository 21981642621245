import { schema } from 'normalizr';
import { FileList } from 'schemas/index';

const Deliverable = new schema.Entity(
  'Deliverable',
  {
    Files: FileList
  },
  {
    idAttribute: 'ID'
  }
);

const DeliverableList = [Deliverable];

export { Deliverable, DeliverableList };
