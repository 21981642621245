import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/extensions */
import { ReactComponent as Loader } from 'shared/images/loader--saturn.svg';
import './Loading.less';

export default class Loading extends Component {
  static propTypes = {
    fill: PropTypes.bool
  }

  static defaultProps = {
    fill: true
  }

  state = {
    delay: 100,
    timeout: 100,
    visible: false
  };

  componentDidMount() {
    const { delay } = this.state;
    this.visibilityTimer = setTimeout(() => {
      this.setState({ visible: true });
    }, delay);
  }

  componentWillUnmount() {
    if (this.visibilityTimer) {
      clearTimeout(this.visibilityTimer);
    }
  }

  render() {
    const { timeout, visible } = this.state;
    const { fill } = this.props;

    const loaderClasses = classNames('loader', {
      'loader--fill': fill
    });

    return (
      <CSSTransition
        in={visible}
        timeout={timeout}
        classNames="loader-animation"
      >
        <div className={loaderClasses}>
          <Loader />
        </div>
      </CSSTransition>
    );
  }
}
