import mergeWith from 'lodash/mergeWith';
import { mergeWithArray } from 'utils/dataUtils';

import {
  TEAM_CREATE,
  TEAM_FETCH,
  TEAMS_FETCH
} from 'constants/teamConstants';

/**
 * Returns a new 'TeamsByUserId' state
 * @param  {Object}  [state={}] The teams or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with team data
 */
export function teamsByUserId(state = {}, action) {
  const { payload, type, userId } = action;

  switch (type) {
    case TEAMS_FETCH._START:
    case TEAMS_FETCH._ERROR:
      return {
        ...state,
        [userId]: {
          ...action,
          items: []
        }
      };
    case TEAMS_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [userId]: {
            ...action,
            items: payload.result
          }
        };
      }
      return state;
    case TEAM_CREATE._SUCCESS:
      if (payload && payload.result) {
        return mergeWith({}, state, {
          [userId]: {
            isLoading: false,
            items: [payload.result]
          }
        }, mergeWithArray);
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'CreateTeam' state
 * @param  {Object}  [state={}] The created project
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function createTeam(state = {}, action) {
  const { teamId, type } = action;

  switch (type) {
    case TEAM_CREATE._START:
    case TEAM_CREATE._ERROR: {
      return { ...action };
    }
    case TEAM_CREATE._SUCCESS:
      return {
        teamId
      };
    default:
      return state;
  }
}

/**
 * Returns a new 'ProjectsById' state
 * @param  {Object}  [state={}] The project or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function teamById(state = {}, action) {
  const { payload, teamId, type } = action;

  switch (type) {
    case TEAM_FETCH._START:
    case TEAM_FETCH._ERROR:
      return {
        ...state,
        [teamId]: action
      };
    case TEAM_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [teamId]: action
        };
      }
      return state;
    default:
      return state;
  }
}
