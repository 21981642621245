import { arrayToObject } from '../utils/arrayUtils';

export const ACHIEVEMENT_TYPE_ENUM = arrayToObject([
  'USER',
  'PROJECT',
  'TEAM',
  'ORGANIZATION'
]);

export const AVATAR_BEHAVIOR_ENUM = arrayToObject([
  'SHOW',
  'TOGGLE',
  'HIDE'
]);

export const BEACON_ADJUST_REASON_ENUM = arrayToObject([
  'PENDING_CANDIDATE',
  'SOURCING'
]);

export const BEACON_CADENCE_ENUM = arrayToObject([
  'WEEKLY',
  'MONTHLY'
]);

export const BEACON_STATUS_ENUM = arrayToObject([
  'PAUSED',
  'SYSTEM_PAUSED',
  'SOURCING',
  'HIRED'
]);

export const BEACON_ACTIVE_SOURCING_TYPES_ENUM = arrayToObject([
  'MANUAL',
  'ADS',
  'ACTIVE_REFERRALS',
  'REFERRALS'
]);

export const BEACON_APPROVAL_STATUS_ENUM = arrayToObject([
  'APPROVED',
  'PENDING',
  'REJECTED'
]);

export const BEACON_BILLING_TYPE_ENUM = arrayToObject([
  'INVOICE',
  'INDIVIDUAL'
]);

export const BEACON_PAUSE_REASON_ENUM = arrayToObject([
  'TEMPORARY_PAUSE',
  'LOW_QUALITY_MATCHES',
  'POOR_EXPERIENCE',
  'HIRED_OUTSIDE_OF_RUPIE',
  'PRICE_TOO_HIGH',
  'NOT_ENOUGH_TALENT_SEEN',
  'NEED_TO_MAKE_CHANGES',
  'OTHER_REASON',
  'USER_CREATED_BEACON',
  'RUPIE_CREATED_BEACON',
  'UNCONFIRMED_BEACON',
  'PENDING_APPROVAL',
  'REJECTED_CHANGES',
  'UNREVIEWED_TALENT'
]);

export const BILLING_PROFILE_TYPE = arrayToObject([
  'STRIPE',
  'BRAINTREE',
  'BLUESNAP'
]);

export const BILLING_SUBSCRIPTION_PLAN_STATUS = arrayToObject([
  'ACTIVE',
  'INACTIVE'
]);

export const BILLING_SUBSCRIPTION_STATUS = arrayToObject([
  'DRAFT',
  'ACTIVE',
  'CANCELED'
]);

export const BILLING_SUBSCRIPTION_PLAN_TYPES = arrayToObject([
  'FREE',
  'STUDIO',
  'RECRUITER'
]);

export const BILLING_SUBSCRIPTION_PLAN_CHARGE_FREQUENCY = arrayToObject([
  'MONTHLY',
  'ANNUALLY',
  'WEEKLY'
]);

export const BILLING_VENDOR_PAYOUT_STATUS = arrayToObject([
  'INCOMPLETE',
  'PENDING',
  'APPROVED',
  'DECLINED',
  'SUSPENDED'
]);

export const BILLING_VENDOR_STATUS = arrayToObject([
  'ACTIVE',
  'INCOMPLETE'
]);

export const COMMENT_TYPE_ENUM = arrayToObject([
  'SYSTEM',
  'USER'
]);

export const CONVERSATION_STATE_ENUM = arrayToObject([
  'OPEN',
  'LOCKED'
]);

export const CONVERSATION_TYPE_ENUM = arrayToObject([
  'GIG',
  'TASK',
  'EVENT',
  'MILESTONE',
  'PROJECT',
  'DM',
  'GROUP',
  'NOTES'
]);

export const DELIVERABLE_MEDIA_TYPE_ENUM = arrayToObject([
  'ASSET',
  'AVATAR',
  'BANNER',
  'GALLERY'
]);

export const DELIVERABLE_STATUS_ENUM = arrayToObject([
  'PENDING',
  'REJECTED'
]);

// Mapping that corresponds to the names of entities in the DB
export const ENTITY_TYPE_ENUM = {
  BEACON: 'Beacon',
  BILLING_PROFILE: 'BillingProfile',
  COMMENT: 'Comment',
  CONVERSATION: 'Conversation',
  CREDIT: 'Credit',
  DELIVERABLE: 'Deliverable',
  EVENT: 'Event',
  EVENT_SUBMISSION: 'EventSubmission',
  EVENT_UPDATE: 'EventUpdate',
  FEATURE: 'Feature',
  FILE: 'File',
  GAME: 'Game',
  GAMES: 'Games',
  GENRE: 'Genre',
  GENRES: 'Genres',
  GIG: 'Gig',
  INTEGRATION: 'Integration',
  INTERNAL_RECORD: 'InternalRecord',
  INVITATION: 'Invitation',
  JOB: 'Job',
  JOB_APPLICATION: 'JobApplication',
  LABEL: 'Label',
  LABELS: 'Labels',
  MEDIA: 'Media',
  MEMBER: 'Member',
  MILESTONE: 'Milestone',
  MILESTONES: 'Milestones',
  NOTIFICATION: 'Notification',
  ORGANIZATION: 'Organization',
  PHASE: 'Phase',
  PHASES: 'Phases',
  PRODUCER: 'Producer',
  PROJECT: 'Project',
  PROPOSAL: 'Proposal',
  QUEST: 'Quest',
  QUESTXUSERXTALENT: 'QuestxUserxTalent',
  QUIZ: 'Quiz',
  REACTION: 'Reaction',
  ROLODEX: 'Rolodex',
  SCHEDULE_EVENT: 'ScheduleEvent',
  SIGNAL: 'Signal',
  SKILL: 'Skill',
  SKILLS: 'Skills',
  SUBSCRIPTION: 'Subscription',
  SUGGESTED_TALENT: 'SuggestedTalent',
  SUGGESTED_TASK: 'SuggestedTask',
  SYSTEM: 'System',
  TAG: 'Tag',
  TAGS: 'Tags',
  TALENT_FILTER: 'TalentFilter',
  TASK: 'Task',
  TEAM: 'Team',
  USER: 'User',
  USER_STUB: 'UserStub',
  USER_WORK_APPLICATION: 'UserWorkApplication',
  VENDOR_PROFILE: 'VendorProfile',
  VOUCH: 'Vouch',
  WORK_APPLICATION: 'WorkApplication',
  WORK_APPLICATIONS: 'WorkApplications',
  WORK_EXPERIENCE: 'WorkExperience',
  WORK_PREFERENCES: 'WorkPreferences'
};

export const EVENT_MEDIA_TYPE_ENUM = arrayToObject([
  'AVATAR',
  'BANNER',
  'ASSET'
]);

export const EVENT_TYPE_ENUM = arrayToObject([
  'COMPETITION',
  'EDUCATION'
]);

export const EVENT_STATE_ENUM = arrayToObject([
  'REVIEW',
  'APPROVED',
  'REJECTED'
]);

export const EMPLOYMENT_TYPE_ENUM = arrayToObject([
  'FULL_TIME',
  'PART_TIME',
  'CONTRACT',
  'INTERNSHIP'
]);

export const ENDORSEMENT_TYPE_ENUM = arrayToObject([
  'SYSTEM',
  'USER'
]);

export const FEATURE_MEDIA_TYPE_ENUM = arrayToObject([
  'BANNER',
  'ASSET'
]);

export const FEATURE_STATE_ENUM = arrayToObject([
  'COMPLETE',
  'REFUND',
  'OPEN',
  'PROGRESS',
  'INACTIVE',
  'FUNDED'
]);

export const FILE_TYPE_ENUM = arrayToObject([
  'ARCHIVE',
  'AUDIO',
  'BINARY',
  'DOCUMENT',
  'EXECUTABLE',
  'IMAGE',
  'MODEL_3D',
  'VIDEO'
]);

export const GIG_MEDIA_TYPE_ENUM = arrayToObject([
  'ASSET',
  'AVATAR',
  'BANNER',
  'GALLERY'
]);

export const GIG_STATUS_ENUM = arrayToObject([
  'DRAFT',
  'OPEN',
  'AWAITING',
  'PROGRESS',
  'COMPLETE',
  'CLOSED',
  'FLAGGED',
  'REVIEW'
]);

export const JOB_STATUS_ENUM = arrayToObject([
  'DRAFT',
  'OPEN',
  'INITIAL_CALL',
  'WAITING_FOR_CANDIDATES',
  'INTERVIEWING',
  'PENDING_HIRE',
  'ON_HOLD',
  'FILLED',
  'FLAGGED',
  'CLOSED'
]);

export const GIG_WORK_TYPE_ENUM = arrayToObject([
  'CONTRACT',
  'FULLTIME'
]);

export const GIG_TERMS_STATUS_ENUM = arrayToObject([
  'CONFIRMED',
  'ACCEPTED',
  'REJECTED',
  'AWAITING'
]);

export const INTEGRATIONS_ENUM = { GREENHOUSE: 'Greenhouse' };

export const INVITATION_PARENT_TYPE_ENUM = arrayToObject([
  'ORGANIZATION',
  'QUEST',
  'ROLODEX',
  'TEAM',
  'BEACON'
]);

export const INTERNAL_RECORD_MEDIUM_ENUM = arrayToObject([
  'LINKEDIN',
  'PHONE',
  'EMAIL',
  'SMS',
  'VIDEO',
  'FACEBOOK',
  'TWITTER',
  'OTHER'
]);

export const INTERNAL_RECORD_STATUS_ENUM = arrayToObject([
  'ADDED_TO_BEACON_STUB',
  'ADDED_TO_BEACON',
  'DO_NOT_CONTACT',
  'FULLY_ONBOARDED',
  'FEEDBACK_POSITIVE',
  'FEEDBACK_NEGATIVE',
  'FEEDBACK_NEUTRAL',
  'HAPPILY_EMPLOYED',
  'HIRED_FROM_BEACON',
  'INTERVIEWING',
  'LIFE_EVENT',
  'NOT_QUALIFIED_STUB',
  'PENDING_OUTREACH',
  'PENDING_HIRE',
  'PENDING_REGISTRATION',
  'QUALIFIED_STUB',
  'REACH_OUT_LATER',
  'RECENTLY_HIRED',
  'REGISTERED',
  'REJECTED_FROM_BEACON',
  'SEE_NOTES',
  'STUB_EMAILED',
  'SENT_INVITE',
  'SOURCE_STUB_ADDED',
  'SOURCE_USER_ADDED',
  'VET_QUALIFIED',
  'VET_UNQUALIFIED',
  'OUTREACH_ATTEMPT_VIP_REQUEST',
  'OUTREACH_ATTEMPT_REG_REQUEST',
  'OUTREACH_ATTEMPT_SCREEN_REQUEST',
  'OUTREACH_ATTEMPT_MISSING_DATA_REQUEST',
  'OUTREACH_RESPONSE_DO_NOT_CONTACT',
  'OUTREACH_RESPONSE_INTERESTED',
  'OUTREACH_RESPONSE_NO_RESPONSE',
  'OUTREACH_RESPONSE_NOT_INTERESTED',
  'OUTREACH_RESPONSE_SCHEDULED_SCREEN',
  'OUTREACH_RESPONSE_SCREEN',
  'OUTREACH_RESPONSE_TRY_LATER',
  'TALENT_VIP_REGISTRATION',
  'TALENT_REGISTRATION',
  'TALENT_INTERVIEWING',
  'TALENT_HIRED',
  'TALENT_OPTED_IN',
  'TALENT_OPTED_OUT',
  'SYSTEM_GENERATED_SCREENING_NOTE',
  'REVIEWED_PROFILE_LINKEDIN',
  'REVIEWED_PROFILE_RUPIE',
  'DROPPED_TALENT_APPROVED',
  'DROPPED_TALENT_REJECTED'
]);

export const INTERNAL_RECORD_SENTIMENT_ENUM = arrayToObject([
  'SENTIMENT_NEGATIVE_NOT_INTERESTED_IN_STUDIO',
  'SENTIMENT_NEGATIVE_NOT_GOOD_GENRE',
  'SENTIMENT_NEGATIVE_WORKED_THERE_BEFORE',
  'SENTIMENT_NEGATIVE_BAD_STUDIO_REPUTATION',
  'SENTIMENT_NEGATIVE_PAY',
  'SENTIMENT_NEGATIVE_LOCATION',
  'SENTIMENT_NEGATIVE_NOT_QUALIFIED',
  'SENTIMENT_NEGATIVE_OVER_EXPERIENCED',
  'SENTIMENT_NEGATIVE_CAREER_PATH_MISMATCH',
  'SENTIMENT_NEGATIVE_SIZE_OF_STUDIO'
]);

export const INVITATION_RECEIVER_TYPE_ENUM = arrayToObject([
  'TEAM',
  'USER'
]);

export const INVITATION_TYPE_ENUM = arrayToObject([
  'BEACON',
  'BEACON_INTRODUCTION',
  'GIG',
  'INTERNAL_RECORD',
  'JOB',
  'ORGANIZATION',
  'PROJECT',
  'QUEST',
  'REFERRAL',
  'ROLODEX',
  'TEAM',
  'TALENT_FILTER',
  'VOUCH',
  'SUGGESTED_TALENT'
]);

export const INVITATION_SENDER_TYPE_ENUM = arrayToObject([
  'SYSTEM',
  'USER',
  'INTERNAL'
]);

export const INVOICE_STATUS_ENUM = arrayToObject([
  'UNPAID',
  'PARTIALLY_PAID',
  'PAID',
  'OVER_PAID',
  'OVERDUE',
  'FLAGGED',
  'ROLODEX'
]);

export const JOB_APPLICATION_STATUS_ENUM = arrayToObject([
  'NOT_INTERESTED',
  'INTERESTED',
  'APPLIED',
  'INTERVIEW',
  'PENDING_HIRE',
  'HIRED',
  'REJECTED'
]);

export const JOB_APPLICATION_DECLINE_REASON_ENUM = arrayToObject([
  'NOT_RIGHT_TIME',
  'NOT_INTERESTED_IN_STUDIO',
  'NOT_GOOD_GENRE',
  'WORKED_THERE_BEFORE',
  'BAD_STUDIO_REPUTATION',
  'PAY',
  'LOCATION',
  'NOT_QUALIFIED',
  'OVER_EXPERIENCED',
  'CAREER_PATH_MISMATCH',
  'SIZE_OF_STUDIO',
  'OTHER'
]);

export const JOB_TYPE_ENUM = arrayToObject([
  'FULL_TIME',
  'PART_TIME',
  'CONTRACT'
]);

export const JOB_BENEFIT_DEFAULTS = arrayToObject([
  'HEALTH_INSURANCE',
  'BONUSES',
  '401K',
  'CHILDCARE',
  'RELOCATION_ASSISTANCE',
  'REMOTE_OPTION',
  'PAID_TIME_OFF'
]);

export const LABEL_TYPE_ENUM = arrayToObject([
  'GENRE',
  'SKILL',
  'TAG',
  'LABEL',
  'GENRE_DEFAULT',
  'SKILL_DEFAULT',
  'TAG_DEFAULT',
  'LABEL_DEFAULT'
]);

export const MEDIA_TYPE_ENUM = arrayToObject([
  'ASSET',
  'AVATAR',
  'BANNER',
  'GALLERY',
  'PANORAMA'
]);

export const MILESTONE_STATE_ENUM = arrayToObject([
  'OPEN',
  'PLANNING',
  'CLOSED',
  'PROGRESS',
  'INACTIVE',
  'COMPLETE',
  'FUNDED'
]);

export const MILESTONE_MEDIA_TYPE_ENUM = arrayToObject([
  'BANNER',
  'ASSET'
]);

export const NOTIFICATION_TYPE_ENUM = arrayToObject([
  'INFO',
  'WARNING',
  'MENTION',
  'PROMPT',
  'PROBLEM',
  'SUCCESS'
]);

export const ORG_MEDIA_TYPE_ENUM = arrayToObject([
  'AVATAR',
  'BANNER',
  'ASSET'
]);

export const ROLODEX_TYPE_ENUM = arrayToObject([
  'MASTER',
  'SHARED',
  'BEACON',
  'PRIVATE'
]);

export const ORG_SIZE_ENUM = arrayToObject([
  'INDIE',
  'AA',
  'AAA'
]);

export const PHASE_TYPE_ENUM = arrayToObject([
  'PROJECT',
  'PROJECT_DEFAULT'
]);

export const PROFILE_CHECKLIST_ENUM = arrayToObject([
  'ONBOARDING',
  'WORK_EXPERIENCE',
  'AVATAR',
  'BANNER',
  'ASSESSMENTS',
  'WORK_APPLICATION'
]);

export const PROJECT_MEDIA_TYPE_ENUM = arrayToObject([
  'AVATAR',
  'BANNER',
  'GALLERY',
  'ASSET'
]);

export const PROJECT_STATE_ENUM = arrayToObject([
  'DRAFT',
  'PUBLISHED'
]);

export const PROPOSAL_CREATOR_TYPE_ENUM = arrayToObject([
  'PROVIDER',
  'WORKER'
]);

export const PROPOSAL_LICENSE_TYPE_ENUM = arrayToObject([
  'PROPRIETARY', //  https://en.wikipedia.org/wiki/Proprietary_software
  'FREE', //  http://artlibre.org/licence/lal/en/
  'ATTRIBUTION', //  https://creativecommons.org/licenses/by-sa/4.0/
  'NODERIVS', //  https://creativecommons.org/licenses/by-nd/4.0/
  'NON_COMMERCIAL' //  https://creativecommons.org/licenses/by-nc/4.0/
]);

export const PROPOSAL_PAY_TYPE_ENUM = arrayToObject([
  'FREE',
  'REVSHARE',
  'PAID'
]);

export const PROPOSAL_PRICING_TYPE_ENUM = arrayToObject([
  'DELIVERABLE',
  'TIME'
]);

export const PROPOSAL_STATUS_ENUM = arrayToObject([
  'DRAFT',
  'AWAITING_WORKER',
  'ACCEPTED_INITIAL',
  'AWAITING_PROVIDER',
  'ACCEPTED',
  'REJECTED',
  'COUNTERED', // system assigned after new Counter is made
  'CONFIRMED',
  'NOT_ACCEPTED', // system assigned after checkout
  'IN_PROGRESS', // system assigned after checkout
  'DONE',
  'OVERDUE', // system assigned
  'COMPLETE',
  'CLOSED', // system assigned
  'AWAITING_PAYMENT',
  'HIRING'
]);

export const PROPOSAL_TYPE_ENUM = arrayToObject([
  'INITIAL',
  'COUNTER',
  'REVISION'
]);

export const QUEST_INVITATION_STATUS_ENUM = arrayToObject([
  'PROFILE_AWAITING',
  'PROFILE_UNQUALIFIED',
  'PROFILE_PARTIAL_MATCH',
  'PROFILE_INTERVIEW',
  'INVITED',
  'REGISTERED',
  'AWAITING',
  'REJECTED',
  'QUEUED',
  'INTRODUCED',
  'INTRODUCED_REJECTED',
  'ACCEPTED',
  'PAID'
]);

export const QUEST_SELF_OPT_IN_STATUS_ENUM = arrayToObject([
  'APPROVED',
  'DECLINED',
  'MORE_INFORMATION',
  'PENDING',
  'REJECTED'
]);

export const QUIZ_CATEGORY_ENUM = arrayToObject([
  'ONBOARDING',
  'TECHNICAL',
  'BUSINESS',
  'ART',
  'MUSIC',
  'ANIMATION',
  'VFX',
  'TESTING'
]);

export const REACTION_TYPE_ENUM = arrayToObject([
  'LIKE',
  'HEART',
  'DISLIKE',
  'LAUGH',
  'CRY'
]);

export const ROLODEX_SOURCE_TYPE_ENUM = arrayToObject([
  'JOB',
  'BEACON',
  'TALENT_FILTER',
  'EXTENSION'
]);

export const SCHEDULE_EVENT_METHOD_ENUM = arrayToObject([
  'IN_PERSON',
  'PHONE',
  'VIDEO'
]);

export const SCHEDULE_EVENT_TYPE_ENUM = arrayToObject([
  'GIG',
  'JOB',
  'BLOCKED',
  'GOOGLE_EVENT',
  'PROVIDER_INTERVIEW',
  'APPLICANT_INTERVIEW'
]);

export const SCHEDULE_EVENT_MODAL_STATUS_ENUM = arrayToObject([
  'VIEW',
  'CREATE',
  'UPDATE'
]);

export const STEP_META_SUB_TYPE_ENUM = arrayToObject([
  'DEFAULT',
  'USER_REGISTRATION'
]);

export const STEP_META_TYPE_ENUM = arrayToObject([
  'GIG', 'USER'
]);

export const SIGNAL_TALENT_STATUS_ENUM = arrayToObject([
  'SUGGESTED',
  'INTERESTED',
  'ASSIGNED',
  'TALENT_REJECTED',
  'TALENT_WARMED',
  'INTRODUCED'
]);

export const SUGGESTED_TALENT_STUB_TYPE_ENUM = arrayToObject([
  'SYSTEM',
  'USER'
]);

export const SUGGESTED_TALENT_STUB_SOURCE_ENUM = arrayToObject([
  'ADMIN_COLD_INVITE',
  'ADMIN_TEASE',
  'ADMIN_SOURCED',
  'ALREADY_ON_QUEUE',
  'COPIED',
  'EXTENSION',
  'MACHINE_LEARNING',
  'QUEST_INTRODUCTION',
  'QUEST_SELF_OPT_IN',
  'VOUCH_REFERRAL'
]);

export const SUGGESTED_TALENT_STUB_STATUS_ENUM = arrayToObject([
  'AWAITING',
  'REJECTED',
  'PENDING',
  'APPROVED',
  'DROPPED',
  'APPROVAL_REJECTED',
  'AWAITING_REGISTRATION',
  'AWAITING_REGISTRATION_VIP',
  'AWAITING_SCREEN',
  'ADDED_TO_QUEUE',
  'BACKED_OUT',
  'NEVER_RESPONDED'
]);

export const SUGGESTED_TALENT_STATUS_ENUM = arrayToObject([
  'PENDING',
  'LIKED',
  'DISLIKED',
  'INTERNAL'
]);

export const SUGGESTED_TALENT_URGENCY_FLAGS = arrayToObject([
  'OTHER_OFFER',
  'TOP_TALENT',
  'RARE_TALENT',
  'VERY_INTERESTED'
]);

export const SUGGESTED_TALENT_HIGHLIGHTS = arrayToObject([
  'AAA_TALENT',
  'SHIPPED_GAME',
  'CAN_RELOCATE',
  'REMOTE_OK',
  'GENRE_FAN',
  'GAME_FAN',
  'GENRE_EXPERIENCE',
  'FAST_NOTICE'
]);

export const SUGGESTED_TALENT_FEEDBACK_REACTION = arrayToObject([
  'GOOD',
  'MID',
  'POOR'
]);

export const SUGGESTED_TALENT_HIGHLIGHT_ENUM = arrayToObject([
  'SKILL',
  'LOCATION',
  'REMOTE',
  'PAY',
  'CULTURE',
  'WORKED_GENRE',
  'PLAYED_GENRE'
]);

export const TALENT_FILTER_TALENT_CATEGORY_ENUM = arrayToObject([
  'TECHNICAL',
  'BUSINESS',
  'MUSIC',
  'ART',
  'ANIMATION',
  'VFX',
  'TESTING'
]);

export const TALENT_FILTER_ROLE_TITLE_ENUM = arrayToObject([
  'VP',
  'DIRECTOR',
  'MANAGER',
  'SUPERVISOR',
  'STAFF',
  'LEAD',
  'SENIOR',
  'ASSOCIATE'
]);

export const TALENT_QUEUE_USER_SOURCE_ENUM = arrayToObject([
  'COLD_INVITE',
  'SELF_OPTED_IN',
  'MACHINE_LEARNING',
  'EXISTING_ADDED',
  'SUGGESTED_QUEUED',
  'TEASE_EMAIL'
]);

export const TALENT_QUEUE_USER_STATUS_ENUM = arrayToObject([
  'PENDING',
  'APPROVED',
  'REJECTED',
  'MORE_INFORMATION',
  'AWAITING_UNPAUSE',
  'AWAITING_SCREEN',
  'AWAITING_MISSING_DATA',
  'AWAITING_APPROVAL',
  'BACKED_OUT'
]);

export const TALENT_QUEUE_OPT_IN_STATUS_ENUM = arrayToObject([
  'AWAITING',
  'OPTED_IN',
  'OPTED_OUT',
  'SYSTEM_OPTED_IN'
]);

export const TASK_STATE_ENUM = arrayToObject([
  'COMPLETE',
  'PROGRESS',
  'OPEN',
  'BLOCKED',
  'INACTIVE',
  'HIDDEN'
]);

export const TEAM_MEDIA_TYPE_ENUM = arrayToObject([
  'AVATAR',
  'BANNER',
  'ASSET'
]);

export const TEAM_TYPE_ENUM = arrayToObject([
  'STUDIO',
  'TEAM',
  'RECRUITING'
]);

export const ORG_TYPE_ENUM = arrayToObject([
  'STUDIO',
  'AGENCY',
  'OTHER'
]);

export const TRANSACTION_METHOD_TYPE_ENUM = arrayToObject([
  'CC',
  'ACH',
  'PAYPAL',
  'RUPIE_CREDIT'
]);

// Do not change: these match the payment processing status on Bluesnap's API
export const TRANSACTION_STATUS_ENUM = arrayToObject([
  'PENDING',
  'SUCCESS',
  'FAIL',
  'REFUNDED',
  'CHARGEBACKED',
  'IN_PROGRESS',
  'IN_QUEUE'
]);

export const TRANSACTION_TYPE_ENUM = arrayToObject([
  'AUTHORIZE',
  'CHARGE',
  'CHARGEBACK',
  'REFUND'
]);

export const USER_SHARD_ENUM = arrayToObject([
  'ONBOARDING',
  'PROFILE_CHECKLIST'
]);

export const USER_MEDIA_TYPE_ENUM = arrayToObject([
  'AVATAR',
  'BANNER',
  'ASSET'
]);

export const USER_WORK_APPLICATION_STATUS_ENUM = arrayToObject([
  'DRAFT',
  'PENDING',
  'MISSING_INFO',
  'COMPLETE',
  'REJECTED'
]);

export const VIP_REGISTRATION_FORM_STEPS_ENUM = arrayToObject([
  'RESUME_UPLOAD',
  'COMPLETE'
]);

export const VOUCH_STATUS_ENUM = arrayToObject([
  'PENDING',
  'REJECTED',
  'VOUCHED'
]);

export const WORK_CONTEXT_ENUM = arrayToObject([
  'PROFESSIONAL',
  'PERSONAL'
]);

export const WORK_EXPERIENCE_MEDIA_TYPE_ENUM = arrayToObject([
  'ASSET',
  'AVATAR',
  'BANNER',
  'GALLERY'
]);

export const WORK_EXPERIENCE_LEVEL_ENUM = arrayToObject([
  'ENTRY',
  'EXECUTIVE_SENIOR',
  'EXECUTIVE',
  'EXPERIENCED_SENIOR',
  'EXPERIENCED',
  'MANAGEMENT_SENIOR',
  'MANAGEMENT'
]);

export const WORK_EXPERIENCE_SOURCE_TYPE_ENUM = arrayToObject([
  'INTERNAL',
  'LINKEDIN',
  'MERGED',
  'SOVREN',
  'USER',
  'PEOPLE_DATA_LABS'
]);

export const WORK_EXPERIENCE_TYPE_ENUM = arrayToObject([
  'JOB',
  'EDUCATION',
  'PORTFOLIO',
  'INITIATIVE'
]);

export const WORK_PREFERENCES_GOALS_DEFAULTS = arrayToObject([
  'HIGHER_SALARY',
  'BETTER_WORK_ENVIRONMENT',
  'BETTER_CULTURE',
  'FLEXIBLE_LOCATION',
  'DEFFERENT_POSITION',
  'SKILLS'
]);
