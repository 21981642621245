/* eslint-disable import/export, import/namespace */
export * from 'shared/web/constants/globalConstants';

// Corresponds with entity model names on server
// Often utilized in requests
export const NEW_ID = 'new';

export { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

// re-export from global
export * from './endpointConstants';
export ROUTE_PATHS from './routeConstants';
