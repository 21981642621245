import { merge } from 'lodash';
import * as fileConstants from 'constants/fileConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

export default function entities(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    const { file = {}, fileId } = action;

    switch (type) {
      case fileConstants.FILE_REMOVE._SUCCESS: {
        const newState = {
          ...state
        };

        const { MediaID } = newState[ENTITY_TYPES.FILE][fileId || file.ID];
        if (MediaID && MediaID.length) {
          delete newState[ENTITY_TYPES.MEDIA][MediaID];
        }

        delete newState[ENTITY_TYPES.FILE][fileId || file.ID];

        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
