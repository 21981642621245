import { object } from 'prop-types';
import { createSelector } from 'reselect';

import { getWorkExperienceFilesAndMedia } from 'shared/web/utils/workExperienceUtils';

import {
  getFileEntities,
  getMediaEntities,
  getWorkExperienceEntities,
} from './stateSelectors';

export const getWorkExperiencesByUserId = createSelector(
  (state, props) => props.userId,
  getWorkExperienceEntities,
  getFileEntities,
  getMediaEntities,
  (userId, workExperienceEntities, fileEntities, mediaEntities) => Object.values(workExperienceEntities)
    .filter(workExperience => workExperience.UserID === userId && (workExperience.Imported === null || workExperience.Imported))
    .map(workExperience => ({
      ...workExperience,
      ...getWorkExperienceFilesAndMedia(workExperience, fileEntities, mediaEntities)
    }))
);

export const getWorkExperienceById = createSelector(
  (state, props) => props.experienceId,
  getWorkExperienceEntities,
  getFileEntities,
  getMediaEntities,
  (experienceId, workExperienceEntities, fileEntities, mediaEntities) => Object.values(workExperienceEntities)
    .filter(workExperience => workExperience.ID === experienceId)
    .map(workExperience => ({
      ...workExperience,
      ...getWorkExperienceFilesAndMedia(workExperience, fileEntities, mediaEntities)
    }))
);

export const getExperiencesToImportByUserId = createSelector(
  (state, props) => props.userId,
  getWorkExperienceEntities,
  getFileEntities,
  getMediaEntities,
  (userId, workExperienceEntities, fileEntities, mediaEntities) => Object.values(workExperienceEntities)
    .filter(workExperience => workExperience.UserID === userId && workExperience.Imported === false)
    .map(workExperience => ({
      ...workExperience,
      ...getWorkExperienceFilesAndMedia(workExperience, fileEntities, mediaEntities)
    }))
);

export const selectWorkExperiencesToVouch = createSelector(
  (state, props) => props.userId,
  getWorkExperienceEntities,
  getFileEntities,
  getMediaEntities,
  (userId, workExperienceEntities, fileEntities, mediaEntities) => Object.values(workExperienceEntities)
    .map(workExperience => ({
      ...workExperience,
      ...getWorkExperienceFilesAndMedia(workExperience, fileEntities, mediaEntities)
    }))
    .filter((workExperience) => workExperience?.Vouches?.length > 0)
    .filter((workExperience) => workExperience?.Vouches[0]?.Requester !== undefined)
    .reduce((result, workExperience) => {
      (result[workExperience?.Vouches[0]?.Status] || (result[workExperience?.Vouches[0]?.Status] = [])).push(workExperience);
      return result;
    }, {})
);
