import { schema } from 'normalizr';

// eslint-disable-next-line import/no-cycle
import {
  FileList,
  MediaList,
  MilestoneList,
  Organization,
  Task,
  Team,
  User
} from './index';

// Entities
const Category = new schema.Entity(
  'Category',
  {},
  {
    idAttribute: 'ID'
  }
);

const Event = new schema.Entity(
  'Event',
  {},
  {
    idAttribute: 'ID'
  }
);
const Game = new schema.Entity(
  'Game',
  {
    Category: [Category]
  },
  {
    idAttribute: 'ID'
  }
);

const Role = new schema.Entity(
  'Roles',
  {},
  {
    idAttribute: 'ID'
  }
);

const Project = new schema.Entity(
  'Project',
  {
    Events: [Event],
    Game,
    Files: FileList,
    Media: MediaList,
    MemberOrganizations: [Organization],
    Members: [User],
    MemberTeams: [Team],
    Milestones: MilestoneList,
    Organization,
    Roles: [Role],
    Tasks: [Task],
    Team,
    User
  },
  {
    idAttribute: 'ID'
  }
);

const ProjectList = [Project];

export { Project, ProjectList };
