export function addTrailingSlash(url = '') {
  let newUrl = url;
  const lastChar = url.substr(-1); // Selects the last character
  if (lastChar !== '/') { // If the last character is not a slash
    newUrl += '/'; // Append a slash to it.
  }
  return newUrl;
}

export const getClientUrl = () => {
  const clientProtocol = process.env.CLIENT_PROTOCOL;
  const clientPort = process.env.CLIENT_PORT;
  const clientHost = process.env.CLIENT_HOST;
  return `${clientProtocol}://${clientHost}${clientPort ? ':' + clientPort : ''}`;
};

export function silentlySetQueryStringParameter(name, value) {
  const params = new URLSearchParams(window.location.search);
  params.set(name, value);
  window.history.replaceState({}, '', decodeURIComponent(`${window.location.pathname}?${params}`));
}
