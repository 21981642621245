import { USER_SHARD_ENUM as USER_SHARD } from 'shared/constants/enumConstants';

export const ADD_SHARDS_TO_USER_SUCCESS = 'ADD_SHARDS_TO_USER_SUCCESS';

export const SHARD_MAP = {
  [USER_SHARD.ONBOARDING]: {
    Amount: 100,
    MaxAllowed: 1
  },
  [USER_SHARD.PROFILE_CHECKLIST]: {
    Amount: 100,
    MaxAllowed: 1
  }
};
