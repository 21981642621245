import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withId } from 'utils/dataUtils';
import { getEntityType } from 'utils/entityUtils';
import { ROUTE_PATHS } from 'constants/global';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

import OrganizationDisplay from './OrganizationDisplay';
import TeamDisplay from './TeamDisplay';
import UserAvatarDisplay from './UserAvatarDisplay';

import './CombinedEntityDisplay.less';

const { ORGANIZATION, TEAM, USER } = ENTITY_TYPES;

const CombinedEntityDisplay = props => {
  const {
    avatarProps = {},
    entity,
    excludeLink,
    linkable,
    transformLinkFn,
    nameOverride,
    onClick,
    textOnly
  } = props;

  const entityType = getEntityType(entity);
  let entityComponent;
  let linkPath;

  if (!entity) {
    return null;
  }

  if (entityType === ORGANIZATION) {
    entityComponent = <OrganizationDisplay {...props} avatarProps={avatarProps} />;
  }

  if (entityType === TEAM) {
    entityComponent = <TeamDisplay {...props} avatarProps={avatarProps} />;
  }

  if (!entityComponent) {
    entityComponent = <UserAvatarDisplay {...props} avatarProps={avatarProps} />;
  }

  if (entityType === ORGANIZATION) {
    linkPath = ROUTE_PATHS.ORGANIZATION;
  } else if (entityType === TEAM) {
    linkPath = ROUTE_PATHS.TEAM;
  } else {
    linkPath = ROUTE_PATHS.USER_PROFILE;
  }

  // Link & text (eg no avatar)
  if (textOnly) {
    return (
      <Link
        disabled={!linkable}
        onClick={() => {
          onClick(transformLinkFn(withId(linkPath, entity.ID)));
        }}
        to={transformLinkFn(withId(linkPath, entity.ID))}
        className="combined-entity-display combined-entity-display--text-only"
      >
        {nameOverride || entity.FullName || entity.Name || entity.Title}
      </Link>
    );
  }

  // Avatar and/or Text without Link
  if (excludeLink) {
    return (
      <div className="combined-entity-display">
        {entityComponent}
      </div>
    );
  }

  // Link with Avatar and/or Text (eg. always avatar, but may exclude text)
  return (
    <Link disabled={!linkable} to={transformLinkFn(withId(linkPath, entity.ID))} className="combined-entity-display">
      {entityComponent}
    </Link>
  );
};

CombinedEntityDisplay.propTypes = {
  avatarProps: PropTypes.object,
  entity: PropTypes.object.isRequired,
  excludeLink: PropTypes.bool,
  transformLinkFn: PropTypes.func,
  linkable: PropTypes.bool,
  nameOverride: PropTypes.oneOfType(
    [
      PropTypes.object,
      PropTypes.string
    ]
  ),
  onClick: PropTypes.func,
  textOnly: PropTypes.bool
};

CombinedEntityDisplay.defaultProps = {
  avatarProps: {},
  excludeLink: false,
  transformLinkFn: link => link,
  linkable: true,
  nameOverride: null,
  onClick: () => { },
  textOnly: false
};

export default CombinedEntityDisplay;
