import React from 'react';

import { generateAsyncActions } from 'utils/actionUtils';
import { USER_SHARD_ENUM as USER_SHARD } from 'shared/constants/enumConstants';

export const CHECK_ADD_SHARDS = generateAsyncActions('CHECK_ADD_SHARDS');
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SHARD_MODAL_REASON_MAP = {
  [USER_SHARD.ONBOARDING]: <span className="shard-modal__text">Well done! You've received <strong>100 Shards</strong> for completely onboarding! You'll have the opportunity to accumulate more shards as you move on; providing access to exclusive Rupie perks!</span>,
  [USER_SHARD.PROFILE_CHECKLIST]: <span className="shard-modal__text">Your profile is now complete! You're ready to work on Rupie now!</span>
};
