import buildUrl from 'build-url';
import { normalize } from 'normalizr';
import queryString from 'query-string';

import {
  BillingProfile,
  Invoice,
  InvoiceList,
  Subscription,
  User,
  VendorProfile
} from 'schemas/index';
import { errorState, loadingState, successState } from 'utils/actionUtils';
import { withId, withIds } from 'utils/dataUtils';
import * as billingConstants from 'constants/billingConstants';
import { BILLING_ENDPOINT } from 'constants/endpointConstants';
import { ROUTE_PATHS } from 'constants/global';
import { getUserId } from 'selectors/userSelectors';

/**
 * Async action that gets the billing profile
 * @param  {String} billingProfileId     The id for billingprofile
 * @param  {String} data      The updated conversation data
 * @return {Function}         The Thunk function to resolve
 */
export function getBillingProfile(billingProfileId, organizationId) {
  return (dispatch, getState) => {
    const { FETCH_BILLING_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(FETCH_BILLING_PROFILE, { userId }),
        success: billingProfile => successState(FETCH_BILLING_PROFILE, normalize(billingProfile || {}, BillingProfile), { userId, billingProfileId }),
        error: err => errorState(FETCH_BILLING_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'get',
        endpoint: `${BILLING_ENDPOINT}/profile`,
        queryParams: {
          organizationId: organizationId || undefined
        }
      }
    });
  };
}

/**
 * Update the current user's invoices
 * @param {object} values - data to be updated
 * @TODO: add to the postman
 */
export function fetchUserInvoices() {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    const { FETCH_USER_INVOICES } = billingConstants;

    return dispatch({
      types: {
        loading: () => loadingState(FETCH_USER_INVOICES, { ID }),
        success: invoices => successState(FETCH_USER_INVOICES, normalize(invoices, InvoiceList), { ID }),
        error: err => errorState(FETCH_USER_INVOICES, err)
      },
      apiParams: {
        method: 'get',
        endpoint: `${BILLING_ENDPOINT}/invoices`
      }
    });
  };
}

/**
 * Retrieve subscription by subscription Id
 * @param subscriptionId
 */
export function getSubscription(subscriptionId) {
  return (dispatch, getState) => {
    const { FETCH_SUBSCRIPTION } = billingConstants;

    return dispatch({
      types: {
        loading: () => loadingState(FETCH_SUBSCRIPTION, { subscriptionId }),
        success: subscription => successState(FETCH_SUBSCRIPTION, normalize(subscription, Subscription), { subscriptionId }),
        error: err => errorState(FETCH_SUBSCRIPTION, err)
      },
      apiParams: {
        method: 'get',
        endpoint: withId(`${BILLING_ENDPOINT}/subscription/:id`, subscriptionId)

      }
    });
  };
}

/**
 * Update invoice by Id
 * @param {object} values - data to be updated
 * @TODO: add to the postman
 */
export function fetchUserInvoice(invoiceId) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    const { FETCH_USER_INVOICE } = billingConstants;

    return dispatch({
      types: {
        loading: () => loadingState(FETCH_USER_INVOICE, { ID }),
        success: invoice => successState(FETCH_USER_INVOICE, normalize(invoice, Invoice), { ID }),
        error: err => errorState(FETCH_USER_INVOICE, err)
      },
      apiParams: {
        method: 'get',
        endpoint: withId(`${BILLING_ENDPOINT}/invoice/:id`, invoiceId)
      }
    });
  };
}

/**
 * Async action that sets a billing profile on an entity
 * @param  {String} billingProfileId     The id for billingprofile
 * @param  {String} billingProfileId     The id for entity
 * @param  {String} entityType     The type of the entity
 * @return {Function}         The Thunk function to resolve
 */
export function setBillingProfileOnEntity(billingProfileId, entityId, entityType, methodData, methodType, organizationId) {
  return (dispatch, getState) => {
    const { SET_ENTITY_BILLING_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(SET_ENTITY_BILLING_PROFILE, { userId }),
        success: billingProfile => successState(SET_ENTITY_BILLING_PROFILE, normalize(billingProfile, BillingProfile), {
          entityType, entityId, userId, billingProfileId
        }),
        error: err => errorState(SET_ENTITY_BILLING_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'put',
        endpoint: buildUrl(`${BILLING_ENDPOINT}/profile/${billingProfileId}/entity`),
        data: {
          MethodData: methodData,
          MethodType: methodType
        },
        queryParams: {
          organizationId: organizationId || undefined,
          entityType,
          entityId
        }
      }
    });
  };
}

/**
 * Async action that gets a billing profile for an entity
 * @param  {String} billingProfileId     The id for billingprofile
 * @param  {String} billingProfileId     The id for entity
 * @param  {String} entityType     The type of the entity
 * @return {Function}         The Thunk function to resolve
 */
export function getBillingProfileForEntity(billingProfileId, entityId, entityType, organizationId) {
  return (dispatch, getState) => {
    const { GET_ENTITY_BILLING_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(GET_ENTITY_BILLING_PROFILE, { userId }),
        success: billingProfile => successState(GET_ENTITY_BILLING_PROFILE, normalize(billingProfile, BillingProfile), { userId, billingProfileId }),
        error: err => errorState(GET_ENTITY_BILLING_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'get',
        endpoint: buildUrl(`${BILLING_ENDPOINT}/profile/${billingProfileId}/entity`),
        queryParams: {
          organizationId: organizationId || undefined,
          entityType,
          entityId
        }
      }
    });
  };
}

/**
 * Async action that creates a subscription
 * @param  {String} billingProfileId     The id for the associated billing profile
 * @param  {String} methodData
 * @param  {String} methodType
 * @param  {String} subscriptionType
 * @param  {String} chargeFrequency
 * @return {Function}         The Thunk function to resolve
 */
export function createSubscription(billingProfileId, methodData, methodType, subscriptionType, chargeFrequency, organizationId) {
  return (dispatch, getState) => {
    const { CREATE_SUBSCRIPTION } = billingConstants;

    return dispatch({
      types: {
        loading: () => loadingState(CREATE_SUBSCRIPTION, { billingProfileId }),
        success: subscription => successState(CREATE_SUBSCRIPTION, normalize(subscription, Subscription), { billingProfileId }),
        error: err => errorState(CREATE_SUBSCRIPTION, err, null, { billingProfileId })
      },
      apiParams: {
        method: 'post',
        endpoint: `${BILLING_ENDPOINT}/subscription`,
        queryParams: {
          organizationId: organizationId || undefined
        },
        data: {
          billingProfileId,
          methodData,
          methodType,
          subscriptionType,
          chargeFrequency
        }
      }
    });
  };
}

/**
 * Async action that updates an conversation
 * @param  {String} conversationId     The id for the desired conversation
 * @param  {Object} data      The updated conversation data
 * @return {Function}         The Thunk function to resolve
 */
export function createBillingProfile(fields, makeDefault = false, defaultMethodData, organizationId) {
  return (dispatch, getState) => {
    const { CREATE_BILLING_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(CREATE_BILLING_PROFILE, { userId }),
        success: billingSort => successState(CREATE_BILLING_PROFILE, normalize({
          BillingProfile: billingSort
        }, User), { userId }),
        error: err => errorState(CREATE_BILLING_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'post',
        endpoint: `${BILLING_ENDPOINT}/profile`,
        data: {
          fields,
          makeDefault,
          defaultMethodData
        },
        queryParams: {
          organizationId: organizationId || undefined
        }
      }
    });
  };
}

/**
 * Async action that updates an conversation
 * @param  {String} conversationId     The id for the desired conversation
 * @param  {Object} data      The updated conversation data
 * @return {Function}         The Thunk function to resolve
 */
export function updatePaymentMethod(fields, makeDefault = false, defaultMethodData, organizationId, previousBillingMethod, previousValues) {
  return (dispatch, getState) => {
    const { UPDATE_BILLING_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(UPDATE_BILLING_PROFILE, { userId }),
        success: billingSort => successState(UPDATE_BILLING_PROFILE, normalize({ BillingProfile: billingSort }, User), { userId }),
        error: err => errorState(UPDATE_BILLING_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'patch',
        endpoint: `${BILLING_ENDPOINT}/profile`,
        data: {
          defaultMethodData,
          fields,
          makeDefault,
          previousBillingMethod,
          previousValues
        },
        queryParams: {
          organizationId: organizationId || undefined
        }
      }
    });
  };
}

/**
 * Async action that deletes payment method from profile
 * @param  {String} billingProfileId     The id for billingprofile
 * @param  {Object} paymentData     the payment data (cc or ach data)
 * @param  {Object} paymentType   the type of payment (cc or ACH)
 * @return {Function}         The Thunk function to resolve
 */
export function deletePaymentMethod(billingProfileId, paymentData, paymentType, organizationId) {
  if (!paymentType) {
    throw new Error('Missing paymentType for delete');
  }

  return (dispatch, getState) => {
    const { DELETE_BILLING_PAYMENT_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(DELETE_BILLING_PAYMENT_PROFILE, { userId }),
        success: () => successState(DELETE_BILLING_PAYMENT_PROFILE, { entities: {} }, { paymentData, paymentType, billingProfileId }),
        error: err => errorState(DELETE_BILLING_PAYMENT_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'delete',
        endpoint: `${BILLING_ENDPOINT}/profile`,
        queryParams: {
          organizationId: organizationId || undefined,
          paymentData: JSON.stringify(paymentData),
          paymentType
        }
      }
    });
  };
}

/**
 * Async action that gets the vendor profile
 * @param  {String} vendorProfileId     The id for vendorProfile
 * @return {Function}         The Thunk function to resolve
 */
export function getVendorProfile(entityType, entityId) {
  return (dispatch, getState) => {
    const { FETCH_VENDOR_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(FETCH_VENDOR_PROFILE, { userId }),
        success: vendorProfile => successState(FETCH_VENDOR_PROFILE, normalize(vendorProfile || {}, VendorProfile), { userId }),
        error: err => errorState(FETCH_VENDOR_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'get',
        endpoint: `${BILLING_ENDPOINT}/vendor/${entityType.toLowerCase()}/${entityId}/profile`
      }
    });
  };
}

/**
 * Async action that updates the vendor profile
 * @param  {String} vendorProfileId     The id for vendorProfile
 * @return {Function}         The Thunk function to resolve
 */
export function updateVendorProfile(fields, entityType, entityId) {
  return (dispatch, getState) => {
    const { UPDATE_VENDOR_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(UPDATE_VENDOR_PROFILE, { userId }),
        success: vendorProfile => successState(UPDATE_VENDOR_PROFILE, normalize(vendorProfile, VendorProfile), {
          userId,
          vendorProfileId: vendorProfile.ID
        }),
        error: err => errorState(UPDATE_VENDOR_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'patch',
        endpoint: `${BILLING_ENDPOINT}/vendor/${entityType.toLowerCase()}/${entityId}/profile`,
        data: {
          fields
        }
      }
    });
  };
}

/**
 * Async action that deletes a vendor profile
 * @param  {String} vendorProfileId     The id for vendorProfileId
 * @return {Function}         The Thunk function to resolve
 */
export function deleteVendorProfile(vendorProfileId, entityType, entityId) {
  return (dispatch, getState) => {
    const { DELETE_VENDOR_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(DELETE_VENDOR_PROFILE, { userId }),
        success: () => successState(DELETE_VENDOR_PROFILE, { entities: {} }, { vendorProfileId }),
        error: err => errorState(DELETE_VENDOR_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'delete',
        endpoint: withId(`${BILLING_ENDPOINT}/vendor/${entityType.toLowerCase()}/${entityId}/profile/:id`, vendorProfileId)
      }
    });
  };
}

/**
 * Async action that creates a vendor profile
 * @param  {Object} fields   the vendor fields
 * @return {Function}         The Thunk function to resolve
 */
export function createVendorProfile(fields, entityType, entityId) {
  return (dispatch, getState) => {
    const { CREATE_VENDOR_PROFILE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(CREATE_VENDOR_PROFILE, { userId }),
        success: vendorProfile => successState(CREATE_VENDOR_PROFILE, normalize(vendorProfile, VendorProfile), { vendorProfileId: vendorProfile.ID, userId }),
        error: err => errorState(CREATE_VENDOR_PROFILE, err, null, { userId })
      },
      apiParams: {
        method: 'post',
        endpoint: `${BILLING_ENDPOINT}/vendor/${entityType.toLowerCase()}/${entityId}/profile`,
        data: {
          fields
        }
      }
    });
  };
}

/**
 * Async action that charges a CC or ACH
 * @param  {Object} fields   the vendor fields
 * @return {Function}         The Thunk function to resolve
 */
export function makeCharge(amount, currency, methodData, methodType, lineItems, entityType, entityId, transactionType, invoiceId, organizationId) {
  if (!methodData || !amount || !lineItems || !entityId || !entityType || !transactionType || !currency || !methodType) {
    throw new Error('Missing data for charge action');
  }

  return (dispatch, getState) => {
    const { CREATE_BILLING_CHARGE } = billingConstants;

    const userId = getUserId(getState());

    return dispatch({
      types: {
        loading: () => loadingState(CREATE_BILLING_CHARGE, { userId }),
        success: () => successState(CREATE_BILLING_CHARGE, { userId }),
        error: err => errorState(CREATE_BILLING_CHARGE, err, null, { userId })
      },
      apiParams: {
        method: 'PUT',
        endpoint: `${BILLING_ENDPOINT}/pay`,
        queryParams: {
          organizationId: organizationId || undefined
        },
        data: {
          amount,
          currency,
          methodData,
          methodType,
          lineItems,
          transactionType,
          entityType,
          entityId,
          invoiceId
        }
      }
    });
  };
}

/**
 * Async action that charges a CC or ACH for subscriptions
 * @return {Function}         The Thunk function to resolve
 * @param subscriptionId
 */
export function makeSubscriptionCharge(subscriptionId, organizationId) {
  if (!subscriptionId) {
    throw new Error('Missing data for subscription charge action');
  }

  return dispatch => {
    const { SUBSCRIBE_BILLING_SUBSCRIPTION } = billingConstants;

    return dispatch({
      types: {
        loading: () => loadingState(SUBSCRIBE_BILLING_SUBSCRIPTION, { subscriptionId }),
        success: subscription => successState(SUBSCRIBE_BILLING_SUBSCRIPTION, normalize(subscription, Subscription), { subscriptionId }),
        error: err => errorState(SUBSCRIBE_BILLING_SUBSCRIPTION, err, null, { subscriptionId })
      },
      apiParams: {
        method: 'put',
        endpoint: `${BILLING_ENDPOINT}/paysubscription`,
        queryParams: {
          organizationId: organizationId || undefined
        },
        data: {
          subscriptionId
        }
      }
    });
  };
}

/**
 * Async action that converts a currency to USD
 * @return {Function}
 * @param dollarAmount
 * @param currency
 */
export function getCurrencyValue(currency, dollarAmount) {
  const { FETCH_CONVERTED_CURRENCY_VALUE } = billingConstants;

  return (dispatch, getState) => dispatch({
    types: {
      loading: () => loadingState(FETCH_CONVERTED_CURRENCY_VALUE),
      success: amount => ({
        type: FETCH_CONVERTED_CURRENCY_VALUE._SUCCESS,
        amount
      }),
      error: err => errorState(FETCH_CONVERTED_CURRENCY_VALUE)
    },
    apiParams: {
      method: 'post',
      endpoint: ROUTE_PATHS.CONVERT_CURRENCY_ENDPOINT,
      data: { currency, dollarAmount }
    }
  });
}

/**
 * Get invoice receipt by Id
 * @param {string} invoiceId - invoice's id
 */
export function getInvoiceReceipt(invoiceId) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    const { FETCH_INVOICE_RECEIPT } = billingConstants;

    return dispatch({
      types: {
        loading: () => loadingState(FETCH_INVOICE_RECEIPT, { ID }),
        success: invoice => successState(FETCH_INVOICE_RECEIPT, normalize(invoice, Invoice), { ID }),
        error: err => errorState(FETCH_INVOICE_RECEIPT, err)
      },
      apiParams: {
        method: 'get',
        endpoint: withId(`${BILLING_ENDPOINT}/receipt/:id`, invoiceId)
      }
    });
  };
}
