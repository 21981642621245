import { getBaseBeacon } from 'selectors/beaconSelectors';
import { getBaseEvent } from 'selectors/eventSelectors';
import { getBaseGig } from 'selectors/gigSelectors';
import { getBaseOrganization } from 'selectors/organizationSelectors';
import { getProjectWithMembers } from 'selectors/projectSelectors';
import { getBaseTeam } from 'selectors/teamSelectors';
/**
 * Gets the user roles for a team
 * @param  {Object} state        The state object
 * @param  {Object} [options={}] The options
 * @return {Array}              Roles
 */
export function getUserTeamRoles (state, config) {
  const { ID } = config;
  const team = getBaseTeam(state, { TeamID: ID });
  const { Team: teamEntity } = team;
  let roles = [];

  if (teamEntity.UserRole) {
    roles = roles.concat([teamEntity.UserRole]);
  }

  return roles;
}

/**
 * Gets the user roles for a event
 * @param  {Object} state        The state object
 * @param  {Object} [options={}] The options
 * @return {Array}              Roles
 */
export function getUserEventRoles (state, config) {
  const { ID } = config;
  const event = getBaseEvent(state, { EventID: ID });
  const { Event: eventEntity } = event;
  let roles = [];

  if (eventEntity.UserRole) {
    roles = roles.concat([eventEntity.UserRole]);
  }

  return roles;
}

/**
 * Gets the user roles for a gig
 * @param  {Object} state        The state object
 * @param  {Object} [options={}] The options
 * @return {Array}               Roles
 */
export function getUserGigRoles (state, config) {
  const { gigId } = config;
  const gigEntity = getBaseGig(state, { GigID: gigId, gigId });
  let roles = [];

  if (gigEntity.UserRole) {
    roles = roles.concat([gigEntity.UserRole]);
  }

  return roles;
}

/**
 * Gets the user roles for a orgs
 * @param  {Object} state        The state object
 * @param  {Object} [options={}] The options
 * @return {Array}              Roles
 */
export function getUserOrganizationRoles (state, config) {
  const { ID } = config;
  const org = getBaseOrganization(state, { OrganizationID: ID });
  const { Organization: orgEntity } = org;
  let roles = [];

  if (orgEntity.UserRole) {
    roles = roles.concat([orgEntity.UserRole]);
  }

  return roles;
}

/**
 * Gets the user roles for a beacons
 * @param  {Object} state        The state object
 * @param  {Object} [options={}] The options
 * @return {Array}              Roles
 */
export function getUserBeaconRoles (state, config) {
  const { ID } = config;
  const beacon = getBaseBeacon(state, { beaconId: ID });
  const { Beacon: beaconEntity } = beacon;
  let roles = [];

  if (beacon.UserRole) {
    roles = roles.concat([beacon.UserRole]);
  }

  return roles;
}

/**
 * Gets the user roles for a project
 * @param  {Object} state        The state object
 * @param  {Object} [options={}] The options
 * @return {Array}              Roles
 * TODO add call to getOrganizationProjectRoles
 */
export function getUserProjectRoles (state) {
  const project = getProjectWithMembers(state);
  const { Project: projectEntity } = project;
  let roles = [];

  if (projectEntity.UserRole) {
    roles = roles.concat([projectEntity.UserRole]);
  }

  return roles;
}
