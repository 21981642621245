import max from 'lodash/maxBy';
import { v4 as uuid4 } from 'uuid';

import {
  PUSH_BANNER_ALERT,
  REMOVE_BANNER_ALERT
} from 'constants/bannerAlertsConstants';

import { CLEAR_BANNER_ALERTS } from '../constants/bannerAlertsConstants';

const defaultState = {
  items: [],
  loading: false,
  error: null
};

export function bannerAlertsReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case PUSH_BANNER_ALERT:

      // Don't allow to push the same banner alerts
      if (action.id && state.bannerAlert.find(bannerAlert => bannerAlert.id === action.id)) {
        return state;
      }

      const highestPriority = max(state.bannerAlerts, 'priority'); // eslint-disable-line no-case-declarations

      return {
        ...state,
        items: [
          ...state.items,
          {
            ...action.data,
            id: uuid4(),
            priority: highestPriority ? highestPriority.priority + 1 : 1
          }
        ]
      };
    case REMOVE_BANNER_ALERT:
      return {
        ...state,
        items: state.items.filter(obj => obj.id !== action.id)
      };
    case CLEAR_BANNER_ALERTS:
      return {
        ...state,
        items: defaultState.items
      };
    default:
      return state;
  }
}
