import { lazy } from 'react';

import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from 'utils/authUtils';

// Need to apply the HOCs here to avoid applying them inside the render method
export const BeaconsView = () => userIsAuthenticatedRedir(lazy(() => import('views/Beacons/Beacons')));
export const BeaconView = () => userIsAuthenticatedRedir(lazy(() => import('views/Beacon/Beacon')));
export const CreateProjectView = () => userIsAuthenticatedRedir(lazy(() => import('views/Project/Create/CreateContainer')));
export const DashboardView = () => userIsAuthenticatedRedir(lazy(() => import('views/Dashboard/Dashboard')));
export const EventsDiscoverView = () => userIsAuthenticatedRedir(lazy(() => import('views/Events/EventsDiscoverContainer')));
export const EventsView = () => userIsAuthenticatedRedir(lazy(() => import('views/Events/EventsContainer')));
export const EstimateViewGuest = () => lazy(() => import('views/Estimate/Estimate'));
export const EstimateView = () => userIsAuthenticatedRedir(lazy(() => import('views/Estimate/Estimate')));
export const EventView = () => userIsAuthenticatedRedir(lazy(() => import('views/Event/EventContainer')));
export const ExtensionView = () => userIsAuthenticatedRedir(lazy(() => import('views/Extension/Extension')));
export const ForgotView = () => lazy(() => import('views/Forgot/ForgotContainer'));
export const GigView = () => userIsAuthenticatedRedir(lazy(() => import('views/Gig/GigContainer')));
export const IntegrationsView = () => userIsAuthenticatedRedir(lazy(() => import('views/Integrations/Integrations')));
export const IntegrationView = () => userIsAuthenticatedRedir(lazy(() => import('views/Integrations/Integration')));
export const InvoiceView = () => userIsAuthenticatedRedir(lazy(() => import('views/Invoice/Invoice')));
export const JobClaimView = () => userIsAuthenticatedRedir(lazy(() => import('views/JobClaim/JobClaim')));
export const JobView = () => userIsAuthenticatedRedir(lazy(() => import('views/Job/JobContainer')));
export const LoginView = () => userIsNotAuthenticatedRedir(lazy(() => import('views/Login/Login')));
export const LogoutView = () => lazy(() => import('views/Logout/Logout'));
export const NotificationsUnsubscribeView = () => userIsAuthenticatedRedir(lazy(() => import('views/Notifications/Unsubscribe')));
export const OnboardingView = () => userIsAuthenticatedRedir(lazy(() => import('views/Onboarding/Onboarding')));
export const OrganizationsView = () => userIsAuthenticatedRedir(lazy(() => import('views/Organizations/Organizations')));
export const OrganizationView = () => userIsAuthenticatedRedir(lazy(() => import('views/Organizations/Organization/OrganizationContainer')));
export const ProfileView = () => userIsAuthenticatedRedir(lazy(() => import('views/User/Profile/WorkProfile/WorkProfile')));
export const ProjectsView = () => userIsAuthenticatedRedir(lazy(() => import('views/Projects/Projects')));
export const ProjectView = () => userIsAuthenticatedRedir(lazy(() => import('views/Project/ProjectContainer')));
export const QuestsView = () => userIsAuthenticatedRedir(lazy(() => import('views/Quests/QuestsContainer')));
export const ReceiptView = () => lazy(() => import('views/Receipt/Receipt'));
export const RegistrationView = () => lazy(() => import('views/Registration/Registration'));
export const ResetView = () => lazy(() => import('views/Reset/ResetContainer'));
export const RolodexView = () => userIsAuthenticatedRedir(lazy(() => import('views/Rolodex/RolodexContainer')));
export const SignalViewGuest = () => lazy(() => import('views/Signal/Signal'));
export const SignalView = () => userIsAuthenticatedRedir(lazy(() => import('views/Signal/Signal')));
export const TalentView = () => userIsAuthenticatedRedir(lazy(() => import('views/Talent/Talent')));
export const TeamsView = () => userIsAuthenticatedRedir(lazy(() => import('views/Teams/Teams')));
export const TeamView = () => userIsAuthenticatedRedir(lazy(() => import('views/Team/TeamContainer')));
export const UserContainerView = () => userIsAuthenticatedRedir(lazy(() => import('views/User/UserContainer')));
export const UserEmailChangeView = () => userIsAuthenticatedRedir(lazy(() => import('views/User/Actions/ConfirmEmailChange')));
export const UserRegistrationVerifyView = () => userIsAuthenticatedRedir(lazy(() => import('views/User/Actions/VerifyUserRegistration')));
export const VerifyView = () => userIsAuthenticatedRedir(lazy(() => import('views/Verify/VerifyContainer')));
export const VipRegistrationView = () => lazy(() => import('views/Registration/VipRegistration'));
export const WorkView = () => userIsAuthenticatedRedir(lazy(() => import('views/Work/WorkContainer')));
