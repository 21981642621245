import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getGemColors } from 'utils/avatarUtils';
import { ReactComponent as GemImage } from 'images/gem_stout.svg';
import './Gem.less';

// 💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎
// Gets some colorful little shiny pieces of compressed coal that have existed for thousands of years without a single interaction from humans.
// Just look how they sparkle, crystalline refraction bending photon waves through the smooth, cool facets.
// Just for you, unique in the way you are unique.
// 💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎💎
export default class Gem extends Component {
  static propTypes = {
    // seed is a date
    seed: PropTypes.string.isRequired
  }

  constructor() {
    super();
    this.setGemColors = this.setGemColors.bind(this);
    this.gemRef = React.createRef();
  }

  componentDidMount() {
    this.setGemColors();
  }

  setGemColors() {
    const { seed } = this.props;
    const colors = getGemColors(moment(seed).valueOf());
    const { current: gemImage } = this.gemRef;

    const highlights = [...gemImage.querySelectorAll('[class^=highlight]')];
    const borders = [...gemImage.querySelectorAll('[class^=border]')];
    const background = gemImage.querySelector('.background');

    const [
      borderColor,
      backgroundColor,
      centerHighlightColor,
      highlightColor
    ] = colors;

    [
      ...highlights, ...borders
    ].forEach(border => {
      border.style.stroke = borderColor;
    });

    background.style.fill = backgroundColor;

    highlights.forEach(highlights => {
      if (highlights.classList.contains('highlight_center')) {
        highlights.style.fill = centerHighlightColor;
      } else {
        highlights.style.fill = highlightColor;
      }
    });
  }

  render() {
    return (
      <span className="rupie-gem-wrapper" ref={this.gemRef}>
        <GemImage className="rupie-gem" />
      </span>
    );
  }
}
