import {
  TRANSACTIONS_IS_LOADING,
  TRANSACTIONS_FETCH_SUCCESS,
  TRANSACTIONS_HAS_ERRORED
} from 'constants/walletConstants';

// REDUCERS are just pure functions that take an action(s) strings
// and return a new PART OF THE GLOBAL STATE
// this PART is bound to the components themselves and then
// they are re-rendered with the new 'props'
export function transactionsIsLoading(state = false, action) {
  switch (action.type) {
    case TRANSACTIONS_IS_LOADING:
      return action.payload.isLoading;
    default:
      return state;
  }
}

// when shit hits the fan
export function transactionsHasErrored(state = false, action) {
  switch (action.type) {
    case TRANSACTIONS_HAS_ERRORED:
      return action.payload.hasErrored;
    default:
      return state;
  }
}

/**
 * Returns a new 'transactions' state
 * @param  {Array}  [state=[]] The transactions or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with transactions
 */
export function transactions(state = [], action) {
  const { payload } = action;
  switch (action.type) {
    case TRANSACTIONS_FETCH_SUCCESS:
      if (payload && payload.entities && payload.entities.transactions) {
        return {
          ...state,
          ...payload.entities.transactions
        };
      }
      return state;
    default:
      return state;
  }
}
