import { schema } from 'normalizr';

const Media = new schema.Entity(
  'Media',
  {},
  {
    idAttribute: 'ID'
  }
);

const MediaList = [Media];

export { Media, MediaList };
