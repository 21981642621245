import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { NOT_FOUND } from 'http-status-codes';
import { Helmet } from 'react-helmet';
import './NotFound.less';
import Message from 'images/message.svg';
import { randomItem } from 'utils/arrayUtils';

export default class NotFound extends Component {
  static propTypes = {
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool
    ]),
    status: PropTypes.number
  };

  static defaultProps = {
    message: false,
    status: NOT_FOUND
  };

  render() {
    const { message, status } = this.props;
    const quotes = [
      '\\[T]/',
      'Critical hit, it\'s super effective',
      'The cake is a lie. 🎂',
      'Would you kindly?',
      'Did I ever tell you the definition of insanity?'
    ];
    return (

      <Row className="four-oh-four">
        <Helmet>
          <title>Not Found</title>
        </Helmet>

        <section className="shrug">
          <div className="flex-container">
            <div className="header-container">
              <h2 className="header-message">
                {
                  message || randomItem(quotes)
                }
              </h2>
              <h1>
                {status || NOT_FOUND}
              </h1>
            </div>
            <img src={Message} alt="message" />
          </div>

        </section>
      </Row>

    );
  }
}
