import { merge } from 'lodash';

import { REMOVE_ROLODEX_TALENT } from 'constants/rolodexConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { USER } = ENTITY_TYPES;

export default function rolodexEntitiesReducers(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    const { entities } = payload;

    switch (type) {
      case REMOVE_ROLODEX_TALENT._SUCCESS: {
        const { talentId } = action;

        const newUserState = {
          ...state[USER][talentId]
        };

        delete newUserState.RolodexxUser;

        const newState = {
          ...state,
          [USER]: {
            ...state[USER],
            [talentId]: newUserState
          }
        };

        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
