// Insights
import { QUEST_INVITATION_STATUS_ENUM, QUEST_SELF_OPT_IN_STATUS_ENUM } from 'shared/constants/enumConstants';

export const TOTAL_EARNED_FROM_QUESTS = 'TOTAL_EARNED_FROM_QUESTS';
export const TOTAL_QUESTS_INTRODUCTIONS = 'TOTAL_QUESTS_INTRODUCTIONS';
export const TOTAL_QUESTS_INTRODUCTIONS_REWARDED = 'TOTAL_QUESTS_INTRODUCTIONS_REWARDED';

export const DEFAULT_INSIGHTS = [
  TOTAL_EARNED_FROM_QUESTS,
  TOTAL_QUESTS_INTRODUCTIONS,
  TOTAL_QUESTS_INTRODUCTIONS_REWARDED
];

// quest invite states
export const ACCEPTED = 'Accepted';
export const QUEUED = 'Queued';
export const INVITED = 'Invited';
export const PAID = 'Paid';
export const REGISTERED = 'Registered';
export const INTRODUCED = 'Introduced';
export const REJECTED_INVITE = 'Rejected';

export const QUEST_INVITE_STATE_MAP = {
  [QUEST_INVITATION_STATUS_ENUM.ACCEPTED]: {
    color: 'green',
    title: ACCEPTED,
    tooltip: 'Woot! A studio loved your introduction and ye has been rewarded!'
  },
  [QUEST_INVITATION_STATUS_ENUM.INVITED]: {
    color: 'blue',
    title: INVITED,
    tooltip: 'Invited, but not yet registered.'
  },
  [QUEST_INVITATION_STATUS_ENUM.INTRODUCED]: {
    color: 'blue',
    title: INTRODUCED,
    tooltip: 'Candidate was introduced to studio and we await approval.'
  },
  [QUEST_INVITATION_STATUS_ENUM.PAID]: {
    color: 'green',
    title: PAID,
    tooltip: 'Oh yes you did! You got paid for this introduction!'
  },
  [QUEST_INVITATION_STATUS_ENUM.REJECTED]: {
    color: 'red',
    title: REJECTED_INVITE,
    tooltip: 'Candidate was rejected. Check your notes for details on what happened.'
  },
  [QUEST_INVITATION_STATUS_ENUM.QUEUED]: {
    color: 'yellow',
    title: QUEUED,
    tooltip: 'Your introduction has been queued up for studio review.'
  },
  [QUEST_INVITATION_STATUS_ENUM.REGISTERED]: {
    color: 'yellow',
    title: REGISTERED,
    tooltip: 'Your introduction has registered, but we haven\'t reviewed them just yet.'
  }
};

export const QUEST_BEACON_RECRUITER_PERCENTAGE = 40;
export const QUEST_OPT_IN_SEARCH_EXPIRY_DAYS = 20;

// quest self-opt-in states
export const APPROVED = 'Qualified';
export const DECLINED = 'Declined';
export const MORE_INFORMATION = 'Missing Information';
export const PENDING = 'Pending';
export const REJECTED = 'Unqualified';

export const QUEST_OPPORTUNITY_STATE_MAP = {
  [QUEST_SELF_OPT_IN_STATUS_ENUM.APPROVED]: {
    message: 'You\'re are currently recruiting for this role.',
    description: 'Great work. Make sure to find talent by the time you have available.',
    title: 'Opted-In',
    color: 'green'
    // icon: faThumbsDown
  },
  [QUEST_SELF_OPT_IN_STATUS_ENUM.DECLINED]: {
    message: 'You\'ve declined this role.',
    description: 'This role is now not accessible to you.',
    title: 'Declined',
    color: 'red'
    // icon: faThumbsDown
  },
  [QUEST_SELF_OPT_IN_STATUS_ENUM.MORE_INFORMATION]: {
    message: 'More Information Needed',
    description: 'We need more information from you. We\'ve sent you an email with what we need.',
    title: 'More Information',
    color: 'yellow'
    // icon: faThumbsDown
  },
  [QUEST_SELF_OPT_IN_STATUS_ENUM.PENDING]: {
    message: 'Nice work!',
    description: 'Hold tight while we review your profile for this role.',
    title: 'Interested',
    color: 'blue'
    // icon: faGrin
  },
  [QUEST_SELF_OPT_IN_STATUS_ENUM.REJECTED]: {
    message: 'This role is not available to recruit for.',
    description: 'This role either expired or you were banned from recruiting on it for another reason. Reach out to support for help.',
    title: 'Not Accessible',
    color: 'red'
    // icon: faThumbsDown
  }
};
