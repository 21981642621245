import merge from 'lodash/merge';

import * as billingConstants from 'constants/billingConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { CC, ACH } = billingConstants;
const { BILLING_PROFILE, BEACON, VENDOR_PROFILE } = ENTITY_TYPES;

export default function entities(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    const {
      paymentData, paymentType, entityType, entityId, billingProfileId, vendorProfileId
    } = action;

    switch (type) {
      case billingConstants.DELETE_BILLING_PAYMENT_PROFILE._SUCCESS: {
        const newState = {
          ...state
        };

        const paymentSources = newState[BILLING_PROFILE][billingProfileId].PaymentSources || {};
        const { creditCardInfo = [], ecpDetails = [] } = paymentSources || {};

        if (paymentType === CC) {
          newState[BILLING_PROFILE][billingProfileId].PaymentSources.creditCardInfo = creditCardInfo.filter(creditCard => {
            const { creditCard: { cardLastFourDigits } = creditCard } = creditCard;
            if (cardLastFourDigits.toString() !== paymentData.cardLastFourDigits.toString()) {
              return creditCard;
            }
          });
        }

        if (paymentType === ACH) {
          newState[BILLING_PROFILE][billingProfileId].PaymentSources.ecpDetails = ecpDetails.filter(ecp => {
            const { ecp: { publicAccountNumber, publicRoutingNumber } = ecp } = ecp;
            if (publicAccountNumber.toString() !== paymentData.publicAccountNumber.toString() && publicRoutingNumber.toString() !== paymentData.publicRoutingNumber.toString()) {
              return ecp;
            }
          });
        }

        return newState;
      }
      case billingConstants.DELETE_VENDOR_PROFILE._SUCCESS: {
        const newState = {
          ...state
        };

        delete newState[VENDOR_PROFILE][vendorProfileId];
        return newState;
      }
      case billingConstants.SET_ENTITY_BILLING_PROFILE._SUCCESS: {
        const newState = {
          ...state
        };

        // also update the beacon
        if (entityType === BEACON) {
          const { MethodData, MethodType } = payload.entities.BillingProfile[billingProfileId][`${entityType}xBillingProfile`];
          newState[BEACON][entityId] = {
            ...newState[BEACON][entityId],
            MethodData,
            MethodType
          };
        }

        newState[BILLING_PROFILE][billingProfileId][`${entityType}xBillingProfile`] = payload.entities.BillingProfile[billingProfileId][`${entityType}xBillingProfile`];

        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
