export const ACCOUNT_ENDPOINT = '/account';
export const BEACON_ENDPOINT = '/beacon';
export const BEACONS_ENDPOINT = '/beacons';
export const BILLING_ENDPOINT = '/billing';
export const CONVERSATION_ENDPOINT = '/conversation';
export const CREDIT_ENDPOINT = '/credit';
export const CREDITS_ENDPOINT = '/credits';
export const DELIVERABLE_ENDPOINT = '/deliverable';
export const DELIVERABLES_ENDPOINT = '/deliverables';
export const EVENT_ENDPOINT = '/event';
export const EVENTS_ENDPOINT = '/events';
export const EXTENSION_ENDPOINT = '/extension';
export const FILES_ENDPOINT = '/files';
export const FORGOT_ENDPOINT = '/forgot';
export const GIG_ENDPOINT = '/gig';
export const GIGS_ENDPOINT = '/gigs';
export const GREENHOUSE_ENDPOINT = '/integrations/greenhouse';
export const INTEGRATION_ENDPOINT = '/integration';
export const INTEGRATIONS_ENDPOINT = '/integrations';
export const INVITATIONS_ENDPOINT = '/invitations';
export const INVOICE_ENDPOINT = '/invoice';
export const JOB_ENDPOINT = '/job';
export const JOBS_ENDPOINT = '/jobs';
export const LABELS_ENDPOINT = '/labels';
export const LOGIN_ENDPOINT = '/login';
export const LOGOUT_ENDPOINT = '/logout';
export const MEDIA_ENDPOINT = '/media';
export const MEMBER_SEARCH_ENDPOINT = '/searchMembers';
export const MILESTONE_ENDPOINT = '/milestone';
export const MILESTONES_ENDPOINT = '/milestones';
export const NOTIFICATION_ENDPOINT = '/notification';
export const NOTIFICATIONS_ENDPOINT = '/notifications';
export const ONBOARDING_ENDPOINT = '/onboarding';
export const ORGANIZATION_ENDPOINT = '/organization';
export const ORGANIZATIONS_ENDPOINT = '/organizations';
export const PHASES_ENDPOINT = '/phases';
export const PRISM_ENDPOINT = '/prism';
export const PRISMS_ENDPOINT = '/prisms';
export const PROFILE_ENDPOINT = '/profile';
export const PROJECT_ENDPOINT = '/project';
export const PROJECTS_ENDPOINT = '/projects';
export const QUEST_ENDPOINT = '/quest';
export const QUESTS_ENDPOINT = '/quests';
export const QUIZ_ENDPOINT = '/quiz';
export const QUIZZES_ENDPOINT = '/quizzes';
export const RECEIPT_ENDPOINT = '/receipt';
export const RECURRING_EMAIL_UNSUBSCRIBE = '/recurring-email-queue-remove';
export const REFERRAL_ENDPOINT = '/referral';
export const REGISTER_ENDPOINT = '/register';
export const REGISTER_VIP_ENDPOINT = '/register/vip';
export const RESEND_ENDPOINT = '/resend';
export const RESET_ENDPOINT = '/reset';
export const ROLODEX_ENDPOINT = '/rolodex';
export const SCHEDULE_EVENT_ENDPOINT = '/scheduleEvent';
export const SCHEDULE_EVENTS_ENDPOINT = '/scheduleEvents';
export const SEARCH_ENDPOINT = '/search';
export const SKILL_ENDPOINT = '/skill';
export const SKILLS_ENDPOINT = '/skills';
export const SIGNAL_ENDPOINT = '/signal';
export const STEP_META_ENDPOINT = '/stepmeta';
export const SUGGESTED_TALENT_ENDPOINT = '/suggested-talent';
export const SUGGESTED_TASK_ENDPOINT = '/suggestedtask';
export const SUGGESTED_TASKS_ENDPOINT = '/suggestedtasks';
export const SUPPORT_ARTICLE_ENDPOINT = '/support/article/:id';
export const TALENT_ENDPOINT = '/talent';
export const TASK_ENDPOINT = '/task';
export const TASKS_ENDPOINT = '/tasks';
export const TEAM_ENDPOINT = '/team';
export const TEAMS_ENDPOINT = '/teams';
export const TRANSACTION_ENDPOINT = '/transaction';
export const TRANSACTIONS_ENDPOINT = '/transactions';
export const USER_ENDPOINT = '/user';
export const USER_STUB_ENDPOINT = '/user-stub';
export const USER_INTEGRATIONS_ENDPOINT = '/user-integrations';
export const VERIFY_ENDPOINT = '/verify';
export const VOUCH_ENDPOINT = '/vouch';
export const VOUCHES_ENDPOINT = '/vouches';
export const WALLET_ENDPOINT = '/wallet';
export const WORK_APPLICATION_ENDPOINT = '/work-application';
export const WORK_ENDPOINT = '/work';
export const WORK_EXPERIENCE_ENDPOINT = '/work-experience';
export const WORK_EXPERIENCES_ENDPOINT = '/work-experiences';
