import { schema } from 'normalizr';

const WorkPreferences = new schema.Entity(
  'WorkPreferences',
  {},
  {
    idAttribute: 'ID'
  }
);

const WorkPreferencesList = [WorkPreferences];

export { WorkPreferences, WorkPreferencesList };
