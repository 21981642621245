import merge from 'lodash/merge';

import { APP_DEFAULTS_SUCCESS } from 'constants/defaultConstants';

// This reducer listens to any actions that have an "entity"
// key in them. If there no action type-specific logic needs to be performed,
// it will merge the previous entities state with the entities returned in the
// action payload
export default function entities(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    switch (type) {
      case APP_DEFAULTS_SUCCESS: {
        const extractedEntities = payload.entities.reduce((next, normalized) => ({ ...next, ...normalized.entities }), {});
        return { ...state, ...extractedEntities };
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }

  return state;
}
