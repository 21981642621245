import React from 'react';
import { Redirect, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { userIsLoggedIn } from 'utils/authUtils';
import * as loadable from 'utils/loadableComponents';
import withOnboardingRedirect from 'utils/onboardingUtils';
import { withTracker } from 'utils/trackingUtils';
import { ROUTE_PATHS } from 'constants/global';
import { JOB_CLAIM, NOTIFICATIONS_UNSUBSCRIBE } from 'shared/web/constants/routeConstants';
import BannerAlerts from 'containers/BannerAlerts/BannerAlerts';
import { DefaultView, GenericView, WebsiteView } from 'containers/Layouts/Layouts';
import NotFound from 'components/HttpStatus/NotFound';
import Loading from 'components/Loading/Loading';

import './App.less';

const {
  BEACON_CREATE,
  BEACON,
  BEACONS,
  DASHBOARD,
  EVENT,
  EVENTS_DISCOVER,
  EVENTS,
  EXTENSION,
  FORGOT,
  GIG,
  INTEGRATION,
  INTEGRATIONS,
  INVOICE,
  JOB,
  LOGIN,
  LOGOUT,
  NOT_FOUND,
  ONBOARDING,
  ORGANIZATION,
  ORGANIZATIONS,
  PROJECT_CREATE,
  PROJECT,
  PROJECTS,
  QUESTS,
  RECEIPT,
  REGISTER,
  REGISTER_VIP,
  RESET,
  ESTIMATE,
  ROLODEX,
  SIGNAL,
  TALENT,
  TEAM,
  TEAMS,
  USER,
  VERIFY_EMAIL_CHANGE,
  VERIFY_USER_REGISTRATION,
  VERIFY,
  WORK
} = ROUTE_PATHS;

const DefaultViewWithOnboarding = withOnboardingRedirect(DefaultView);

// we have to do this so webpack will build our prod package correctly
const BeaconsView = loadable.BeaconsView();
const BeaconView = loadable.BeaconView();
const CreateProjectView = loadable.CreateProjectView();
const DashboardView = loadable.DashboardView();
const EstimateView = loadable.EstimateView();
const EstimateViewGuest = loadable.EstimateViewGuest();
const EventsDiscoverView = loadable.EventsDiscoverView();
const EventsView = loadable.EventsView();
const EventView = loadable.EventView();
const ExtensionView = loadable.ExtensionView();
const ForgotView = loadable.ForgotView();
const GigView = loadable.GigView();
const IntegrationsView = loadable.IntegrationsView();
const IntegrationView = loadable.IntegrationView();
const InvoiceView = loadable.InvoiceView();
const JobClaimView = loadable.JobClaimView();
const JobView = loadable.JobView();
const LoginView = loadable.LoginView();
const LogoutView = loadable.LogoutView();
const NotificationsUnsubscribeView = loadable.NotificationsUnsubscribeView();
const OnboardingView = loadable.OnboardingView();
const OrganizationsView = loadable.OrganizationsView();
const OrganizationView = loadable.OrganizationView();
const ProjectsView = loadable.ProjectsView();
const ProjectView = loadable.ProjectView();
const QuestsView = loadable.QuestsView();
const ReceiptView = loadable.ReceiptView();
const RegistrationView = loadable.RegistrationView();
const ResetView = loadable.ResetView();
const RolodexView = loadable.RolodexView();
const SignalView = loadable.SignalView();
const SignalViewGuest = loadable.SignalViewGuest();
const TalentView = loadable.TalentView();
const TeamsView = loadable.TeamsView();
const TeamView = loadable.TeamView();
const UserContainerView = loadable.UserContainerView();
const UserEmailChangeView = loadable.UserEmailChangeView();
const UserRegistrationVerifyView = loadable.UserRegistrationVerifyView();
const VerifyView = loadable.VerifyView();
const VipRegistrationView = loadable.VipRegistrationView();
const WorkView = loadable.WorkView();

function App({ location }) {
  return (
    <React.Fragment>
      <BannerAlerts />
      {/* <React.Suspense fallback={<Loading />}> */}
      <Switch>
        <DefaultView path={INVOICE} component={withTracker(InvoiceView)} />
        <DefaultView path={ONBOARDING} hideNav component={withTracker(OnboardingView)} />
        <DefaultView path={RECEIPT} hideNav component={withTracker(ReceiptView)} />
        <DefaultView path={VERIFY_EMAIL_CHANGE} component={withTracker(UserEmailChangeView)} />
        <DefaultView path={VERIFY_USER_REGISTRATION} component={withTracker(UserRegistrationVerifyView)} />
        <DefaultView path={VERIFY} component={withTracker(VerifyView)} />
        <DefaultViewWithOnboarding
          path={[
            BEACON, BEACON_CREATE
          ]}
          component={withTracker(BeaconView)}
        />
        <DefaultViewWithOnboarding path={BEACONS} exact component={withTracker(BeaconsView)} />
        <DefaultViewWithOnboarding path={DASHBOARD} component={withTracker(DashboardView)} />
        <DefaultViewWithOnboarding path={EVENT} component={withTracker(EventView)} />
        <DefaultViewWithOnboarding path={EVENTS_DISCOVER} component={withTracker(EventsDiscoverView)} />
        <DefaultViewWithOnboarding path={EVENTS} exact component={withTracker(EventsView)} />
        <DefaultViewWithOnboarding path={EXTENSION} component={withTracker(ExtensionView)} />
        <DefaultViewWithOnboarding path={GIG} component={withTracker(GigView)} />
        <DefaultViewWithOnboarding path={INTEGRATION} component={withTracker(IntegrationView)} />
        <DefaultViewWithOnboarding path={INTEGRATIONS} component={withTracker(IntegrationsView)} />
        <DefaultViewWithOnboarding path={JOB_CLAIM} component={withTracker(JobClaimView)} />
        <DefaultViewWithOnboarding path={JOB} component={withTracker(JobView)} />
        <DefaultViewWithOnboarding path={NOT_FOUND} component={withTracker(NotFound)} />
        <DefaultViewWithOnboarding path={NOTIFICATIONS_UNSUBSCRIBE} component={withTracker(NotificationsUnsubscribeView)} />
        <DefaultViewWithOnboarding path={ORGANIZATION} component={withTracker(OrganizationView)} />
        <DefaultViewWithOnboarding path={ORGANIZATIONS} component={withTracker(OrganizationsView)} />
        <DefaultViewWithOnboarding path={PROJECT_CREATE} exact component={withTracker(CreateProjectView)} />
        <DefaultViewWithOnboarding path={PROJECT} component={withTracker(ProjectView)} />
        <DefaultViewWithOnboarding path={PROJECTS} component={withTracker(ProjectsView)} />
        <DefaultViewWithOnboarding path={QUESTS} exact component={withTracker(QuestsView)} />
        <DefaultViewWithOnboarding path={ROLODEX} component={withTracker(RolodexView)} />
        <DefaultViewWithOnboarding path={TALENT} component={withTracker(TalentView)} />
        <DefaultViewWithOnboarding path={TEAM} component={withTracker(TeamView)} />
        <DefaultViewWithOnboarding path={TEAMS} component={withTracker(TeamsView)} />
        <DefaultViewWithOnboarding path={USER} component={withTracker(UserContainerView)} />
        <DefaultViewWithOnboarding path={WORK} component={withTracker(WorkView)} />
        {
          userIsLoggedIn()
            ? <DefaultViewWithOnboarding path={ESTIMATE} component={withTracker(EstimateView)} />
            : <WebsiteView path={ESTIMATE} component={withTracker(EstimateViewGuest)} />
        }
        {
          userIsLoggedIn()
            ? <DefaultViewWithOnboarding path={SIGNAL} component={withTracker(SignalView)} />
            : <WebsiteView layout="isMarketing" path={SIGNAL} component={withTracker(SignalViewGuest)} />
        }
        <GenericView path={FORGOT} component={withTracker(ForgotView)} />
        <GenericView path={LOGIN} component={withTracker(LoginView)} />
        <GenericView path={LOGOUT} component={withTracker(LogoutView)} />
        <GenericView path={REGISTER_VIP} component={withTracker(VipRegistrationView)} />
        <GenericView
          component={withTracker(RegistrationView)}
          description="Sign up for Rupie to gain access to the games industry's best pre-vetted, full-time hires and flexible contractors prepped and ready to connect with recruiting and hiring professionals."
          path={REGISTER}
          title="Rupie Sign Up"
        />
        <GenericView path={RESET} component={withTracker(ResetView)} />
        {
          userIsLoggedIn()
            ? <Redirect path="/" to={{ pathname: DASHBOARD, search: location.search }} />
            : <Redirect path="/" to={{ pathname: LOGIN, search: location.search }} />
        }
        <Redirect to={NOT_FOUND} />
      </Switch>
      {/* </React.Suspense> */}
    </React.Fragment>
  );
}

App.propTypes = {
  location: PropTypes.object
};

App.defaultProps = {
  location: null
};

export default withRouter(App);
