import {
  SEARCH_START,
  SEARCH_DONE,
  SEARCH_ERROR
} from 'constants/searchConstants';
import { MEMBER_SEARCH_ENDPOINT, SEARCH_ENDPOINT } from 'constants/global';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

export function fetchMemberSearch(query = '', searchScope) {
  if (!searchScope) {
    throw Error('A searchScope is required for Member search');
  }

  const parentsParams = Object.keys(searchScope)
    .reduce((strng, key) => `${strng}&parents[${key}]=${searchScope[key]}`, '?');

  return {
    types: {
      loading: SEARCH_START,
      success: SEARCH_DONE,
      error: SEARCH_ERROR
    },
    apiParams: {
      endpoint: `${MEMBER_SEARCH_ENDPOINT}${parentsParams}`,
      data: {
        q: query
      }
    }
  };
}

export function fetchSearch(searchType, query = '', types = [], searchScope) {
  switch (searchType) {
    case ENTITY_TYPES.MEMBER:
      return fetchMemberSearch(query, searchScope);
    default:
      return {
        types: {
          loading: SEARCH_START,
          success: SEARCH_DONE,
          error: SEARCH_ERROR
        },
        apiParams: {
          endpoint: SEARCH_ENDPOINT,
          data: {
            q: query,
            types
          }
        }
      };
  }
}
