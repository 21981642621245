
import {
  GIG_FETCH_META,
  GIG_FETCH_SUMMARY,
  GIG_FETCH_PROPOSAL_META
} from 'constants/gigConstants';

export function proposalMetaDataById(state = {}, action) {
  const { payload, type, gigId } = action;

  switch (type) {
    case GIG_FETCH_PROPOSAL_META._SUCCESS:
      return {
        ...state,
        [gigId]: {
          ...payload
        }
      };
    default:
      return state;
  }
}

export function gigMetaDataById(state = {}, action) {
  const { payload, type, gigId } = action;

  switch (type) {
    case GIG_FETCH_META._SUCCESS:
    case GIG_FETCH_SUMMARY._SUCCESS:
      return {
        ...state,
        [gigId]: {
          ...state[gigId],
          ...payload
        }
      };
    default:
      return state;
  }
}
