import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getBreakpoints } from 'shared/web/utils/imageUtils';

import './Image.less';

export default class Image extends Component {
  static propTypes = {
    className: PropTypes.string,
    clickable: PropTypes.bool,
    image: PropTypes.object.isRequired,
    maxWidth: PropTypes.number,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  }

  static defaultProps = {
    className: '',
    clickable: false,
    maxWidth: 1603,
    onClick: () => { },
    onMouseEnter: () => { },
    onMouseLeave: () => { }
  }

  render() {
    const {
      image,
      maxWidth,
      className,
      onClick,
      onMouseLeave,
      onMouseEnter,
      clickable
    } = this.props;

    const {
      Description, src: imgSrc, Title, Url: UrlObject
    } = image;

    const { breakpoints, srcSet, src } = getBreakpoints(UrlObject, maxWidth);

    return (
      <div
        className={classNames('image', className)}
        onKeyPress={onClick}
        onClick={onClick}
        role="button"
        tabIndex="-1"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ProgressiveImage
          src={imgSrc || src}
          srcSetData={
            srcSet && {
              srcSet,
              sizes: `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`
            }
          }
          placeholder={breakpoints ? breakpoints.blur : src}
        >
          {
            (src, loading, srcSetData) => (
              <img
                alt={image ? (Description || Title) : ''}
                className={classNames({
                  'image-blur': loading,
                  'image--clickable': clickable
                })}
                sizes={srcSetData && srcSetData.sizes}
                src={imgSrc || src}
                srcSet={srcSetData && srcSetData.srcSet}
                title={Title}
              />
            )
          }
        </ProgressiveImage>
      </div>
    );
  }
}
