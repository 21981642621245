import merge from 'lodash/merge';
import * as deliverableConstants from 'constants/deliverableConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { DELIVERABLE } = ENTITY_TYPES;

export default function entities(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    const { deliverable } = action;

    switch (type) {
      case deliverableConstants.DELETE_DELIVERABLE._SUCCESS: {
        const newState = {
          ...state
        };
        delete newState[DELIVERABLE][deliverable.ID];
        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
