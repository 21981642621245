import { schema } from 'normalizr';

export const Prism = new schema.Entity(
  'Prism',
  {},
  {
    idAttribute: 'ID'
  }
);

export const PrismList = [Prism];
