import { createHashHistory, createBrowserHistory } from 'history';
import setupPolyfills from 'utils/polyfillUtils';
import { matchPath } from 'react-router';

setupPolyfills();

// eslint-disable-next-line import/no-mutable-exports
export let history;

if (window.electron) {
  history = createHashHistory();
} else {
  history = createBrowserHistory();
}


export const getMatchPath = (history, path) => matchPath(history.location.pathname, {
  path,
  exact: false,
  strict: false
});
