import { generateAsyncActions } from 'utils/actionUtils';

export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID';
export const SET_CURRENT_PROJECT_PERMALINK = 'SET_CURRENT_PROJECT_PERMALINK';

export const CREATE_PROJECT = generateAsyncActions('CREATE_PROJECT');

export const PROJECT_FETCH = generateAsyncActions('PROJECT_FETCH');

export const PROJECT_UPDATE = generateAsyncActions('PROJECT_UPDATE');

export const PROJECT_DELETE = generateAsyncActions('PROJECT_DELETE');

export const PROJECTS_FETCH = generateAsyncActions('PROJECTS_FETCH');

export const PROJECT_ADD_MEDIA = generateAsyncActions('PROJECT_ADD_MEDIA');

export const PROJECT_DELETE_MEDIA = generateAsyncActions('PROJECT_DELETE_MEDIA');

export const PROJECT_UPDATE_OWNER = generateAsyncActions('PROJECT_UPDATE_OWNER');

export const PROJECT_ADD_MEMBER = generateAsyncActions('PROJECT_ADD_MEMBER');

export const PROJECT_REMOVE_MEMBER = generateAsyncActions('PROJECT_REMOVE_MEMBER');

export const PROJECT_UPDATE_MEMBER = generateAsyncActions('PROJECT_UPDATE_MEMBER');

export const PROJECT_SET_SOCIAL = generateAsyncActions('PROJECT_SET_SOCIAL');

export const PROJECT_CHANGED = 'PROJECT_CHANGED';
export const PROJECT_DELETED = 'PROJECT_DELETED';

export const PROJECT_MENU_ITEMS = {
  OVERVIEW: 'Overview',
  BUILD: 'Build',
  SETTINGS: 'Settings',
  WORK: 'Work',
  COMMUNITY: 'Community'
};
