export const NOVICE = 'Novice';
export const APPRENTICE = 'Apprentice';
export const ADVANCED = 'Advanced';
export const COMPETENT = 'Competent';
export const PROFICIENT = 'Proficient';
export const EXPERT = 'Expert';

export const WORK_EXPERIENCE_YEARS = {
  [NOVICE]: 0,
  [APPRENTICE]: '1',
  [ADVANCED]: '3',
  [COMPETENT]: '5',
  [PROFICIENT]: '10',
  [EXPERT]: '15'
};
