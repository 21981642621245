/* eslint-disable simple-import-sort/imports */
import { normalize } from 'normalizr';

import { APP_DEFAULTS_SUCCESS, APP_DEFAULTS_ERROR, APP_DEFAULTS_START } from 'constants/defaultConstants';
import { LABELS_ENDPOINT, PHASES_ENDPOINT, SKILLS_ENDPOINT } from 'constants/global';
import {
  GenreList, LabelList, PhaseList, SkillList, TagList
} from 'schemas/index';
import { loadingState } from 'utils/actionUtils';

function appDefaultsHasErrored(error) {
  return {
    error,
    isLoading: false,
    type: APP_DEFAULTS_ERROR
  };
}

function fetchLabelDefaults() {
  return {
    apiParams: {
      endpoint: `${LABELS_ENDPOINT}`,
      returnPromise: true
    },
    types: {}
  };
}

function fetchSkillsDefaults() {
  return {
    apiParams: {
      endpoint: `${SKILLS_ENDPOINT}`,
      returnPromise: true
    },
    types: {}
  };
}

/**
 * Async action that fetches application defaults
 * @return {Function}         The Thunk function to resolve
 */
export function fetchAppDefaults() {
  return async dispatch => {
    dispatch(loadingState(APP_DEFAULTS_START));

    const defaultsRequest = await Promise.all(
      [
        dispatch(fetchLabelDefaults()),
        dispatch(fetchSkillsDefaults())
      ]
    ).catch(error => {
      dispatch(appDefaultsHasErrored(error));
    });

    const defaultData = (defaultsRequest || []).reduce((next, request) => {
      if (request.data) {
        next.push(request.data);
      }
      return next;
    }, []);

    const [
      LabelCollection, Skills
    ] = defaultData;

    const { Genres = [], Labels = [], Tags = [] } = LabelCollection || {};

    const entities = [];

    if (Labels && LabelList) {
      entities.push(normalize(Labels, LabelList));
    }
    if (Genres && GenreList) {
      entities.push(normalize(Genres, GenreList));
    }
    if (Tags && TagList) {
      entities.push(normalize(Tags, TagList));
    }
    if (Skills && SkillList) {
      entities.push(normalize(Skills, SkillList));
    }

    if (Labels && LabelList) {
      return dispatch({
        payload: {
          entities
        },
        type: APP_DEFAULTS_SUCCESS
      });
    }
  };
}
