import { ADD_SHARDS_TO_USER_SUCCESS } from 'shared/constants/shardConstants';
import { CLOSE_MODAL } from 'constants/shardConstants';

export function shardModal(state = { visible: false, reason: null }, action) {
  const { payload, type } = action;
  switch (type) {
    case ADD_SHARDS_TO_USER_SUCCESS:
    case CLOSE_MODAL:
      const { shardModal } = payload;
      return shardModal;
    default:
      return state;
  }
}
