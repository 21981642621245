import { createSelector } from 'reselect';

import { getHirePreferencesEntities } from 'selectors/stateSelectors';
import { getUserByIDFilter } from 'selectors/userSelectors';

export const getHiringPreferences = createSelector(
  getUserByIDFilter,
  getHirePreferencesEntities,
  (user, hirePreferencesEntities) => Object.values(hirePreferencesEntities).find(({ UserxHirePreferences }) => UserxHirePreferences.UserID === user.ID)
);
