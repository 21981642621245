import {
  GET_SUPPORT_ARTICLES,
} from 'constants/supportConstants';

/**
 * Returns a new 'supportArticles' state
 * @param  {Object}  [articleState={}] The article or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with article
 */
export function supportArticles(state = {}, action) {
  const { type, supportArticleName, payload } = action;

  switch (type) {
    case GET_SUPPORT_ARTICLES._START:
    case GET_SUPPORT_ARTICLES._ERROR: {
      return { ...state };
    }
    case GET_SUPPORT_ARTICLES._SUCCESS:
      return { ...state, [supportArticleName]: payload };
    default:
      return state;
  }
}
