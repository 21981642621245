import { normalize } from 'normalizr';

import { Credit, CreditList } from 'schemas/index';
import { errorState, loadingState, successState } from 'utils/actionUtils';
import { withId, withIds } from 'utils/dataUtils';
import {
  GET_CREDIT,
  GET_CREDITS
} from 'constants/creditConstants';
import { CREDIT_ENDPOINT, CREDITS_ENDPOINT } from 'constants/endpointConstants';

/**
 * Async action that fetches credits
 * @param  {String} organizationId   optional organization to get
 * @return {Function}         The Thunk function to resolve
 */
export function fetchCredits(organizationId) {
  return (dispatch, getState) => dispatch({
    types: {
      loading: () => loadingState(GET_CREDITS, { organizationId }),
      success: credits => successState(GET_CREDITS, normalize(credits, CreditList), { organizationId }),
      error: err => errorState(GET_CREDITS, err)
    },
    apiParams: {
      method: 'get',
      endpoint: `${CREDITS_ENDPOINT}`,
      queryParams: { ...organizationId ? { organizationId } : {} }
    }
  });
}

/**
 * Async action that fetches a credit
 * @param  {String} creditId  the credit id
 * @return {Function}         The Thunk function to resolve
 */
export function fetchCredit(creditId) {
  return (dispatch, getState) => dispatch({
    types: {
      loading: () => loadingState(GET_CREDIT, { creditId }),
      success: credit => successState(GET_CREDIT, normalize(credit, Credit), { creditId }),
      error: err => errorState(GET_CREDIT, err)
    },
    apiParams: {
      method: 'get',
      endpoint: `${CREDIT_ENDPOINT}/${creditId}`
    }
  });
}
