import { createSelector } from 'reselect';
import { getUserId } from 'selectors/userSelectors';
import { getNotificationEntities } from './stateSelectors';

export const getNotifications = createSelector(
  getUserId,
  getNotificationEntities,
  (userId, notificationEntities) => Object.values(notificationEntities)
    .filter(notification => notification.UserID === userId)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
);
