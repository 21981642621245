// sets the action symbols
const ACTIONS = {
  BILLING: Symbol('billing'),
  DELETE: Symbol('delete'),
  GET: Symbol('get'),
  MANAGE_GIG: Symbol('manage gig'),
  MODERATE: Symbol('moderate'),
  PATCH: Symbol('patch'),
  POST: Symbol('post'),
  PRIVATE: Symbol('private'),
  PUT: Symbol('put'),
  READ: Symbol('read')
};

// defines the config
const RBAC_CONFIG = {
  ROLES: {
    // is god among humans
    ADMIN: {
      id: 1,
      can: [
        ACTIONS.DELETE,
        ACTIONS.PATCH
      ],
      inherits: [
        'MANAGER',
        'CONTRIBUTOR'
      ]
    },
    // can read private stuff and write manager stuff
    MANAGER: {
      id: 2,
      can: [
        ACTIONS.MODERATE,
        ACTIONS.POST,
        ACTIONS.PRIVATE,
        ACTIONS.BILLING,
        ACTIONS.MANAGE_GIG,
        ACTIONS.PUT
      ],
      inherits: ['CONTRIBUTOR']
    },
    // can read and write to their own stuff
    CONTRIBUTOR: {
      id: 3,
      can: [
        ACTIONS.GET, ACTIONS.READ
      ],
      inherits: []
    }
  }
};

function processRoleData(roles) {
  Object.keys(roles).forEach(typeKey => {
    const currentType = RBAC_CONFIG.ROLES[typeKey];

    currentType.inherits.forEach(accessType => {
      roles[typeKey].can = [
        ...currentType.can,
        ...RBAC_CONFIG.ROLES[accessType].can
      ];
    });
  });

  return roles;
}

// creates a processed instance of the ACCESS_CONTROL data
const ACCESS_CONTROL = {
  ...{
    // check if the actions for a given role are legit
    can(actions = [], roleIDs) {
      const roles = processRoleData({ ...this.ROLES });

      // ensure Array and find min value
      const lowestID = Math.min(...!Array.isArray(roleIDs) ? [roleIDs] : roleIDs);
      if (!actions.length) {
        throw Error('missing actions for can in ACCESS_CONTROL');
      }
      return !!Object.values(roles).reduce((prev, current) => {
        if (current && current.id && current.id === lowestID) {
          const matched = current.can.some(element => actions.indexOf(element) > -1);
          if (matched) {
            prev.push(matched);
          } else {
            return prev;
          }
        }
        return prev;
      }, []).length;
    },
    // get the role by actions from ASC sorted list of roles
    getRoleByActions(actions) {
      const roles = Object.values(this.ROLES).sort((rolePrev, roleCurrent) => rolePrev.id < roleCurrent.id)
        .reduce((prev, current) => {
          if (current) {
            const foundActions = current.can.filter(action => actions.indexOf(action) !== -1);

            if (prev > 0) {
              return prev;
            } if (foundActions.length === actions.length) {
              return current.id;
            }
          }

          return prev;
        }, 0);

      if (!roles) {
        throw Error('Role not found for actions!');
      }

      return roles;
    },
    getRoleNameFromId(id) {
      return Object.entries(this.ROLES).reduce((prev, [
        roleName, roleData
      ]) => {
        let val = prev;
        if (id === roleData.id) {
          val = roleName;
        }
        return val;
      }, '');
    },
    getRoleIdfromName(name) {
      return Object.entries(this.ROLES).reduce((prev, [
        roleName, roleData
      ]) => {
        let val = prev;
        if (name.toUpperCase() === roleName.toUpperCase()) {
          val = roleData.id;
        }
        return val;
      }, null);
    }
  },
  ...RBAC_CONFIG,
  ACTIONS
};

export {
  ACCESS_CONTROL,
  ACTIONS,
  RBAC_CONFIG
};
