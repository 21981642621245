import { USER_ENDPOINT } from 'constants/global';
import { CHECK_ADD_SHARDS, CLOSE_MODAL } from 'constants/shardConstants';
import { loadingState, errorState, successState } from 'utils/actionUtils';
import { withId } from 'utils/dataUtils';
import { getUserId } from 'selectors/userSelectors';

export function checkAddShards(reason) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(CHECK_ADD_SHARDS, { ID }),
        success: () => successState(CHECK_ADD_SHARDS, { entities: {} }, {}),
        error: err => errorState(CHECK_ADD_SHARDS, err)
      },
      apiParams: {
        method: 'post',
        endpoint: withId(`${USER_ENDPOINT}/:id/shards`, ID),
        data: {
          reason
        }
      }
    });
  };
}

/**
 * closes the shard modal
 */
export function closeShardModal() {
  return dispatch => dispatch({
    type: CLOSE_MODAL,
    payload: {
      shardModal: {
        visible: false,
        reason: null
      }
    }
  });
}
