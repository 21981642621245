import { schema } from 'normalizr';

const Producer = new schema.Entity(
  'Producer',
  {},
  {
    idAttribute: 'ID'
  }
);

const ProducerList = [Producer];

export {
  Producer, ProducerList
};
