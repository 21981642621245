import { schema } from 'normalizr';

// eslint-disable-next-line import/no-cycle
import { TeamList, User, UserList } from './index';

const Comment = new schema.Entity(
  'Comment',
  {
    Author: User
  },
  {
    idAttribute: 'ID'
  }
);

const CommentList = [Comment];

const Conversation = new schema.Entity(
  'Conversation',
  {
    Author: User,
    ConversationGigUser: UserList,
    ConversationGigTeam: TeamList,
    Comments: CommentList,
    TalentNotesTalent: UserList
  },
  {
    idAttribute: 'ID'
  }
);

const ConversationList = [Conversation];

export {
  Comment, CommentList, Conversation, ConversationList
};
