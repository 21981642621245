export const SOCKET_PATH = 'ws/';

export const SOCKET_PROXY_ACTION = 'SOCKET_PROXY_ACTION';
export const SOCKET_PROJECT_CHANGED = 'SOCKET_PROJECT_CHANGED';
export const SOCKET_PROJECT_DELETED = 'SOCKET_PROJECT_DELETED';

export const SOCKET_TASK_CHANGED = 'SOCKET_TASK_CHANGED';
export const SOCKET_GIG_CHANGED = 'SOCKET_GIG_CHANGED';
export const SOCKET_CONVERSATION_CHANGED = 'SOCKET_CONVERSATION_CHANGED';
export const SOCKET_CONVERSATION_WILL_CHANGE = 'SOCKET_CONVERSATION_WILL_CHANGE';

export const SOCKET_CONVERSATION_COMMENT_ADDED = 'SOCKET_CONVERSATION_COMMENT_ADDED';

export const SOCKET_CONVERSATION_USER_IS_TYPING = 'SOCKET_CONVERSATION_USER_IS_TYPING';

export const SOCKET_ADD_SHARDS_TO_USER = 'SOCKET_ADD_SHARDS_TO_USER';
