import { generateAsyncActions } from 'utils/actionUtils';
import { USER_WORK_APPLICATION_STATUS_ENUM } from 'shared/constants/enumConstants';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';

export const FETCH_USER_PROFILE_START = 'FETCH_USER_PROFILE_START';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';

export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const USER_PHOTO_FETCH_START = 'USER_PHOTO_FETCH_START';
export const USER_PHOTO_FETCH_SUCCESS = 'USER_PHOTO_FETCH_SUCCESS';
export const USER_PHOTO_FETCH_ERROR = 'USER_PHOTO_FETCH_ERROR';

export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_UPDATE_PREFS_START = 'USER_UPDATE_PREFS_START';
export const USER_UPDATE_PREFS_SUCCESS = 'USER_UPDATE_PREFS_SUCCESS';
export const USER_UPDATE_PREFS_ERROR = 'USER_UPDATE_PREFS_ERROR';

export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const USER_ADD_MEDIA_START = 'USER_ADD_MEDIA_START';
export const USER_ADD_MEDIA_SUCCESS = 'USER_ADD_MEDIA_SUCCESS';
export const USER_ADD_MEDIA_ERROR = 'USER_ADD_MEDIA_ERROR';

export const USER_DELETE_MEDIA_START = 'USER_DELETE_MEDIA_START';
export const USER_DELETE_MEDIA_SUCCESS = 'USER_DELETE_MEDIA_SUCCESS';
export const USER_DELETE_MEDIA_ERROR = 'USER_DELETE_MEDIA_ERROR';

export const ADD_USER_GAMES = generateAsyncActions('ADD_USER_GAMES');
export const CREATE_USER_REFERRAL_INVITATION = generateAsyncActions('CREATE_USER_REFERRAL_INVITATION');
export const CREATE_WORK_APPLICATION = generateAsyncActions('CREATE_WORK_APPLICATION');
export const FETCH_SENT_REFERRAL_INVITATIONS = generateAsyncActions('FETCH_SENT_REFERRAL_INVITATIONS');
export const FETCH_SENT_REFERRALS = generateAsyncActions('FETCH_SENT_REFERRALS');
export const FETCH_INTERNAL_RECORDS = generateAsyncActions('FETCH_INTERNAL_RECORDS');
export const FETCH_USER_PREMIUM_DATA = generateAsyncActions('FETCH_USER_PREMIUM_DATA');
export const FETCH_USER_STUB = generateAsyncActions('FETCH_USER_STUB');
export const FORGOT_USERNAME = generateAsyncActions('FORGOT_USERNAME');
export const REMOVE_USER_GAMES = generateAsyncActions('REMOVE_USER_GAMES');
export const REMOVE_USER_REFERRAL_INVITATION = generateAsyncActions('REMOVE_USER_REFERRAL_INVITATION');
export const RESEND_USER_REFERRAL_INVITATION = generateAsyncActions('RESEND_USER_REFERRAL_INVITATION');
export const UPDATE_WORK_APPLICATION = generateAsyncActions('UPDATE_WORK_APPLICATION');
export const USER_ADD_SOCIAL = generateAsyncActions('USER_ADD_SOCIAL');
export const USER_BACKFILL = generateAsyncActions('USER_BACKFILL');
export const USER_COMPLETE_ONBOARDING = generateAsyncActions('USER_COMPLETE_ONBOARDING');
export const USER_COMPLETE_REGISTRATION = generateAsyncActions('USER_COMPLETE_REGISTRATION');
export const USER_DISCONNECT_SOCIAL = generateAsyncActions('USER_DISCONNECT_SOCIAL');
export const USER_DOWNLOAD_PDF = generateAsyncActions('USER_DOWNLOAD_PDF');
export const USER_IMPORT_LINKEDIN = generateAsyncActions('USER_IMPORT_LINKEDIN');
export const USER_INSIGHTS = generateAsyncActions('USER_INSIGHTS');
export const USER_REGISTER_VIP = generateAsyncActions('USER_REGISTER_VIP');

export const COMPANY_ROLE = [
  {
    key: 0,
    value: 'recruiter',
    title: 'recruiter'
  },
  {
    key: 1,
    value: 'sourcer',
    title: 'Sourcer'
  },
  {
    key: 2,
    value: 'hiring manager',
    title: 'Hiring Manager'
  },
  {
    key: 3,
    value: 'studio head',
    title: 'Studio Head'
  },
  {
    key: 4,
    value: 'studio contact',
    title: 'Studio Contact'
  },
  {
    key: 5,
    value: 'other',
    title: 'Other'
  }
];

export const MAX_NUMBER_OF_GAMES = 5;
export const USER_DECREMENT_PROFILE_PROGRESS = 'USER_DECREMENT_PROFILE_PROGRESS';
export const USER_INCREMENT_PROFILE_PROGRESS = 'USER_INCREMENT_PROFILE_PROGRESS';

export const WORK_APPLICATION_STATUS_MAP = {
  [USER_WORK_APPLICATION_STATUS_ENUM.DRAFT]: 'rupie-tag--gray',
  [USER_WORK_APPLICATION_STATUS_ENUM.PENDING]: 'rupie-tag--yellow',
  [USER_WORK_APPLICATION_STATUS_ENUM.COMPLETE]: 'rupie-tag--green',
  [USER_WORK_APPLICATION_STATUS_ENUM.REJECTED]: 'rupie-tag--red'
};

export const WORK_MODE_WORK = 'work';
export const WORK_MODE_HIRE = 'hire';
