import { generateAsyncActions } from 'utils/actionUtils';

export const FETCH_ROLODEX = generateAsyncActions('FETCH_ROLODEX');
export const UPDATE_ROLODEX = generateAsyncActions('UPDATE_ROLODEX');
export const FETCH_ROLODEX_TALENT = generateAsyncActions('FETCH_ROLODEX_TALENT');
export const ROLODEX_TALENT_LIST = 'ROLODEX_TALENT_LIST';
export const REMOVE_ROLODEX_TALENT = generateAsyncActions('REMOVE_ROLODEX_TALENT');
export const CREATE_ROLODEX_TALENT = generateAsyncActions('CREATE_ROLODEX_TALENT');
export const UPDATE_ROLODEX_TALENT = generateAsyncActions('UPDATE_ROLODEX_TALENT');
export const DOWNLOAD_TALENT_LIST = generateAsyncActions('DOWNLOAD_TALENT_LIST');
export const DOWNLOAD_TALENT_RESUME = generateAsyncActions('DOWNLOAD_TALENT_RESUME');
export const EMAIL_TALENT_UPDATE_REQUEST = generateAsyncActions('EMAIL_TALENT_UPDATE_REQUEST');
