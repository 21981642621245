import { schema } from 'normalizr';

const Game = new schema.Entity(
  'Game',
  {},
  {
    idAttribute: 'ID'
  }
);

const GameList = [Game];

export { Game, GameList };
