import { generateAsyncActions } from 'utils/actionUtils';
import { SCHEDULE_EVENT_METHOD_ENUM } from 'shared/constants/enumConstants';

export const SCHEDULE_EVENT_FETCH = generateAsyncActions('SCHEDULE_EVENT_FETCH');
export const SCHEDULE_EVENT_FETCH_WITH_USERS = generateAsyncActions('SCHEDULE_EVENT_FETCH_WITH_USERS');
export const SCHEDULE_EVENT_BLOCK_TIME = generateAsyncActions('SCHEDULE_EVENT_BLOCK_TIME');
export const SCHEDULE_EVENT_REMOVE_BLOCK_TIME = generateAsyncActions('SCHEDULE_EVENT_REMOVE_BLOCK_TIME');
export const SCHEDULE_EVENT_BOOK_ENTITY = generateAsyncActions('SCHEDULE_EVENT_BOOK_ENTITY');
export const REQUEST_SCHEDULE_TIME = generateAsyncActions('REQUEST_SCHEDULE_TIME');
export const FETCH_SCHEDULE_EVENT = generateAsyncActions('FETCH_SCHEDULE_EVENT');
export const CREATE_GOOGLE_EVENT = generateAsyncActions('CREATE_GOOGLE_EVENT');
export const UPDATE_SCHEDULE_EVENT = generateAsyncActions('UPDATE_SCHEDULE_EVENT');
export const REMOVE_SCHEDULE_EVENT = generateAsyncActions('REMOVE_SCHEDULE_EVENT');

const { IN_PERSON, PHONE, VIDEO } = SCHEDULE_EVENT_METHOD_ENUM;

export const SCHEDULE_EVENT_METHOD_LABEL = {
  [IN_PERSON]: 'Method: In Person',
  [PHONE]: 'Method: Phone Call',
  [VIDEO]: 'Method: Video Chat'
};
