import { compact, has } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { ACCESS_CONTROL } from 'shared/utils/authorization';
import { LAYOUT_DEFAULTS } from 'constants/layoutConstants';
import {
  INVITATION_SENDER_TYPE_ENUM as INVITATION_SENDER_TYPE,
  INVITATION_TYPE_ENUM as INVITATION_TYPE,
  USER_MEDIA_TYPE_ENUM
} from 'shared/constants/enumConstants';
import { filterEntitiesById } from 'selectors/genericSelectors';
import {
  getConversationEntities,
  getFileEntities,
  getGameEntities,
  getHirePreferencesEntities,
  getInternalRecordEntities,
  getInvitationEntities,
  getMediaEntities,
  getProfileEntities,
  getQuizEntities,
  getReferralEntities,
  getUserEntities,
  getUserSkillEntities,
  getUserStubEntities,
  getWorkApplicationEntities,
  getWorkPreferencesEntities
} from 'selectors/stateSelectors';

export function getUserFilesAndMedia(user, fileEntities, mediaEntities = []) {
  const userMediaFiles = Object.values(fileEntities).filter(file => {
    if (file.UserxFile && file.UserxFile.UserID === user.ID && file.Media) {
      return file;
    }
  });
  const userMediaIDs = user.Media || [];
  const userMedia = compact(userMediaIDs.reduce((entities, item) => entities.concat(mediaEntities[item]), []));

  const userFiles = Object.values(fileEntities).filter(file => {
    if (file.UserxFile && file.UserxFile.UserID === user.ID && !file.Media) {
      return file;
    }
  });

  const userAvatar = userMedia.find(item => {
    const { UserxMedia } = item;

    if (!UserxMedia) {
      return false;
    }

    return UserxMedia.Type === USER_MEDIA_TYPE_ENUM.AVATAR;
  }) || userMediaFiles.find(file => {
    const { MediaID } = file;
    if (!MediaID) {
      return false;
    }
    return Object.values(mediaEntities).find(media => media.Type === USER_MEDIA_TYPE_ENUM.AVATAR && media.ID === MediaID);
  }) || {};

  const userBanner = userMedia.find(item => {
    const { UserxMedia } = item;

    if (!UserxMedia) {
      return false;
    }

    return UserxMedia.Type === USER_MEDIA_TYPE_ENUM.BANNER;
  }) || userMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === USER_MEDIA_TYPE_ENUM.BANNER && media.ID === MediaID);
  }) || {};

  return {
    userAvatar,
    userBanner,
    userFiles
  };
}

export const userStateExists = state => {
  if (has(state, 'user.currentUser.data.User.ID')) {
    return true;
  }

  return false;
};

export const getUserId = state => {
  if (userStateExists(state)) {
    return state?.user?.currentUser?.data?.User?.ID;
  }

  return null;
};

// if ProjectID is passed to the connected component, will extract and use this project in the selector
export const getUserByIDFilter = (state, props) => {
  const { UserID, userId, match } = props;

  const currentUserId = UserID || userId;

  if (props && currentUserId) {
    return getUserEntities(state)[currentUserId];
  } if (match && match.params.id) {
    return getUserEntities(state)[match.params.id];
  } if (props.useCurrentUser) {
    return getUserEntities(state)[getUserId(state)];
  }
  return {};
};

export const getBaseUserProfile = createSelector(
  getUserByIDFilter,
  getFileEntities,
  getGameEntities,
  getHirePreferencesEntities,
  getInternalRecordEntities,
  getMediaEntities,
  getProfileEntities,
  getQuizEntities,
  getUserSkillEntities,
  getWorkApplicationEntities,
  getWorkPreferencesEntities,
  getConversationEntities,
  (User = {},
    fileEntities,
    gameEntities,
    hirePreferencesEntities,
    internalRecordEntities,
    mediaEntities,
    profileEntities,
    quizEntities,
    userSkillEntities,
    workApplicationEntities,
    workPreferencesEntities,
    conversationEntities) => {
    const Profile = Object.values(profileEntities).filter(profile => profile.UserID === User.ID)[0] || {};

    const SelfNotes = Object.values(conversationEntities).reduce((selfNotes, conversation) => {
      if (conversation.UserxTalentxConversation && conversation.UserxTalentxConversation.TalentID === User.ID) {
        selfNotes.push(conversation);
      }
      return selfNotes;
    }, []);

    const Skills = Object.values(userSkillEntities)
      .filter(skill => skill.UserID === User.ID)
      .sort((a, b) => b.XP - a.XP);

    const WorkApplications = Object.values(workApplicationEntities)
      .filter(workApplication => {
        if (workApplication.UserID === User.ID) {
          workApplication.File = Object.values(fileEntities).find(file => workApplication.FileID === file.ID);
          return workApplication;
        }
      })
      .sort((a, b) => (new Date(a.createdAt) - new Date(b.createdAt)));

    const WorkPreferences = filterEntitiesById(User.WorkPreferences, workPreferencesEntities);
    const HirePreferences = Object.values(hirePreferencesEntities).find(({ UserxHirePreferences }) => UserxHirePreferences.UserID === User.ID);
    const InternalRecords = Object.values(internalRecordEntities).filter(InternalRecord => InternalRecord?.TalentID === User.ID)
      .sort((a, b) => moment(a.createdAt) - moment(b.createdAt));

    const Games = Object.values(gameEntities)
      .filter(game => {
        const { UserxGame: { UserID = null } = {} } = game;
        return UserID === User.ID;
      });

    const { error, isLoading } = User;

    return {
      User: {
        ...User,
        HirePreferences,
        InternalRecords,
        Skills,
        WorkApplications,
        WorkPreferences
      },
      Games,
      Quizzes: Object.values(quizEntities),
      SelfNotes,
      ...getUserFilesAndMedia(User, fileEntities, mediaEntities),
      Profile,
      isLoading,
      error
    };
  }
);

export const getUserIdFilter = (state, props) => {
  const { UserID } = props;

  return UserID || getUserId(state);
};

// TODO: rename and add getBaseUserProfile selector to it so that its adding work data
// TODO: update those files that are calling it, so that the states come back correctly (eg. getBaseUserProfile has User, etc)
export const getUserData = createSelector(
  state => userStateExists(state) && state.user.currentUser.data.User,
  getFileEntities,
  getMediaEntities,
  getWorkApplicationEntities,
  getWorkPreferencesEntities,
  getHirePreferencesEntities,
  (user, fileEntities, mediaEntities, workApplicationEntities, workPreferencesEntities, hirePreferencesEntities) => {
    if (user) {
      const Media = filterEntitiesById(user.Media, mediaEntities);

      const WorkApplications = Object.values(workApplicationEntities)
        .filter(workApplication => workApplication.UserID === user.ID)
        .map(workApplication => ({ ...workApplication, File: Object.values(fileEntities).find(file => file.ID === workApplication.FileID) }))
        .sort((a, b) => (new Date(a.createdAt) - new Date(b.createdAt)));

      const WorkPreferences = filterEntitiesById(user.WorkPreferences, workPreferencesEntities);
      const HirePreferences = Object.values(hirePreferencesEntities).find(({ UserxHirePreferences }) => UserxHirePreferences.UserID === user.ID);

      return {
        ...user,
        Media,
        ...getUserFilesAndMedia(user, fileEntities, mediaEntities),
        WorkApplications,
        WorkPreferences,
        HirePreferences
      };
    }

    return null;
  }
);

export const getUserState = state => {
  if (has(state, 'user.currentUser')) {
    return state.user.currentUser;
  }

  return null;
};

export const getUserPrefs = state => {
  if (has(state, 'user.currentUser.data.User.Meta.Preferences')) {
    return {
      ...LAYOUT_DEFAULTS,
      ...state.user.currentUser.data.User.Meta.Preferences
    };
  }

  return LAYOUT_DEFAULTS;
};

export const getUserMeta = state => {
  if (has(state, 'user.currentUser.data.User.Meta')) {
    return {
      ...state.user.currentUser.data.User.Meta
    };
  }

  return {};
};

export const getUserShards = state => {
  const userId = getUserId(state);
  if (has(state, `entities.User.${userId}.ShardCount`)) {
    return state.entities.User[userId].ShardCount;
  }

  return 0;
};

export const selectSentReferralInvitations = createSelector(
  getUserId,
  getInvitationEntities,
  (userId, invitationEntities) => Object.values(invitationEntities)
    .filter(({ SenderID, Type, SenderType }) => SenderID === userId && Type === INVITATION_TYPE.REFERRAL && SenderType === INVITATION_SENDER_TYPE.USER)
);

export const getSentReferralsByUserID = createSelector(
  getUserId,
  getReferralEntities,
  (userId, referralEntities) => Object.values(referralEntities)
    .filter(({ UserID }) => UserID === userId)
);

export const isUserAuthorized = roles => (actions = []) => ACCESS_CONTROL.can(actions, roles);

export const selectUserStubByID = createSelector(
  (state, props) => props.userStubId,
  getUserStubEntities,
  (userStubId, userStubEntities) => Object.values(userStubEntities)
    .find(({ ID }) => ID === userStubId)
);
