import {
  CURRENT_TALENT_SOURCED_COUNT,
  TOTAL_TALENT_SUGGESTED
} from 'shared/constants/beaconConstants';
import {
  TOTAL_EARNED_FROM_QUESTS,
  TOTAL_QUESTS_INTRODUCTIONS,
  TOTAL_QUESTS_INTRODUCTIONS_REWARDED
} from 'shared/constants/questConstants';
import {
  GAMES_WORKED_ON,
  SKILLS_EXPERIENCE_BY_YEAR,
  TOTAL_YEARS_OF_EXPERIENCE
} from 'shared/constants/userConstants';

export const INSIGHTS_MAP = {
  [CURRENT_TALENT_SOURCED_COUNT]: {
    component: 'InsightGeneric',
    title: 'Total Talent Sourced'
  },
  [GAMES_WORKED_ON]: {
    component: 'InsightUserGamesWorkedOn',
    title: 'Games Shipped'
  },
  [SKILLS_EXPERIENCE_BY_YEAR]: {
    component: 'InsightUserSkillExperience',
    title: 'Top Skills'
  },
  [TOTAL_EARNED_FROM_QUESTS]: {
    component: 'InsightQuestTotalEarned',
    title: 'Total Earned'
  },
  [TOTAL_QUESTS_INTRODUCTIONS]: {
    component: 'InsightGeneric',
    title: 'Total Invites'
  },
  [TOTAL_QUESTS_INTRODUCTIONS_REWARDED]: {
    component: 'InsightGeneric',
    title: 'Total Invites Awarded'
  },
  [TOTAL_TALENT_SUGGESTED]: {
    component: 'InsightGeneric',
    title: 'Total Suggested Candidates'
  },
  [TOTAL_YEARS_OF_EXPERIENCE]: {
    component: 'InsightUserExperience',
    title: 'Years of Experience'
  }
};
