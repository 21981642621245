import { SUGGESTED_TALENT_HIGHLIGHTS } from 'shared/constants/enumConstants';

export const RUPIE_MARGIN = 0.8;
export const MIN_COST = 56.00;
export const MAX_COST = 128.00;
export const MAX_EXPERIENCE_LEVEL = 15;
export const MAX_DIFFICULTY = 4;

export const CURRENT_TALENT_SOURCED_COUNT = 'CURRENT_TALENT_SOURCED_COUNT';
export const TOTAL_TALENT_SUGGESTED = 'TOTAL_TALENT_SUGGESTED';

export const DEFAULT_INSIGHTS = [
  CURRENT_TALENT_SOURCED_COUNT,
  TOTAL_TALENT_SUGGESTED
];

export const HIGHLIGHTS_COPY = {
  [SUGGESTED_TALENT_HIGHLIGHTS.AAA_TALENT]: 'AAA experience',
  [SUGGESTED_TALENT_HIGHLIGHTS.SHIPPED_GAME]: 'Has shipped a game',
  [SUGGESTED_TALENT_HIGHLIGHTS.CAN_RELOCATE]: 'Can relocate',
  [SUGGESTED_TALENT_HIGHLIGHTS.REMOTE_OK]: 'Remote work is ok',
  [SUGGESTED_TALENT_HIGHLIGHTS.GENRE_FAN]: 'Fan of your genre',
  [SUGGESTED_TALENT_HIGHLIGHTS.GAME_FAN]: 'Fan of your game(s)',
  [SUGGESTED_TALENT_HIGHLIGHTS.GENRE_EXPERIENCE]: 'Experience in your genre',
  [SUGGESTED_TALENT_HIGHLIGHTS.FAST_NOTICE]: 'Can give fast notice'
};
