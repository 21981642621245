import { difference, merge, omit } from 'lodash';
import {
  EVENT_ADD_MEDIA,
  EVENT_DELETE_MEDIA,
  EVENT_DELETE,
  EVENT_UPDATE
} from 'constants/eventConstants';
import { filterMediaIdsToRemove } from 'reducers/utils/mediaUtils';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { EVENT } = ENTITY_TYPES;

export default function eventEntitiesReducers(state = {}, action) {
  const { payload, type, eventId } = action;

  if (payload && payload.entities) {
    switch (type) {
      case EVENT_ADD_MEDIA._SUCCESS: {
        const { mediaId, mediaType } = action;
        const updatedEvent = { ...state.Event[eventId] };
        const eventMedia = updatedEvent.Media || [];
        const removedMediaIds = filterMediaIdsToRemove(eventMedia, state.Media, mediaType);

        updatedEvent.Media = difference(eventMedia, removedMediaIds).concat(mediaId);

        return merge({}, state, payload.entities, {
          Event: {
            [eventId]: updatedEvent
          }
        });
      }
      case EVENT_DELETE._SUCCESS: {
        const newState = {
          ...state
        };

        delete newState[EVENT][eventId];
        return newState;
      }
      case EVENT_UPDATE._SUCCESS: {
        const updatedEventEntity = {
          ...state.Event[eventId],
          Categories: payload.entities.Event[eventId].Categories
        };

        return {
          ...state,
          Event: {
            ...state.Event,
            [eventId]: updatedEventEntity
          }
        };
      }
      case EVENT_DELETE_MEDIA._SUCCESS: {
        const { mediaId } = action;
        const updatedEventEntity = {
          ...state.Event[eventId],
          Media: state.Event[eventId].Media.filter(id => id !== mediaId)
        };
        const updatedMedia = omit(state.Media, mediaId);

        return {
          ...state,
          Event: {
            ...state.Event,
            [eventId]: updatedEventEntity
          },
          Media: updatedMedia
        };
      }
      default:
        return state;
    }
  }
  return state;
}
