import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Button, notification } from 'antd';
import HttpStatus from 'http-status-codes';
import queryString from 'query-string';

import {
  VERIFY_HASH_ERROR
} from 'constants/verifyConstants';
import {
  VERIFY_USER_CHANGE_EMAIL,
  VERIFY_USER_REGISTRATION
} from 'shared/constants/hashesConstants';
import { getCurrentUser } from 'actions/userActions';
import { resendHash, validateHash } from 'actions/verifyActions';
import { getUserData } from 'selectors/userSelectors';

// TODO: pass type here as well so that we can denote when something is an error, success, etc
const openNotification = (message, description, btn) => {
  const key = `open${Date.now()}`;
  const args = {
    message,
    description,
    duration: 0,
    btn,
    key
  };
  notification.open(args);
};

class RouteHashes extends React.PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    getCurrentUser: PropTypes.func.isRequired,
    location: PropTypes.object,
    resendHash: PropTypes.func.isRequired,
    validateHash: PropTypes.func.isRequired
  }

  static defaultProps = {
    location: null,
    currentUser: {}
  };

  state = {
    validated: false
  }

  componentDidMount() {
    const {
      location,
      currentUser
    } = this.props;
    const { validated } = this.state;

    const { Meta: { Registration = {}, Onboarding = {} } = {} } = currentUser || {};

    if (!validated && (Registration.completed || Onboarding.completed) && location.search) {
      this.validateURLHash();
    }
  }

  componentDidUpdate() {
    const {
      location,
      currentUser
    } = this.props;
    const { validated } = this.state;

    const { Meta: { Registration = {}, Onboarding = {} } = {} } = currentUser || {};

    if (!validated && (Registration.completed || Onboarding.completed) && location.search) {
      this.validateURLHash();
    }
  }

  handleResendHash(hash) {
    const {
      resendHash
    } = this.props;

    resendHash(hash)
      .then(res => {
        if (!res.errorStatus) {
          openNotification(
            'Confirmation E-mail was sent!'
          );
        } else {
          openNotification(
            `Hmm... seems that we can't resend you the confirmation e-mail`
          );
        }
      });
    this.setState({ validated: false });
  }

  async validateURLHash() {
    const {
      currentUser,
      getCurrentUser,
      validateHash,
      location,
      history
    } = this.props;
    const { validated } = this.state;
    const searchParams = new URLSearchParams(location.search);
    const { usedHash } = queryString.parse(window.location.search);
    if (searchParams.get('hash') && !validated && !usedHash) {
      this.setState({
        validated: true
      }, async () => {
        const hashData = await validateHash(searchParams.get('hash'));

        const { type, payload: { message } = {} } = hashData || {};
        if (!hashData.errorStatus && type !== VERIFY_HASH_ERROR) {
          if (message) openNotification(message);

          history.push({
            search: queryString.stringify({
              usedHash: true
            })
          });
        } else if (hashData.errorStatus && hashData.errorStatus !== HttpStatus.NOT_FOUND) {
          this.setState({ validated: false }, () => {
            openNotification(
              'Verification code expired',
              <Button className="ant-btn-link" onClick={() => this.handleResendHash(searchParams.get('hash'))}>Resend confirmation link</Button>
            );
          });
        } else {
          history.push({
            search: queryString.stringify({
              usedHash: true
            })
          });
        }
      });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, props) => ({ currentUser: getUserData(state, props) });

export default withRouter(connect(mapStateToProps, {
  getCurrentUser,
  validateHash,
  resendHash
})(RouteHashes));
