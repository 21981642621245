import {
  TASK_STATE_ENUM
} from 'shared/constants/enumConstants';
import {
  TASK_ASSIGN,
  SUGGESTED_TASK_ASSIGN,
  TASK_CREATE,
  SUGGESTED_TASK_CREATE,
  TASK_DELETE,
  SUGGESTED_TASK_DELETE,
  TASK_UPDATE,
  SUGGESTED_TASK_UPDATE
} from 'constants/taskConstants';
import { ENTITY_TYPES } from 'constants/global';
import merge from 'lodash/merge';

const { MILESTONE } = ENTITY_TYPES;

export default function taskEntitiesReducers(state = {}, action) {
  const {
    payload, type, suggested, currentIdentity
  } = action;

  const taskEntity = suggested ? 'SuggestedTask' : 'Task';
  const countName = !suggested ? 'TaskCount' : 'SuggestedTaskCount';
  const openCountName = !suggested ? 'OpenTaskCount' : 'OpenSuggestedTaskCount';

  if (payload && payload.entities) {
    switch (type) {
      // When a task is created, it needs to be added to the task collection on
      // the relevant parent entity (Project, Milestone, etc.) in the store
      case SUGGESTED_TASK_CREATE._SUCCESS:
      case TASK_CREATE._SUCCESS: {
        const { parentEntity, payload } = action;
        const newState = { ...state };

        if (parentEntity) {
          const { entities, result } = payload;
          const newTask = entities[taskEntity][result];
          const newStateParent = { ...state[parentEntity.type] };
          const newStateEntity = newStateParent[parentEntity.id];

          if (newStateEntity[countName]) {
            newStateEntity[countName] += 1;
          } else {
            newStateEntity[countName] = 1;
          }

          if (newStateEntity[openCountName]
            && (newTask.State !== TASK_STATE_ENUM.HIDDEN || newTask.State !== TASK_STATE_ENUM.COMPLETE)) {
            newStateEntity[openCountName] += 1;
          }

          newState[parentEntity.type] = newStateParent;
        }

        return merge({}, state, newState, payload.entities);
      }
      case SUGGESTED_TASK_UPDATE._SUCCESS:
      case TASK_UPDATE._SUCCESS: {
        const { parentEntity, payload } = action;
        const { entities, result, suggested } = payload;
        const newState = { ...state };
        const newTask = entities[taskEntity][result];
        const stateTask = { ...state[taskEntity] };
        const stateTaskEntity = stateTask[result];
        const prevTaskState = stateTaskEntity.State;
        const newTaskState = newTask.State;

        if (parentEntity) {
          const newStateParent = { ...state[parentEntity.type] };
          const newStateEntity = newStateParent[parentEntity.id];
          // Update the parent entity's open task count if a tasks's state has changed
          if (newStateEntity[openCountName] >= 0) {
            if (newTask.State === TASK_STATE_ENUM.HIDDEN || newTask.State === TASK_STATE_ENUM.COMPLETE) {
              if (newStateEntity[openCountName] > 0) {
                newStateEntity[openCountName] -= 1;
              }
            } else {
              newStateEntity[openCountName] += 1;
            }
          }
          newState[parentEntity.type] = newStateParent;
        }

        return merge({}, state, newState, payload.entities);
      }
      // Removes the task from the previous entity, adds it to the new entity
      case SUGGESTED_TASK_ASSIGN._SUCCESS:
      case TASK_ASSIGN._SUCCESS: {
        const { newParentEntity, prevParentEntity, taskId } = action;
        const newState = { ...state };
        const newStateTask = { ...newState[taskEntity][taskId] };
        const newStateTaskExtra = { ...newState[ENTITY_TYPES.TASK] };
        const parentEntity = newState[`${newParentEntity.type}`][newParentEntity.id];

        // delete the old task if it exists
        if (suggested && currentIdentity === ENTITY_TYPES.TASK) {
          if (parentEntity) {
            parentEntity.SuggestedTaskCount = parseInt((parentEntity.SuggestedTaskCount || 0), 10) + 1;
          }
          delete newStateTaskExtra[taskId];
        }

        if (!suggested || (suggested && currentIdentity === ENTITY_TYPES.SUGGESTED_TASK)) {
          newStateTask[`${taskEntity}x${newParentEntity.type}`] = {
            [`${taskEntity}ID`]: taskId,
            [`${newParentEntity.type}ID`]: newParentEntity.id,
            Order: newParentEntity.order
          };

          delete newStateTask[`${taskEntity}x${prevParentEntity.type}`];
        }

        return {
          ...state,
          ...suggested ? {
            [ENTITY_TYPES.TASK]: {
              ...newStateTaskExtra
            }
          } : {},
          ...{
            [taskEntity]: {
              ...state[taskEntity],
              [taskId]: newStateTask
            }
          }
        };
      }
      case SUGGESTED_TASK_DELETE._SUCCESS:
      case TASK_DELETE._SUCCESS: {
        const { parentEntity, taskId } = action;
        const newTaskState = { ...state[taskEntity] };
        const deletedTask = newTaskState[taskId];
        const newState = {};

        if (parentEntity) {
          const newStateParent = { ...state[parentEntity.type] };
          const newStateEntity = newStateParent[parentEntity.id];

          if (newStateEntity[countName] && newStateEntity[countName] > 0) {
            newStateEntity[countName] -= 1;
          } else {
            newStateEntity[countName] = 0;
          }

          if (newStateEntity[openCountName]
            && newStateEntity[openCountName] > 0
            && (deletedTask.State !== TASK_STATE_ENUM.HIDDEN || deletedTask.State !== TASK_STATE_ENUM.COMPLETE)) {
            newStateEntity[openCountName] -= 1;
          } else {
            newStateEntity[openCountName] = 0;
          }
        }

        delete newTaskState[taskId];
        newState[taskEntity] = newTaskState;

        return {
          ...state,
          ...newState
        };
      }
      default:
        return state;
    }
  }
  return state;
}
