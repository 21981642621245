import { schema } from 'normalizr';

const Notification = new schema.Entity(
  'Notification',
  {},
  {
    idAttribute: 'ID'
  }
);

const NotificationList = [Notification];

export { Notification, NotificationList };
