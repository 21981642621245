import buildUrl from 'build-url';
import StatusCodes from 'http-status-codes';
import has from 'lodash/has';

import { v1 } from 'utils/apiUtils';
import { LOGOUT_SUCCESS } from 'constants/userConstants';

const {
  ACCEPTED, CREATED, FORBIDDEN, NO_CONTENT, NOT_FOUND, OK, UNAUTHORIZED
} = StatusCodes;
/**
 * API middleware handle all API calls within the APP
 * This middleware uses axios as HTTP library and can be changed into the other library without needs to rewrite actionCreators or reducers
 * input params will be the same and output params will be the same too
 *
 * Example of API call action
 *
 * function dummyApiCall() {
 *  return {
 *    types: { // Types can be extended with more actions. @see api.tests.js for more usages
 *      loading: FETCH_DUMMY_LOADING,
 *      success: FETCH_DUMMY_SUCCESS,
 *      error: FETCH_DUMMY_ERROR
 *    },
 *    queryParams: {},
 *    pageParams: {
 *      offset: the offset number
 *      limit: the records limit
 *      filterString: the filter string
 *    },
 *    id: <string> // optional unique identifier for custom actions in middleware
 *  }
 * }
 */
export default function apiMiddleware() {
  return next => action => {
    // Continue to the next middleware if action not contain `types` property
    if (!action.types || !action.apiParams) {
      return next(action);
    }

    const {
      types,
      apiParams: {
        method = 'get',
        endpoint,
        data = {},
        headers: customHeaders = {},
        returnPromise = false,
        queryParams,
        responseType
      },
      id
    } = action;

    const {
      loading: loadingType,
      success: successType,
      error: errorType
    } = types;

    const headers = {
      ...customHeaders // Here is custom headers populated
    };

    const request = v1({
      method,
      data: JSON.stringify(data),
      url: buildUrl(endpoint.replace(/^\//, ''), {
        queryParams
      }),
      headers,
      responseType,
      params: method === 'get' && typeof data !== 'undefined' ? { ...data } : undefined
    });

    if (returnPromise) {
      return request;
    }

    if (typeof loadingType === 'function') {
      next(loadingType(true));
    } else if (typeof loadingType === 'object') {
      next(loadingType);
    } else {
      // Dispatch initial loading of action so components can show loaders ^_^
      next({
        type: loadingType,
        isLoading: true
      });
    }

    return request.then(response => {
      if (![
        OK, NO_CONTENT, ACCEPTED, CREATED
      ].includes(response.status)) {
        throw Error(response);
      }

      return response;
    })
      .then(response => {
        const isLoading = false;

        if (typeof successType === 'function') {
          return next({
            isLoading: false,
            ...successType(response.data)
          });
        }

        return next({
          type: successType,
          isLoading,
          // payload should contain only the response body and nothing more for clear payload data
          // If you need to send more properties add more properties into the `next ({})` call
          payload: response.data
        });
      })
      .catch(error => {
        const isLoading = false;
        const { response: { status } = {} } = error;

        if (![
          NOT_FOUND, UNAUTHORIZED, FORBIDDEN
        ].includes(status)) {
          console.error(error);
        }
        // ID is custom parameter to identify the action without parsing their types
        // Middleware needs to know by knowing the ID which action is login and tolerate the 401 in it
        if (((id && id !== 'login'))
        && status === FORBIDDEN) {
          localStorage.removeItem('SESSION');
          // This break current action and force dispatch user logout
          // @TODO: logout is not needed. This place can dispatch Token renewal and after the new token is successful obtained it can continue with dispatching of the previous action
          return next({
            type: LOGOUT_SUCCESS,
            isLoading: false
          });
        }

        if (typeof errorType === 'function') {
          return next(errorType(error));
        }

        // if errorType is only constant with no custom definition
        return next({
          type: errorType || 'UNCAUGHT_ERROR',
          // If error contain error message from server then send it
          error: has(error, 'response.data') ? error.response.data : true,
          // Error status can be used to correctly show error message and what happened
          errorStatus: has(error, 'response.status') ? error.response.status : 0,
          isLoading
        });
      });
  };
}
