import {
  APP_DEFAULTS_ERROR,
  APP_DEFAULTS_START,
  APP_DEFAULTS_SUCCESS
} from 'constants/defaultConstants';

export function defaultsLoaded(state = { loaded: false }, action = {}) {
  switch (action.type) {
    case APP_DEFAULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loaded: true
      };
    case APP_DEFAULTS_START:
    case APP_DEFAULTS_ERROR:
      return {
        isLoading: true,
        loaded: false
      };
    default:
      return state;
  }
}
