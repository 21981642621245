import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import classNames from 'classnames';

import { getUserData } from 'selectors/userSelectors';
import RouteHashes from 'containers/Layouts/Handlers/RouteHashes';
import RouteReferrals from 'containers/Layouts/Handlers/RouteReferrals';
import PageMeta from 'containers/PageWrapper/PageMeta/PageMeta';
import Loading from 'components/Loading/Loading';

import './GenericLayout.less';

const { Content } = Layout;

// TODO: migrate to Hubspot chat
// const resetIntercom = () => {
//   window.Intercom('update', {
//     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
//     created_at: null,
//     email: null,
//     name: null,
//     user_id: null
//   });
// };

class GenericLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    currentUser: PropTypes.object,
    description: PropTypes.string,
    isLessThanLarge: PropTypes.bool.isRequired,
    mediaType: PropTypes.string.isRequired,
    orientation: PropTypes.string,
    title: PropTypes.string
  }

  static defaultProps = {
    currentUser: null,
    description: '',
    orientation: null,
    title: ''
  }

  // TODO: migrate to Hubspot chat
  // componentDidMount() {
  //   const intercomLauncher = document.querySelector('.intercom-launcher');

  //   window.Intercom('shutdown');
  //   // guest view
  //   if (!intercomLauncher) {
  //     resetIntercom();
  //   }
  // }

  // componentDidUpdate() {
  //   const intercomLauncher = document.querySelector('.intercom-launcher');
  //   const { currentUser } = this.props;

  //   if (currentUser && !intercomLauncher) {
  //     const {
  //       createdAt, Email, FullName, ID
  //     } = currentUser;

  //     window.Intercom('boot', {
  //       app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  //       created_at: createdAt,
  //       email: Email,
  //       name: FullName,
  //       user_id: ID
  //     });
  //   } else {
  //     resetIntercom();
  //   }
  // }

  render() {
    const {
      children,
      description,
      isLessThanLarge,
      mediaType,
      orientation,
      title
    } = this.props;

    const layoutClass = classNames('layout--generic', {
      'wrapper--is-xs': mediaType === 'extraSmall',
      'wrapper--is-sm': mediaType === 'small',
      'wrapper--is-md': mediaType === 'medium',
      'wrapper--is-lg': mediaType === 'large',
      'wrapper--is-xl': mediaType === 'extraLarge',
      'wrapper--is-infinity': (orientation !== null) && mediaType === 'infinity',
      'wrapper--is-lt-large': isLessThanLarge,
      'wrapper--is-mobile': window.isMobile || mediaType === 'extraSmall'
    });

    return (
      <Layout className={layoutClass}>
        <PageMeta desc={description} title={title} />
        <RouteReferrals />
        <RouteHashes />
        <Suspense fallback={<Loading />}>
          <Content>
            {children}
          </Content>
        </Suspense>
      </Layout>
    );
  }
}

const mapStateToProps = state => (
  {
    currentUser: getUserData(state),
    isLessThanLarge: state.browser.lessThan.large,
    mediaType: state.browser.mediaType,
    orientation: state.browser.orientation
  }
);

export default withRouter(connect(mapStateToProps)(GenericLayout));
