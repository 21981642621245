import React from 'react';
import { Avatar } from 'antd';

import { generateAsyncActions } from 'utils/actionUtils';
import { QUIZ_CATEGORY_ENUM } from 'shared/constants/enumConstants';

import archerHidden from 'images/avatars/avatar-hidden--archer.svg';
import artisanHidden from 'images/avatars/avatar-hidden--artisan.svg';
import bardHidden from 'images/avatars/avatar-hidden--bard.svg';
import clericHidden from 'images/avatars/avatar-hidden--cleric.svg';
import enchanterHidden from 'images/avatars/avatar-hidden--enchanter.svg';
import healerHidden from 'images/avatars/avatar-hidden--healer.svg';
import tinkererHidden from 'images/avatars/avatar-hidden--tinkerer.svg';
import warriorHidden from 'images/avatars/avatar-hidden--warrior.svg';
import wizardHidden from 'images/avatars/avatar-hidden--wizard.svg';
import archer from 'images/avatars/avatar-personality--archer.svg';
import artisan from 'images/avatars/avatar-personality--artisan.svg';
import bard from 'images/avatars/avatar-personality--bard.svg';
import cleric from 'images/avatars/avatar-personality--cleric.svg';
import enchanter from 'images/avatars/avatar-personality--enchanter.svg';
import healer from 'images/avatars/avatar-personality--healer.svg';
import tinkerer from 'images/avatars/avatar-personality--tinkerer.svg';
import warrior from 'images/avatars/avatar-personality--warrior.svg';
import wizard from 'images/avatars/avatar-personality--wizard.svg';

export const GET_USER_QUIZZES = generateAsyncActions('GET_USER_QUIZZES');
export const GET_USER_QUIZ = generateAsyncActions('GET_USER_QUIZ');
export const UPDATE_USER_QUIZ = generateAsyncActions('UPDATE_USER_QUIZ');

export const PLAYER_TYPE_ASSESSMENT = 'Player Type Assessment';
export const PLAYER_TYPE_ASSESSMENT_MAX = 20;

export const ARTISAN = 'Artisan';
export const BARD = 'Bard';
export const CLERIC = 'Cleric';
export const ENCHANTER = 'Enchanter';
export const HEALER = 'Healer';
export const RANGER = 'Ranger';
export const TINKERER = 'Tinkerer';
export const WARRIOR = 'Warrior';
export const WIZARD = 'Wizard';

export const PERSONALITY_AVATAR_MAP = {
  [ARTISAN]: artisan,
  [BARD]: bard,
  [CLERIC]: cleric,
  [ENCHANTER]: enchanter,
  [HEALER]: healer,
  [RANGER]: archer,
  [TINKERER]: tinkerer,
  [WARRIOR]: warrior,
  [WIZARD]: wizard
};

export const BARTLE_PLAYER_TYPE_ASSESSMENT_MAP = {
  A: { // bartle achiever
    name: WIZARD,
    icon: <Avatar src={wizard} size="large" alt={WIZARD} />
  },
  E: { // bartle explorer
    name: RANGER,
    icon: <Avatar src={archer} size="large" alt={RANGER} />
  },
  S: { // bartle socializer
    name: BARD,
    icon: <Avatar src={bard} size="large" alt={BARD} />
  },
  K: { // bartle killer
    name: WARRIOR,
    icon: <Avatar src={warrior} size="large" alt={WARRIOR} />
  }
};

function getAvatar(size, src, alt) {
  return (<Avatar src={src} size={size} alt={alt} />);
}

export const PLAYER_TYPE_ASSESSMENT_MAP = {
  advancement: {
    name: WIZARD,
    simpleName: 'Self Teaching',
    max: 24,
    description: `Wizards try to gain as much knowledge as possible. Wizards will try to learn every detail about the task they have been given.`,
    strong: `Wizards respond well to tasks that can be easily broken down into a todo list.`,
    icon: (size = 'large') => getAvatar(size, wizard, WIZARD),
    iconHidden: (size = 'large') => getAvatar(size, wizardHidden, WIZARD)
  },
  competition: {
    name: WARRIOR,
    simpleName: 'Hustle',
    max: 16,
    description: `Warriors have a competitive fire inside their belly. Warriors, similarly to Wizards, want to expand their abilities, but Warriors want to be the best at what they do.`,
    strong: `Warriors respond well to tasks that have easily tracked metrics.`,
    icon: (size = 'large') => getAvatar(size, warrior, WARRIOR),
    iconHidden: (size = 'large') => getAvatar(size, warriorHidden, WARRIOR)
  },
  customization: {
    name: ARTISAN,
    simpleName: 'Creativity',
    max: 12,
    description: `Artisans have a strong sense of self and how they want to express themselves to the world. Artisans are unafraid to be themselves and let everyone know who they are.`,
    strong: `Artisans respond well to tasks that have room for self expression.`,
    icon: (size = 'large') => getAvatar(size, artisan, ARTISAN),
    iconHidden: (size = 'large') => getAvatar(size, artisanHidden, ARTISAN)
  },
  discovery: {
    name: RANGER,
    simpleName: 'Exploration',
    max: 16,
    description: `Rangers are unafraid of the unknown. Rangers have a stubbornness about them that won't go away if they know there is something out there to be found.`,
    strong: `Rangers respond well to tasks that are different to any task that they've done before.`,
    icon: (size = 'large') => getAvatar(size, archer, RANGER),
    iconHidden: (size = 'large') => getAvatar(size, archerHidden, RANGER)
  },
  mechanics: {
    name: TINKERER,
    simpleName: 'Detail Oriented',
    max: 16,
    description: `Tinkerers want to get into the nitty-gritty details of their tasks. When given a problem, tinkerers will tend to build their own solution rather than look elsewhere.`,
    strong: `Tinkerers respond well to tasks that deal with the minutiae of a system.`,
    icon: (size = 'large') => getAvatar(size, tinkerer, TINKERER),
    iconHidden: (size = 'large') => getAvatar(size, tinkererHidden, TINKERER)
  },
  socializing: {
    name: BARD,
    simpleName: 'Communication',
    max: 16,
    description: `Bards want to share their knowledge and stories with anyone and everyone. Bards thrive in collaborating with their peers, rather than competing with them.`,
    strong: `Bards respond well to tasks that involve working with others.`,
    icon: (size = 'large') => getAvatar(size, bard, BARD),
    iconHidden: (size = 'large') => getAvatar(size, bardHidden, BARD)
  },
  relationship: {
    name: HEALER,
    simpleName: 'Nuture',
    max: 12,
    description: `Healers care deeply about the well-being of their friends and teammates. Healers will move mountains in order to help their friends.`,
    strong: `Healers respond well to tasks that help people around them.`,
    icon: (size = 'large') => getAvatar(size, healer, HEALER),
    iconHidden: (size = 'large') => getAvatar(size, healerHidden, HEALER)
  },
  roleplaying: {
    name: ENCHANTER,
    simpleName: 'Specializing',
    max: 16,
    description: `Enchanters understand the history and gravitas of the world that surrounds them. Enchanters use that knowledge to carve out a niche for themselves.`,
    strong: `Enchanters respond well to tasks that only they can do because of their unique abilities`,
    icon: (size = 'large') => getAvatar(size, enchanter, ENCHANTER),
    iconHidden: (size = 'large') => getAvatar(size, enchanterHidden, ENCHANTER)
  },
  teamwork: {
    name: CLERIC,
    simpleName: 'Collaborating',
    max: 24,
    description: `Clerics want to use their skills to elevate the whole group, rather than themselves. Clerics care more about group achievements rather than individual accolades.`,
    strong: `Clerics respond well to tasks where they support those who work with them.`,
    icon: (size = 'large') => getAvatar(size, cleric, CLERIC),
    iconHidden: (size = 'large') => getAvatar(size, clericHidden, CLERIC)
  }
};

export const quizCategoryMap = {
  [QUIZ_CATEGORY_ENUM.ONBOARDING]: {
    title: 'Onboarding Assessment',
    color: 'purple'
  },
  [QUIZ_CATEGORY_ENUM.TECHNICAL]: {
    title: 'Technical Assessment',
    color: 'red'
  },
  [QUIZ_CATEGORY_ENUM.BUSINESS]: {
    title: 'Business Assessment',
    color: 'green'
  },
  [QUIZ_CATEGORY_ENUM.MUSIC]: {
    title: 'Music Assessment',
    color: 'blue'
  },
  [QUIZ_CATEGORY_ENUM.ART]: {
    title: 'Art Assessment',
    color: 'blue'
  },
  [QUIZ_CATEGORY_ENUM.ANIMATION]: {
    title: 'Animation Assessment',
    color: 'yellow'
  },
  [QUIZ_CATEGORY_ENUM.VFX]: {
    title: 'VFX Assessment',
    color: 'purple'
  },
  [QUIZ_CATEGORY_ENUM.TESTING]: {
    title: 'Testing Assessment',
    color: 'green'
  }
};
