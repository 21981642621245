import { schema } from 'normalizr';

// eslint-disable-next-line import/no-cycle
import {
  Beacon,
  Job,
  Prism,
  Quest
} from './index';

const TalentFilter = new schema.Entity(
  'TalentFilter',
  {
    Beacon,
    Job,
    Prism,
    Quest
  },
  {
    idAttribute: 'ID'
  }
);

Beacon.define({ TalentFilter });
Job.define({ TalentFilter });
Prism.define({ TalentFilter });
Quest.define({ TalentFilter });

const TalentFilterList = [TalentFilter];

export {
  TalentFilter,
  TalentFilterList
};
