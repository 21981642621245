import { schema } from 'normalizr';
import { User } from 'schemas/index';

const Referral = new schema.Entity(
  'Referral',
  {
    User
  },
  {
    idAttribute: 'ID'
  }
);

const ReferralList = [Referral];

User.define({
  Referrals: ReferralList
});

export {
  Referral,
  ReferralList
};
