import { schema } from 'normalizr';

import { InternalRecordList, Organization, User } from 'schemas/index';

export const Beacon = new schema.Entity(
  'Beacon',
  {
    User,
    Organization,
    InternalRecords: InternalRecordList
  },
  {
    idAttribute: 'ID'
  }
);

export const BeaconList = [Beacon];
