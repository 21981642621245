import { difference, merge, omit } from 'lodash';
import {
  TEAM_ADD_MEDIA,
  TEAM_DELETE_MEDIA,
  TEAM_MEMBER_REMOVE,
  TEAM_MEMBER_CHANGE_COMMISSION
} from 'constants/teamConstants';
import { filterMediaIdsToRemove } from 'reducers/utils/mediaUtils';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { TEAM } = ENTITY_TYPES;

export default function teamEntitiesReducers(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    switch (type) {
      case TEAM_ADD_MEDIA._SUCCESS: {
        const { mediaId, mediaType, teamId } = action;
        const updatedTeam = { ...state.Team[teamId] };
        const teamMedia = updatedTeam.Media || [];
        const removedMediaIds = filterMediaIdsToRemove(teamMedia, state.Media, mediaType);

        updatedTeam.Media = difference(teamMedia, removedMediaIds).concat(mediaId);

        return merge({}, state, payload.entities, {
          Team: {
            [teamId]: updatedTeam
          }
        });
      }
      case TEAM_DELETE_MEDIA._SUCCESS: {
        const { mediaId, teamId } = action;
        const updatedTeamEntity = {
          ...state.Team[teamId],
          Media: state.Team[teamId].Media.filter(id => id !== mediaId)
        };
        const updatedMedia = omit(state.Media, mediaId);

        return {
          ...state,
          Team: {
            ...state.Team,
            [teamId]: updatedTeamEntity
          },
          Media: updatedMedia
        };
      }
      case TEAM_MEMBER_REMOVE._SUCCESS: {
        const { teamId, userId } = action;
        const newState = {
          ...state
        };
        const index = newState[TEAM][teamId].Members.findIndex(element => element.ID === userId);
        delete newState[TEAM][teamId].Members[index];
        return newState;
      }
      case TEAM_MEMBER_CHANGE_COMMISSION._SUCCESS: {
        const { join, userId, teamId } = action;
        const newState = {
          ...state
        };
        const index = newState[TEAM][teamId].Members.findIndex(member => member.ID === userId);
        const updatedMember = {
          ...newState[TEAM][teamId].Members[index],
          UserxRolexTeam: join
        };

        newState[TEAM][teamId].Members[index] = updatedMember;
        return newState;
      }
      default:
        return state;
    }
  }
  return state;
}
