import { generateAsyncActions } from 'utils/actionUtils';

export const JOB_CREATE_TIP = 'JOB_CREATE_TIP';
export const GREENHOUSE_INTEGRATION_TIP = 'GREENHOUSE_INTEGRATION_TIP';

export const ARTICLE_ID_MAP = {
  [JOB_CREATE_TIP]: '4292445',
  [GREENHOUSE_INTEGRATION_TIP]: process.env.NODE_ENV === 'production' ? '4357168' : '4357227'
};

export const GET_SUPPORT_ARTICLES = generateAsyncActions('GET_SUPPORT_ARTICLES');
