import { schema } from 'normalizr';
import { Media } from 'schemas/index';

const File = new schema.Entity(
  'File',
  {
    Media
  },
  {
    idAttribute: 'ID'
  }
);

const FileList = [File];

Media.define({
  Files: FileList
});

export { File, FileList };
