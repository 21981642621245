import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon, Input, Layout } from 'antd';
import classNames from 'classnames';
import Animate from 'rc-animate';

import { ENTITY_TYPES } from 'constants/global';
import { CombinedEntityDisplayWithSearch } from 'components/EntityDisplay/WrappedDisplayComponents';
import EntitySearch from 'components/Form/Custom/EntitySearch';

import HeaderNav from './HeaderNav/HeaderNav';

import './Header.less';

const { Header } = Layout;

class HeaderView extends PureComponent {
  static propTypes = {
    isMobileOrSmall: PropTypes.bool.isRequired,
    logo: PropTypes.string.isRequired,
    sideViewCollapsed: PropTypes.bool.isRequired,
    toggleSide: PropTypes.func.isRequired
  }

  state = {
    visible: true
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.visible) {
      return {
        visible: true
      };
    }
    return null;
  }

  render() {
    const {
      sideViewCollapsed, isMobileOrSmall, logo, toggleSide, changeRoute
    } = this.props;

    const { visible } = this.state;

    const headerClass = classNames('global-header', {
      'global-header--is-collapsed': sideViewCollapsed,
      'global-header--is-mobile': isMobileOrSmall
    });

    return (
      <Animate component="" transitionName="fade">
        {
          visible ? (
            <Header className={headerClass}>
              <div className="global-header__container">
                {isMobileOrSmall && (
                  <Link to="/" className="global-header__logo" key="logo">
                    <img src={logo} alt="logo" width="32" />
                  </Link>
                )}
                <Icon
                  className="global-header__trigger"
                  type={sideViewCollapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={toggleSide}
                />
                {/* TODO add search icon with full-page search on mobile */}
                {/* TODO: make search contextual (talent vs studio) */}
                {/* {
                  !isMobileOrSmall && (
                    <EntitySearch
                      entitiesMapping={{
                        [ENTITY_TYPES.BEACON]: {
                          value: beacon => ({
                            entity: beacon,
                            type: 'Beacon'
                          }),
                          label: beacon => beacon.Title
                        },
                        [ENTITY_TYPES.QUEST]: {
                          value: quest => ({
                            entity: quest,
                            type: 'Quest'
                          }),
                          label: quest => quest.Title
                        },
                        [ENTITY_TYPES.TEAM]: {
                          value: team => ({
                            entity: team,
                            type: 'Team'
                          }),
                          label: team => team.Name
                        }
                      }}
                      size="default"
                      placeholder="Search for teams, quests, and more"
                      handleChange={({ value }) => {
                        changeRoute(`/${value.type.toLowerCase()}/${value.entity.ID}`);
                      }}
                      style={{ width: !isMobileOrSmall ? 300 : 'auto', flex: !isMobileOrSmall ? 0.3 : 0.9 }}
                    />
                  )
                } */}
                <HeaderNav {...this.props} />
              </div>
            </Header>
          ) : null
        }
      </Animate>
    );
  }
}

export default HeaderView;
