import { createSelector } from 'reselect';

import { getOrgFilesAndMedia } from 'selectors/organizationSelectors';
import {
  getCreditEntities,
  getFileEntities,
  getMediaEntities,
  getOrganizationEntities
} from 'selectors/stateSelectors';
import { getUserId } from 'selectors/userSelectors';

const getCreditByIDFilter = (state, props) => {
  const { creditId, match } = props;

  if (creditId) {
    const credit = getCreditEntities(state)[creditId];
    return credit;
  }
  if (match && match.params.id) {
    return getCreditEntities(state)[match.params.id];
  }
  return {};
};

export const getBaseCredit = createSelector(
  getCreditByIDFilter,
  Credit => Credit || {}
);

export const getCreditWithEntities = createSelector(
  getBaseCredit,
  getOrganizationEntities,
  getFileEntities,
  getMediaEntities,
  (credit, orgEntities, fileEntities, mediaEntities) => {
    const creditObj = { ...credit };
    const Organization = Object.values(orgEntities).reduce((prev, org) => {
      if (org.ID === creditObj?.OrganizationID) {
        return {
          ...org,
          ...getOrgFilesAndMedia(org, fileEntities, mediaEntities)
        };
      }
      return prev;
    }, null);

    return {
      Credit: creditObj,
      Organization
    };
  }
);

export const getCredits = createSelector(
  getCreditEntities,
  getOrganizationEntities,
  getFileEntities,
  getMediaEntities,
  (creditEntities, orgEntities, fileEntities, mediaEntities) => Object.values(creditEntities)
    .reduce((credits, credit) => {
      const creditObj = { ...credit };
      creditObj.Organization = Object.values(orgEntities).reduce((prev, org) => {
        if (org.ID === creditObj?.OrganizationID) {
          return {
            ...org,
            ...getOrgFilesAndMedia(org, fileEntities, mediaEntities)
          };
        }
        return prev;
      }, null);

      return credits.concat(creditObj);
    }, [])
);

export const getCreditsByOrganizatonId = createSelector(
  (state, props) => props.organizationId,
  getCreditEntities,
  getOrganizationEntities,
  getFileEntities,
  getMediaEntities,
  (orgId, creditEntities, orgEntities, fileEntities, mediaEntities) => Object.values(creditEntities)
    .filter(credit => credit?.OrganizationID === orgId)
    .reduce((credits, credit) => {
      const creditObj = { ...credit };
      creditObj.Organization = Object.values(orgEntities).reduce((prev, org) => {
        if (org.ID === creditObj?.OrganizationID) {
          return {
            ...org,
            ...getOrgFilesAndMedia(org, fileEntities, mediaEntities)
          };
        }
        return prev;
      }, null);

      return credits.concat(creditObj);
    }, [])
);
