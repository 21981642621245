import { createSelector } from 'reselect';

import { ENTITY_TYPES } from 'constants/global';
import { getOrgFilesAndMedia } from 'selectors/organizationSelectors';
import {
  getBeaconEntities,
  getBillingProfileEntities,
  getInvoiceEntities,
  getJobEntities,
  getOrganizationEntities,
  getProposalEntities,
  getSubscriptionEntities,
  getUserEntities,
  getVendorProfileEntities
} from 'selectors/stateSelectors';

export const getBaseBillingProfile = createSelector(
  (state, props) => (props.User ? props.User.ID : (props.match && props.match.params.id)),
  (state, props) => props.OrganizationID,
  getBillingProfileEntities,
  (userId, orgId, billingProfileEntities) => Object.values(billingProfileEntities).find(billingProfile => {
    if (orgId) {
      return billingProfile.OrganizationID === orgId;
    }
    return billingProfile.UserID === userId;
  }) || {}
);

export const getEntityBillingProfile = createSelector(
  (state, props) => props.entityId,
  (state, props) => props.entityType,
  getBillingProfileEntities,
  (entityId, entityType, billingProfileEntities) => {
    const billingProfile = Object.values(billingProfileEntities).reduce((accum, billingProfile) => {
      const joinString = `${entityType}xBillingProfile`;
      if (billingProfile[joinString] && billingProfile[joinString][0]) {
        if (billingProfile[joinString][0][`${entityType}ID`] === entityId) {
          return billingProfile[joinString][0];
        }
      }
    }, {});

    return billingProfile;
  }
);

export const getUserInvoices = createSelector(
  (state, props) => (props.userId || (props.match && props.match.params.id)),
  getInvoiceEntities,
  (userId, invoiceEntities) => Object.values(invoiceEntities).filter(invoice => invoice.UserID === userId)
);

export const getUserSubscription = createSelector(
  (state, props) => (props.billingProfileId),
  getSubscriptionEntities,
  (billingProfileId, subscriptionEntities) => Object.values(subscriptionEntities)
    .filter(subscription => subscription.BillingProfileID === billingProfileId)[0] || {}
);

export const getSubscriptionById = createSelector(
  (state, props) => (props.subscriptionId),
  getSubscriptionEntities,
  (subscriptionId, subscriptionEntities) => Object.values(subscriptionEntities)
    .filter(subscription => subscription.ID === subscriptionId)[0] || {}
);

export const getInvoiceWithEntities = createSelector(
  (state, props) => (props.invoiceId || (props.match && props.match.params.id)),
  getInvoiceEntities,
  getJobEntities,
  getBeaconEntities,
  getProposalEntities,
  getOrganizationEntities,
  getUserEntities,
  (invoiceId, invoiceEntities, jobEntities, beaconEntities, proposalEntities, organizationEntities, userEntities) => {
    const invoice = Object.values(invoiceEntities).filter(invoice => invoice.UUID === invoiceId)[0] || {};

    return {
      Invoice: invoice,
      Beacon: (invoice.Beacons || []).map(beaconId => beaconEntities[beaconId])[0],
      Job: (invoice.Job || []).map(jobId => jobEntities[jobId])[0],
      Organization: invoice.OrganizationID ? Object.values(organizationEntities).filter(org => org.ID === invoice.OrganizationID)[0] : {},
      Proposal: (invoice.Proposal || []).map(proposalId => proposalEntities[proposalId])[0],
      User: invoice.UserID ? Object.values(userEntities).filter(user => user.ID === invoice.UserID)[0] : {}
    };
  }
);

export const getBaseVendorProfile = createSelector(
  (state, props) => (props.entityId ? props.entityId : (props.match && props.match.params.id)),
  (state, props) => props.entityType,
  getVendorProfileEntities,
  (entityId, entityType, VendorProfileEntities) => Object.values(VendorProfileEntities).filter(vendorProfile => {
    if (entityType === ENTITY_TYPES.USER && entityId) {
      const { UserxVendorProfile } = vendorProfile;
      if (UserxVendorProfile && UserxVendorProfile.UserID === entityId) {
        return vendorProfile;
      }
    }
    if (entityType === ENTITY_TYPES.TEAM && entityId) {
      const { TeamxVendorProfile } = vendorProfile;
      if (TeamxVendorProfile && TeamxVendorProfile.TeamID === entityId) {
        return vendorProfile;
      }
    }
  })[0]
);
