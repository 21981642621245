import axios from 'axios';

import {
  API_PREFIX, API_PREFIX_NO_SLASH, API_VERSION, SERVER_HOST, SERVER_PROTOCOL
} from 'shared/web/constants/globalConstants';

export const getBaseHeaders = function (session) {
  return {
    Accept: `application/vnd.rupie::${API_VERSION}+xml`,
    ...session ? { Authorization: `Bearer ${session.Token}` } : {}
  };
};

export const getBaseUrl = function(noSlash) {
  return `${SERVER_PROTOCOL}://${SERVER_HOST}${noSlash ? API_PREFIX_NO_SLASH : API_PREFIX}`;
};

export const setHeaders = function (data, headers, session) {
  // set the API version so we can change it later via .env
  headers.common.Accept += `, application/vnd.rupie::${API_VERSION}+xml`;
  // if there is a JWT, at the Authorization header
  if (session !== null) {
    headers.common.Authorization = `Bearer ${session.Token}`;
  }

  return data;
};

export const setupAxios = headersFunction => {
  // Adding our accept header for versioning, eventually we'll prob want this to
  // be configured globally, but for now we can do it this way
  const v1 = axios.create({
    baseURL: getBaseUrl(),
    transformRequest: [headersFunction]
  });

  // setup default content-type
  v1.defaults.headers.put['Content-Type'] = 'application/json';
  v1.defaults.headers.post['Content-Type'] = 'application/json';
  v1.defaults.headers.patch['Content-Type'] = 'application/json';
  v1.defaults.headers.delete['Content-Type'] = 'application/json';

  return v1;
};
