import { applyMiddleware, compose, createStore } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createExpirationTransform from 'redux-persist-transform-expire';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createSocketIoMiddleware from 'redux-socket.io';
import thunk from 'redux-thunk';
import apiMiddleware from 'middleware/api';

import aclMiddleware from 'middleware/acl';
import { socket } from 'utils/apiUtils';
import { ENTITY_TYPE_ENUM } from 'shared/constants/enumConstants';
import rootReducer from 'reducers';

// when we make changes to skills, etc we need to up this value
// and reset the state
const migrations = {
  0: state => ({}),
  1: state => ({}),
  2: state => ({}),
  3: state => ({}),
  4: state => ({}),
  5: state => ({}),
  6: state => ({}),
  7: state => ({}),
  8: state => ({}),
  9: state => ({}),
  10: state => ({}),
  11: state => ({})
};

const {
  GENRE, LABEL, PHASE, SKILL, TAG
} = ENTITY_TYPE_ENUM;

// expire key
const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt'
});

// configure redux persist
const persistConfig = {
  key: 'root',
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  transforms: [
    // save default entities
    createWhitelistFilter('entities', [
      GENRE, LABEL, PHASE, SKILL, TAG
    ]),
    // save User Meta
    createWhitelistFilter('user', ['currentUser.data.User.Meta']),
    createWhitelistFilter('defaultsLoaded', ['loaded']),
    expireTransform
  ],
  version: 6, // this value needs to be incremented when skills, or cached entities change
  whitelist: [
    'defaultsLoaded', 'entities', 'user'
  ]
};

// TODO fix this when ambassador adds Session Affinity (sticky session)
// https://github.com/itaylor/redux-socket.io
const socketIoMiddleware = createSocketIoMiddleware(socket, 'ws/');

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// this method gets called in the main index.js file
// it creates the global app state ("store") and applies
// the various middleware (e,g "thunk" allows us to make async
// ACTION calls)
export default function configureStore(initialState) {
  const storeArgs = [
    persistReducer(persistConfig, rootReducer),
    initialState
  ];

  const middleware = applyMiddleware(thunk, aclMiddleware, apiMiddleware, socketIoMiddleware);

  if (process.env.NODE_ENV === 'development') {
    storeArgs.push(composeEnhancers(responsiveStoreEnhancer, middleware));
  } else {
    storeArgs.push(composeEnhancers(responsiveStoreEnhancer, middleware));
  }

  const store = createStore(...storeArgs);
  const persistor = persistStore(store);
  return { persistor, store };
}
