import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { orderBy } from 'lodash';
import { Alert } from 'antd';
import { removeBannerAlert, clearBannerAlerts } from 'actions/bannerAlertsActions';

class BannerAlerts extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    clearBannerAlerts: PropTypes.func.isRequired,
    removeBannerAlert: PropTypes.func.isRequired
  }

  componentDidMount() {
    // Banner alerts need to be dismissed when route is changed
    this.props.clearBannerAlerts();
  }

  render() {
    const { items, removeBannerAlert } = this.props;

    return (
      <React.Fragment>
        {orderBy(items, ['priority'], ['desc']).map(alert => (
          <Alert
            afterClose={() => removeBannerAlert(alert.id)}
            key={`alert-${alert.id}`}
            message={alert.title}
            description={alert.message}
            type={alert.type}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default withRouter(connect(state => state.bannerAlerts, {
  removeBannerAlert,
  clearBannerAlerts
})(BannerAlerts));
