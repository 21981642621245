import { MEDIA_TYPE_ENUM } from 'shared/constants/enumConstants';

export function filterMediaIdsToRemove(mediaIds, mediaEntities = {}, mediaType) {
  if (mediaType === MEDIA_TYPE_ENUM.AVATAR || mediaType === MEDIA_TYPE_ENUM.BANNER) {
    const filteredMediaEntities = mediaIds.reduce((accum, id) => {
      if (mediaEntities[id]) {
        accum.concat(mediaEntities[id]);
      }
      return accum;
    }, []);
    const mediaEntitiesByType = filteredMediaEntities.filter(entity => entity.Type === mediaType);
    return mediaEntitiesByType.map(entity => entity.ID);
  }
  return [];
}
