import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { WORK_MODE_HIRE, WORK_MODE_WORK } from 'constants/userConstants';
import { getUserData } from 'selectors/userSelectors';
import SideMenu from 'containers/Layouts/Side/SideMenu/SideMenu';
import ProfileChecklist from 'containers/ProfileChecklist/ProfileChecklist';

import './SideContainer.less';

const { Sider } = Layout;

class SideContainer extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    logo: PropTypes.string.isRequired,
    sideViewCollapsed: PropTypes.bool.isRequired,
    theme: PropTypes.string.isRequired
  }

  static defaultProps = {
    currentUser: {}
  }

  render() {
    const {
      currentUser, logo, menuNotifications, sideViewCollapsed, theme
    } = this.props;

    const { Meta: { Preferences = {}, Onboarding = {} } = {} } = currentUser || {};
    const mode = Preferences.workMode || (Onboarding.userType === 'talent' ? WORK_MODE_WORK : WORK_MODE_HIRE);

    const siderClassName = classNames('sider', theme, {
      'sider-collapsed': sideViewCollapsed
    });

    return (
      <Sider
        className={siderClassName}
        collapsed={sideViewCollapsed}
        collapsedWidth="4rem"
        collapsible
        theme={theme}
        trigger={null}
        width="13rem"
      >
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Rupie Network" />
          </Link>
        </div>
        {
          !isEmpty(currentUser) && (
            <SideMenu
              menuNotifications
              {...this.props}
              isOpen={!sideViewCollapsed}
              mode="inline"
              userType={Onboarding.userType}
              workMode={mode}
              currentUser={currentUser}
            />
          )
        }
        {
          mode === WORK_MODE_WORK && (
            <div className="sider__progress-indicator">
              <ProfileChecklist mini fetchChecklist checkShards />
            </div>
          )
        }
      </Sider>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: getUserData(state),
  menuNotifications: state?.menuReducers?.menuNotifications
});

export default withRouter(connect(mapStateToProps)(SideContainer));
