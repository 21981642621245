import { schema } from 'normalizr';

// eslint-disable-next-line import/no-cycle
import { MediaList } from './index';

const Member = new schema.Entity(
  'Member',
  {},
  {
    idAttribute: 'ID'
  }
);

const MemberOrganization = new schema.Entity(
  'MemberOrganization',
  {
    Media: MediaList
  },
  {
    idAttribute: 'ID'
  }
);

const MemberTeam = new schema.Entity(
  'MemberTeam',
  {
    Media: MediaList
  },
  {
    idAttribute: 'ID'
  }
);

const MemberList = [Member];
const MemberOrganizationList = [MemberOrganization];
const MemberTeamList = [MemberTeam];

export {
  Member,
  MemberList,
  MemberOrganization,
  MemberOrganizationList,
  MemberTeamList
};
