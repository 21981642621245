import { schema } from 'normalizr';

const Phase = new schema.Entity(
  'Phase',
  {},
  {
    idAttribute: 'ID'
  }
);

const PhaseList = [Phase];

export { Phase, PhaseList };
