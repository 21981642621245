import { normalize } from 'normalizr';

import { withId } from 'utils/dataUtils';
import { loadingState, errorState, successState } from 'utils/actionUtils';
import { getUserId } from 'selectors/userSelectors';
import { NotificationList, Notification } from 'schemas/index';
import { FETCH_NOTIFICATIONS, READ_NOTIFICATION, NOTIFICATION_UNSUBSCRIBE } from 'constants/notificationConstants';
import { NOTIFICATIONS_ENDPOINT, NOTIFICATION_ENDPOINT, RECURRING_EMAIL_UNSUBSCRIBE } from 'constants/global';

export function fetchNotificationsByUserId() {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(FETCH_NOTIFICATIONS, { userId }),
        success: notifications => successState(FETCH_NOTIFICATIONS, normalize(notifications, NotificationList), { userId }),
        error: err => errorState(FETCH_NOTIFICATIONS, err)
      },
      apiParams: {
        method: 'get',
        endpoint: `${NOTIFICATIONS_ENDPOINT}`
      }
    });
  };
}

export function markRead(id) {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(READ_NOTIFICATION, { id }),
      success: notification => successState(READ_NOTIFICATION, normalize(notification, Notification), { id }),
      error: err => errorState(READ_NOTIFICATION, err)
    },
    apiParams: {
      method: 'patch',
      endpoint: withId(`${NOTIFICATION_ENDPOINT}/:id`, id),
      data: {
        Read: true
      }
    }
  });
}

export function fetchNotificationUnsubscribe(entityId, entityType, emailType) {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(NOTIFICATION_UNSUBSCRIBE, { entityId, emailType, entityType }),
      success: () => successState(NOTIFICATION_UNSUBSCRIBE, null, { entityId, emailType, entityType }),
      error: err => errorState(NOTIFICATION_UNSUBSCRIBE, err)
    },
    apiParams: {
      method: 'delete',
      endpoint: RECURRING_EMAIL_UNSUBSCRIBE,
      data: {
        entityId, entityType, emailType
      }
    }
  });
}
