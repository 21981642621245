import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import DefaultLayout from './DefaultLayout/DefaultLayout';
import GenericLayout from './GenericLayout/GenericLayout';
import WebsiteLayout from './WebsiteLayout/WebsiteLayout';

export const DefaultView = ({ component: Component, hideNav, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <DefaultLayout hideNav={hideNav}>
        <ErrorBoundary>
          <Component {...matchProps} />
        </ErrorBoundary>
      </DefaultLayout>
    )}
  />
);

export const GenericView = ({
  component: Component,
  description = '',
  title = '',
  ...rest
}) => (
  <Route
    {...rest}
    render={matchProps => (
      <GenericLayout description={description} title={title}>
        <ErrorBoundary>
          <Component {...matchProps} />
        </ErrorBoundary>
      </GenericLayout>
    )}
  />
);

export const WebsiteView = ({
  component: Component,
  description = '',
  layout = '',
  title = '',
  ...rest
}) => (
  <Route
    {...rest}
    render={matchProps => (
      <WebsiteLayout description={description} layout={layout} title={title}>
        <ErrorBoundary>
          <Component {...matchProps} />
        </ErrorBoundary>
      </WebsiteLayout>
    )}
  />
);
