import {
  ADD_FORM,
  RESET_FORM,
  UPDATE_FORM,
  GET_FORM
} from 'constants/formConstants';

export function formByID(state = {}, action) {
  // the formID and the data
  const { formData, ID, type } = action;
  switch (type) {
    case GET_FORM:
      return state[ID] || {};
    case ADD_FORM:
      return {
        ...state,
        [ID]: { ...formData, ...state[ID] || {} }
      };
    case RESET_FORM:
      const newState = { ...state };
      delete newState[ID];
      return newState;
    case UPDATE_FORM:
      const existingForm = state[ID] || {};
      const newForm = { ...existingForm, ...formData };

      return {
        ...state,
        newForm
      };
    default:
      return state;
  }
}
