import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar as AntAvatar,
  Tooltip
} from 'antd';
import classNames from 'classnames';

import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';
import Image from 'containers/FileMedia/FileMediaDisplay/ImageDisplay/Image';
import Gem from 'components/Gem/Gem';

import './AvatarDisplay.less';

export default class Avatar extends Component {
  static propTypes = {
    avatarProps: PropTypes.object,
    comment: PropTypes.bool,
    placeholderSeed: PropTypes.string,
    tooltipMessage: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    type: PropTypes.string,
    onClick: PropTypes.func,
    clickable: PropTypes.bool
  }

  static defaultProps = {
    avatarProps: {
      alt: '',
      className: '',
      src: null,
      shape: 'circle'
    },
    clickable: false,
    placeholderSeed: null,
    onClick: () => { },
    comment: false,
    tooltipMessage: null,
    type: ENTITY_TYPES.USER
  }

  constructor(props) {
    super(props);
    const { avatarProps = {}, comment } = props;
    const { className, shape = 'circle' } = avatarProps;
    this.avatarClassname = classNames(
      'ant-avatar',
      'rupie-avatar',
      `ant-avatar-${shape}`,
      className,
      { 'rupie-comment-avatar': comment }
    );
  }

  render() {
    const {
      avatarProps,
      placeholderSeed,
      tooltipMessage,
      onClick,
      clickable
    } = this.props;
    const { src, shape } = avatarProps;

    return (
      <Tooltip destroyTooltipOnHide title={tooltipMessage} arrowPointAtCenter autoAdjustOverflow>
        {
          (src && src.Url)
            ? (
              <Image
                clickable={clickable}
                image={{ ...src }}
                onClick={onClick}
                className={this.avatarClassname}
              />
            )
            : (
              <AntAvatar
                onClick={onClick}
                {...avatarProps}
                className={this.avatarClassname}
                src={false}
                shape={shape}
              >
                <Gem seed={placeholderSeed} />
              </AntAvatar>
            )
        }
      </Tooltip>
    );
  }
}
