import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  faCheck, faQuestion, faQuestionCircle, faTimes
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card, Collapse, List, Skeleton,
  Tag, Tooltip, Typography
} from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { withId } from 'utils/dataUtils';
import { ROUTE_PATHS } from 'constants/global';
import {
  ENTITY_TYPE_ENUM, QUIZ_CATEGORY_ENUM, USER_SHARD_ENUM as USER_SHARD,
  USER_WORK_APPLICATION_STATUS_ENUM as USER_WORK_APPLICATION_STATUS
} from 'shared/constants/enumConstants';
import { getVendorProfile } from 'actions/billingActions';
import { fetchUserQuizzes } from 'actions/quizActions';
import { checkAddShards } from 'actions/shardActions';
import { getUserProfile } from 'actions/userActions';
import { getWorkExperiences } from 'actions/workExperienceActions';
import { getBaseVendorProfile } from 'selectors/billingSelectors';
import { getBaseUserProfile, getUserId } from 'selectors/userSelectors';
import { getWorkExperiencesByUserId } from 'selectors/workExperienceSelectors';
import ProgressIndicator from 'containers/ProgressIndicator/ProgressIndicator';
import BodyHeader from 'components/BodyHeader/BodyHeader';

import './ProfileChecklist.less';

const { Panel } = Collapse;
const { Title } = Typography;

const { USER } = ENTITY_TYPE_ENUM;
const {
  ONBOARDING,
  USER_ASSESSMENTS,
  USER_GAMING_PROFILE,
  USER_PROFILE,
  USER_SETTINGS_WORK_ELIGIBILITY,
  USER_SETTINGS_WORK_PAYOUT,
  USER_EXPERIENCES
} = ROUTE_PATHS;

class ProfileChecklist extends React.Component {
  static propTypes = {
    checkAddShards: PropTypes.func.isRequired,
    checkShards: PropTypes.bool,
    fetchChecklist: PropTypes.bool,
    fetchUserQuizzes: PropTypes.func.isRequired,
    Games: PropTypes.array,
    getUserProfile: PropTypes.func.isRequired,
    getVendorProfile: PropTypes.func.isRequired,
    getWorkExperiences: PropTypes.func.isRequired,
    mini: PropTypes.bool,
    noCard: PropTypes.bool,
    onlyIncomplete: PropTypes.bool,
    Profile: PropTypes.object.isRequired,
    Quizzes: PropTypes.array,
    spacedSection: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    User: PropTypes.object.isRequired,
    userAvatar: PropTypes.object,
    userBanner: PropTypes.object,
    userId: PropTypes.string,
    className: PropTypes.string,
    currentUserId: PropTypes.string.isRequired,
    VendorProfile: PropTypes.object,
    withRating: PropTypes.bool,
    workExperiences: PropTypes.array
  };

  static defaultProps = {
    checkShards: false,
    fetchChecklist: false,
    Games: [],
    className: '',
    mini: false,
    noCard: false,
    onlyIncomplete: false,
    tooltipTitle: 'Complete these tasks to get ready to work on Rupie.',
    spacedSection: true,
    Quizzes: [],
    userAvatar: {},
    userBanner: {},
    userId: null,
    VendorProfile: {},
    withRating: false,
    workExperiences: []
  }

  componentDidMount() {
    const {
      fetchChecklist,
      fetchUserQuizzes,
      getUserProfile,
      getVendorProfile,
      getWorkExperiences,
      Profile,
      Quizzes,
      User,
      userId,
      currentUserId,
      VendorProfile,
      workExperiences
    } = this.props;
    const { ProfileChecklist } = User;

    if (fetchChecklist) {
      if (!Number.isInteger(ProfileChecklist) || isEmpty(Profile) || !Profile.ID) {
        getUserProfile((userId || currentUserId));
      }

      if (!Quizzes.length) {
        fetchUserQuizzes();
      }

      if (!VendorProfile || isEmpty(VendorProfile)) {
        getVendorProfile(USER, (userId || currentUserId));
      }

      if (!workExperiences.length) {
        getWorkExperiences();
      }
    }
  }

  // componentDidUpdate(prevProps) {
  //   const { User: { ProfileChecklist } = {}, checkAddShards, checkShards } = this.props;
  //   const { User: { ProfileChecklist: prevProfileChecklist } = {} } = prevProps;

  //   if (checkShards && (prevProfileChecklist < 100 && ProfileChecklist === 100)) {
  //     checkAddShards(USER_SHARD.PROFILE_CHECKLIST);
  //   }
  // }

  render() {
    const {
      currentUserId,
      Games,
      mini,
      noCard,
      onlyIncomplete,
      tooltipTitle,
      spacedSection,
      Quizzes,
      User,
      userAvatar,
      userBanner,
      userId,
      className,
      VendorProfile,
      withRating,
      workExperiences
    } = this.props;

    if (mini && (isEmpty(User) || !Number.isInteger(User.ProfileChecklist))) {
      return null;
    }

    if (isEmpty(User) || !Number.isInteger(User.ProfileChecklist)) {
      return (
        <Card className="rupie-mb-4">
          <Skeleton />
        </Card>
      );
    }

    const {
      Meta: { Onboarding } = {},
      WorkApplications = [],
      ProfileChecklist
    } = User;

    const allOnboardingQuizzesComplete = Quizzes.reduce((prev, { Category, UserxQuiz = {} }) => {
      if (prev === false && Category === QUIZ_CATEGORY_ENUM.ONBOARDING) {
        return !isEmpty(UserxQuiz)
          && UserxQuiz.deletedAt === null
          && UserxQuiz.UserID === (userId || currentUserId)
          && UserxQuiz.Complete;
      }
      return prev;
    }, false);

    const checklist = [
      {
        title: 'Complete Onboarding',
        completed: Onboarding && Onboarding.completed,
        time: '2 mins',
        link: ONBOARDING
      },
      {
        title: 'Add Work Experience',
        completed: workExperiences && workExperiences.length && workExperiences.filter(({ Title }) => Title !== null).length,
        time: '3 mins per Experience',
        link: withId(USER_EXPERIENCES, currentUserId)
      },
      {
        title: 'Add Avatar',
        completed: !isEmpty(userAvatar),
        optionalText: '(not required)',
        time: '1 min',
        link: withId(USER_PROFILE, currentUserId)
      },
      {
        title: 'Add Banner Image',
        completed: !isEmpty(userBanner),
        optionalText: '(not required but highly recommended)',
        time: '1 min',
        link: withId(USER_PROFILE, currentUserId)
      },
      {
        title: 'Complete Onboarding Assessments',
        completed: allOnboardingQuizzesComplete,
        optionalText: '(not required)',
        time: '4 mins',
        link: withId(USER_ASSESSMENTS, currentUserId)
      }
      // {
      //   title: 'Submit a Profile Review',
      //   optionalText: '(not required but highly recommended)',
      //   completed: WorkApplications.length && WorkApplications.filter(app => app.Status !== USER_WORK_APPLICATION_STATUS.DRAFT).length,
      //   time: '3 mins',
      //   link: withId(USER_SETTINGS_WORK_ELIGIBILITY, currentUserId)
      // }
    ];

    const allChecked = ProfileChecklist === 100;
    const rating = [
      {
        min: 0,
        max: 20,
        title: 'Feeble'
      },
      {
        min: 20,
        max: 40,
        title: 'Typical'
      },
      {
        min: 40,
        max: 60,
        title: 'Fantastic'
      },
      {
        min: 60,
        max: 80,
        title: 'Sensational'
      },
      {
        min: 80,
        max: 100,
        title: 'Unearthly'
      }
    ];

    if (mini) {
      return (
        <div className="mini">
          <Link to={withId(USER_PROFILE, currentUserId)}>
            <ProgressIndicator percent={ProfileChecklist} />
          </Link>
          {withRating
            && (
              <Title level={2}>
                &ldquo;
                {
                  rating.reduce((rating, { min, max, title }) => {
                    if (ProfileChecklist >= min && ProfileChecklist < max) {
                      return title;
                    }
                    return rating;
                  }, 'God-Like')
                }
                &rdquo;
              </Title>
            )}
        </div>
      );
    }

    const checklistComponent = (
      <List
        dataSource={checklist}
        renderItem={item => {
          const {
            title,
            completed,
            time,
            link,
            optionalText
          } = item;

          const completedIcon = (completed ? faCheck : faTimes);

          return (!onlyIncomplete || (!completed && !optionalText)) ? (
            <List.Item
              key={title}
              extra={time && time.length && <Tag className="rupie-tag--secondary">{time}</Tag>}
            >
              <List.Item.Meta
                className={classNames('checklist__title', { 'checklist__title--completed': completed })}
                title={(
                  <React.Fragment>
                    <div className={classNames('checklist__status-button', {
                      'checklist__status-button--completed': completed,
                      'checklist__status-button--optional': optionalText
                    })}
                    >
                      <FontAwesomeIcon icon={optionalText && !completed ? faQuestion : completedIcon} />
                    </div>
                    {link && !completed ? <Link to={link}>{title}</Link> : title}
                  </React.Fragment>
                )}
                description={optionalText}
              />
            </List.Item>
          ) : <React.Fragment />;
        }}
      />
    );

    if (noCard) {
      return <div className="checklist">{checklistComponent}</div>;
    }

    return (
      <Card bordered={false} className={classNames('checklist', className, { 'rupie-mb-4': spacedSection })}>
        <BodyHeader
          isLead
          title={(
            <span>
              Profile Requirements&nbsp;
              {!allChecked
                && (
                  <Tooltip title={tooltipTitle} placement="right">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Tooltip>
                )}
            </span>
          )}
        />
        {allChecked
          ? (
            <Collapse>
              <Panel
                header={(
                  <React.Fragment>
                    <div className={classNames('checklist__status-button', 'checklist__status-button--completed')}>
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    Click to see completed requirements.
                  </React.Fragment>
                )}
                key="1"
              >
                {checklistComponent}
              </Panel>
            </Collapse>
          )
          : checklistComponent}
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentUserId = getUserId(state, props);
  const { userId } = props;
  const id = userId || currentUserId;
  return {
    currentUserId,
    ...getBaseUserProfile(state, { userId: id, ...props }),
    VendorProfile: getBaseVendorProfile(state, { ...props, entityId: id, entityType: USER }),
    workExperiences: getWorkExperiencesByUserId(state, { userId: id, props })
  };
};

export default connect(mapStateToProps, {
  checkAddShards,
  getUserProfile,
  fetchUserQuizzes,
  getWorkExperiences,
  getVendorProfile
})(ProfileChecklist);
