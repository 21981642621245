import { generateAsyncActions } from 'utils/actionUtils';

export const CREATE_VOUCH = generateAsyncActions('CREATE_VOUCH');
export const CREATE_WORK_EXPERIENCE = generateAsyncActions('CREATE_WORK_EXPERIENCE');
export const GET_EXPERIENCES_TO_IMPORT = generateAsyncActions('GET_EXPERIENCES_TO_IMPORT');
export const GET_VOUCH_REQUESTS = generateAsyncActions('GET_VOUCH_REQUESTS');
export const GET_VOUCHES = generateAsyncActions('GET_VOUCHES');
export const GET_WORK_EXPERIENCES = generateAsyncActions('GET_WORK_EXPERIENCES');
export const REMOVE_WORK_EXPERIENCE = generateAsyncActions('REMOVE_WORK_EXPERIENCE');
export const UPDATE_VOUCH = generateAsyncActions('UPDATE_VOUCH');
export const UPDATE_WORK_EXPERIENCE = generateAsyncActions('UPDATE_WORK_EXPERIENCE');

export * from 'shared/web/constants/workExperienceConstants';
