export const SERVER_PROTOCOL = process.env.REACT_APP_SERVER_PROTOCOL; // eslint-disable-line
export const SERVER_HOST = /localhost/g.test(process.env.REACT_APP_SERVER_HOST)
  ? `${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}`
  : process.env.REACT_APP_SERVER_HOST; // eslint-disable-line
export const CLIENT_HOST = /localhost/g.test(process.env.REACT_APP_CLIENT_HOST)
  && process.env.REACT_APP_CLIENT_PORT
  ? `${process.env.REACT_APP_CLIENT_HOST}:${process.env.REACT_APP_CLIENT_PORT}`
  : process.env.REACT_APP_CLIENT_HOST; // eslint-disable-line
export const API_VERSION = process.env.REACT_APP_API_VERSION; // eslint-disable-line
export const API_PREFIX = '/api/';
export const API_PREFIX_NO_SLASH = '/api';
