import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';

import {
  Invitation,
  Vouch,
  VouchList,
  WorkExperience,
  WorkExperienceList,
} from 'schemas/index';
import { errorState, loadingState, successState } from 'utils/actionUtils';
import { withId } from 'utils/dataUtils';
import { WORK_EXPERIENCE_ENDPOINT, WORK_EXPERIENCES_ENDPOINT } from 'constants/global';
import {
  CREATE_VOUCH,
  CREATE_WORK_EXPERIENCE,
  GET_EXPERIENCES_TO_IMPORT,
  GET_VOUCH_REQUESTS,
  GET_VOUCHES,
  GET_WORK_EXPERIENCES,
  REMOVE_WORK_EXPERIENCE,
  UPDATE_WORK_EXPERIENCE,
} from 'constants/workExperienceConstants';
import { getUserId } from 'selectors/userSelectors';

import { decrementProfileProgress } from './userActions';

export function createWorkExperience(data) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(CREATE_WORK_EXPERIENCE, { userId }),
        success: workExperience => successState(CREATE_WORK_EXPERIENCE, normalize(workExperience, WorkExperience), { userId, experienceId: workExperience.ID }),
        error: err => errorState(CREATE_WORK_EXPERIENCE, err)
      },
      apiParams: {
        method: 'post',
        endpoint: `${WORK_EXPERIENCE_ENDPOINT}`,
        data
      }
    });
  };
}

export function getWorkExperiences(UserID = null) {
  return (dispatch, getState) => {
    const userId = UserID || getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(GET_WORK_EXPERIENCES, { userId }),
        success: workExperiences => successState(GET_WORK_EXPERIENCES, normalize(workExperiences, WorkExperienceList), { userId }),
        error: err => errorState(GET_WORK_EXPERIENCES, err)
      },
      apiParams: {
        method: 'get',
        endpoint: UserID ? withId(`${WORK_EXPERIENCES_ENDPOINT}/:userId`, userId, ':userId') : WORK_EXPERIENCES_ENDPOINT
      }
    });
  };
}

/**
 * Get all experiences that are marked as Imported: false
 * @param {String} UserID to fetch non-imported experiences for
 * @returns {Array} of experience objects
 */
export function getExperiencesToImport(UserID = null) {
  return (dispatch, getState) => {
    const userId = UserID || getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(GET_EXPERIENCES_TO_IMPORT, { userId }),
        success: workExperiences => successState(GET_EXPERIENCES_TO_IMPORT, normalize(workExperiences, WorkExperienceList), { userId }),
        error: err => errorState(GET_EXPERIENCES_TO_IMPORT, err)
      },
      apiParams: {
        method: 'get',
        endpoint: withId(`${WORK_EXPERIENCES_ENDPOINT}/:userId/import`, userId, ':userId')
      }
    });
  };
}

export function removeWorkExperience(id) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(REMOVE_WORK_EXPERIENCE, { userId }),
        success: workExperience => {
          const { entities: { WorkExperience: stateWorkExperience } = {} } = getState();
          const { ID, Title } = workExperience;
          const stateExperiences = !isEmpty(stateWorkExperience) ? Object.keys(stateWorkExperience) : [];
          const [stateExperience] = stateExperiences;

          // decrement if the incoming work experience is the last of the user's work experiences
          // ie the number of work experiences in the store is 1 AND it is the incoming work experience
          if (stateExperiences.length === 1
            && stateExperience === ID
            && Title !== null) {
            dispatch(decrementProfileProgress(userId));
          }
          return successState(REMOVE_WORK_EXPERIENCE, { entities: {} }, { experienceId: ID });
        },
        error: err => errorState(REMOVE_WORK_EXPERIENCE, err)
      },
      apiParams: {
        method: 'delete',
        endpoint: withId(`${WORK_EXPERIENCE_ENDPOINT}/:id`, id)
      }
    });
  };
}

export function updateWorkExperience(data, removedItems = {}) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    const { entities: { WorkExperience: stateWorkExperience } = {} } = getState();
    const { ID } = data;
    return dispatch({
      types: {
        loading: () => loadingState(UPDATE_WORK_EXPERIENCE, { userId }),
        success: workExperience => {
          const { Title = '' } = workExperience;
          const stateExperiences = !isEmpty(stateWorkExperience) ? Object.values(stateWorkExperience) : [];
          const [stateExperience] = stateExperiences;

          // increment if they have added their first work experience
          // ie the incoming work experience has a title AND there is only one work experience in the state (the previous version of the incoming experience) AND that the experience is the draft version of the incoming experience (ie it doesn't have a title)
          // if (Title.length && stateExperiences.length === 1 && !stateExperience.Title) {
          //   dispatch(incrementProfileProgress(userId));
          // }

          return successState(UPDATE_WORK_EXPERIENCE, normalize(workExperience, WorkExperience), { userId, experienceId: workExperience.ID, ...removedItems });
        },
        error: err => errorState(UPDATE_WORK_EXPERIENCE, err)
      },
      apiParams: {
        method: 'patch',
        endpoint: withId(`${WORK_EXPERIENCE_ENDPOINT}/:id`, ID),
        data
      }
    });
  };
}

/**
 * Async action that creates a vouch referral from a user for a work experience
 * @return {Function} The Thunk function to resolve
 */
export function createVouch(id, data) {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(CREATE_VOUCH, { id }),
      success: entity => {
        const { Username } = entity;
        return (
          Username
            ? successState(CREATE_VOUCH, normalize({
              ID: id,
              Referrals: { entity }
            }, Vouch), { id })
            : successState(CREATE_VOUCH, normalize(entity, Invitation), { id })
        );
      },
      error: err => errorState(CREATE_VOUCH, err)
    },
    apiParams: {
      endpoint: `${WORK_EXPERIENCE_ENDPOINT}/${id}/vouch`,
      method: 'post',
      data
    }
  });
}

/**
 * Async action that lists vouches for a given work experience ID
 * @return {Function} The Thunk function to resolve
 */
export function getVouches(id) {
  return {
    types: {
      loading: () => loadingState(GET_VOUCHES, { id }),
      success: vouches => successState(GET_VOUCHES, normalize(vouches, VouchList), { id }),
      error: err => errorState(GET_VOUCHES, err, null)
    },
    apiParams: {
      endpoint: `${WORK_EXPERIENCE_ENDPOINT}/${id}/vouches`,
      method: 'get',
    }
  };
}

export function getWorkExperiencesToVouch(UserID = null) {
  return (dispatch, getState) => {
    const userId = UserID || getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(GET_VOUCH_REQUESTS, { userId }),
        success: (workExperiences) => successState(
            GET_VOUCH_REQUESTS,
            normalize(workExperiences, WorkExperienceList),
            { userId }
          ),
        error: (err) => errorState(GET_VOUCH_REQUESTS, err),
      },
      apiParams: {
        endpoint: `${WORK_EXPERIENCES_ENDPOINT}/vouches`,
        method: 'get',
      },
    });
  };
}
