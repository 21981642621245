import {
  MILESTONE_CREATE,
  MILESTONE_UPDATE
} from 'constants/milestoneConstants';

/**
 * Returns a new 'MilestonesByProjectID' state
 * @param  {Array}  [state=[]] The projects or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function milestonesByProjectID(state = [], action) {
  const { type } = action;

  switch (type) {
    default:
      return state;
  }
}

/**
 * Returns a new 'CreateMilestone' state
 * @param  {Object}  [state={}] The create request state
 * @param  {Object} action      The actions object
 * @return {Object}             new state with create request data
 */
export function createMilestone(state = {}, action) {
  const { MilestoneID, type } = action;

  switch (type) {
    case MILESTONE_CREATE._START:
    case MILESTONE_CREATE._ERROR: {
      return { ...action };
    }
    case MILESTONE_CREATE._SUCCESS:
      return {
        MilestoneID
      };
    default:
      return state;
  }
}

/**
 * Returns a new 'UpdateMilestone' state
 * @param  {Object}  [state={}] The create request state
 * @param  {Object} action      The actions object
 * @return {Object}             new state with create request data
 */
export function updateMilestone(state = {}, action) {
  const { type, MilestoneID } = action;

  switch (type) {
    case MILESTONE_UPDATE._START:
    case MILESTONE_UPDATE._SUCCESS:
    case MILESTONE_UPDATE._ERROR:
      return {
        [MilestoneID]: action
      };
    default:
      return state;
  }
}
