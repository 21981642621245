import { schema } from 'normalizr';

export const BillingProfile = new schema.Entity(
  'BillingProfile',
  {},
  {
    idAttribute: 'ID'
  }
);

export const VendorProfile = new schema.Entity(
  'VendorProfile',
  {},
  {
    idAttribute: 'ID'
  }
);


export const VendorProfileList = [VendorProfile];
