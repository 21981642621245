import { merge } from 'lodash';
import {
  PRISM_DELETE,
  PRISM_UPDATE
} from 'constants/prismConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const {
  PRISM
} = ENTITY_TYPES;

export default function prismEntitiesReducers(state = {}, action) {
  const { payload, type } = action;
  if (payload && payload.entities) {
    const {
      jobId
    } = action;
    const { entities } = payload;

    switch (type) {
      case PRISM_DELETE._SUCCESS: {
        const newState = {
          ...state
        };
        delete newState[PRISM][jobId];
        return newState;
      }
      case PRISM_UPDATE._SUCCESS: {
        const updateJob = entities[PRISM][jobId];

        const newState = {
          ...state,
          [PRISM]: {
            [jobId]: {
              ...updateJob
            }
          }
        };
        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
