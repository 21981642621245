import React, { Component } from 'react';
import { VictoryPie, VictoryLabel, } from 'victory';
import { Tooltip } from 'antd';

class ProgressIndicator extends Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
  }

  getData() {
    const { percent } = this.props;
    return [
      { x: 1, y: percent }, { x: 2, y: 100 - percent }
    ];
  }

  render() {
    const {
      title, fontSize, noToolTip, percent
    } = this.props;
    return (
      <Tooltip title={!noToolTip && (title || 'Rupie Profile Progress')} placement="right">
        <svg
          className="progress-indicator"
          viewBox="0 0 400 400"
          width="100%"
          height="100%"
        >
          <VictoryPie
            standalone={false}
            data={this.getData(percent)}
            innerRadius={120}
            labels={() => null}
            style={{
              cursor: 'pointer',
              data: {
                fill: ({ datum }) => {
                  const color = '#ee2a7b';
                  if (datum.x === 1 && datum.y === 100) {
                    return '#0fa04a';
                  }
                  return datum.x === 1 ? color : '#f2f2f2';
                }
              }
            }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={200}
            y={200}
            text={`${Math.round(percent)}%`}
            style={{ fontSize: fontSize || 70, fill: percent === 100 ? '#0fa04a' : '#ca1010', cursor: 'pointer' }}
          />
        </svg>
      </Tooltip>
    );
  }
}

export default ProgressIndicator;
