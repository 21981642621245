import React from 'react';
import {
  faBuilding,
  faCog,
  faSignOut,
  faUserFriends,
  faWallet
} from '@fortawesome/pro-solid-svg-icons';

import { ROUTE_PATHS } from 'constants/global';
import { WORK_MODE_HIRE, WORK_MODE_WORK } from 'constants/userConstants';

/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/extensions */
import { ReactComponent as Beacon } from 'shared/images/icons/icon-dashboard--beacon.svg';
import { ReactComponent as Event } from 'shared/images/icons/icon-dashboard--event.svg';
import { ReactComponent as Extension } from 'shared/images/icons/icon-dashboard--extension.svg';
import { ReactComponent as Hire } from 'shared/images/icons/icon-dashboard--hire.svg';
import { ReactComponent as Home } from 'shared/images/icons/icon-dashboard--home.svg';
// import  { ReactComponent as Lighthouse} from 'shared/images/icons/icon-dashboard--lighthouse.svg';
import { ReactComponent as Profile } from 'shared/images/icons/icon-dashboard--profile.svg';
import { ReactComponent as Quest } from 'shared/images/icons/icon-dashboard--quest.svg';
import { ReactComponent as Rolodex } from 'shared/images/icons/icon-dashboard--rolodex.svg';
import { ReactComponent as Work } from 'shared/images/icons/icon-dashboard--work.svg';

export const SET_MENU_NOTIFICATIONS = 'SET_MENU_NOTIFICATIONS';

export * from 'shared/web/constants/breakpointConstants';

// General layout constants
export const LAYOUT = 'LAYOUT';
export const LAYOUT_DEFAULTS = {
  SideViewCollapsed: true,
  Theme: 'dark'
};

// Section menu constants
export const MENUGROUP = 'MENUGROUP';
export const SUBMENU = 'SUBMENU';

// Side constants
export const SIDE_MENU_DATA = [
  {
    disabled: false,
    hidden: false,
    image: <Home />,
    label: 'View the status of most things; all in one place.',
    link: ROUTE_PATHS.DASHBOARD,
    name: 'Dashboard',
    notifications: 0,
    roles: []
  },
  {
    aliases: [
      '/experience',
      '/experiences',
      '/overview',
      '/profile',
      '/introductions',
      '/referrals',
      '/personality',
      '/schedule',
      '/settings'
    ],
    disabled: false,
    hidden: (workMode, userType) => workMode === WORK_MODE_HIRE || userType === 'recruiter',
    image: <Profile />,
    label: 'View your experiences, schedule, settings, and more.',
    link: ROUTE_PATHS.USER_PROFILE_SHORTCUT,
    name: 'Profile',
    notifications: 0,
    roles: []
  },
  {
    disabled: false,
    hidden: (workMode, userType) => workMode === WORK_MODE_HIRE || userType === 'recruiter',
    image: <Work />,
    label: 'Find your next dream opportunity.',
    link: ROUTE_PATHS.WORK,
    name: 'Opportunities',
    notifications: 0,
    roles: []
  },
  // {
  //   hidden: (workMode, userType) => workMode === WORK_MODE_HIRE || userType === 'recruiter',
  //   image: <Quest />,
  //   label: 'Refer other great talent and earn loot!',
  //   link: ROUTE_PATHS.QUESTS,
  //   name: 'Quests',
  //   notifications: 0,
  //   roles: []
  // },
  {
    aliases: ['/beacon'],
    hidden: (workMode, userType) => workMode === WORK_MODE_WORK || userType === 'recruiter',
    image: <Beacon />,
    label: 'Find the best talent for your roles!',
    link: ROUTE_PATHS.BEACONS,
    name: 'Beacons',
    notifications: 0,
    roles: []
  },
  {
    hidden: (workMode, userType) => workMode === WORK_MODE_WORK || userType === 'recruiter',
    image: <Hire />,
    label: `Tap into Rupie's Candidate Pipeline!`,
    link: ROUTE_PATHS.SIGNAL,
    name: 'Signal',
    notifications: 0,
    roles: []
  },
  {
    disabled: false,
    hidden: (workMode, userType) => workMode === WORK_MODE_WORK || userType === 'recruiter',
    image: <Rolodex />,
    label: 'Your Rolodex',
    link: ROUTE_PATHS.ROLODEX,
    name: 'Rolodex',
    notifications: 0,
    role: ''
  },
  // {
  // aliases: [
  //   '/gig', '/work/gig'
  // ],
  // disabled: false,
  // hidden: workMode => workMode === WORK_MODE_WORK,
  // image: <Lighthouse />,
  // image: <Lighthouse />,
  // label: 'Manager Jobs with Lighthouses',
  // label: 'Manager Jobs with Lighthouses',
  // link: ROUTE_PATHS.WORK,
  // name: 'Lighthouse',
  // name: 'Lighthouse',
  // notifications: 0,
  // roles: []
  // },
  {
    aliases: ['/extension'],
    disabled: false,
    hidden: workMode => workMode === WORK_MODE_WORK,
    image: <Extension />,
    label: 'See industry insights overlaying LinkedIn.',
    link: ROUTE_PATHS.EXTENSION,
    name: 'Extension',
    notifications: 0,
    roles: []
  },
  {
    aliases: ['/event'],
    disabled: false,
    hidden: true,
    image: <Event />,
    label: 'Explore All Jams and Events.',
    link: ROUTE_PATHS.EVENTS,
    name: 'Jams and Event',
    notifications: 0,
    role: ''
  }
];

// Header constants
export const HEADER_MENU_DATA = [
  // {
  //   disabled: false,
  //   hidden: false,
  //   icon: faGamepadAlt,
  //   key: 'projects',
  //   label: 'Explore the Projects using Rupie!',
  //   link: ROUTE_PATHS.PROJECTS,
  //   name: 'Projects'
  // },
  {
    icon: faUserFriends,
    key: 'teams',
    label: 'View your Teams',
    link: ROUTE_PATHS.TEAMS,
    name: 'Teams'
  },
  {
    icon: faBuilding,
    key: 'orgs',
    label: 'View your Companies',
    link: ROUTE_PATHS.ORGANIZATIONS,
    name: 'Companies'
  },
  {
    disabled: false,
    hidden: false,
    icon: faWallet,
    key: 'billing',
    label: 'Manage your billing & subscriptions',
    link: ROUTE_PATHS.USER_SETTINGS_PAYMENTS,
    name: 'Billing & Subscriptions'
  },
  // {
  //   disabled: false,
  //   hidden: false,
  //   icon: faPlug,
  //   key: 'integrations',
  //   label: 'Manage your integrations',
  //   link: ROUTE_PATHS.INTEGRATIONS,
  //   name: 'Integrations'
  // },
  {
    disabled: false,
    hidden: false,
    icon: faCog,
    key: 'settings',
    label: 'Update your settings',
    link: ROUTE_PATHS.USER_SETTINGS_ID,
    name: 'Settings'
  },
  {
    disabled: false,
    hidden: false,
    icon: faSignOut,
    key: 'logout',
    label: 'Logout',
    link: ROUTE_PATHS.LOGOUT,
    name: 'Logout'
  }
];
