import { EMPLOYMENT_TYPE_ENUM, WORK_CONTEXT_ENUM, WORK_EXPERIENCE_TYPE_ENUM } from 'shared/constants/enumConstants';
import {
  ADVANCED,
  APPRENTICE,
  COMPETENT,
  EXPERT,
  NOVICE,
  PROFICIENT,
  WORK_EXPERIENCE_YEARS
} from 'shared/constants/workExperienceConstants';

import education from 'shared/images/icons/icon-exp--education.svg';
import initiative from 'shared/images/icons/icon-exp--initiative.svg';
import portfolio from 'shared/images/icons/icon-exp--portfolio.svg';
import work from 'shared/images/icons/icon-exp--work.svg';

const {
  CONTRACT, FULL_TIME, INTERNSHIP, PART_TIME
} = EMPLOYMENT_TYPE_ENUM;
const {
  EDUCATION, INITIATIVE, JOB, PORTFOLIO
} = WORK_EXPERIENCE_TYPE_ENUM;

const ASSETS = 'Assets';
const COMPANYNAME = 'CompanyName';
const DATERANGE = 'DateRange';
const DESCRIPTION = 'Description';
const EMPLOYMENTTYPE = 'EmploymentType';
const GAMESHIPPED = 'GameShipped';
const GENRES = 'Genres';
const LOCATION = 'Location';
const SKILLS = 'Skills';
const TITLE = 'Title';
const WORKCONTEXT = 'WorkContext';
const WORKEXPERIENCEID = 'WorkExperienceID';

export const WORK_EXPERIENCE_TYPE_MAP = [
  {
    title: 'Job',
    icon: work,
    color: 'green',
    id: JOB,
    fields: [
      TITLE,
      DESCRIPTION,
      COMPANYNAME,
      LOCATION,
      EMPLOYMENTTYPE,
      DATERANGE,
      SKILLS,
      GENRES,
      GAMESHIPPED
    ],
    tooltips: {
      create: 'Create a new Job experience'
    }
  },
  {
    title: 'Education',
    icon: education,
    color: 'blue',
    id: EDUCATION,
    fields: [
      COMPANYNAME,
      DATERANGE,
      DESCRIPTION,
      LOCATION,
      TITLE
    ],
    tooltips: {
      create: 'Create a new Education experience'
    }
  },
  {
    title: 'Portfolio',
    icon: portfolio,
    color: 'purple',
    id: PORTFOLIO,
    fields: [
      ASSETS,
      COMPANYNAME,
      DATERANGE,
      DESCRIPTION,
      EMPLOYMENTTYPE,
      GENRES,
      LOCATION,
      SKILLS,
      TITLE,
      WORKCONTEXT,
      WORKEXPERIENCEID
    ],
    tooltips: {
      create: 'Create a new Portfolio experience'
    }
  },
  {
    title: 'Initiative',
    icon: initiative,
    color: 'orange',
    id: INITIATIVE,
    fields: [
      ASSETS,
      COMPANYNAME,
      DATERANGE,
      DESCRIPTION,
      GAMESHIPPED,
      GENRES,
      LOCATION,
      SKILLS,
      TITLE,
      WORKCONTEXT,
      WORKEXPERIENCEID
    ],
    tooltips: {
      create: 'Create a new Initiative experience'
    }
  }
];

export const WORK_EXPERIENCE_LEVELS = [
  {
    key: 0,
    levelName: WORK_EXPERIENCE_YEARS[NOVICE],
    articleName: 'a',
    title: 'less than a year',
    value: 0
  },
  {
    key: '1',
    levelName: WORK_EXPERIENCE_YEARS[APPRENTICE],
    articleName: 'an',
    title: '1+ years',
    value: '1'
  },
  {
    key: '3',
    levelName: WORK_EXPERIENCE_YEARS[ADVANCED],
    articleName: 'an',
    title: '3+ years',
    value: '3'
  },
  {
    key: '5',
    levelName: WORK_EXPERIENCE_YEARS[COMPETENT],
    articleName: 'a',
    title: '5+ years',
    value: '5'
  },
  {
    key: '10',
    levelName: WORK_EXPERIENCE_YEARS[PROFICIENT],
    articleName: 'a',
    title: '10+ years',
    value: '10'
  },
  {
    key: '15',
    levelName: WORK_EXPERIENCE_YEARS[EXPERT],
    articleName: 'an',
    title: '15+ years',
    value: '15'
  }
];

export const WORK_EXPERIENCE_EMPLOYMENT_TYPES = [
  {
    key: FULL_TIME,
    title: 'Full-time',
    value: FULL_TIME
  },
  {
    key: PART_TIME,
    title: 'Part-time',
    value: PART_TIME
  },
  {
    key: CONTRACT,
    title: 'Contract',
    value: CONTRACT
  },
  {
    key: INTERNSHIP,
    title: 'Internship',
    value: INTERNSHIP
  }
];

export * from 'shared/constants/workExperienceConstants';
