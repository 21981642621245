import { merge } from 'lodash';
import {
  GIG_DELETE,
  GIG_PROPOSAL_DELETE,
  GIG_PROPOSAL_REMOVE_MILESTONE,
  GIG_PROPOSAL_UPDATE,
  GIG_UPDATE
} from 'constants/gigConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const {
  GIG, MILESTONE, PROPOSAL, SUGGESTED_TASK
} = ENTITY_TYPES;

export default function gigEntitiesReducers(state = {}, action) {
  const {
    payload, removedGenres = [], removedSkills = [], type
  } = action;

  if (payload && payload.entities) {
    const {
      gigId, milestoneId, proposalId, previousProposalId, previousProposalStatus
    } = action;

    switch (type) {
      case GIG_DELETE._SUCCESS: {
        const newState = {
          ...state
        };
        delete newState[GIG][gigId];
        return newState;
      }

      case GIG_UPDATE._SUCCESS: {
        const newState = {
          ...state
        };

        const { Genres = [], Skills = [] } = newState[GIG][gigId];

        newState[GIG][gigId].Genres = Genres.filter(genre => {
          const removedGenresIds = removedGenres.map(genre => genre.ID);
          if (!removedGenresIds.includes(genre.ID)) {
            return genre;
          }
          return false;
        });

        newState[GIG][gigId].Skills = Skills.filter(skill => {
          const removedSkillIds = removedSkills.map(skill => skill.ID);
          if (!removedSkillIds.includes(skill.ID)) {
            return skill;
          }
          return false;
        });

        return newState;
      }

      case GIG_PROPOSAL_DELETE._SUCCESS: {
        const newState = { ...state };

        delete newState[PROPOSAL][proposalId];
        return newState;
      }

      case GIG_PROPOSAL_REMOVE_MILESTONE._SUCCESS: {
        const newState = { ...state };

        newState[SUGGESTED_TASK] = Object.values(newState[SUGGESTED_TASK] || {}).reduce((accum, suggestedTask) => {
          if (!suggestedTask.SuggestedTaskxMilestone || suggestedTask.SuggestedTaskxMilestone.MilestoneID !== milestoneId) {
            accum[suggestedTask.ID] = suggestedTask;
          }
          return accum;
        }, {});

        const milestone = newState[MILESTONE][milestoneId];
        const proposalIndex = (milestone.Proposals || []).reduce((accum, proposal, index) => {
          if (proposal.ProposalxMilestone && proposal.ProposalxMilestone.ProposalID === proposalId) {
            return index;
          }
        }, null);

        if (proposalIndex !== null) {
          delete newState[MILESTONE][milestoneId].Proposals;
        }

        return newState;
      }

      case GIG_PROPOSAL_UPDATE._SUCCESS: {
        const newState = { ...state };
        // TODO: get previousProposalId coming back
        if (previousProposalId && previousProposalStatus !== undefined) {
          newState[PROPOSAL][previousProposalId].Status = previousProposalStatus;
          return newState;
        }

        return newState;
      }

      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
