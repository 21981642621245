import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import {
  CONVERSATION_REMOVE_COMMENT_SUCCESS,
  CONVERSATION_DELETE_SUCCESS,
  CONVERSATION_REMOVE_REACTION_SUCCESS,
  CONVERSATION_ADD_REACTION_SUCCESS,
  CONVERSATION_FETCH_SUCCESS,
  CONVERSATION_EDIT_COMMENT_SUCCESS
} from 'constants/conversationConstants';
import {
  SOCKET_PATH,
  SOCKET_CONVERSATION_USER_IS_TYPING
} from 'shared/constants/socketConstants';
import { mergeWithArray } from 'utils/dataUtils';

export default function entities(state = {}, action) {
  const { payload, type } = action;

  if (payload) {
    if (payload.entities) {
      const { conversationId, commentId, reactionId } = action;
      switch (type) {
        case CONVERSATION_REMOVE_COMMENT_SUCCESS: {
          const newState = {
            ...state
          };
          delete newState.Comment[commentId];
          return newState;
        }
        case CONVERSATION_DELETE_SUCCESS: {
          const newState = {
            ...state
          };
          delete newState.Conversation[conversationId];
          return newState;
        }
        case CONVERSATION_REMOVE_REACTION_SUCCESS: {
          const newState = {
            ...state
          };

          newState.Comment[commentId].Reactions = newState.Comment[commentId].Reactions
            .filter(reaction => {
              if (reaction.ID !== reactionId) {
                return reaction;
              }
            });

          return newState;
        }
        case CONVERSATION_ADD_REACTION_SUCCESS: {
          const newState = {
            ...state,
          };

          newState.Comment[commentId] = mergeWith({}, payload.entities.Comment[commentId], state.Comment[commentId], mergeWithArray);
          return newState;
        }

        case CONVERSATION_FETCH_SUCCESS: {
          return {
            ...state,
            Conversation: {
              ...state.Conversation,
              [conversationId]: action.payload.entities.Conversation[conversationId]
            }
          };
        }
        case CONVERSATION_EDIT_COMMENT_SUCCESS: {
          const newState = {
            ...state
          };

          const hasComment = newState.Comment && newState.Comment[commentId];
          const newComment = payload.entities.Comment[commentId];

          if (hasComment) {
            newState.Comment[commentId] = newComment;
          } else {
            if (!newState.Comment) {
              newState.Comment = {};
            }
            newState.Comment[commentId] = newComment;
          }

          return newState;
        }
        default:
          // mergeWith/mergeWithArray are only necessary if we're mutating a collection
          // of items on an entity attribute. Otherwise, we'll just use merge to optimize
          return merge({}, state, payload.entities);
      }
    }

    switch (action.type) {
      case `${SOCKET_PATH}${SOCKET_CONVERSATION_USER_IS_TYPING}`:
        const { conversationID, payload: { ended }, userId } = action;
        const oldTypingUsers = state.Conversation[conversationID].TypingUsers || [];
        let typingUsers = [...oldTypingUsers];

        if (ended) {
          typingUsers = typingUsers.filter(user => user !== userId);
        } else if (!ended && typingUsers.indexOf(userId) === -1) {
          typingUsers.push(userId);
        }

        return {
          ...state,
          Conversation: {
            ...state.Conversation,
            [conversationID]: {
              ...state.Conversation[conversationID],
              TypingUsers: typingUsers
            }
          }
        };
      default:
        return state;
    }
  }

  return state;
}
