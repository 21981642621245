import { randomItem } from 'shared/utils/arrayUtils';
import { convertToBreakpoints } from 'shared/web/utils/mediaUtils';
import { ENTITY_TYPE_ENUM, MEDIA_TYPE_ENUM } from 'shared/constants/enumConstants';
import * as breakpointConstants from 'shared/web/constants/breakpointConstants';

const { TEAM, USER } = ENTITY_TYPE_ENUM;
const { AVATAR, BANNER, GALLERY } = MEDIA_TYPE_ENUM;

const avatars = [
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587125/static/avatars/avatar-personality--wizard.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587123/static/avatars/avatar-personality--healer.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587123/static/avatars/avatar-personality--cleric.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587123/static/avatars/avatar-personality--warrior.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587123/static/avatars/avatar-personality--tinkerer.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587123/static/avatars/avatar-personality--enchanter.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587123/static/avatars/avatar-personality--bard.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587121/static/avatars/avatar-personality--archer.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587121/static/avatars/avatar-personality--artisan.png'
];
export const getRandomAvatarPlaceholder = () => randomItem(avatars);

const hiddenAvatars = [
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587121/static/avatars/avatar-hidden--wizard.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587121/static/avatars/avatar-hidden--tinkerer.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587121/static/avatars/avatar-hidden--warrior.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587119/static/avatars/avatar-hidden--archer.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587119/static/avatars/avatar-hidden--bard.png,',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587119/static/avatars/avatar-hidden--artisan.png',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587119/static/avatars/avatar-hidden--enchanter.png,',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587119/static/avatars/avatar-hidden--healer.png,',
  'https://res.cloudinary.com/rpicdn/image/upload/v1636587119/static/avatars/avatar-hidden--cleric.png,'
];
export const getRandomHiddenAvatarPlaceholder = () => randomItem(hiddenAvatars);

export function getPlaceholderByMediaType(mediaType, entityType) {
  let placeholder;

  switch (mediaType) {
    case AVATAR:
      if (entityType === TEAM) {
        placeholder = 'https://res.cloudinary.com/rpicdn/image/upload/v1590007045/static/placeholders/placeholder-team.png';
      } else if (entityType === USER) {
        placeholder = 'https://res.cloudinary.com/rpicdn/image/upload/v1590007045/static/placeholders/placeholder-user-1.png';
      } else {
        placeholder = 'https://res.cloudinary.com/rpicdn/image/upload/v1590007045/static/placeholders/placeholder.png';
      }
      break;

    case BANNER:
      if (entityType === USER) {
        placeholder = 'https://res.cloudinary.com/rpicdn/image/upload/v1604382806/static/placeholders/banner--user.png';
      } else {
        placeholder = 'https://res.cloudinary.com/rpicdn/image/upload/v1590004733/static/placeholders/placeholder-banner.png';
      }
      break;

    case GALLERY:
      placeholder = 'https://res.cloudinary.com/rpicdn/image/upload/v1590004733/static/placeholders/placeholder-project.png';
      break;

    default:
      placeholder = 'https://res.cloudinary.com/rpicdn/image/upload/v1590004733/static/placeholders/placeholder.png';
  }

  return placeholder;
}

export function getBreakpoints(UrlObject, maxWidth) {
  const breakPointObject = {};

  if (!UrlObject) {
    return breakPointObject;
  }
  // convert urls to match breakpoints and order
  const breakpoints = convertToBreakpoints(UrlObject, maxWidth);
  const breakPointKeys = Object.keys(breakpoints);

  breakPointObject.breakpoints = breakpoints;

  // build srcSet
  breakPointObject.srcSet = breakPointKeys.reduce((prev, size, index) => {
    const url = breakpoints[size];
    const breakPointConst = breakpointConstants[`BREAKPOINT_${size.toUpperCase()}`];
    const srcSetItem = `${prev}${url} ${breakPointConst}w`;

    return (breakPointKeys.length - 1) === index ? srcSetItem : `${srcSetItem}, `;
  }, '');

  // set default size for src
  breakPointObject.src = breakpoints[breakPointKeys[breakPointKeys.length - 1]];
  return breakPointObject;
}
