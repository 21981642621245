import { createSelector } from 'reselect';
import { compact, isEmpty, uniqBy } from 'lodash';

import {
  ENTITY_TYPE_ENUM as ENTITY_TYPES,
  PROJECT_MEDIA_TYPE_ENUM as PROJECT_MEDIA_TYPE
} from 'shared/constants/enumConstants';
import { getTeamFilesAndMedia } from 'selectors/teamSelectors';
import { getOrgFilesAndMedia } from 'selectors/organizationSelectors';
import { getUserFilesAndMedia, getUserId } from 'selectors/userSelectors';
import { ACCESS_CONTROL } from 'shared/utils/authorization';
import {
  getCurrentProject,
  getEventEntities,
  getFileEntities,
  getMediaEntities,
  getMilestoneEntities,
  getOrganizationEntities,
  getProjectEntities,
  getProjectsByUserIDState,
  getTaskEntities,
  getTeamEntities,
  getUserEntities
} from './stateSelectors';

const { AVATAR, BANNER, GALLERY } = PROJECT_MEDIA_TYPE;

export function getProjectFilesAndMedia(project, fileEntities, mediaEntities) {
  const projectMediaFiles = Object.values(fileEntities).filter(file => {
    const { ProjectxFile, Media } = file;
    if (ProjectxFile && ProjectxFile.ProjectID === project.ID && Media) {
      return file;
    }
  });

  const projectFiles = Object.values(fileEntities).filter(file => {
    const { ProjectxFile, Media } = file;
    if (ProjectxFile && ProjectxFile.ProjectID === project.ID && !Media) {
      return file;
    }
  }) || [];

  const projectMediaIDs = project.Media || [];
  const projectMedia = compact(projectMediaIDs.reduce((entities, item) => entities.concat(mediaEntities[item]), []));

  const projectAvatar = projectMedia.find(item => {
    const { ProjectxMedia } = item;

    if (!ProjectxMedia) {
      return false;
    }

    return ProjectxMedia.Type === AVATAR;
  }) || projectMediaFiles.find(file => {
    const { MediaID } = file;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === AVATAR && media.ID === MediaID);
  }) || {};

  const projectBanner = projectMedia.find(item => {
    const { ProjectxMedia } = item;

    if (!ProjectxMedia) {
      return false;
    }

    return ProjectxMedia.Type === BANNER;
  }) || projectMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === BANNER && media.ID === MediaID);
  }) || {};

  const projectGallery = [
    ...(projectMedia || []).filter(media => {
      const { ProjectxMedia } = media;

      if (ProjectxMedia && ProjectxMedia.Type === GALLERY) {
        return media;
      }
    }),

    ...(projectMediaFiles || []).filter(file => {
      const { MediaID } = file;
      return MediaID && Object.values(mediaEntities).find(media => media.Type === GALLERY && media.ID === MediaID);
    })
  ];

  return {
    projectAvatar,
    projectBanner,
    projectFiles,
    projectGallery
  };
}

const sortTaskList = (list, type) => list.sort((a, b) => {
  const aValue = a[`Taskx${type}`];
  const bValue = b[`Taskx${type}`];

  if (!aValue || !bValue) {
    return;
  }

  if (!aValue.Order || !bValue.Order) {
    return;
  }

  return aValue.Order - bValue.Order;
});

const getProjectMilestones = (Project, Milestones) => {
  let ProjectMilestones = [];

  if (Project.Milestones && Project.Milestones.length) {
    ProjectMilestones = Project.Milestones.reduce((milestones, milestoneID) => milestones.concat({ ...Milestones[milestoneID] }), []);
  }

  return ProjectMilestones;
};

export const getProjectMembers = (Project, Members, fileEntities, mediaEntities) => {
  let projectMembers = [];

  if (Project.Members && Project.Members.length) {
    projectMembers = Project.Members.reduce((members, memberID) => {
      const Member = Members[memberID];
      if (Member) {
        return members.concat({
          ...Member,
          ...getUserFilesAndMedia(Member, fileEntities, mediaEntities)
        });
      }
      return members;
    }, []);
  }

  return projectMembers;
};

export const getProjectOrganizations = (Project, Organizations, fileEntities, mediaEntities) => {
  const { MemberOrganizations } = Project;
  let projectOrgs = [];

  if ((MemberOrganizations && MemberOrganizations.length) && !isEmpty(Organizations)) {
    projectOrgs = MemberOrganizations.reduce((orgs, orgID) => orgs.concat({
      ...Organizations[orgID],
      ...getOrgFilesAndMedia(Organizations[orgID], fileEntities, mediaEntities)
    }), []);
  }

  return projectOrgs;
};

export const getProjectTeams = (Project, Teams, fileEntities, mediaEntities) => {
  const { MemberTeams } = Project;
  let projectTeams = [];

  if (MemberTeams && MemberTeams.length && !isEmpty(Teams)) {
    projectTeams = MemberTeams.reduce((teams, teamID) => teams.concat({
      ...Teams[teamID],
      ...getTeamFilesAndMedia(Teams[teamID], fileEntities, mediaEntities)
    }), []);
  }

  return projectTeams;
};

export const getProjectOwner = (
  { UserID, OrganizationID, TeamID },
  userEntities,
  organizationEntities,
  fileEntities,
  mediaEntities,
  teamEntities = []
) => {
  let projectOwner;
  if (organizationEntities[OrganizationID]) {
    const orgEntity = organizationEntities[OrganizationID];
    projectOwner = {
      ...orgEntity,
      ...getOrgFilesAndMedia(orgEntity, fileEntities, mediaEntities)
    };
  } else if (userEntities[UserID]) {
    const userEntity = userEntities[UserID];
    projectOwner = {
      ...userEntity,
      ...getUserFilesAndMedia(userEntity, fileEntities, mediaEntities)
    };
  } else if (teamEntities[TeamID]) {
    const teamEntity = teamEntities[TeamID];
    projectOwner = {
      ...teamEntity,
      ...getTeamFilesAndMedia(teamEntity, fileEntities, mediaEntities)
    };
  }
  return projectOwner;
};

// if ProjectID is passed to the connected component, will extract and use this project in the selector
const getProjectByIDFilter = (state, props) => {
  if (props && props.ProjectID) {
    return getProjectEntities(state)[props.ProjectID];
  }
  if (props && props.projectId) {
    return getProjectEntities(state)[props.projectId];
  }
  return {};
};

const getProjectUser = (Project, Users) => Users[Project.UserID] || {};

const getTaskEntityList = (taskIDs, taskEntities) => {
  let tasks = [];

  if (taskIDs && taskIDs.length) {
    tasks = taskIDs.reduce((newTasks, taskID) => newTasks.concat({ ...taskEntities[taskID] }), []);
  }
  return tasks;
};

export const buildProjectContributors = project => {
  const {
    Members,
    MemberOrganizations,
    MemberTeams,
    projectOwner = {}
  } = project;

  return {
    projectOwner,
    Members,
    MemberOrganizations,
    MemberTeams
  };
};

export const getProjectFunding = projectMilestones => {
  const Funding = projectMilestones.reduce((funding, milestone) => {
    const { FundingGoal, FundingTotal } = milestone;

    return {
      FundingGoal: funding.FundingGoal + FundingGoal,
      FundingTotal: funding.FundingTotal + FundingTotal
    };
  }, {
    FundingGoal: 0,
    FundingTotal: 0
  });

  return Funding;
};

const getUserIdFilter = (state, props) => {
  const { UserID } = props;

  return UserID || getUserId(state);
};

export const getProjectsByUserID = createSelector(
  getUserIdFilter,
  getProjectsByUserIDState,
  getProjectEntities,
  getFileEntities,
  getMediaEntities,
  (UserID, ProjectsByUserID, ProjectEntities, fileEntities, mediaEntities) => {
    const userProjectsState = ProjectsByUserID[UserID] || {};
    const userProjectIDs = userProjectsState.items || [];

    const Projects = userProjectIDs.reduce((projects, id) => {
      const project = ProjectEntities[id];
      const Media = getProjectFilesAndMedia(project, fileEntities, mediaEntities);
      return projects.concat({ ...project, ...Media });
    }, []);

    const { error, isLoading, projectsTotal } = userProjectsState;

    return {
      error,
      isLoading,
      Projects,
      projectsTotal,
      UserID
    };
  }
);

export const getProjectsByUserPermissions = createSelector(
  (state, props) => props.projectUserActions,
  getUserId,
  getProjectsByUserIDState,
  getProjectEntities,
  (projectUserActions, currentUserID, ProjectsByUserID, ProjectEntities) => {
    const userProjectsState = ProjectsByUserID[currentUserID] || {};
    const userProjectIDs = userProjectsState.items || [];

    const Projects = userProjectIDs.reduce((projects, id) => {
      const project = ProjectEntities[id];

      if (ACCESS_CONTROL.can(projectUserActions, project.UserRole)) {
        projects.push(project);
      }
      return projects;
    }, []);

    const { error, isLoading, projectsTotal } = userProjectsState;
    return {
      error,
      isLoading,
      Projects,
      projectsTotal,
      currentUserID
    };
  }
);

export const getBaseProject = createSelector(
  getProjectByIDFilter,
  getCurrentProject,
  getProjectEntities,
  (projectFromID, currentProject, ProjectEntities) => {
    const project = (projectFromID && projectFromID.ID) ? projectFromID : currentProject;
    const { ID } = project;
    const Project = ProjectEntities[ID] || {};
    return {
      Project
    };
  }
);

export const getProjectWithFilesAndMedia = createSelector(
  getBaseProject,
  getFileEntities,
  getMediaEntities,
  (baseProject, fileEntities, mediaEntities) => ({
    ...baseProject,
    ...getProjectFilesAndMedia(baseProject.Project, fileEntities, mediaEntities)
  })
);

export const getProjectWithOwner = createSelector(
  getBaseProject,
  getUserEntities,
  getOrganizationEntities,
  getTeamEntities,
  getFileEntities,
  getMediaEntities,
  (baseProject, userEntities, organizationEntities, teamEntities, fileEntities, mediaEntities) => {
    const { Project } = baseProject;

    const projectOwner = getProjectOwner(
      Project,
      userEntities,
      organizationEntities,
      fileEntities,
      mediaEntities,
      teamEntities
    );

    return {
      ...baseProject,
      projectOwner
    };
  }
);

export const getProjectWithMembers = createSelector(
  getProjectWithOwner,
  getUserEntities,
  getOrganizationEntities,
  getTeamEntities,
  getFileEntities,
  getMediaEntities,
  (projectWithUsers, membersEntities, organizationEntities, teamEntities, fileEntities, mediaEntities) => {
    const { Project } = projectWithUsers;
    const Members = getProjectMembers(Project, membersEntities, fileEntities, mediaEntities);
    const MemberOrganizations = getProjectOrganizations(Project, organizationEntities, fileEntities, mediaEntities);
    const MemberTeams = getProjectTeams(Project, teamEntities, fileEntities, mediaEntities);
    return {
      ...projectWithUsers,
      Members,
      MemberOrganizations,
      MemberTeams
    };
  }
);

// TODO: We should not need a selector this involved. We'll break out the child
// entity lookups into individual component providers/containers
export const getProjectWithEntities = createSelector(
  getBaseProject,
  getMilestoneEntities,
  getUserEntities,
  getTaskEntities,
  getEventEntities,
  (baseProject, Milestones, Users, Tasks, Events) => {
    const { Project } = baseProject;
    const ProjectMilestones = getProjectMilestones(Project, Milestones);
    const BacklogTasks = sortTaskList(getTaskEntityList(baseProject.Tasks, Tasks), ENTITY_TYPES.PROJECT);
    const ProjectUser = getProjectUser(Project, Users);
    const ProjectEvents = Project.Events ? Project.Events.reduce((events, eventId) => events.concat(Events[eventId]), []) : [];
    const Funding = getProjectFunding(ProjectMilestones);
    ProjectMilestones.forEach(milestone => {
      milestone.Tasks = (milestone.Tasks) ? getTaskEntityList(milestone.Tasks, Tasks) : [];
      milestone.Tasks = sortTaskList(milestone.Tasks, ENTITY_TYPES.MILESTONE);
    });

    return {
      ...baseProject,
      BacklogTasks,
      Milestones: ProjectMilestones,
      Project,
      User: ProjectUser,
      Funding,
      Events: ProjectEvents
    };
  }
);

export const getProjectWithEntitiesAndMembers = createSelector(
  getProjectWithEntities,
  getProjectWithMembers,
  getProjectWithOwner,
  (projectWithEntities, projectWithMembers, projectWithOwner) => {
    const {
      Members,
      MemberOrganizations,
      MemberTeams,
    } = projectWithMembers;

    const { projectOwner } = projectWithOwner;

    return {
      ...projectWithEntities,
      projectOwner,
      Members: uniqBy(Members, 'ID'),
      MemberOrganizations: uniqBy(MemberOrganizations, 'ID'),
      MemberTeams: uniqBy(MemberTeams, 'ID')
    };
  }
);

export const getProjectContributors = createSelector(
  getProjectWithEntitiesAndMembers,
  projectWithEntities => buildProjectContributors(projectWithEntities)
);
