import {
  faArchive,
  faCube,
  faFileAlt,
  faFilm,
  faHeadVr,
  faImage,
  faVideo,
  faWaveformPath
} from '@fortawesome/pro-duotone-svg-icons';

import { generateAsyncActions } from 'utils/actionUtils';
import {
  FILE_TYPE_ENUM as FILE_TYPE,
  MEDIA_TYPE_ENUM as MEDIA_TYPE
} from 'shared/constants/enumConstants';

export const {
  ARCHIVE,
  AUDIO,
  BINARY,
  DOCUMENT,
  EXECUTABLE,
  IMAGE,
  MODEL_3D,
  VIDEO
} = FILE_TYPE;

const {
  EMBED,
  PANORAMA
} = MEDIA_TYPE;

export const FILE_MENU_CONFIG = {
  [ARCHIVE]: {
    title: 'Archive',
    icon: faArchive
  },
  [AUDIO]: {
    title: 'Sound',
    icon: faWaveformPath
  },
  [DOCUMENT]: {
    title: 'Document',
    icon: faFileAlt
  },
  [EMBED]: {
    title: 'Video Embed',
    icon: faVideo
  },
  [IMAGE]: {
    title: 'Image',
    icon: faImage
  },
  [MODEL_3D]: {
    title: '3D Model',
    icon: faCube
  },
  [PANORAMA]: {
    title: 'Panorama',
    icon: faHeadVr
  },
  [VIDEO]: {
    title: 'Video Clip',
    icon: faFilm
  }
};

export const FETCH_FILES = generateAsyncActions('FETCH_FILES');
export const FILE_REMOVE = generateAsyncActions('FILE_REMOVE');
export const FILE_UPLOAD = generateAsyncActions('FILE_UPLOAD');

/*
  note: send the resume to the grade endpoint in a FormData with the key resume
  note: the :id in the skill and parse endpoints comes from gradeResponse.data.vars.rez_id
 */
export const REZSCORE_PATH = 'https://rezscore.com/a/';
export const REZSCORE_GRADE = `${REZSCORE_PATH}${process.env.REACT_APP_REZSCORE_KEY}/grade.json`;
export const REZSCORE_SKILL = `${REZSCORE_PATH}${process.env.REACT_APP_REZSCORE_KEY}/skill/rez/:id.json`;
export const REZSCORE_PARSE = `${REZSCORE_PATH}${process.env.REACT_APP_REZSCORE_KEY}/parse/:id.json`;
