import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedReduxRedirect, connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import Cryptr from 'cryptr';
import Cookies from 'js-cookie';
import has from 'lodash/has';

import { history } from 'utils/historyUtils';
import { tracker } from 'utils/trackingUtils';
import { CLIENT_HOST } from 'constants/global';
import { loginUser } from 'actions/userActions';
import { userStateExists } from 'selectors/userSelectors';
import Loading from 'components/Loading/Loading';

const appVersion = process.env.REACT_APP_RUPIE_VERSION;
const cryptr = new Cryptr(appVersion);

export const removeSession = () => {
  localStorage.removeItem('SESSION');
  Cookies.remove('loggedIn');
};

// Fetch & Set Local Storage Session
export const setSession = session => {
  try {
    // encrypt session with AES using the appVersion which changes per deploy
    // This isn't fool proof, but it at least helps obfuscate XSS attacks between
    // Token expirations. Cookies open worse attacks, even HttpOnly
    // TODO uncomment when this doesn't break puppeteer
    // session.Token = cryptr.encrypt(session.Token);
    localStorage.setItem('SESSION', JSON.stringify(session));
    // for showing header bar on the website
    const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.rupie.io';
    const redirect = process.env.REACT_APP_CLIENT_PROTOCOL + '://' + CLIENT_HOST;
    Cookies.set('loggedIn', { loggedIn: true, redirect }, { domain, expires: 3 });
  } catch (err) {
    console.log('error setting session', err);
  }
};
export const getSession = () => {
  const session = JSON.parse(localStorage.getItem('SESSION'));
  // TODO uncomment when this doesn't break puppeteer
  // if (session) {
  //   try {
  //     session.Token = cryptr.decrypt(session.Token);
  //   } catch (err) {
  //     // bad stuff happened, remove session
  //     console.log('error decrypting, removing session', err);
  //     return removeSession();
  //   }
  // }
  return session;
};

// Redux Auth Wrapper
const locationHelper = locationHelperBuilder({});

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => userStateExists(state),
  authenticatingSelector: state => userStateExists(state) && state.user.currentUser.isLoading,
  wrapperDisplayName: 'UserIsAuthenticated'
};

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults);

export const userIsLoggedIn = () => {
  const storedCredentials = getSession();
  tracker.set({ userId: getSession() && getSession().ID });
  return has(storedCredentials, 'ID') && has(storedCredentials, 'Token');
};

export const userIsAuthenticatedRedir = connectedReduxRedirect({
  redirectPath: '/login',
  AuthenticatingComponent: Loading,
  redirectAction: newLoc => async dispatch => {
    const { search = '' } = newLoc;
    if (search) {
      newLoc.pathname = `${newLoc.pathname}?${search}`;
    }
    // we are now trying to redirect to login because currentUser is null
    if (getSession() && getSession().ID) {
      // get the currentUser and don't redirect
      const loginResult = await dispatch(loginUser());
      if (loginResult && loginResult.type === 'LOGIN_ERROR') {
        removeSession();
      } else {
        return loginResult;
      }
    }
    // redirect to login, because we don't have a current user
    history.replace(newLoc.pathname);
    //  window.location.replace(newLoc.pathname);
  },
  ...userIsAuthenticatedDefaults
});

// add userEmailVerifiedRedir

const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: state => !userStateExists(state) || !(getSession() && getSession().ID),
  wrapperDisplayName: 'UserIsNotAuthenticated'
};

export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults);

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || `/${ownProps.location.search}`,
  allowRedirectBack: false
});

export const userIsAdminRedir = connectedRouterRedirect({
  redirectPath: '/login',
  allowRedirectBack: false,
  authenticatedSelector: state => userStateExists(state) && state.user.currentUser.data.isAdmin,
  predicate: user => user.isAdmin,
  wrapperDisplayName: 'UserIsAdmin'
});

export { cryptr };
