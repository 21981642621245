import { difference, merge, omit } from 'lodash';
import {
  ORGANIZATION_ADD_MEDIA,
  ORGANIZATION_DELETE_MEDIA,
  ORGANIZATION_MEMBER_REMOVE
} from 'constants/organizationConstants';
import { filterMediaIdsToRemove } from 'reducers/utils/mediaUtils';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { ORGANIZATION } = ENTITY_TYPES;

export default function organizationEntitiesReducers(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    switch (type) {
      case ORGANIZATION_ADD_MEDIA._SUCCESS: {
        const { mediaId, mediaType, organizationId } = action;
        const updatedOrg = { ...state.Organization[organizationId] };
        const orgMedia = updatedOrg.Media || [];
        const removedMediaIds = filterMediaIdsToRemove(orgMedia, state.Media, mediaType);

        updatedOrg.Media = difference(orgMedia, removedMediaIds).concat(mediaId);

        return merge({}, state, payload.entities, {
          Organization: {
            [organizationId]: updatedOrg
          }
        });
      }
      case ORGANIZATION_DELETE_MEDIA._SUCCESS: {
        const { mediaId, organizationId } = action;
        const updatedOrganizationEntity = {
          ...state.Organization[organizationId],
          Media: state.Organization[organizationId].Media.filter(id => id !== mediaId)
        };
        const updatedMedia = omit(state.Media, mediaId);

        return {
          ...state,
          Organization: {
            ...state.Organization,
            [organizationId]: updatedOrganizationEntity
          },
          Media: updatedMedia
        };
      }
      case ORGANIZATION_MEMBER_REMOVE._SUCCESS: {
        const { orgId, userId } = action;
        const newState = {
          ...state
        };
        const index = newState[ORGANIZATION][orgId].Members.findIndex(element => element.ID === userId);
        delete newState[ORGANIZATION][orgId].Members[index];
        return newState;
      }
      default:
        return state;
    }
  }
  return state;
}
