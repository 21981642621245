import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

import UncaughtException from 'components/HttpStatus/UncaughtException';

export default class ErrorBoundary extends Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false, isChunkError: false };
  }

  componentDidCatch(error) {
    const isChunkError = error.toString().indexOf('ChunkLoadError') > -1;
    // if we have a chunk load error, just reload the page
    if (isChunkError) {
      window.location.reload(true);
    }

    this.setState({ hasError: true, isChunkError });

    if (!isChunkError) {
      Sentry.captureException(error);
    }
  }

  render() {
    const { hasError, isChunkError } = this.state;
    const { children } = this.props;

    if (isChunkError) {
      return null;
    }

    if (hasError) {
      // You can render any custom fallback UI
      return <UncaughtException />;
    }
    return children;
  }
}
