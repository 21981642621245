import { message } from 'antd';
import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import diff from 'object-diff';
import queryString from 'query-string';

import {
  InternalRecordList,
  Invitation,
  InvitationList,
  ReferralList,
  User,
  UserStub
} from 'schemas/index';
import { errorState, loadingState, successState } from 'utils/actionUtils';
import { closeSocket, openSocket } from 'utils/apiUtils';
import { getSession, removeSession, setSession } from 'utils/authUtils';
import { withId, withIds } from 'utils/dataUtils';
import { history } from 'utils/historyUtils';
import { tracker } from 'utils/trackingUtils';
import { isExperimentEnabled } from 'shared/utils/dataUtils';
import * as conversationConstants from 'constants/conversationConstants';
import {
  FORGOT_ENDPOINT,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  REGISTER_ENDPOINT,
  REGISTER_VIP_ENDPOINT,
  RESET_ENDPOINT,
  USER_ENDPOINT
} from 'constants/global';
import {
  ADD_USER_GAMES,
  CREATE_USER_REFERRAL_INVITATION,
  CREATE_WORK_APPLICATION,
  FETCH_INTERNAL_RECORDS,
  FETCH_SENT_REFERRAL_INVITATIONS,
  FETCH_SENT_REFERRALS,
  FETCH_USER_ERROR,
  FETCH_USER_PREMIUM_DATA,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_START,
  FETCH_USER_STUB,
  FETCH_USER_SUCCESS,
  FORGOT_USERNAME,
  LOGIN_ERROR,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_START,
  PASSWORD_RESET_SUCCESS,
  REGISTER_USER_ERROR,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REMOVE_USER_GAMES,
  REMOVE_USER_REFERRAL_INVITATION,
  RESEND_USER_REFERRAL_INVITATION,
  UPDATE_WORK_APPLICATION,
  USER_ADD_SOCIAL,
  USER_BACKFILL,
  USER_COMPLETE_ONBOARDING,
  USER_COMPLETE_REGISTRATION,
  USER_DECREMENT_PROFILE_PROGRESS,
  USER_DISCONNECT_SOCIAL,
  USER_DOWNLOAD_PDF,
  USER_IMPORT_LINKEDIN,
  USER_INCREMENT_PROFILE_PROGRESS,
  USER_PHOTO_FETCH_ERROR,
  USER_PHOTO_FETCH_START,
  USER_PHOTO_FETCH_SUCCESS,
  USER_REGISTER_VIP,
  USER_UPDATE_ERROR,
  USER_UPDATE_PREFS_ERROR,
  USER_UPDATE_PREFS_START,
  USER_UPDATE_PREFS_SUCCESS,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS
} from 'constants/userConstants';
import { ENTITY_TYPE_ENUM, USER_WORK_APPLICATION_STATUS_ENUM as USER_WORK_APPLICATION_STATUS } from 'shared/constants/enumConstants';
import { IS_EXTENSION_EXPERIMENT_ENABLED } from 'shared/constants/experimentConstants';
import { clearBannerAlerts } from 'actions/bannerAlertsActions';
import { getUserData, getUserId, getUserPrefs } from 'selectors/userSelectors';

const browser = (window.browser) ? window.browser : window.chrome;

/**
 * Attempts to backfill a user from their LinkedInUrl and/or Resume
 * both personal and experience data
 */
export function backfillUser(ID, options) {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(USER_BACKFILL),
      success: user => successState(USER_BACKFILL, normalize(user, User), { ID: user.ID }),
      error: err => errorState(USER_BACKFILL, err)
    },
    apiParams: {
      method: 'post',
      endpoint: `${USER_ENDPOINT}/${ID}/backfill`,
      queryParams: {
        ...options
      }
    }
  });
}

export function getCurrentUser(ID, base = false) {
  return {
    types: {
      loading: FETCH_USER_START,
      success: userData => {
        const normalizedUser = normalize(userData, User);
        const payload = {
          ...normalizedUser,
          User: normalizedUser.entities.User[normalizedUser.result],
          Token: getSession().Token
        };
        return {
          type: FETCH_USER_SUCCESS,
          payload
        };
      },
      error: FETCH_USER_ERROR
    },
    apiParams: {
      endpoint: `${USER_ENDPOINT}/${ID}?base=${base}`
    }
  };
}

export function getUserPremiumData(userId, beaconId) {
  return (dispatch, getState) => {
    const ID = userId !== 'profile' ? userId : getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(FETCH_USER_PREMIUM_DATA, { ID }),
        success: userData => successState(FETCH_USER_PREMIUM_DATA, userData && normalize(userData, User), { ID }),
        error: err => errorState(FETCH_USER_PREMIUM_DATA, err, null, { ID })
      },
      apiParams: {
        endpoint: `${USER_ENDPOINT}/${ID}/premium?beaconId=${beaconId}`
      }
    });
  };
}

export function getUserProfile(userId) {
  return (dispatch, getState) => {
    const ID = userId !== 'profile' ? userId : getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(FETCH_USER_PROFILE_START, { ID }),
        success: userProfile => successState(FETCH_USER_PROFILE_SUCCESS, normalize(userProfile, User), { ID }),
        error: err => errorState(FETCH_USER_PROFILE_ERROR, err, null, { ID })
      },
      apiParams: {
        endpoint: `${USER_ENDPOINT}/${ID}/profile`
      }
    });
  };
}

export function getUserStub(id) {
  return (dispatch, getState) => dispatch({
    types: {
      loading: FETCH_USER_STUB._START,
      success: userStub => successState(FETCH_USER_STUB, normalize(userStub, UserStub), { ID: id }),
      error: FETCH_USER_STUB._ERROR
    },
    apiParams: {
      endpoint: `/user-stub/${id}`,
      method: 'get'
    }
  });
}

export function triggerExtensionClose(user) {
  if (browser && browser.runtime?.sendMessage) {
    if (isExperimentEnabled(user, IS_EXTENSION_EXPERIMENT_ENABLED)) {
      browser.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, { session: getSession() });
      browser.runtime.sendMessage(process.env.REACT_APP_EXTENSION_INTERNAL_ID, { session: getSession() });
    } else {
      message.error("Sorry, you don't have access to the Chrome Browser Extension :-(");
    }
  }
}

export function loginUser(data) {
  // if we are logging in from existing JWT, dispatch
  if (!data && getSession() && getSession().Token) {
    return dispatch => {
      dispatch({
        type: LOGIN_START
      });

      return dispatch(getCurrentUser(getSession().ID, false))
        .then(data => {
          if (data.type === FETCH_USER_ERROR) {
            return dispatch(errorState(LOGIN_ERROR, data.error));
          }

          if (getSession()) {
            const { User } = data.payload || {};

            openSocket();
            // set tracking for GA
            tracker.set({ userId: getSession().ID });
            window.analytics.identify(getSession().ID, {
              email: User.Email,
              name: User.FullName
            });

            const { Meta: { Onboarding: { completed: onboardingCompleted = false } = {}, Registration: { completed: registrationCompleted = false } = {} } = {} } = User;
            const { extension } = queryString.parse(window.location.search);
            // sends the session up to the extension
            if (extension && (onboardingCompleted || registrationCompleted)) {
              triggerExtensionClose(User);
            }

            return dispatch({
              type: LOGIN_SUCCESS,
              payload: data.payload
            });
          }

          return dispatch({
            type: LOGIN_ERROR
          });
        });
    };
  }

  // cleanup before sending
  if (data.Username) {
    data.Username = data.Username.trim();
  }
  data.Password = data.Password.trim();

  return {
    id: 'login',
    types: {
      loading: LOGIN_START,
      success: user => {
        const { Token, User: userData } = user;
        const { ID } = userData;
        setSession({
          Token,
          ID
        });
        const { extension } = queryString.parse(window.location.search);
        const { Meta: { Onboarding: { completed: onboardingCompleted = false } = {}, Registration: { completed: registrationCompleted = false } = {} } = {} } = userData;
        // sends the session up to the extension
        if (extension && (onboardingCompleted || registrationCompleted)) {
          triggerExtensionClose(userData);
        }
        // open active socket
        openSocket();
        // set tracking for GA
        tracker.set({ userId: ID });
        window.analytics.identify(ID, {
          email: User.Email,
          name: User.FullName
        });

        return {
          type: LOGIN_SUCCESS,
          payload: {
            ...user,
            ...normalize(userData, User)
          }
        };
      },
      error: err => errorState(LOGIN_ERROR, err, err.response ? err.response.status : 400)
    },
    apiParams: {
      endpoint: LOGIN_ENDPOINT,
      method: 'post',
      data
    }
  };
}

export function logoutUser(justClear = false) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    dispatch(clearBannerAlerts());
    closeSocket();

    // TODO: migrate to hubspot chat
    // window.Intercom('shutdown');

    if (justClear) {
      removeSession();
      return dispatch({
        type: LOGOUT_SUCCESS
      });
    }

    return dispatch({
      types: {
        loading: LOGOUT_START,
        success: () => {
          removeSession();
          // need this to go here to fix issue with resetting redux state
          history.replace('/');
          return {
            type: LOGOUT_SUCCESS
          };
        },
        error: () => {
          removeSession();
          return {
            type: LOGOUT_ERROR
          };
        }
      },
      apiParams: {
        endpoint: `${LOGOUT_ENDPOINT}/${ID}`
      }
    });
  };
}

export function resetPassword(email = '', password = '', hash = '') {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(PASSWORD_RESET_START),
      success: () => successState(PASSWORD_RESET_SUCCESS),
      error: err => {
        console.log(err);
        return errorState(PASSWORD_RESET_ERROR, err);
      }
    },
    apiParams: {
      endpoint: `${RESET_ENDPOINT}?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&hash=${hash}`
    }
  });
}

export function forgotUsername(email = '') {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(FORGOT_USERNAME),
      success: () => successState(FORGOT_USERNAME),
      error: err => {
        console.log(err);
        return errorState(FORGOT_USERNAME, err);
      }
    },
    apiParams: {
      endpoint: `${FORGOT_ENDPOINT}?email=${encodeURIComponent(email)}`
    }
  });
}

export function importExperiencesFromLinkedin(profileUrl) {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(USER_IMPORT_LINKEDIN),
      success: user => successState(USER_IMPORT_LINKEDIN, normalize(user, User), { ID: user.ID }),
      error: err => errorState(USER_IMPORT_LINKEDIN, err)
    },
    apiParams: {
      endpoint: `${USER_ENDPOINT}/import/linkedin`,
      queryParams: {
        profileUrl
      }
    }
  });
}

export function completeOnboarding() {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(USER_COMPLETE_ONBOARDING),
      success: user => successState(USER_UPDATE_SUCCESS, normalize(user, User), { ID: user.ID }),
      error: err => errorState(USER_COMPLETE_ONBOARDING, err)
    },
    apiParams: {
      method: 'put',
      endpoint: `${USER_ENDPOINT}/onboarding`
    }
  });
}

export function completeRegistration() {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(USER_COMPLETE_REGISTRATION),
      success: user => successState(USER_COMPLETE_REGISTRATION, normalize(user, User), { ID: user.ID }),
      error: err => errorState(USER_COMPLETE_REGISTRATION, err)
    },
    apiParams: {
      method: 'put',
      endpoint: `${USER_ENDPOINT}/registration`
    }
  });
}

/**
  * Async action that sends user creation data to the server
  * @param {Object} [data] - New User data to send
  * @param {String} [hash] - hash if sent
  */
export function registerUser(data, hash = null) {
  // cleanup before sending
  data.Password = data.Password.trim();

  const hashCode = hash ? `?${queryString.stringify({ hashCode: hash })}` : '';
  const endpoint = REGISTER_ENDPOINT + hashCode;

  return {
    types: {
      loading: REGISTER_USER_START,
      success: ({ Token, User: userData, deleteHash }) => {
        setSession({ Token, ID: userData.ID });
        return successState(REGISTER_USER_SUCCESS, {
          ID: userData.ID,
          User: normalize(userData, User)
        }, { deleteHash });
      },
      error: REGISTER_USER_ERROR
    },
    apiParams: {
      method: 'post',
      data,
      endpoint
    }
  };
}

/**
  * Async action that sends user creation data to the server
  * @param {Object} [data] - New User data to send
  * @param {String} [hash] - hash if sent
  */
export function registerUserVip(data) {
  return {
    types: {
      loading: USER_REGISTER_VIP._START,
      success: ({ deleteHash, Token, User: userData }) => {
        const { ID } = userData;
        setSession({ ID, Token });
        return successState(USER_REGISTER_VIP._SUCCESS, normalize(userData, User), { ID });
      },
      error: USER_REGISTER_VIP._ERROR
    },
    apiParams: {
      data,
      endpoint: `${REGISTER_VIP_ENDPOINT}`,
      method: 'post'
    }
  };
}

/**
  * Fetches a photo URL by userId.
  * If width and height are provided, it will fetch a photo of the requested dimensions
  * @param  {string|number|undefined} width - in pixels
  * @param  {string|number|undefined} height - in pixels
  */
export function getUserPhoto(width, height) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    const query = width && height ? `?width=${width}&height=${height}` : '';

    return dispatch({
      types: {
        loading: USER_PHOTO_FETCH_START,
        success: USER_PHOTO_FETCH_SUCCESS,
        error: USER_PHOTO_FETCH_ERROR
      },
      apiParams: {
        endpoint: `${USER_ENDPOINT}/${ID}/photo${query}`
      }
    });
  };
}

/**
   * Update the current User and return an updated User
   * @param {object} values - data to be updated
   * @TODO: add to the postman
   */
export function updateUser(values, givenId = null) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(USER_UPDATE_START, { ID }),
        success: user => successState(USER_UPDATE_SUCCESS, normalize(user, User), { ID }),
        error: err => errorState(USER_UPDATE_ERROR, err)
      },
      apiParams: {
        method: 'patch',
        endpoint: `${USER_ENDPOINT}/${ID || givenId}`,
        data: values
      }
    });
  };
}

/**
   * Update the current User and return an updated User
   * @param {object} values - data to be updated
   * @TODO: add to the postman
   */
export function updateUserPrefs(prefs) {
  return (dispatch, getState) => {
    const currentPrefs = getUserPrefs(getState());
    // if we don't need to update, don't
    if (Object.values(diff(currentPrefs, prefs)).length === 0) {
      return Promise.resolve();
    }
    const ID = getUserId(getState());
    const user = getUserData(getState(), { userId: ID });
    const { Meta = {} } = user;
    const preferenceState = {
      Meta: {
        Preferences: {
          ...getUserPrefs(getState()),
          ...prefs
        }
      }
    };

    dispatch({
      type: USER_UPDATE_PREFS_SUCCESS,
      payload: normalize({ ID, ...preferenceState }, User)
    });

    return dispatch({
      types: {
        success: () => successState(USER_UPDATE_PREFS_SUCCESS, normalize({ ID, ...preferenceState }, User)),
        loading: () => loadingState(USER_UPDATE_PREFS_START),
        error: err => errorState(USER_UPDATE_PREFS_ERROR, err)
      },
      apiParams: {
        method: 'patch',
        endpoint: `${USER_ENDPOINT}/${ID}`,
        data: preferenceState
      }
    });
  };
}

/**
   * adds user data from a social network
   * @param {String} socialType name of the social network
   * @param {Object} options any extra info needed
   */
export function addUserSocial(socialType, options = {}) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(USER_ADD_SOCIAL, { ID }),
        success: userProfile => successState(USER_ADD_SOCIAL, normalize(userProfile, User), { ID }),
        error: err => errorState(USER_ADD_SOCIAL, err)
      },
      apiParams: {
        method: 'post',
        endpoint: `${USER_ENDPOINT}/${ID}/social`,
        data: {
          id: ID,
          socialType,
          ...options
        }
      }
    });
  };
}

/**
   * deletes a users social information
   * @param  {String} socialType name of the social network
   */
export function disconnectUserSocial(socialType) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(USER_DISCONNECT_SOCIAL, { ID }),
        success: user => successState(USER_DISCONNECT_SOCIAL, normalize(user, User), { userProfileID: user.Profile.ID, socialType }),
        error: err => errorState(USER_DISCONNECT_SOCIAL, err)
      },
      apiParams: {
        method: 'delete',
        endpoint: `${USER_ENDPOINT}/${ID}/social`,
        data: {
          id: ID,
          socialType
        }
      }
    });
  };
}

/**
  * increments users profile checklist
  * @param {String} userId userId of user to update
  */
export function incrementProfileProgress(userId) {
  return {
    type: USER_INCREMENT_PROFILE_PROGRESS,
    userId,
    payload: {
      entities: {}
    }
  };
}

/**
   * increments users profile checklist
   * @param {String} userId userId of user to update
   */
export function decrementProfileProgress(userId) {
  return {
    type: USER_DECREMENT_PROFILE_PROGRESS,
    userId,
    payload: {
      entities: {}
    }
  };
}

/**
  * creates a work application for a user
  * @param  {String} userId of user to create application for
  */
export function createWorkApplication(userId) {
  return dispatch => dispatch({
    types: {
      loading: () => loadingState(CREATE_WORK_APPLICATION, { userId }),
      success: workApplication => successState(CREATE_WORK_APPLICATION, normalize({ userId, WorkApplications: [workApplication] }, User)),
      error: err => errorState(CREATE_WORK_APPLICATION, err)
    },
    apiParams: {
      method: 'post',
      endpoint: `${USER_ENDPOINT}/${userId}/work-application`
    }
  });
}

/**
  * updates a work application for a user
  * @param {Object} data to update the work application
  */
export function updateWorkApplication(data) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(UPDATE_WORK_APPLICATION, { ID }),
        success: workApplication => {
          const { Status = '' } = workApplication;
          const { entities: { WorkApplication: stateWorkApplication } = {} } = getState();
          const applications = Object.values(stateWorkApplication);
          // increment if they have submitted their first application
          // ie the status of the incoming application is pending (it gets set to pending on submit from the WorkEligibility page) AND there is only one work application in the state (the previous version of the incoming application) AND that the experience is the draft version of the incoming application
          if (Status === USER_WORK_APPLICATION_STATUS.PENDING
            && applications.length === 1
            && applications[0].Status === USER_WORK_APPLICATION_STATUS.DRAFT) {
            dispatch(incrementProfileProgress(ID));
          }
          return successState(UPDATE_WORK_APPLICATION, normalize({ ID, WorkApplications: [workApplication] }, User));
        },
        error: err => errorState(UPDATE_WORK_APPLICATION, err)
      },
      apiParams: {
        method: 'patch',
        endpoint: withId(`${USER_ENDPOINT}/:id/work-application`, ID),
        data
      }
    });
  };
}

/**
   * adds a games to a user
   * @param {object} data data to create games and add them to the user
   */
export function addGamesToUser(data) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(ADD_USER_GAMES, { ID }),
        success: games => {
          const { entities: { Game: stateGames } = {} } = getState();
          // increment if the user is going from having no favorite games to having favorite games
          // ie there is no Game in the state OR if the game object is empty
          if (!stateGames || isEmpty(stateGames)) {
            dispatch(incrementProfileProgress(ID));
          }
          return successState(ADD_USER_GAMES, normalize({ ID, Games: games }, User));
        },
        error: err => errorState(ADD_USER_GAMES, err)
      },
      apiParams: {
        method: 'post',
        endpoint: withId(`${USER_ENDPOINT}/:id/games`, ID),
        data
      }
    });
  };
}

/**
   * removes a game from a user
   * @param {String} gameId id of the game to be removed
   */
export function removeGameFromUser(gameId) {
  return (dispatch, getState) => {
    const ID = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(REMOVE_USER_GAMES, { ID }),
        success: game => {
          const { entities: { Game: stateGames } = {} } = getState();
          const games = !isEmpty(stateGames) && Object.keys(stateGames);
          // decrement if the incoming game is the last of the user's favorite games
          // ie the number of games in the store is 1 AND it is the incoming game
          if (games && games.length === 1 && games[0] === gameId) {
            dispatch(decrementProfileProgress(ID));
          }
          return successState(REMOVE_USER_GAMES, { entities: {} }, { game });
        },
        error: err => errorState(REMOVE_USER_GAMES, err)
      },
      apiParams: {
        method: 'delete',
        endpoint: withIds(`${USER_ENDPOINT}/:id/games/:gameId`, [
          {
            id: ID,
            placeholder: ':id'
          }, {
            id: gameId,
            placeholder: ':gameId'
          }
        ])
      }
    });
  };
}

/**
   * creates a referral between a user and an email
   * @param {Object} data contains email of the person being referred
   */
export function createReferralInvitation(data) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(CREATE_USER_REFERRAL_INVITATION, { userId }),
        success: referral => successState(CREATE_USER_REFERRAL_INVITATION, normalize(referral, Invitation), { userId }),
        error: err => errorState(CREATE_USER_REFERRAL_INVITATION, err)
      },
      apiParams: {
        method: 'post',
        endpoint: `${USER_ENDPOINT}/${userId}/referral`,
        data
      }
    });
  };
}

/**
  * gets all the referral invitations sent by the current user
  */
export function getSentReferralInvitations() {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(FETCH_SENT_REFERRAL_INVITATIONS, { userId }),
        success: referrals => successState(FETCH_SENT_REFERRAL_INVITATIONS, normalize(referrals, InvitationList), { userId }),
        error: err => errorState(FETCH_SENT_REFERRAL_INVITATIONS, err)
      },
      apiParams: {
        endpoint: `${USER_ENDPOINT}/${userId}/referral-invitations`,
        method: 'get'
      }
    });
  };
}

/**
   * removes the referral with referralId
   */
export function removeReferralInvitation(referralId) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(REMOVE_USER_REFERRAL_INVITATION, { userId }),
        success: referral => successState(REMOVE_USER_REFERRAL_INVITATION, { entities: {} }, { referral }),
        error: err => errorState(REMOVE_USER_REFERRAL_INVITATION, err)
      },
      apiParams: {
        method: 'delete',
        endpoint: withIds(`${USER_ENDPOINT}/:id/referral/:referralId`, [
          {
            id: userId,
            placeholder: ':id'
          }, {
            id: referralId,
            placeholder: ':referralId'
          }
        ])
      }
    });
  };
}

export function resendReferralInvitation(referralId) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(RESEND_USER_REFERRAL_INVITATION, { userId }),
        success: referral => successState(RESEND_USER_REFERRAL_INVITATION, normalize(referral, Invitation), { userId, referralId }),
        error: err => errorState(RESEND_USER_REFERRAL_INVITATION, err)
      },
      apiParams: {
        method: 'post',
        endpoint: withIds(`${USER_ENDPOINT}/:id/referral/:referralId`, [
          {
            id: userId,
            placeholder: ':id'
          }, {
            id: referralId,
            placeholder: ':referralId'
          }
        ])
      }
    });
  };
}

/**
 * gets all the referrals sent by the current user
 */
export function getSentReferrals() {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(FETCH_SENT_REFERRALS, { userId }),
        success: referrals => successState(FETCH_SENT_REFERRALS, normalize(referrals, ReferralList), { userId }),
        error: err => errorState(FETCH_SENT_REFERRALS, err)
      },
      apiParams: {
        method: 'get',
        endpoint: `${USER_ENDPOINT}/${userId}/referrals`
      }
    });
  };
}

/**
 * gets all internal records associated with user
 */
export function getUserInternalRecords(talentId) {
  return (dispatch, getState) => dispatch({
    types: {
      loading: () => loadingState(FETCH_INTERNAL_RECORDS, { talentId }),
      success: internalRecords => successState(FETCH_INTERNAL_RECORDS, normalize(internalRecords, InternalRecordList), { talentId }),
      error: err => errorState(FETCH_INTERNAL_RECORDS, err)
    },
    apiParams: {
      method: 'get',
      endpoint: `${USER_ENDPOINT}/${talentId}/talent-internal-records`
    }
  });
}

/**
 * Async action that triggers profile pdf download
 */
export function downloadProfilePDF(userId) {
  return (dispatch, getState) => {
    const user = getUserData(getState(), { userId });
    const { FirstName, LastName } = user;

    return dispatch({
      types: {
        loading: () => loadingState(USER_DOWNLOAD_PDF),
        success: data => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${FirstName}-${LastName}-rupie-resume.pdf`);
          document.body.appendChild(link);
          link.click();

          return successState(USER_DOWNLOAD_PDF);
        },
        error: err => errorState(USER_DOWNLOAD_PDF)
      },
      apiParams: {
        responseType: 'blob',
        method: 'get',
        endpoint: `${USER_ENDPOINT}/${userId}/pdf`
      }
    });
  };
}

/**
 * Async action that creates a new conversation
 * @param {String} userId The userId for the user to add the conversation to
 * @return {Function}    The Thunk function to resolve
*/
export function createConversation(userId) {
  const {
    CONVERSATION_CREATE_START,
    CONVERSATION_CREATE_SUCCESS,
    CONVERSATION_CREATE_ERROR
  } = conversationConstants;
  const { USER } = ENTITY_TYPE_ENUM;

  return {
    types: {
      loading: () => loadingState(CONVERSATION_CREATE_START, { entityId: userId }),
      success: conversation => successState(
        CONVERSATION_CREATE_SUCCESS,
        normalize(
          {
            ID: userId,
            Conversations: [conversation],
            LastWorkerConversation: [conversation]
          },
          User
        ),
        { userId, conversationId: conversation.ID }
      ),
      error: err => errorState(CONVERSATION_CREATE_ERROR, err, null, { entityId: userId })
    },
    apiParams: {
      method: 'post',
      data: {
        Type: USER.toUpperCase()
      },
      endpoint: `${USER_ENDPOINT}/${userId}/conversations`
    }
  };
}
