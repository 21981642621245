import { schema } from 'normalizr';

const Integration = new schema.Entity(
  'Integration',
  {},
  {
    idAttribute: 'ID'
  }
);

const UserIntegration = new schema.Entity(
  'UserIntegration',
  {},
  {
    idAttribute: 'ID'
  }
);

const GreenhouseJob = new schema.Entity(
  'GreenhouseJob',
  {},
  {
    idAttribute: 'id'
  }
);

const IntegrationList = [Integration];
const GreenhouseJobList = [GreenhouseJob];
export {
  GreenhouseJob, GreenhouseJobList, Integration, IntegrationList, UserIntegration
};
