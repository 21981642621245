import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tooltip } from 'antd';
import { has } from 'lodash';

import EntitySearch from 'components/Form/Custom/EntitySearch';

import './SearchableUserEntity.less';

function showRemoveConfirmModal(action, text) {
  Modal.confirm({
    title: text,
    icon: 'none',
    okText: 'Yes',
    cancelText: 'No',
    onOk() {
      action();
    }
  });
}

export default function withSearch(WrappedComponent) {
  return class SearchableUserEntity extends Component {
    static propTypes = {
      allowEmpty: PropTypes.bool,
      emptyMessage: PropTypes.string,
      entity: PropTypes.object,
      handleChange: PropTypes.func,
      showRemoveConfirm: PropTypes.bool,
      isSearching: PropTypes.bool,
      removeConfirmText: PropTypes.string,
      tooltipMessage: PropTypes.string
    }

    static defaultProps = {
      allowEmpty: false,
      emptyMessage: '--',
      entity: null,
      handleChange: () => {},
      isSearching: false,
      showRemoveConfirm: false,
      removeConfirmText: 'Are you sure you want to remove this?',
      tooltipMessage: 'Search for a user'
    }

    constructor(props) {
      const { allowEmpty, isSearching, entity } = props;
      super(props);

      this.state = {
        isSearching: isSearching || (!allowEmpty && !entity),
        entity: entity || null
      };

      this.handleChange = this.handleChange.bind(this);
      this.toggleViewState = this.toggleViewState.bind(this);
    }

    toggleViewState() {
      const { isSearching } = this.state;
      this.setState({ isSearching: !isSearching });
    }

    handleChange(result) {
      const { allowEmpty } = this.props;

      this.setState({ entity: result && result.value });
      this.props.handleChange(result);

      // If we allow a null entity value, we don't need to switch views
      // when it is nulled out
      if (allowEmpty && !result) {
        return;
      }

      this.toggleViewState();
    }

    render() {
      const {
        allowEmpty, showRemoveConfirm, removeConfirmText, emptyMessage, tooltipMessage
      } = this.props;
      const { isSearching, entity } = this.state;

      if (isSearching || (!allowEmpty && !entity)) {
        return (
          <div className="d-inline-flex w-100">
            <EntitySearch {...this.props} handleChange={result => this.handleChange(result)} />
            <button
              type="button"
              className="button-plain"
              onClick={this.toggleViewState}
              disabled={(!allowEmpty && !entity)}
            >
              {
                entity
                && (
                  <FontAwesomeIcon
                    className="rupie-mx-3"
                    icon={faTimes}
                    size="sm"
                    onClick={this.toggleViewState}
                  />
                )
              }
            </button>
          </div>
        );
      }

      const entityData = has(entity, 'entity') ? entity.entity : entity;

      return (
        <span>
          <Tooltip title={tooltipMessage}>
            {
              !entityData
                ? (
                  <Button onClick={this.toggleViewState}>
                    {emptyMessage}
                  </Button>
                    )
                : (
                  <button type="button" className="button-plain" onClick={this.toggleViewState}>
                    <WrappedComponent entity={entityData} />
                  </button>
                  )
            }
          </Tooltip>
          {entity
            && (
              <button
                type="button"
                className="button-plain"
                onClick={() => {
                  if (showRemoveConfirm) {
                    showRemoveConfirmModal(() => {
                      this.toggleViewState();
                      this.handleChange(null);
                    }, removeConfirmText);
                  } else {
                    this.toggleViewState();
                    this.handleChange(null);
                  }
                }}
              >
                <FontAwesomeIcon
                  className="rupie-mx-3"
                  icon={faTimes}
                  size="sm"
                />
              </button>
            )
          }
        </span>
      );
    }
  };
}
