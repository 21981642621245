import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { ORGANIZATION, TEAM, USER } = ENTITY_TYPES;

/**
 * Gets entity type based on Entity passed in.
 *
 * @param {Object} entity the object of the entity
 * @returns {String} returns either null, the type, or the enum
 */
export const getEntityType = entity => {
  if (!entity) {
    return null;
  }

  if (entity.type) {
    return entity.type;
  }

  if (entity.Username) {
    return USER;
  }

  // TODO: create another identity type for Team
  if (Object.keys(entity).includes('OrganizationID')) {
    return TEAM;
  }

  return ORGANIZATION;
};
