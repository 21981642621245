import {
  EVENTS_DISCOVER_FETCH,
  EVENT_FETCH,
  EVENT_SUBMISSIONS,
  EVENT_UPDATES_DISCOVER_FETCH,
  EVENT_UPDATES,
  EVENT_UPDATE
} from 'constants/eventConstants';

/**
 * Returns a new 'discoverEvents' state
 * @param  {Object}  [state={}] The events or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with event data
 */
export function discoverEvents(state = {}, action) {
  const { payload, type } = action;

  switch (type) {
    case EVENTS_DISCOVER_FETCH._START:
    case EVENTS_DISCOVER_FETCH._ERROR:
      return state;
    case EVENTS_DISCOVER_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          ...payload.result
        };
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'eventById' state
 * @param  {Object}  [state={}] The event or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with event data
 */
export function eventById(state = {}, action) {
  const {
    payload, eventId, type, error
  } = action;
  switch (type) {
    case EVENT_FETCH._START:
      return {
        [eventId]: {
          ...state,
          action
        }
      };
    case EVENT_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [eventId]: action
        };
      }
      return state;
    case EVENT_FETCH._ERROR:
      return {
        [eventId]: {
          ...state,
          ...action,
          error
        }
      };
    default:
      return state;
  }
}

/**
 * Returns a new 'submissionsByEventId' state
 * @param  {Object}  [state={}] The event or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with event data
 */
export function pagedSubmissionIds(state = {}, action) {
  const {
    payload, eventId, type, page = false
  } = action;
  switch (type) {
    case EVENT_SUBMISSIONS._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [eventId]: [...payload.result]
        };
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'discoverEventUpdates' state
 * @param  {Object}  [state={}] The events or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with event data
 */
export function discoverEventUpdates(state = {}, action) {
  const { payload, type } = action;

  switch (type) {
    case EVENT_UPDATES_DISCOVER_FETCH._START:
    case EVENT_UPDATES_DISCOVER_FETCH._ERROR:
      return state;
    case EVENT_UPDATES_DISCOVER_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          ...payload.result
        };
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'eventById' state
 * @param  {Object}  [state={}] The event or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with event data
 */
export function eventUpdateById(state = {}, action) {
  const {
    payload, updateId, type, error
  } = action;
  switch (type) {
    case EVENT_UPDATE._START:
      return {
        [updateId]: {
          ...state,
          action
        }
      };
    case EVENT_UPDATE._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [updateId]: action
        };
      }
      return state;
    case EVENT_UPDATE._ERROR:
      return {
        [updateId]: {
          ...state,
          ...action,
          error
        }
      };
    default:
      return state;
  }
}

export function pagedUpdateIds(state = {}, action) {
  const {
    payload, eventId, type, page = false
  } = action;
  switch (type) {
    case EVENT_UPDATES._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [eventId]: [...payload.result]
        };
      }
      return state;
    default:
      return state;
  }
}

export {
  eventById as byID
};
