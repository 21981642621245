import { merge, mergeWith } from 'lodash';
import {
  PROJECT_DELETE_MEDIA,
  PROJECT_DELETE,
  PROJECT_ADD_MEDIA,
  PROJECT_UPDATE_OWNER
} from 'constants/projectConstants';
import { SOCKET_PROJECT_DELETED } from 'shared/constants/socketConstants';
import { mergeWithArray } from 'utils/dataUtils';

export default function projectEntitiesReducers(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    switch (type) {
      case PROJECT_UPDATE_OWNER._SUCCESS: {
        const { projectID } = action;
        const { Organization, User, Team } = payload.entities;
        let updatedStateProject = {
          ...state.Project[projectID]
        };

        // TODO clean this up
        updatedStateProject = {
          ...updatedStateProject,
          User: User ? User[payload.result] : null,
          UserID: User ? payload.result : null,
          Organization: Organization ? Organization[payload.result] : null,
          OrganizationID: Organization ? payload.result : null,
          Team: Team ? Team[payload.result] : null,
          TeamID: Team ? payload.result : null,
          MemberOrganizations: updatedStateProject.MemberOrganizations
            .concat(payload.result)
            .filter(id => ((User || Team) ? id !== payload.result : id)),
          MemberTeams: updatedStateProject.MemberTeams
            .concat(payload.result)
            .filter(id => ((User || Organization) ? id !== payload.result : id))
        };

        return {
          ...state,
          ...payload.entities,
          Project: {
            ...state.Project,
            [projectID]: updatedStateProject
          }
        };
      }
      case PROJECT_DELETE_MEDIA._SUCCESS: {
        const { mediaID, projectID } = action;
        const updatedProjectEntity = {
          ...state.Project[projectID],
          Media: state.Project[projectID].Media.filter(id => id !== mediaID)
        };

        return {
          ...state,
          Project: {
            ...state.Project,
            [projectID]: updatedProjectEntity
          }
        };
      }
      case PROJECT_ADD_MEDIA._SUCCESS: {
        const { projectID } = action;
        const updatedProject = mergeWith({}, state.Project[projectID], mergeWithArray);

        updatedProject.Media = updatedProject.Media.concat(payload.result);

        return merge({}, state, {
          Project: {
            [action.projectID]: updatedProject
          }
        });
      }
      case SOCKET_PROJECT_DELETED:
      case PROJECT_DELETE._SUCCESS: {
        const newState = { ...state };
        delete newState.Project[action.projectId];
        return newState;
      }
      default:
        return state;
    }
  }
  return state;
}
