import { generateAsyncActions } from 'utils/actionUtils';

export const VERIFY_HASH_START = 'VERIFY_HASH_START';
export const VERIFY_HASH_SUCCESS = 'VERIFY_HASH_SUCCESS';
export const VERIFY_HASH_ERROR = 'VERIFY_HASH_ERROR';

export const RESEND_HASH_START = 'RESEND_HASH_START';
export const RESEND_HASH_SUCCESS = 'RESEND_HASH_SUCCESS';
export const RESEND_HASH_ERROR = 'RESEND_HASH_ERROR';

export const RESEND_ID = generateAsyncActions('RESEND_ID');
