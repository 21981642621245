import { ACCESS_CONTROL } from 'shared/utils/authorization';
import {
  getUserBeaconRoles,
  getUserEventRoles,
  getUserGigRoles,
  getUserOrganizationRoles,
  getUserProjectRoles,
  getUserTeamRoles
} from 'selectors/roleSelectors';
import { getUserId } from 'selectors/userSelectors';

const { ACTIONS } = ACCESS_CONTROL;

// to prevent minifier from obfuscating function name lookups
const ROLE_SELECTORS = {
  getUserEventRoles,
  getUserGigRoles,
  getUserOrganizationRoles,
  getUserProjectRoles,
  getUserTeamRoles,
  getUserBeaconRoles
};

/**
 * Returns member roles from a state of selectors
 * @param  {Object} state              The app state
 * @param  {String} [actor='User']     the actor acting
 * @param  {Object} options            extra options to pass to a selector
 * @property {String} actorID          specify the specific actor ID
 * @param  {Array}  [actedEntities=[]] the acted entity name strings
 * @return {[type]}                    [description]
 */
function getMemberRoles(state, actor = 'User', options = {}, actedEntities = []) {
  const entitiesArray = Array.isArray(actedEntities) ? actedEntities : [actedEntities];
  return entitiesArray.reduce((roles, entity) => {
    const roleMethod = `get${actor}${entity}Roles`;
    // the corresponding role method and return the role data
    if (Object.keys(ROLE_SELECTORS).includes(roleMethod)) {
      return roles.concat(ROLE_SELECTORS[roleMethod](state, options));
    }
    return roles;
  }, []);
}

/**
 * Any request that has the acl keyword will pass through this and handle
 * RBAC authorization based on action
 *
 * example:
 *
 * return (dispatch, getState) => dispatch({
 *  types: {
 *   loading: ...,
 *   error: ...,
 *   success: ...
 * },
 * acl: {
 *   actions: [ACTIONS.DELETE],
 *   roleSelector: getProjectUserRole
 * }
 * });
 *
 */
export default function aclMiddleware({ getState }) {
  return next => action => {
    // Continue to the next middleware if action not contain `types` property
    if (!action.types || !action.acl) {
      return next(action);
    }

    const {
      types,
      acl
    } = action;

    const {
      error: errorType
    } = types;

    const {
      userID = getUserId(getState()),
      actions = [],
      actor = 'User',
      acted = [],
      options
    } = acl;

    if (!acted.length) {
      throw Error(`Missing acted entity list for ACL`);
    }

    // default error
    const error = new Error(`User ${userID} is not allowed to perform action ${actions.map(action => action.toString())}`);

    const memberRoles = getMemberRoles(getState(), actor, options, acted);

    if (ACCESS_CONTROL.can(actions, memberRoles)) {
      return next(action);
    } if (types && typeof types.error === 'function') {
      return next(errorType(error));
    } if (types && types.error) {
      return next({
        type: errorType,
        error
      });
    }
    throw Error(error);
  };
}

export { ACCESS_CONTROL, ACTIONS };

/**
 * curry helper function for state level ACL
 * use this to mapStateToProps
 * @param  {String}  actor   the actor who wants to perform actions
 * @param  {Object}  state   the app state
 * @param  {Object}  options optional options
 * @return {Boolean}         Can the user perform actions
 */
export function isAuthorized(actor, state, aclOptions = {}) {
  return (actions, actingEntities, options = aclOptions) => ACCESS_CONTROL.can(actions, getMemberRoles(state, actor, options, actingEntities));
}
