import React from 'react';
import PropTypes from 'prop-types';

import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';
import Avatar from 'components/Avatar/AvatarDisplay';

const OrganizationDisplay = props => {
  const {
    avatarClassName,
    avatarOnly,
    entity,
    emptyMessage,
    tooltipMessage,
    avatarProps = {}
  } = props;

  if (!entity && emptyMessage) {
    return emptyMessage;
  }

  if (!entity && !emptyMessage) {
    throw Error('If an entity is empty, a emptyMessage must be passed');
  }

  const {
    createdAt,
    Name,
    organizationAvatar
  } = entity;

  return (
    <React.Fragment>
      <Avatar
        avatarProps={{
          alt: Name,
          className: avatarClassName,
          src: (organizationAvatar || {}),
          ...avatarProps
        }}
        tooltipMessage={tooltipMessage}
        placeholderSeed={createdAt}
        type={ENTITY_TYPES.ORGANIZATION}
      /> {!avatarOnly && Name }
    </React.Fragment>
  );
};

OrganizationDisplay.propTypes = {
  avatarClassName: PropTypes.string,
  avatarOnly: PropTypes.bool,
  entity: PropTypes.object,
  emptyMessage: PropTypes.string,
  tooltipMessage: PropTypes.string
};

OrganizationDisplay.defaultProps = {
  avatarClassName: '',
  avatarOnly: false,
  entity: null,
  emptyMessage: '--',
  tooltipMessage: null
};

export default OrganizationDisplay;
