import { SET_MENU_NOTIFICATIONS, SIDE_MENU_DATA } from 'constants/layoutConstants';

export function menuNotifications(state = Object.values(SIDE_MENU_DATA).reduce((prev, { link, notifications }) => {
  prev[link] = notifications;
  return prev;
}, {}), action) {
  const { value, menuItemKey, type } = action;

  switch (type) {
    case SET_MENU_NOTIFICATIONS:
      return {
        ...state,
        [menuItemKey]: value
      };
    default:
      return state;
  }
}
