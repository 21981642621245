import { schema } from 'normalizr';

// eslint-disable-next-line import/no-cycle
import { FileList, MediaList } from './index';

const Organization = new schema.Entity(
  'Organization',
  {
    Files: FileList,
    Media: MediaList
  },
  {
    idAttribute: 'ID'
  }
);

const OrganizationList = [Organization];

export { Organization, OrganizationList };
