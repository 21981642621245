import { schema } from 'normalizr';

/* eslint-disable import/no-cycle */
import {
  BillingProfile,
  File,
  FileList,
  GameList,
  HirePreferencesList,
  MediaList,
  Quest,
  QuestxUser,
  QuestxUserxTalent,
  Quiz,
  QuizList,
  ScheduleEventList,
  Signal,
  VendorProfileList,
  WorkPreferencesList
} from './index';

const UserSkill = new schema.Entity(
  'UserSkill',
  {},
  {
    idAttribute: 'ID'
  }
);

const UserStub = new schema.Entity(
  'UserStub',
  {},
  {
    idAttribute: 'ID'
  }
);

const InternalRecord = new schema.Entity(
  'InternalRecord',
  {},
  {
    idAttribute: 'ID'
  }
);

const WorkApplication = new schema.Entity(
  'WorkApplication',
  {
    File
  },
  {
    idAttribute: 'ID'
  }
);

File.define({
  WorkApplication: [WorkApplication]
});

const UserSkillList = [UserSkill];
const InternalRecordList = [InternalRecord];

const Profile = new schema.Entity(
  'Profile',
  {},
  {
    idAttribute: 'ID'
  }
);

const Conversation = new schema.Entity(
  'Conversation',
  {},
  {
    idAttribute: 'ID'
  }
);

const SelfNotes = [Conversation];

const User = new schema.Entity(
  'User',
  {
    BillingProfile,
    Files: FileList,
    Games: GameList,
    HirePreferences: HirePreferencesList,
    InternalRecords: InternalRecordList,
    Media: MediaList,
    Profile,
    QuestxUser,
    QuestxUserxTalent,
    Quizzes: QuizList,
    ScheduleEvents: ScheduleEventList,
    SelfNotes,
    Skills: UserSkillList,
    VendorProfiles: VendorProfileList,
    WorkApplications: [WorkApplication],
    WorkPreferences: WorkPreferencesList
  },
  {
    idAttribute: 'ID'
  }
);

const UserList = [User];

Quest.define({
  IntroducedTo: UserList,
  Introductions: UserList,
  State: [QuestxUser]
});

Quiz.define({
  User: UserList
});

Signal.define({
  User
});

export {
  InternalRecord,
  InternalRecordList,
  Profile,
  User,
  UserList,
  UserSkill,
  UserSkillList,
  UserStub,
  WorkApplication
};
