import { generateAsyncActions } from 'utils/actionUtils';

export const TEAM_FETCH = generateAsyncActions('TEAM_FETCH');

export const TEAMS_FETCH = generateAsyncActions('TEAMS_FETCH');

export const TEAM_CREATE = generateAsyncActions('TEAM_CREATE');

export const TEAM_DELETE = generateAsyncActions('TEAM_DELETE');

export const TEAM_UPDATE = generateAsyncActions('TEAM_UPDATE');

export const TEAM_MEMBER_ADD = generateAsyncActions('TEAM_MEMBER_ADD');

export const TEAM_MEMBER_UPDATE = generateAsyncActions('TEAM_MEMBER_UPDATE');

export const TEAM_MEMBER_REMOVE = generateAsyncActions('TEAM_MEMBER_REMOVE');

export const TEAM_ADD_MEDIA = generateAsyncActions('TEAM_ADD_MEDIA');

export const TEAM_DELETE_MEDIA = generateAsyncActions('TEAM_DELETE_MEDIA');

export const TEAM_INVITE = generateAsyncActions('TEAM_INVITE');

export const TEAM_MEMBER_CHANGE_COMMISSION = generateAsyncActions('TEAM_MEMBER_CHANGE_COMISSION');
