// Entities
export const getBeaconEntities = state => state.entities.Beacon || {};
export const getBillingProfileEntities = state => state.entities.BillingProfile || {};
export const getCommentEntities = state => state.entities.Comment || {};
export const getConversationEntities = state => state.entities.Conversation || {};
export const getCreditEntities = state => state.entities.Credit || {};
export const getDefaultGenreEntities = state => state.entities.Genre || {};
export const getDefaultLabelEntities = state => state.entities.Label || {};
export const getDefaultPhaseEntities = state => state.entities.Phase || {};
export const getDefaultSkillEntities = state => state.entities.Skill || {};
export const getDefaultTagEntities = state => state.entities.Tag || {};
export const getDeliverableEntities = state => state.entities.Deliverable || {};
export const getEventEntities = state => state.entities.Event || {};
export const getEventSubmissionEntities = state => state.entities.Submission || {};
export const getEventUpdateEntities = state => state.entities.EventUpdate || {};
export const getFileEntities = state => state.entities.File || {};
export const getGameEntities = state => state.entities.Game || {};
export const getGigEntities = state => state.entities.Gig || {};
export const getGreenhouseJobEntities = state => state.entities.GreenhouseJob || {};
export const getHirePreferencesEntities = state => state.entities.HirePreferences || {};
export const getIntegrationEntities = state => state.entities.Integration || {};
export const getInternalRecordEntities = state => state.entities.InternalRecord || {};
export const getInvitationEntities = state => state.entities.Invitation || {};
export const getInvoiceEntities = state => state.entities.Invoice || {};
export const getJobApplicationEntities = state => state.entities.JobApplication || {};
export const getJobEntities = state => state.entities.Job || {};
export const getMediaEntities = state => state.entities.Media || {};
export const getMemberEntities = state => state.entities.Member || {};
export const getMilestoneEntities = state => state.entities.Milestone || {};
export const getNotificationEntities = state => state.entities.Notification || {};
export const getOrganizationEntities = state => state.entities.Organization || {};
export const getPrismEntities = state => state.entities.Prism || {};
export const getProducerEntities = state => state.entities.Producer || {};
export const getProfileEntities = state => state.entities.Profile || {};
export const getProjectEntities = state => state.entities.Project || {};
export const getProposalEntities = state => state.entities.Proposal || {};
export const getQuestEntities = state => state.entities.Quest || {};
export const getQuestIntroductionsEntities = state => state.entities.QuestxUserxTalent || {};
export const getQuestStatesEntities = state => state.entities.QuestxUser || {};
export const getQuizEntities = state => state.entities.Quiz || {};
export const getReferralEntities = state => state.entities.Referral || {};
export const getRolodexEntities = state => state.entities.Rolodex || {};
export const getScheduleEventEntities = state => state.entities.ScheduleEvent || {};
export const getSkillEntities = state => state.entities.Skill || {};
export const getSubscriptionEntities = state => state.entities.Subscription || {};
export const getSuggestedTalentEntities = state => state.entities.SuggestedTalent || {};
export const getSuggestedTaskEntities = state => state.entities.SuggestedTask || {};
export const getSignalEntities = state => state.entities.Signal || {};
export const getTalentFilterEntities = state => state.entities.TalentFilter || {};
export const getTaskEntities = state => state.entities.Task || {};
export const getTeamEntities = state => state.entities.Team || {};
export const getTeamSkillEntities = state => state.entities.TeamSkill || {};
export const getTransactionEntities = state => state.entities.Transaction || {};
export const getUserEntities = state => state.entities.User || {};
export const getUserStubEntities = state => state.entities.UserStub || {};
export const getUserIntegrationEntities = state => state.entities.UserIntegration || {};
export const getUserSkillEntities = state => state.entities.UserSkill || {};
export const getVendorProfileEntities = state => state.entities.VendorProfile || {};
export const getVouchEntities = state => state.entities.Vouch || {};
export const getWorkApplicationEntities = state => state.entities.WorkApplication || {};
export const getWorkExperienceEntities = state => state.entities.WorkExperience || {};
export const getWorkPreferencesEntities = state => state.entities.WorkPreferences || {};

// State
export const getBillingClientToken = state => state.Billing.clientToken;
export const getCurrentProject = state => state.currentProject;
export const getDiscoverEvents = state => state.Events.discoverEvents || {};
export const getEventByIDState = state => state.Events.byID || {};
export const getFormByIDState = state => state.formByID || {};
export const getGigMetaDataByGigIdState = state => state.Gigs.gigMetaDataById || {};
export const getOrganizationByIDState = state => state.Organizations.byID || {};
export const getOrganizationsByUserIDState = state => state.Organizations.byUserID || {};
export const getProjectsByEventIDState = state => state.Projects.byEventID || {};
export const getProjectsByUserIDState = state => state.Projects.byUserID || {};
export const getProposalMetaDataByGigIdState = state => state.Gigs.proposalMetaDataById || {};
export const getTeamByIDState = state => state.Teams.byID || {};
export const getTeamsByUserIDState = state => state.Teams.byUserID || {};
export const getUserProfilesByIDState = state => state.user.userProfileByID || {};
