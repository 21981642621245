import { schema } from 'normalizr';

const Subscription = new schema.Entity(
  'Subscription',
  {},
  {
    idAttribute: 'ID'
  }
);

export { Subscription };
