
export const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(START|SUCCESS|ERROR)/.exec(type);

  if (!matches) return state;

  // eslint-disable-next-line array-bracket-newline
  const [,
    requestName, requestState
  ] = matches;

  return {
    ...state,
    [requestName]: requestState === 'START',
  };
};
