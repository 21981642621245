import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import HttpStatus from 'http-status-codes';
import queryString from 'query-string';

import { validateReferral } from 'actions/referralActions';
import { getUserData } from 'selectors/userSelectors';

class RouteReferrals extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
    currentUser: PropTypes.object
  }

  static defaultProps = {
    location: null,
    currentUser: {}
  };

  state = {
    validated: false
  }

  componentDidMount() {
    const {
      location,
      currentUser
    } = this.props;
    const { validated } = this.state;

    const { Meta: { Registration = {}, Onboarding = {} } = {} } = currentUser || {};

    if (!validated && (Registration.completed || Onboarding.completed) && location.search) {
      this.validateURLReferral();
    }
  }

  componentDidUpdate() {
    const {
      location,
      currentUser
    } = this.props;
    const { validated } = this.state;

    const { Meta: { Registration = {}, Onboarding = {} } = {} } = currentUser || {};

    if (!validated && (Registration.completed || Onboarding.completed) && location.search) {
      this.validateURLReferral();
    }
  }

  async validateURLReferral() {
    const {
      validateReferral,
      history
    } = this.props;

    const { referrer, referrerType } = queryString.parse(window.location.search);

    this.setState({
      validated: true
    }, async () => {
      if (referrer) {
        const responseData = await validateReferral(referrer, referrerType);
        const { payload: { Type, message } = {} } = responseData || {};

        const search = queryString.parse(window.location.search);
        delete search.referrer;
        delete search.referrerType;

        // clear the queryParams after
        history.replace({
          search: queryString.stringify(search)
        }, this.state);
      }
    });
  }

  render() {
    return null;
  }
}

RouteReferrals.propTypes = {
  validateReferral: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({ currentUser: getUserData(state, props) });

export default withRouter(connect(mapStateToProps, {
  validateReferral
})(RouteReferrals));
