import React, { Component, Fragment } from 'react';
import { faFacebookSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Divider,
  Layout,
  Row,
  Typography
} from 'antd';

import { ReactComponent as FooterBackground } from 'shared/images/backgrounds/bg-footer.svg';
import './Footer.less';

const { Footer } = Layout;
const { Paragraph, Title } = Typography;
const ButtonGroup = Button.Group;

// react footer component that takes in display type, promo for footer, and  (optionally) a callback function to be called when the footer is redeemed
export default class FooterContainer extends Component {
  render() {
    return (
      <Fragment>
        <div className="footer__header">
          <FooterBackground />
        </div>
        <Footer className="footer">
          <Row
            type="flex"
            gutter={[
              48, 48
            ]}
            justify="space-around"
          >
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <span className="text-uppercase text-muted text-weight-600">About Rupie</span>
              <Title level={3}>Engaging top talent is hard. Let Rupie help.</Title>
              <Paragraph>
                Rupie is a technology-enabled platform that automates the sourcing process for you. The best way to get warm, qualified candidates delivered to your inbox each week.
              </Paragraph>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              {/* <div className="rupie-my-6">
                <Menu mode="horizontal" theme="dark" selectedKeys={[pathName]}>
                  <Menu.Item key="/studio">
                    <Link to="/studio">Hire Talent</Link>
                  </Menu.Item>
                  <Menu.Item key="/talent">
                    <Link to="/talent">Find Opportunity</Link>
                  </Menu.Item>
                  <Menu.Item key="/about">
                    <Link to="/about">Who We Are</Link>
                  </Menu.Item>
                  <Menu.Item key="/signup">
                    <Link to="/signup">Sign Up</Link>
                  </Menu.Item>
                  <Menu.Item key="/login">
                    <Link to="/login">Sign In</Link>
                  </Menu.Item>
                </Menu>
              </div> */}

              <div className="rupie-my-6">
                <ButtonGroup>
                  <Button type="link" aria-label="Rupie LinkedIn Page" href="https://www.linkedin.com/company/rupienetwork" target="_blank" rel="noopener">
                    <FontAwesomeIcon size="2x" icon={faLinkedin} />
                  </Button>
                  <Button type="link" aria-label="Rupie Facebook Page" href="https://facebook.com/rupienetwork" target="_blank" rel="noopener">
                    <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
                  </Button>
                  <Button type="link" aria-label="Rupie Twitter Page" href="https://www.twitter.com/rupienetwork" target="_blank" rel="noopener">
                    <FontAwesomeIcon size="2x" icon={faTwitterSquare} />
                  </Button>
                </ButtonGroup>
              </div>

              <div className="rupie-my-6">
                &copy; {new Date().getFullYear()} Rupie Inc.
                <Divider orientation="center" type="vertical" />
                <a href="https://help.rupie.io" title="Find answers to your questions are Rupie's Help Center">Need Help?</a>
                <Divider orientation="center" type="vertical" />
                <a href="https://rupie.io/tos" title="Find answers to your questions about our Terms of Service">Terms of Service</a>
                <Divider orientation="center" type="vertical" />
                <a href="https://rupie.io/privacy" title="Find answers to your questions about our Privacy Policy">Privacy Policy</a>
              </div>
            </Col>
          </Row>
        </Footer>
      </Fragment>
    );
  }
}
