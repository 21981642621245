import { generateAsyncActions } from 'utils/actionUtils';
import { DELIVERABLE_STATUS_ENUM } from 'shared/constants/enumConstants';
import {
  faCheck,
  faMinusCircle,
  faBan,
  faExclamation,
  faHourglassStart,
  faClock
} from '@fortawesome/pro-solid-svg-icons';

export const FETCH_DELIVERABLES = generateAsyncActions('FETCH_DELIVERABLES');
export const UPDATE_DELIVERABLE = generateAsyncActions('UPDATE_DELIVERABLE');
export const DELETE_DELIVERABLE = generateAsyncActions('DELETE_DELIVERABLE');

// Colors correspond with rupie-tag--* classNames in antd-overrides.less
export const DELIVERABLE_STATUSES = [
  {
    value: DELIVERABLE_STATUS_ENUM.PENDING,
    display: {
      name: 'In Progress',
      color: 'blue',
      icon: faClock
    }
  },
  {
    value: DELIVERABLE_STATUS_ENUM.REJECTED,
    display: {
      name: 'Rejected',
      color: 'red',
      icon: faBan
    }
  },
];

export const DELIVERABLE_STATUS_DISPLAY = DELIVERABLE_STATUSES.reduce((hash, state) => ({
  ...hash,
  [state.value]: state.display
}), {});
