import { merge } from 'lodash';
import {
  JOB_DELETE,
  JOB_UPDATE
} from 'constants/jobConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const {
  JOB
} = ENTITY_TYPES;

export default function jobEntitiesReducers(state = {}, action) {
  const { payload, type } = action;
  if (payload && payload.entities) {
    const {
      jobId
    } = action;
    const { entities } = payload;

    switch (type) {
      case JOB_DELETE._SUCCESS: {
        const newState = {
          ...state
        };
        delete newState[JOB][jobId];
        return newState;
      }
      case JOB_UPDATE._SUCCESS: {
        const updateJob = entities[JOB][jobId];

        const newState = {
          ...state,
          [JOB]: {
            [jobId]: {
              ...updateJob
            }
          }
        };
        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
