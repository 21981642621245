import { schema } from 'normalizr';

const Quiz = new schema.Entity(
  'Quiz',
  {},
  {
    idAttribute: 'ID'
  }
);

const QuizList = [Quiz];

export {
  Quiz, QuizList
};
