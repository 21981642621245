import React, { Component } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  faAlarmClock,
  faBell,
  faCheckCircle,
  faComments,
  faDoNotEnter,
  faExclamationCircle,
  faInfoCircle
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge, Card, Dropdown, Empty, Menu
} from 'antd';
import classNames from 'classnames';

import { NOTIFICATION_TYPE_ENUM as NOTIFICATION_TYPE } from 'shared/constants/enumConstants';
import { fetchNotificationsByUserId, markRead } from 'actions/notificationActions';
import { getNotifications } from 'selectors/notificationSelectors';
import { getUserData, getUserId } from 'selectors/userSelectors';
import IconText from 'components/IconText/IconText';

import './Notifications.less';

const iconMap = {
  [NOTIFICATION_TYPE.INFO]: faInfoCircle,
  [NOTIFICATION_TYPE.MENTION]: faComments,
  [NOTIFICATION_TYPE.PROBLEM]: faExclamationCircle,
  [NOTIFICATION_TYPE.PROMPT]: faAlarmClock,
  [NOTIFICATION_TYPE.SUCCESS]: faCheckCircle,
  [NOTIFICATION_TYPE.WARNING]: faDoNotEnter
};

class Notifications extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    currentUserId: PropTypes.string.isRequired,
    fetchNotificationsByUserId: PropTypes.func.isRequired,
    markRead: PropTypes.func.isRequired,
    Notifications: PropTypes.array.isRequired
  }

  static defaultProps = {
    currentUser: {}
  }

  componentDidMount() {
    const { fetchNotificationsByUserId, currentUserId } = this.props;
    if (currentUserId) {
      fetchNotificationsByUserId(currentUserId);
    }
  }

  handleClick(notification) {
    const { markRead } = this.props;
    if (!notification.Read) {
      markRead(notification.ID);
    }
  }

  render() {
    const { currentUser, Notifications } = this.props;

    if (!currentUser) {
      return null;
    }

    const formattedItem = item => (
      <React.Fragment>
        <div className="notifications__item-text">
          <HTMLEllipsis
            basedOn="words"
            maxLine={2}
            unsafeHTML={item.Description}
          />
        </div>
        <em className="notifications__item-time">
          <Moment ago fromNow>
            {item.createdAt}
          </Moment> ago
        </em>
      </React.Fragment>
    );

    const unReadCount = Notifications.filter(notification => !notification.Read).length;

    const notificationTitle = (
      <Badge className="notifications__title-badge" count={unReadCount} overflowCount={10}>Notifications</Badge>
    );
    const list = (
      <Card title={notificationTitle} style={{ width: 300, padding: 0 }} bordered={false}>
        <Menu className="notifications__menu">
          {
            Notifications.length
              ? Notifications.map(notification => {
                const { ID, Path, Type } = notification;
                return (
                  <Menu.Item key={ID}>
                    <NavLink
                      className={classNames('ant-dropdown-menu-item', {
                        'notifications__menu-item--unread': !notification.Read
                      })}
                      onClick={() => this.handleClick(notification)}
                      to={Path}
                    >
                      <IconText
                        icon={iconMap[Type]}
                        text={formattedItem(notification)}
                      />
                    </NavLink>
                  </Menu.Item>
                );
              })
              : (
                <Empty
                  style={{ padding: '2rem' }}
                  description="No notifications"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )
          }
        </Menu>
      </Card>
    );

    return (
      <Dropdown
        className="notifications"
        overlay={list}
        trigger={['click']}
      >
        <a
          className="ant-dropdown-link"
          onKeyPress={this.handleKeyPress}
          onClick={e => e.preventDefault()}
          role="button"
          tabIndex="0"
        >
          <Badge count={unReadCount} overflowCount={10}>
            <FontAwesomeIcon
              icon={faBell}
              size="2x"
            />
          </Badge>
        </a>
      </Dropdown>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentUserId = getUserId(state, props);
  return {
    currentUserId,
    currentUser: getUserData(state, props),
    Notifications: getNotifications(state, props)
  };
};

const mapDispatchToProps = ({
  fetchNotificationsByUserId,
  markRead
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
