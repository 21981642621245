import * as extensionConstants from 'constants/extensionConstants';

/**
 * Returns a new 'extensionMetrics' state
 * @param  {Object}  [state={}] The event or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with event data
 */
export function extensionMetrics(state = {}, action) {
  const { GET_EXTENSION_METRICS } = extensionConstants;
  const {
    payload, userId, type, error
  } = action;
  switch (type) {
    case GET_EXTENSION_METRICS._SUCCESS:
      if (payload) {
        return {
          ...state,
          [userId]: payload
        };
      }
      return state;
    default:
      return state;
  }
}
