import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { ORG_MEDIA_TYPE_ENUM } from 'shared/constants/enumConstants';
import {
  getFileEntities,
  getMediaEntities,
  getOrganizationByIDState,
  getOrganizationEntities,
  getOrganizationsByUserIDState
} from 'selectors/stateSelectors';
import { getUserId } from 'selectors/userSelectors';

const { AVATAR, BANNER } = ORG_MEDIA_TYPE_ENUM;

export function getOrgFilesAndMedia(organization, fileEntities, mediaEntities) {
  const organizationMediaFiles = Object.values(fileEntities).filter(file => {
    if (file.OrganizationxFile && file.OrganizationxFile.OrganizationID === organization.ID && file.Media) {
      return file;
    }
  });

  const organizationFiles = Object.values(fileEntities).filter(file => {
    if (file.OrganizationxFile && file.OrganizationxFile.OrganizationID === organization.ID && !file.Media) {
      return file;
    }
  });

  const organizationMediaIDs = organization.Media || [];
  const organizationMedia = compact(organizationMediaIDs.reduce((entities, item) => entities.concat(mediaEntities[item]), []));

  const organizationAvatar = organizationMedia.find(item => {
    const { OrganizationxMedia } = item;

    if (!OrganizationxMedia) {
      return false;
    }

    return OrganizationxMedia.Type === AVATAR;
  }) || organizationMediaFiles.find(file => {
    const { MediaID } = file;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === AVATAR && media.ID === MediaID);
  });

  const organizationBanner = organizationMedia.find(item => {
    const { OrganizationxMedia } = item;

    if (!OrganizationxMedia) {
      return false;
    }

    return OrganizationxMedia.Type === BANNER;
  }) || organizationMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === BANNER && media.ID === MediaID);
  });

  return {
    organizationAvatar,
    organizationBanner,
    organizationFiles
  };
}

export const getCurrentUserOrgsWithMedia = createSelector(
  (state, props) => props.UserID || getUserId(state),
  getOrganizationsByUserIDState,
  getOrganizationEntities,
  getMediaEntities,
  getFileEntities,
  (userId, orgsByUserID, orgEntities, mediaEntities, fileEntities) => {
    const userOrgsState = orgsByUserID[userId] || {};
    const userOrgIDs = userOrgsState.items || [];
    const Organizations = userOrgIDs.reduce((orgs, id) => orgs.concat(orgEntities[id]), []);
    const { error, isLoading } = userOrgsState;

    return {
      error,
      isLoading,
      Organizations: Organizations.map(org => ({
        ...org,
        ...getOrgFilesAndMedia(org, fileEntities, mediaEntities)
      })),
      userId
    };
  }
);

// if ProjectID is passed to the connected component, will extract and use this project in the selector
const getOrgByIDFilter = (state, props) => {
  const { OrganizationID, match } = props;
  const orgEntities = getOrganizationEntities(state);
  if (props && OrganizationID) {
    return getOrganizationByIDState(state)[OrganizationID] || Object.values(orgEntities || []).find(org => org.ID === OrganizationID);
  } if (match && match.params.id) {
    return getOrganizationByIDState(state)[match.params.id];
  }
  return {};
};

export const getBaseOrganization = createSelector(
  getOrgByIDFilter,
  getOrganizationEntities,
  (orgByIDState = {}, orgEntities) => {
    const Organization = orgEntities[(orgByIDState.organizationId || orgByIDState.ID)] || {};
    return {
      error: orgByIDState.error,
      isLoading: orgByIDState.isLoading,
      Organization
    };
  }
);

export const getOrganizationWithMedia = createSelector(
  getBaseOrganization,
  getFileEntities,
  getMediaEntities,
  (baseOrganization, fileEntities, mediaEntities) => ({
    ...baseOrganization,
    ...getOrgFilesAndMedia(baseOrganization.Organization, fileEntities, mediaEntities)
  })
);
