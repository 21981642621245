import merge from 'lodash/merge';
import * as skillConstants from 'constants/skillConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { SKILL } = ENTITY_TYPES;

export default function entities(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    const { entitySkillId, entityType } = action;

    switch (type) {
      case skillConstants.REMOVE_SKILL._SUCCESS: {
        const newState = {
          ...state
        };

        delete newState[entityType + SKILL][entitySkillId];

        return newState;
      }

      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }

  return state;
}
