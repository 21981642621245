import { schema } from 'normalizr';

import {
  Gig, Job, Quest, User, Vouch
} from 'schemas/index';

const Invitation = new schema.Entity(
  'Invitation',
  {
    EmailedReceiver: User,
    Receiver: User
  },
  {
    idAttribute: 'ID'
  }
);

const InvitationList = [Invitation];

Vouch.define({
  Invitation
});

Quest.define({
  Invitations: InvitationList
});

Gig.define({
  Invitations: InvitationList
});

Job.define({
  Invitations: InvitationList
});

Quest.define({
  Invitations: InvitationList
});

export {
  Invitation,
  InvitationList
};
