import { createSelector } from 'reselect';
import queryString from 'query-string';
import { compact } from 'lodash';

import {
  GIG_MEDIA_TYPE_ENUM as GIG_MEDIA_TYPE,
  PROPOSAL_STATUS_ENUM as PROPOSAL_STATUS,
  PROPOSAL_TYPE_ENUM as PROPOSAL_TYPE
} from 'shared/constants/enumConstants';
import {
  getConversationEntities,
  getFileEntities,
  getGigEntities,
  getGigMetaDataByGigIdState,
  getInvitationEntities,
  getInvoiceEntities,
  getJobEntities,
  getMediaEntities,
  getProducerEntities,
  getProposalEntities,
  getProposalMetaDataByGigIdState,
  getSuggestedTaskEntities,
  getTeamEntities,
  getUserEntities
} from 'selectors/stateSelectors';
import { getTeamFilesAndMedia } from 'selectors/teamSelectors';
import { getUserData, getUserId, getUserFilesAndMedia } from 'selectors/userSelectors';
import { isAuthorized, ACTIONS } from 'middleware/acl';

const {
  ASSET, AVATAR, BANNER, GALLERY
} = GIG_MEDIA_TYPE;

export function getGigFilesAndMedia(gig, fileEntities, mediaEntities) {
  const gigMediaFiles = Object.values(fileEntities).filter(file => {
    const { GigxFile, Media } = file;
    if (GigxFile && GigxFile.GigID === gig.ID && Media) {
      return file;
    }
  });

  const gigFiles = Object.values(fileEntities).filter(file => {
    const { GigxFile, Media } = file;
    if (GigxFile && GigxFile.GigID === gig.ID && !Media) {
      return file;
    }
  }) || [];

  const gigMediaIDs = gig.Media || [];
  const gigMedia = compact(gigMediaIDs.reduce((entities, item) => entities.concat(mediaEntities[item]), []));

  const gigAvatar = gigMedia.find(item => {
    const { GigxMedia } = item;

    if (!GigxMedia) {
      return false;
    }

    return GigxMedia.Type === AVATAR;
  }) || gigMediaFiles.find(file => {
    const { MediaID } = file;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === AVATAR && media.ID === MediaID);
  }) || {};

  const gigBanner = gigMedia.find(item => {
    const { GigxMedia } = item;

    if (!GigxMedia) {
      return false;
    }

    return GigxMedia.Type === BANNER;
  }) || gigMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === BANNER && media.ID === MediaID);
  }) || {};

  // assets refer to all files without media, and files with media that are typed as ASSET
  const gigAssets = [
    ...(gigMedia || []).filter(media => {
      const { GigxMedia } = media;

      if (GigxMedia && GigxMedia.Type === ASSET) {
        return media;
      }
    }),

    ...(gigMediaFiles || []).filter(file => {
      const { MediaID } = file;
      return MediaID && Object.values(mediaEntities).find(media => media.Type === ASSET && media.ID === MediaID);
    }),

    ...gigFiles
  ];

  const gigGallery = [
    ...(gigMedia || []).filter(media => {
      const { GigxMedia } = media;

      if (GigxMedia && GigxMedia.Type === GALLERY) {
        return media;
      }
    }),

    ...(gigMediaFiles || []).filter(file => {
      const { MediaID } = file;
      return MediaID && Object.values(mediaEntities).find(media => media.Type === GALLERY && media.ID === MediaID);
    })
  ];

  return {
    gigAssets,
    gigAvatar,
    gigBanner,
    gigFiles,
    gigGallery
  };
}

// if GigID is passed to the connected component, extract and use this gig in the selector, otherwise, try with match
const getGigByIDFilter = (state, props) => {
  const { gigId, match } = props;

  if (gigId) {
    return getGigEntities(state)[gigId];
  }
  if (match && match.params.id) {
    return getGigEntities(state)[match.params.id];
  }
  return {};
};

export const getBaseGig = createSelector(
  getGigByIDFilter,
  gig => {
    const Gig = gig || {};
    return Gig;
  }
);

export const getGigRoles = createSelector([
  state => isAuthorized('User', state),
  getBaseGig,
  (state, props) => getUserData(state, props),
  getProducerEntities
], (isUserAuthorized, gig, currentUser, producerEntities) => {
  const isAdmin = currentUser.ID === gig.UserID;
  const isManager = isAdmin || isUserAuthorized([ACTIONS.MANAGE_GIG], 'Gig', { gigId: gig.ID });
  const isContributor = isManager || isUserAuthorized([ACTIONS.READ], 'Gig', { gigId: gig.ID });

  const isProducer = Object.values(producerEntities).filter(producer => producer.UserID === currentUser.ID && producer.GigxProducer.GigID === gig.ID).length > 0;
  const isProvider = isAdmin || isManager || isProducer;
  const isWorker = !!(gig.ID && !isProvider && (((gig.LastProposal || gig.LastConversation) && isContributor) || !isContributor));

  return {
    isAdmin,
    isManager,
    isContributor,
    isProducer,
    isProvider,
    isWorker
  };
});

export const getInitialProposal = createSelector(
  getBaseGig,
  getProposalEntities,
  (baseGig, proposalEntities) => Object.values(proposalEntities).filter(proposal => baseGig.ID === proposal.GigID && proposal.Type === PROPOSAL_TYPE.INITIAL) || []
);

// Returns gigs with proposals and conversations in a single merged collection
export const getGigWithEntities = createSelector(
  getBaseGig,
  getConversationEntities,
  getFileEntities,
  getMediaEntities,
  getInitialProposal,
  getProposalEntities,
  getUserEntities,
  getTeamEntities,
  getJobEntities,
  getInvitationEntities,
  (gig, conversationsEntities, fileEntities, mediaEntities, [initialProposal = {}], proposalEntities, userEntities, teamEntities, jobEntities, invitationEntities) => {
    // filter out initial proposal (as we already have it)
    const proposalArray = Object.values(proposalEntities)
      .filter(proposal => proposal.ID !== initialProposal.ID)
      .filter(proposal => gig.ID === proposal.GigID)
      .sort((a, b) => a.Revision - b.Revision);

    // create an array of users and/or teams that have conversations and/or counter proposals
    const mergedList = compact([
      ...Object.values(conversationsEntities), ...proposalArray
    ])
      .reduce((accum, item) => {
        // check if this is a gig conversation
        const {
          GigxConversation = {}, Gig, ConversationGigUser = [], ConversationGigTeam = []
        } = item;
        const gigConversation = Array.isArray(Gig) && Gig.length && Gig[0].GigxConversation && Gig[0];
        const convoGigID = (GigxConversation.GigID) || (gigConversation && gigConversation.ID);

        const teamId = (gigConversation || convoGigID) ? GigxConversation.TeamID || ConversationGigTeam[0] : item.TeamID;
        const userId = (gigConversation || convoGigID) ? GigxConversation.UserID || ConversationGigUser[0] : item.WorkerID;

        const team = Object.values(teamEntities).filter(team => team.ID === teamId)[0];
        const user = Object.values(userEntities).filter(user => user.ID === userId)[0];

        if (team) {
          Object.assign(team, getTeamFilesAndMedia(team, fileEntities, mediaEntities));
        }

        if (user) {
          Object.assign(user, getUserFilesAndMedia(user, fileEntities, mediaEntities));
        }

        const selectedObject = accum[team ? 'teams' : 'users'][team ? teamId : userId];

        if (team || user) {
          if (convoGigID && convoGigID === gig.ID) {
            accum[team ? 'teams' : 'users'][team ? team.ID : user.ID] = {
              ...selectedObject || {},
              conversation: item,
              user,
              team
            };
          } else if (item.GigID === gig.ID) {
            accum[team ? 'teams' : 'users'][team ? team.ID : user.ID] = {
              ...selectedObject || {},
              proposal: item,
              user,
              team
            };
          }
        }

        return accum;
      }, {
        teams: {},
        users: {}
      });

    return {
      Gig: { ...gig, ...getGigFilesAndMedia(gig, fileEntities, mediaEntities) },
      initialProposal,
      Invitations: Object.values(invitationEntities).filter(invite => invite.GigxInvitation && invite.GigxInvitation.GigID === gig.ID),
      Jobs: Object.values(jobEntities).filter(job => job.GigxJob && job.GigxJob.GigID === gig.ID),
      workerList: Object.values({ ...mergedList.teams, ...mergedList.users })
        .map(item => ({
          updatedAt: item.proposal ? item.proposal.updatedAt : item.conversation.updatedAt,
          ...item
        }))
        .sort((a, b) => (new Date(a.updatedAt) - new Date(b.updatedAt)))
    };
  }
);

// Proposal Selectors

// if proposalId is passed to the connected component, extract and use this proposal in the selector, otherwise, try with match
const getProposalByIDFilter = (state, props) => {
  const { proposalId, match } = props;
  if (props && proposalId) {
    return getProposalEntities(state)[proposalId];
  }
  if (match && match.params.proposalId) {
    return getProposalEntities(state)[match.params.proposalId];
  }
  return {};
};

// get head proposal ID if on initial proposal that has a chain
const getHeadProposalByIDFilter = (state, props) => {
  const { location } = props;
  const { headProposalId = '' } = queryString.parse(location.search);
  if (headProposalId) {
    return getProposalEntities(state)[headProposalId];
  }
};

// get the proposal metadata
export const getProposalMetadata = createSelector(
  getGigByIDFilter,
  getProposalMetaDataByGigIdState,
  (Gig = {}, proposalMetaData) => proposalMetaData[Gig.ID] || {}
);

// get the proposal metadata
export const getGigMetadata = createSelector(
  getGigByIDFilter,
  getGigMetaDataByGigIdState,
  (Gig = {}, gigMetaData) => gigMetaData[Gig.ID] || {}
);

export const getBaseProposal = createSelector(
  getProposalByIDFilter,
  getTeamEntities,
  getUserEntities,
  (Proposal = {}, teamEntities, userEntities) => ({
    Proposal,
    Team: Proposal.TeamID ? Object.values(teamEntities).filter(team => team.ID === Proposal.TeamID)[0] : {},
    Worker: Proposal.WorkerID ? Object.values(userEntities).filter(user => user.ID === Proposal.WorkerID)[0] : {},
    Provider: Proposal.ProviderID ? Object.values(userEntities).filter(user => user.ID === Proposal.ProviderID)[0] : {}
  })
);

export const getProposalWithSuggestedTasks = createSelector(
  getBaseProposal,
  getSuggestedTaskEntities,
  (baseProposal, suggestedTaskEntities) => {
    const suggestedTasks = baseProposal && Object.values(suggestedTaskEntities).filter(suggestedTask => suggestedTask.SuggestedTaskxProposal && suggestedTask.SuggestedTaskxProposal.ProposalID === baseProposal.Proposal.ID);
    return {
      ...baseProposal,
      SuggestedTasks: suggestedTasks
    };
  }
);

export const getProposalWithWorkersAndInvoices = createSelector(
  getBaseProposal,
  getInvoiceEntities,
  (proposalData, invoiceEntities) => {
    const { Proposal } = proposalData;
    const Invoices = Object.values(invoiceEntities).filter(invoice => {
      if (invoice.InvoicexProposal && invoice.InvoicexProposal.ProposalID === Proposal.ID) {
        return invoice;
      }
    });

    return {
      ...proposalData,
      Invoices
    };
  }
);

export const getProposalsByChain = createSelector(
  getBaseGig,
  getBaseProposal,
  getHeadProposalByIDFilter,
  getInitialProposal,
  getProposalEntities,
  (baseGig, baseProposal, headProposal, initialProposal, proposalEntities) => {
    // check for worker
    const { Proposal: baseProposalObject } = baseProposal;
    const [initialProposalObject] = initialProposal || [];
    const { TeamID, WorkerID, ID } = headProposal || baseProposalObject || {};

    if (!initialProposal.length) {
      return {
        proposals: []
      };
    }

    // if there are no proposals, just return the initial one
    if (!headProposal && baseProposalObject.ID === initialProposalObject.ID) {
      return {
        proposals: [initialProposalObject]
      };
    }

    // filter any proposals that match the worker
    // add the initial proposal back & sort by newest to oldest
    let chain = Object.values(proposalEntities)
      .filter(proposal => (
        proposal.ID !== initialProposalObject.ID
        && proposal.GigID === baseGig.ID)
        && (proposal.TeamID === TeamID || proposal.WorkerID === WorkerID))
      .sort((a, b) => b.Revision - a.Revision);

    if (!chain.filter(proposal => proposal.Status === PROPOSAL_STATUS.ACCEPTED_INITIAL).length) {
      chain = chain.concat(initialProposal);
    }

    return { proposals: chain };
  }
);

export const getProviderGigs = createSelector(
  getUserId,
  (state, { projectId }) => projectId && projectId,
  getGigEntities,
  getProposalEntities,
  getConversationEntities,
  (userId, projectId, gigEntities, proposalEntities, conversationEntities) => Object.values(gigEntities)
    .filter(({ Proposals = [] }) => Proposals.reduce((accum, next) => accum || proposalEntities[next].ProviderID === userId, false))
    .filter(gig => !projectId || gig.ProjectID === projectId)
    .map(gig => ({
      ...gig,
      Proposals: gig.Proposals.map(proposal => proposalEntities[proposal]),
      Conversations: gig.Conversations.map(conversation => conversationEntities[conversation])
    }))
    .sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)))
);

export const getWorkerGigs = createSelector(
  [
    getUserId,
    getGigEntities,
    getProposalEntities,
    getConversationEntities,
    getTeamEntities
  ],
  (userId, gigEntities, proposalEntities, conversationEntities, teamEntities) => Object.values(gigEntities)
    .filter(gig => (gig.Proposals || [])
      .reduce((accum, next) => {
        const proposal = proposalEntities[next];
        const members = proposal.TeamID && teamEntities[proposal.TeamID] && teamEntities[proposal.TeamID].Members;
        return accum || proposal.WorkerID === userId || (members && members.reduce((accum, next) => accum && next.ID === userId, true));
      }, false))
    .map(gig => ({
      ...gig,
      Proposals: gig.Proposals.map(proposal => ({
        ...proposalEntities[proposal],
        Team: proposal.TeamID && teamEntities[proposal.TeamID]
      })),
      Conversations: gig.Conversations.map(conversation => conversationEntities[conversation])
    }))
    .sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)))
);

export const getGigs = createSelector(
  getProviderGigs,
  getWorkerGigs,
  (providerGigs, workerGigs) => [
    ...providerGigs, ...workerGigs
  ].sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)))
);
