import { schema } from 'normalizr';

// eslint-disable-next-line import/no-cycle
import {
  InvitationList, TalentFilter
} from './index';

const Quest = new schema.Entity(
  'Quest',
  {},
  {
    idAttribute: 'ID'
  }
);

const QuestList = [Quest];

// quest joins
const QuestxUser = new schema.Entity(
  'QuestxUser',
  { },
  {
    idAttribute: 'ID'
  }
);

const QuestxUserxTalent = new schema.Entity(
  'QuestxUserxTalent',
  { },
  {
    idAttribute: 'ID'
  }
);

export {
  Quest,
  QuestList,
  QuestxUser,
  QuestxUserxTalent
};
