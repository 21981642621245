import { schema } from 'normalizr';

// Entities
const Signal = new schema.Entity(
  'Signal',
  {},
  {
    idAttribute: 'ID'
  }
);

const SignalList = [Signal];

export { Signal, SignalList };
