import { GET_BEACON_INSIGHTS, GET_QUEST_INSIGHTS, GET_USER_INSIGHTS } from 'constants/insightConstants';

/**
 * Returns a new 'beaconInsights' state
 * @param  {Object}  [beaconInsight={}] The insights or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with insights
 */
export function beaconInsights(state = {}, action) {
  const { payload, type, beaconId } = action;

  switch (type) {
    case GET_BEACON_INSIGHTS._START:
    case GET_BEACON_INSIGHTS._ERROR: {
      return { ...state };
    }
    case GET_BEACON_INSIGHTS._SUCCESS:
      if (payload) {
        return {
          ...state,
          [beaconId]: payload
        };
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'questInsights' state
 * @param  {Object}  [questsInsight={}] The insights or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with insights
 */
export function questInsights(state = {}, action) {
  const { payload, type, userId } = action;

  switch (type) {
    case GET_QUEST_INSIGHTS._START:
    case GET_QUEST_INSIGHTS._ERROR: {
      return { ...state };
    }
    case GET_QUEST_INSIGHTS._SUCCESS:
      if (payload) {
        return {
          ...state,
          [userId]: payload
        };
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'userInsights' state
 * @param  {Object}  [userInsight={}] The insights or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with insights
 */
export function userInsights(state = {}, action) {
  const { payload, type, userId } = action;

  switch (type) {
    case GET_USER_INSIGHTS._START:
    case GET_USER_INSIGHTS._ERROR: {
      return { ...state };
    }
    case GET_USER_INSIGHTS._SUCCESS:
      if (payload) {
        return {
          ...state,
          [userId]: payload
        };
      }
      return state;
    default:
      return state;
  }
}
