import { merge } from 'lodash';

import { isStaleTalent } from 'shared/utils/beaconUtils';
import {
  DELETE_BEACON,
  REVIEW_BEACON_TALENT,
  UPDATE_BEACON
} from 'constants/beaconConstants';
import { BEACON_STATUS_ENUM, ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { BEACON } = ENTITY_TYPES;
const { SOURCING } = BEACON_STATUS_ENUM;

export default function beaconEntitiesReducers(state = {}, action) {
  const { payload, type } = action;
  if (payload && payload.entities) {
    const { beaconId } = action;
    const { entities } = payload;

    switch (type) {
      case DELETE_BEACON._SUCCESS: {
        const newState = {
          ...state
        };

        delete newState[BEACON][beaconId];
        return newState;
      }
      case REVIEW_BEACON_TALENT._SUCCESS: {
        const unreviewed = Object.values(entities.SuggestedTalent).filter(({
          createdAt,
          ReactionMovingForward,
          ReactionShowSimilar
        }) => isStaleTalent(createdAt) && ReactionMovingForward === null && ReactionShowSimilar === null).length;

        const newState = {
          ...state,
          ...!unreviewed ? {
            [BEACON]: {
              ...state[BEACON],
              [beaconId]: {
                ...state[BEACON][beaconId],
                Status: SOURCING
              }
            }
          } : {}
        };

        return newState;
      }
      case UPDATE_BEACON._SUCCESS: {
        const updateBeacon = entities[BEACON][beaconId];

        const newState = {
          ...state,
          [BEACON]: {
            [beaconId]: {
              ...updateBeacon
            }
          }
        };
        return newState;
      }
      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
