/**
 * Finds a correct type match from an object against a predicate value
 * This is a helper so that we don't need to pass explicit _START, _ERROR or _SUCCESS constants
 * @param  {Object | String} typeObject Either the object of action type or string
 * @param  {String} match  the predicate to search, START|SUCCESS|ERROR
 * @return {String} the found value
 */
function getActionTypeFromObject(typeObject, match) {
  if (typeof (typeObject) === 'object') {
    const foundType = Object.keys(typeObject)
      .filter(typeValue => new RegExp(`${match}$`).test(typeValue));

    if (!foundType.length) {
      throw Error(`No Type ${match} found for action constant object ${typeObject}`);
    }
    // return the found string
    return foundType[0];
  }
  // type is just a string
  return typeObject;
}

/**
 * Returns a loadingState for action creators
 * @param  {Boolean} [isLoading=true] Whether or not isLoading is true
 * @param  {String | Object}  type  The action creator type, can be the entire object of options
 * @param  {Object}  extraOptions  extra values to include, like entity Ids
 * @return {Object}                The action creator object
 */
export const loadingState = function(isLoading = true, type, extraOptions) {
  return {
    type: getActionTypeFromObject(type, '_START'),
    isLoading,
    ...extraOptions
  };
}.bind(null, true);

/**
 * Returns a errorState for action creators
 * @param  {Boolean} [isLoading=true] Whether or not isLoading is true
 * @param  {String | Object}  type  The action creator type, can be the entire object of options
 * @param  {Object}  extraOptions  extra values to include, like entity Ids
 * @return {Object}                The action creator object
 */
export const errorState = function(isLoading = false, type, error = null, errorStatus, extraOptions) {
  return {
    type: getActionTypeFromObject(type, '_ERROR'),
    isLoading,
    error,
    errorStatus,
    ...extraOptions
  };
}.bind(null, false);

/**
 * Returns a successState for action creators
 * @param  {Boolean} [isLoading=true] Whether or not isLoading is true
 * @param  {String | Object}  type  The action creator type, can be the entire object of options
 * @param  {Object}  extraOptions  extra values to include, like entity Ids
 * @return {Object}                The action creator object
 */
export const successState = function(isLoading = false, type, payload = {}, extraOptions) {
  return {
    type: getActionTypeFromObject(type, '_SUCCESS'),
    payload,
    isLoading,
    ...extraOptions
  };
}.bind(null, false);

/**
 * generates boilerplate async actions
 * @param  {String} prefix  the prefix, like USER_UPATE
 * @param  {Array}  [suffixes=[ '_START', '_ERROR', '_SUCCESS']] The suffixes to add
 * @return {Array}   the boilerplate actions
 */
export const generateAsyncActions = (prefix, suffixes = [
  '_START', '_ERROR', '_SUCCESS'
]) => suffixes.reduce((prev, suffix) => {
  const concatted = `${prefix}${suffix}`;
  prev[concatted] = concatted;
  // alias, so you can do cool stuff like UPDATE_SKILL._SUCCESS
  prev[suffix] = concatted;
  return prev;
}, {});
