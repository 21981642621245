import { schema } from 'normalizr';

const Vouch = new schema.Entity(
  'Vouch',
  {},
  {
    idAttribute: 'ID'
  }
);

const VouchList = [Vouch];

export {
  Vouch,
  VouchList,
};
