import { generateAsyncActions } from 'utils/actionUtils';

export const EVENT_CREATE = generateAsyncActions('EVENT_CREATE');

export const EVENT_FETCH = generateAsyncActions('EVENT_FETCH');

export const EVENTS_FETCH = generateAsyncActions('EVENTS_FETCH');

export const EVENTS_DISCOVER_FETCH = generateAsyncActions('EVENTS_DISCOVER_FETCH');

export const EVENTS_BY_USER = generateAsyncActions('EVENTS_BY_USER');

export const EVENT_DELETE = generateAsyncActions('EVENT_DELETE');

export const EVENT_ADD_TEAM = generateAsyncActions('EVENT_ADD_TEAM');

export const EVENT_ADD_USER = generateAsyncActions('EVENT_ADD_USER');

export const EVENT_ADD_MEDIA = generateAsyncActions('EVENT_ADD_MEDIA');

export const EVENT_CREATE_UPDATE = generateAsyncActions('EVENT_CREATE_UPDATE');

export const EVENT_DELETE_MEDIA = generateAsyncActions('EVENT_DELETE_MEDIA');

export const EVENT_UPDATE = generateAsyncActions('EVENT_UPDATE');

export const EVENT_UPDATES = generateAsyncActions('EVENT_UPDATES');

export const EVENT_UPDATES_DISCOVER_FETCH = generateAsyncActions('EVENT_UPDATES_DISCOVER_FETCH');

export const EVENT_UPDATE_DELETE = generateAsyncActions('EVENT_UPDATE_DELETE');

export const EVENT_ADD_ORGANIZER = generateAsyncActions('EVENT_ADD_ORGANIZER');

export const EVENT_REMOVE_ORGANIZER = generateAsyncActions('EVENT_REMOVE_ORGANIZER');

export const EVENT_PROJECTS_FETCH = generateAsyncActions('EVENT_PROJECTS_FETCH');

export const EVENT_SUBMISSIONS = generateAsyncActions('EVENT_SUBMISSIONS');

export const EVENT_TEAM_SUBMISSIONS = generateAsyncActions('EVENT_TEAM_SUBMISSIONS');

export const EVENT_USER_SUBMISSIONS = generateAsyncActions('EVENT_USER_SUBMISSIONS');

export const EVENT_SUBMISSION = generateAsyncActions('EVENT_SUBMISSION');

export const EVENT_TEAM_SUBMISSION = generateAsyncActions('EVENT_TEAM_SUBMISSION');

export const EVENT_TEAM_FETCH = generateAsyncActions('EVENT_TEAM_FETCH');

export const EVENT_USER_FETCH = generateAsyncActions('EVENT_USER_FETCH');

export const EVENT_UPDATE_SUBMISSION = generateAsyncActions('EVENT_UPDATE_SUBMISSION');

export const EVENT_VOTE_SUBMISSION = generateAsyncActions('EVENT_VOTE_SUBMISSION');

export const EVENT_VOTE_WINNER = generateAsyncActions('EVENT_VOTE_WINNER');
