import { schema } from 'normalizr';

const Transaction = new schema.Entity(
  'Transaction',
  {},
  {
    idAttribute: 'ID'
  }
);
const TransactionList = [Transaction];

export { Transaction, TransactionList };
