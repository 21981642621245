import { schema } from 'normalizr';

// eslint-disable-next-line import/no-cycle
import { SkillList } from './index';

const JobApplication = new schema.Entity(
  'JobApplication',
  {},
  {
    idAttribute: 'ID'
  }
);

const JobApplicationList = [JobApplication];

const Job = new schema.Entity(
  'Job',
  {
    Applications: JobApplicationList,
    Skills: SkillList
  },
  {
    idAttribute: 'ID'
  }
);

const JobList = [Job];

export {
  Job, JobApplication,
  JobList
};
