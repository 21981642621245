import { generateAsyncActions } from 'utils/actionUtils';

export const ORGANIZATION_FETCH = generateAsyncActions('ORGANIZATION_FETCH');

export const ORGANIZATIONS_FETCH = generateAsyncActions('ORGANIZATIONS_FETCH');

export const ORGANIZATION_CREATE = generateAsyncActions('ORGANIZATION_CREATE');

export const ORGANIZATION_DELETE = generateAsyncActions('ORGANIZATION_DELETE');

export const ORGANIZATION_UPDATE = generateAsyncActions('ORGANIZATION_UPDATE');

export const ORGANIZATION_MEMBER_ADD = generateAsyncActions('ORGANIZATION_MEMBER_ADD');

export const ORGANIZATION_MEMBER_UPDATE = generateAsyncActions('ORGANIZATION_MEMBER_UPDATE');

export const ORGANIZATION_MEMBER_REMOVE = generateAsyncActions('ORGANIZATION_MEMBER_REMOVE');

export const ORGANIZATION_TEAM_ADD = generateAsyncActions('ORGANIZATION_TEAM_ADD');

export const ORGANIZATION_TEAM_UPDATE = generateAsyncActions('ORGANIZATION_TEAM_UPDATE');

export const ORGANIZATION_TEAM_REMOVE = generateAsyncActions('ORGANIZATION_TEAM_REMOVE');

export const ORGANIZATION_ADD_MEDIA = generateAsyncActions('ORGANIZATION_ADD_MEDIA');

export const ORGANIZATION_DELETE_MEDIA = generateAsyncActions('ORGANIZATION_DELETE_MEDIA');

export const ORGANIZATION_INVITE = generateAsyncActions('ORGANIZATION_INVITE');
