import { difference, merge, omit } from 'lodash';

import {
  MILESTONE_ADD_MEDIA,
  MILESTONE_DELETE_MEDIA,
  MILESTONE_DELETE
} from 'constants/milestoneConstants';
import { filterMediaIdsToRemove } from 'reducers/utils/mediaUtils';

export default function milestoneEntitiesReducers(state = {}, action) {
  const { payload, type } = action;

  if (payload && payload.entities) {
    switch (type) {
      case MILESTONE_ADD_MEDIA._SUCCESS: {
        const { mediaId, mediaType, milestoneId } = action;
        const updatedMilestone = { ...state.Milestone[milestoneId] };
        const milestoneMedia = updatedMilestone.Media || [];
        const removedMediaIds = filterMediaIdsToRemove(milestoneMedia, state.Media, mediaType);

        updatedMilestone.Media = difference(milestoneMedia, removedMediaIds).concat(mediaId);
        return merge({}, state, payload.entities, {
          Milestone: {
            [milestoneId]: updatedMilestone
          }
        });
      }
      case MILESTONE_DELETE_MEDIA._SUCCESS: {
        const { mediaId, milestoneId } = action;
        const updatedMilestoneEntity = {
          ...state.Milestone[milestoneId],
          Media: state.Milestone[milestoneId].Media.filter(id => id !== mediaId)
        };
        const updatedMedia = omit(state.Media, mediaId);

        return {
          ...state,
          Milestone: {
            ...state.Milestone,
            [milestoneId]: updatedMilestoneEntity
          },
          Media: updatedMedia
        };
      }
      case MILESTONE_DELETE._SUCCESS: {
        const newState = { ...state };
        delete newState.Milestone[action.milestoneId];
        return newState;
      }
      default:
        return state;
    }
  }
  return state;
}
