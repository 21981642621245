import {
  INVITATION_TALENT_LIST
} from 'constants/invitationConstants';

export function invitationData(state = {}, action) {
  const { payload, type } = action;
  switch (type) {
    case INVITATION_TALENT_LIST:
      const { userId, count, invitations } = payload;
      return {
        ...state,
        [userId]: {
          count,
          invitations
        },
      };
    default:
      return state;
  }
}
