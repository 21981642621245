import { schema } from 'normalizr';

const HirePreferences = new schema.Entity(
  'HirePreferences',
  {},
  {
    idAttribute: 'ID'
  }
);

const HirePreferencesList = [HirePreferences];

export { HirePreferences, HirePreferencesList };
