import { generateAsyncActions } from 'utils/actionUtils';
import {
  GIG_STATUS_ENUM as GIG_STATUS,
  PROPOSAL_CREATOR_TYPE_ENUM as PROPOSAL_CREATOR_TYPE,
  PROPOSAL_LICENSE_TYPE_ENUM as PROPOSAL_LICENSE_TYPE,
  PROPOSAL_PAY_TYPE_ENUM as PROPOSAL_PAY_TYPE,
  PROPOSAL_PRICING_TYPE_ENUM as PROPOSAL_PRICING_TYPE,
  PROPOSAL_STATUS_ENUM as PROPOSAL_STATUS,
  PROPOSAL_TYPE_ENUM as PROPOSAL_TYPE
} from 'shared/constants/enumConstants';

export const FETCH_PROVIDER_GIGS = generateAsyncActions('FETCH_PROVIDER_GIGS');
export const FETCH_WORKER_GIGS = generateAsyncActions('FETCH_WORKER_GIGS');
export const GIG_CREATE = generateAsyncActions('GIGS_CREATE');
export const GIG_DELETE = generateAsyncActions('GIG_DELETE');
export const GIG_FETCH = generateAsyncActions('GIG_FETCH');
export const GIG_FETCH_INVITATIONS = generateAsyncActions('GIG_FETCH_INVITATIONS');
export const GIG_FETCH_META = generateAsyncActions('GIGS_FETCH_META');
export const GIG_FETCH_PROPOSAL = generateAsyncActions('GIG_FETCH_PROPOSAL');
export const GIG_FETCH_PROPOSAL_META = generateAsyncActions('GIG_FETCH_PROPOSAL_META');
export const GIG_FETCH_PROPOSAL_STATUS = generateAsyncActions('GIG_FETCH_PROPOSAL_STATUS');
export const GIG_FETCH_PROPOSALS_CONVERSATIONS = generateAsyncActions('GIG_FETCH_PROPOSALS_CONVERSATIONS');
export const GIG_FETCH_SUMMARY = generateAsyncActions('GIGS_FETCH_SUMMARY');
export const GIG_PROPOSAL_ADD_MILESTONES = generateAsyncActions('GIG_PROPOSAL_ADD_MILESTONES');
export const GIG_PROPOSAL_ADD_MILESTONE = generateAsyncActions('GIG_PROPOSAL_ADD_MILESTONE');
export const GIG_PROPOSAL_ADD_TASK = generateAsyncActions('GIG_PROPOSAL_ADD_TASK');
export const GIG_PROPOSAL_CREATE = generateAsyncActions('GIG_PROPOSAL_CREATE');
export const GIG_PROPOSAL_DELETE = generateAsyncActions('GIG_PROPOSAL_DELETE');
export const GIG_PROPOSAL_INVOICE_FETCH = generateAsyncActions('GIG_PROPOSAL_INVOICE_FETCH');
export const GIG_PROPOSAL_REMOVE_MILESTONE = generateAsyncActions('GIG_PROPOSAL_REMOVE_MILESTONE');
export const GIG_PROPOSAL_REMOVE_TASK = generateAsyncActions('GIG_PROPOSAL_REMOVE_TASK');
export const GIG_PROPOSAL_UPDATE = generateAsyncActions('GIG_PROPOSAL_UPDATE');
export const GIG_RATE_PROPOSAL = generateAsyncActions('GIG_RATE_PROPOSAL');
export const GIG_CREATE_JOB = generateAsyncActions('GIG_CREATE_JOB');
export const GIG_ADD_JOB = generateAsyncActions('GIG_ADD_JOB');
export const GIG_UPDATE_JOB = generateAsyncActions('GIG_UPDATE_JOB');
export const GIG_TASKS = generateAsyncActions('GIG_TASKS');
export const GIG_UPDATE = generateAsyncActions('GIG_UPDATE');
export const GIGS_BY_USER = generateAsyncActions('GIGS_BY_USER');
export const GIGS_DISCOVER = generateAsyncActions('GIGS_DISCOVER');
export const GIGS_FETCH = generateAsyncActions('GIGS_FETCH');

export const gigStatusColorMap = {
  [GIG_STATUS.DRAFT]: 'gray',
  [GIG_STATUS.OPEN]: 'purple',
  [GIG_STATUS.CLOSED]: 'green',
  [GIG_STATUS.AWAITING]: 'yellow',
  [GIG_STATUS.PROGRESS]: 'pink',
  [GIG_STATUS.REVIEW]: 'gray',
  [GIG_STATUS.COMPLETE]: 'green',
  [GIG_STATUS.FLAGGED]: 'red'
};

export const proposalEnumCopyMap = {
  [PROPOSAL_CREATOR_TYPE.PROVIDER]: 'Provider',
  [PROPOSAL_CREATOR_TYPE.WORKER]: 'Worker',
  [PROPOSAL_LICENSE_TYPE.ATTRIBUTION]: 'Attribution',
  [PROPOSAL_LICENSE_TYPE.FREE]: 'Free',
  [PROPOSAL_LICENSE_TYPE.NODERIVS]: 'Noderivs',
  [PROPOSAL_LICENSE_TYPE.NON_COMMERCIAL]: 'Non-Commercial',
  [PROPOSAL_LICENSE_TYPE.PROPRIETARY]: 'Proprietary',
  [PROPOSAL_PAY_TYPE.FREE]: 'Free',
  [PROPOSAL_PAY_TYPE.PAID]: 'Paid',
  [PROPOSAL_PAY_TYPE.REVSHARE]: 'Share',
  [PROPOSAL_PRICING_TYPE.DELIVERABLE]: 'Deliverable',
  [PROPOSAL_PRICING_TYPE.TIME]: 'Time',
  [PROPOSAL_TYPE.COUNTER]: 'Counter',
  [PROPOSAL_TYPE.INITIAL]: 'Initial',
  [PROPOSAL_TYPE.REVISION]: 'Revision'
};

export const ACCEPT = 'ACCEPT';
export const CANCEL = 'CANCEL';
export const CLOSE = 'CLOSE';
export const COMPLETE = 'COMPLETE';
export const CONFIRM = 'CONFIRM';
export const COUNTER = 'COUNTER';
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';
export const DONE = 'DONE';
export const PUBLISH = 'PUBLISH';
export const REJECT = 'REJECT';
export const REVISION = 'REVISION';
export const SAVE = 'SAVE';
export const WITHDRAW = 'WITHDRAW';
export const PAY = 'PAY';
export const HIRE = 'HIRE';
export const WORK_AGAIN = 'WORK_AGAIN';
export const REVIEW = 'REVIEW';

export const proposalStatusMap = {
  [PROPOSAL_STATUS.DRAFT]: {
    title: 'Draft',
    color: 'gray'
  },
  [PROPOSAL_STATUS.AWAITING_WORKER]: {
    title: 'Awaiting Worker',
    color: 'yellow'
  },
  [PROPOSAL_STATUS.AWAITING_PROVIDER]: {
    title: 'Awaiting Provider',
    color: 'yellow'
  },
  [PROPOSAL_STATUS.COUNTERED]: {
    title: 'Countered',
    color: 'gray'
  },
  [PROPOSAL_STATUS.ACCEPTED_INITIAL]: {
    title: 'Accepted',
    color: 'yellow'
  },
  [PROPOSAL_STATUS.ACCEPTED]: {
    title: 'Accepted',
    color: 'yellow'
  },
  [PROPOSAL_STATUS.REJECTED]: {
    title: 'Rejected',
    color: 'red'
  },
  [PROPOSAL_STATUS.CONFIRMED]: {
    title: 'Confirmed',
    color: 'green'
  },
  [PROPOSAL_STATUS.AWAITING_PAYMENT]: {
    title: 'Awaiting Payment',
    color: 'red'
  },
  [PROPOSAL_STATUS.IN_PROGRESS]: {
    title: 'In Progress',
    color: 'purple'
  },
  [PROPOSAL_STATUS.OVERDUE]: {
    title: 'Overdue',
    color: 'red'
  },
  [PROPOSAL_STATUS.DONE]: {
    title: 'Done',
    color: 'blue'
  },
  [PROPOSAL_STATUS.COMPLETE]: {
    title: 'Complete',
    color: 'green'
  },
  [PROPOSAL_STATUS.NOT_ACCEPTED]: {
    title: 'Not Accepted',
    color: 'gray'
  },
  [PROPOSAL_STATUS.CLOSED]: {
    title: 'Closed',
    color: 'gray'
  }
};

export const providerControlMap = {
  [PROPOSAL_STATUS.DRAFT]: [
    DELETE, CANCEL, CLOSE, SAVE, PUBLISH
  ],
  [PROPOSAL_STATUS.AWAITING_PROVIDER]: [
    REJECT, COUNTER, CONFIRM
  ],
  [PROPOSAL_STATUS.AWAITING_WORKER]: [WITHDRAW],
  [PROPOSAL_STATUS.ACCEPTED_INITIAL]: [
    REJECT, CONFIRM
  ],
  [PROPOSAL_STATUS.ACCEPTED]: [
    REJECT, CONFIRM
  ],
  [PROPOSAL_STATUS.AWAITING_PAYMENT]: [PAY],
  [PROPOSAL_STATUS.IN_PROGRESS]: [REVISION],
  [PROPOSAL_STATUS.DONE]: [
    REVISION, COMPLETE
  ],
  [PROPOSAL_STATUS.COMPLETE]: [
    HIRE, WORK_AGAIN, REVIEW
  ]
};

export const workerControlMap = {
  [PROPOSAL_STATUS.DRAFT]: [
    DELETE, SAVE, PUBLISH
  ],
  [PROPOSAL_STATUS.AWAITING_WORKER]: [
    REJECT, COUNTER, ACCEPT
  ],
  [PROPOSAL_STATUS.ACCEPTED_INITIAL]: [WITHDRAW],
  [PROPOSAL_STATUS.AWAITING_PROVIDER]: [WITHDRAW],
  [PROPOSAL_STATUS.IN_PROGRESS]: [
    REVISION, DONE
  ],
  [PROPOSAL_STATUS.COMPLETE]: [REVIEW]
};
