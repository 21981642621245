import _ from 'lodash';

export const loadingSelector = actions => state => _(actions)
  .some((actionConfig = '') => {
    const { defaultState = true, action = actionConfig } = actionConfig || {};
    const cleaned = action.replace(/_START|_SUCCESS|_ERROR/, '');
    const isLoading = _.get(state, `loadingReducer.${cleaned}`);
    // if the state is undefined, let us assume we're in a loading state
    return isLoading === undefined ? defaultState : isLoading;
  });

export const errorSelector = actions => state => (
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  _(actions)
    .map(action => {
      const cleaned = action.replace(/_START|_SUCCESS|_ERROR/, '');
      return _.get(state, `errorReducer.${cleaned}`);
    })
    .compact()
    .first() || null);

export const filterEntitiesById = (ids, entities) => {
  if (!ids || !Array.isArray(ids)) {
    return [];
  }

  return ids.reduce((accum, id) => {
    if (entities[id]) {
      return accum.concat(entities[id]);
    }

    return accum;
  }, []);
};
