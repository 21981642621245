import { schema } from 'normalizr';
import { DeliverableList } from 'schemas/index';

const SuggestedTask = new schema.Entity(
  'SuggestedTask',
  {
    Deliverables: DeliverableList
  },
  {
    idAttribute: 'ID'
  }
);

const SuggestedTaskList = [SuggestedTask];

export { SuggestedTask, SuggestedTaskList };
