import {
  VERIFY_REFERRAL
} from 'constants/referralConstants';
import { REFERRAL_ENDPOINT } from 'constants/global';
import { errorState, loadingState, successState } from 'utils/actionUtils';

export function validateReferral(referrer, referrerType) {
  return {
    types: {
      loading: () => loadingState(VERIFY_REFERRAL),
      success: () => successState(VERIFY_REFERRAL),
      error: err => errorState(VERIFY_REFERRAL,)
    },
    apiParams: {
      endpoint: `${REFERRAL_ENDPOINT}`,
      queryParams: {
        referrerType,
        referrer
      }
    }
  };
}
