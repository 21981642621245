import { difference, merge, omit } from 'lodash';

import {
  REMOVE_USER_GAMES,
  REMOVE_USER_REFERRAL_INVITATION,
  RESEND_USER_REFERRAL_INVITATION,
  USER_ADD_MEDIA_SUCCESS,
  USER_DECREMENT_PROFILE_PROGRESS,
  USER_DELETE_MEDIA_SUCCESS,
  USER_DISCONNECT_SOCIAL,
  USER_INCREMENT_PROFILE_PROGRESS
} from 'constants/userConstants';
import {
  ENTITY_TYPE_ENUM as ENTITY_TYPES,
  PROFILE_CHECKLIST_ENUM as PROFILE_CHECKLIST
} from 'shared/constants/enumConstants';
import { filterMediaIdsToRemove } from 'reducers/utils/mediaUtils';

const { GAME, INVITATION } = ENTITY_TYPES;

export default function userEntitiesReducers(state = {}, action) {
  const { payload, type } = action;
  if (payload && payload.entities) {
    switch (type) {
      case USER_ADD_MEDIA_SUCCESS: {
        const { mediaId, mediaType, userId } = action;
        const updatedUser = { ...state.User[userId] };
        const userMedia = updatedUser.Media || [];
        const removedMediaIds = filterMediaIdsToRemove(userMedia, state.Media, mediaType);

        updatedUser.Media = difference(userMedia, removedMediaIds).concat(mediaId);

        return merge({}, state, payload.entities, {
          User: {
            [userId]: updatedUser
          }
        });
      }
      case USER_DELETE_MEDIA_SUCCESS: {
        const { mediaId, userId } = action;
        const updatedUserEntity = {
          ...state.User[userId],
          Media: state.User[userId].Media.filter(id => id !== mediaId)
        };
        const updatedMedia = omit(state.Media, mediaId);

        return {
          ...state,
          User: {
            ...state.User,
            [userId]: updatedUserEntity
          },
          Media: updatedMedia
        };
      }
      case USER_DISCONNECT_SOCIAL._SUCCESS: {
        const { ID, socialType, userProfileID } = action;

        const newState = {
          ...state
        };

        newState.Profile[userProfileID][socialType] = {};
        return newState;
      }
      case REMOVE_USER_GAMES._SUCCESS: {
        const { game } = action;
        const newState = {
          ...state
        };

        delete newState[GAME][game.ID];
        return newState;
      }
      case USER_INCREMENT_PROFILE_PROGRESS: {
        const { userId } = action;
        const newState = {
          ...state
        };
        newState.User[userId].ProfileChecklist += Math.floor(100 / Object.values(PROFILE_CHECKLIST).length);
        return newState;
      }
      case USER_DECREMENT_PROFILE_PROGRESS: {
        const { userId } = action;
        const newState = {
          ...state
        };
        newState.User[userId].ProfileChecklist -= Math.floor(100 / Object.values(PROFILE_CHECKLIST).length);
        return newState;
      }
      case REMOVE_USER_REFERRAL_INVITATION._SUCCESS: {
        const { referral } = action;
        const newState = {
          ...state
        };

        delete newState[INVITATION][referral.ID];
        return newState;
      }
      case RESEND_USER_REFERRAL_INVITATION._SUCCESS: {
        const { referralId } = action;
        const newState = {
          ...state
        };

        delete newState[INVITATION][referralId];
        return newState;
      }
      default:
        return state;
    }
  }

  return state;
}
