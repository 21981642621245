import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

import SideContainer from './SideContainer/SideContainer';

import './Side.less';

const SideView = React.memo(props => {
  const {
    isMobileOrSmall, sideViewCollapsed, toggleSide
  } = props;

  return isMobileOrSmall ? (
    <Drawer
      className="sider-drawer"
      onClose={() => { toggleSide({ sideViewCollapsed: true }); }}
      placement="left"
      style={{
        padding: 0,
        width: '13rem',
        height: '100vh'
      }}
      visible={!sideViewCollapsed}
    >
      <SideContainer {...props} sideViewCollapsed={isMobileOrSmall ? false : sideViewCollapsed} />
    </Drawer>
  ) : (<SideContainer {...props} />);
});

SideView.propTypes = {
  isMobileOrSmall: PropTypes.bool.isRequired,
  sideViewCollapsed: PropTypes.bool.isRequired,
  toggleSide: PropTypes.func.isRequired
};

export default SideView;
