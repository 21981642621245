import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { withId } from 'utils/dataUtils';
import { PERSONALITY_AVATAR_MAP } from 'constants/quizConstants';
import routeConstants from 'constants/routeConstants';
import { WORK_MODE_HIRE, WORK_MODE_WORK } from 'constants/userConstants';
import { AVATAR_BEHAVIOR_ENUM, ENTITY_TYPE_ENUM, MEDIA_TYPE_ENUM } from 'shared/constants/enumConstants';
import { getHiringPreferences } from 'selectors/hirePreferenceSelectors';
import { getBaseUserProfile, getUserData, getUserId } from 'selectors/userSelectors';
import Avatar from 'components/Avatar/AvatarDisplay';

import hiddenAvatar from 'images/avatars/avatar-hidden.svg';

const { HIDE, SHOW, TOGGLE } = AVATAR_BEHAVIOR_ENUM;
const { USER_SETTINGS_HIRE_PREFERENCES } = routeConstants;

const getAvatar = (files = [], media = []) => {
  if (!files.length && !media.length) return null;

  return media.find(item => item.Type === MEDIA_TYPE_ENUM.AVATAR) || files.find(file => {
    if (file.Media) {
      return file.Media.Type === MEDIA_TYPE_ENUM.AVATAR;
    }
  });
};

function arbitraryElement(arr, seed) {
  const charCodes = seed.split('').reduce((a, b, i) => (i === 1 ? a.charCodeAt(0) : +a) + b.charCodeAt(0));
  return arr[charCodes % arr.length];
}

// TODO make this the actual personality
function getPersonalityAvatar(type, seed) {
  if (!type) {
    const seedValue = moment(seed).valueOf();

    return arbitraryElement(Object.values(PERSONALITY_AVATAR_MAP), seedValue.toString());
  }
}

const UserAvatarDisplay = props => {
  const {
    avatarClassName,
    avatarOnly,
    avatarOptions,
    avatarProps = {},
    currentUser,
    currentUserHiringPreferences,
    emptyMessage,
    entity,
    match,
    User,
    userAvatar
  } = props;

  let { tooltipMessage } = props;

  const [
    showAvatar, setShowAvatar
  ] = useState(false);

  if (isEmpty(entity) && emptyMessage) {
    return emptyMessage;
  }

  if (isEmpty(entity) && !emptyMessage) {
    return null;
  }

  const {
    createdAt = entity.createdAt,
    ID: entityId
  } = User;

  const {
    Files,
    Media,
    TruncatedName = User.TruncatedName
  } = entity;

  const { ID: currentUserId, Meta: { Preferences, Onboarding = {} } = {} } = currentUser;
  const { userType } = Onboarding;

  const workMode = Preferences.workMode || (userType === 'talent' ? WORK_MODE_WORK : WORK_MODE_HIRE);

  const defaultAvatarBehavior = workMode === WORK_MODE_HIRE ? TOGGLE : SHOW;
  const avatarBehavior = (currentUserHiringPreferences && workMode === WORK_MODE_HIRE) ? currentUserHiringPreferences.AvatarBehavior : defaultAvatarBehavior;
  const isOwner = currentUserId === entityId;

  const avatar = isEmpty(userAvatar) ? getAvatar(Files, Media) : userAvatar;
  const noAvatar = isEmpty(avatar);
  const srcObject = { ...!noAvatar ? avatar : { Url: getPersonalityAvatar(null, createdAt) } };

  const permanentlyHide = avatarBehavior === HIDE;
  const permanentlyShow = avatarBehavior === SHOW;
  const toggle = avatarBehavior === TOGGLE;

  if (!noAvatar && ((!isOwner && !showAvatar && !permanentlyShow) || (permanentlyHide || !isOwner))) {
    srcObject.Url = getPersonalityAvatar(null, createdAt);
  }

  if (((toggle && !showAvatar) || permanentlyHide) && !noAvatar && !isOwner) {
    tooltipMessage = <span>Hidden based on your <Link to={withId(USER_SETTINGS_HIRE_PREFERENCES, currentUserId)}>anti-bias preferences.</Link></span>;
  }

  if ((permanentlyShow && workMode === WORK_MODE_HIRE) && !noAvatar && !isOwner) {
    tooltipMessage = <span>Shown based on your <Link to={withId(USER_SETTINGS_HIRE_PREFERENCES, currentUserId)}>anti-bias preferences.</Link></span>;
  }

  return (
    (
      <React.Fragment>
        <Avatar
          clickable={toggle}
          onClick={() => toggle && setShowAvatar(!showAvatar)}
          avatarProps={{
            alt: TruncatedName,
            className: avatarClassName,
            src: srcObject,
            ...avatarProps
          }}
          placeholderSeed={createdAt}
          tooltipMessage={tooltipMessage}
          type={ENTITY_TYPE_ENUM.USER}
          {...avatarOptions}
        /> {!avatarOnly && TruncatedName}
      </React.Fragment>
    )
  );
};

UserAvatarDisplay.propTypes = {
  avatarClassName: PropTypes.string,
  avatarOnly: PropTypes.bool,
  avatarBehavior: PropTypes.string,
  avatarOptions: PropTypes.object,
  avatarProps: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  currentUserHiringPreferences: PropTypes.object,
  emptyMessage: PropTypes.string,
  entity: PropTypes.object,
  tooltipMessage: PropTypes.string,
  User: PropTypes.object.isRequired
};

UserAvatarDisplay.defaultProps = {
  avatarClassName: '',
  avatarBehavior: HIDE,
  avatarOnly: false,
  avatarOptions: {},
  avatarProps: {},
  currentUserHiringPreferences: {},
  emptyMessage: '--',
  entity: null,
  tooltipMessage: null
};

const mapStateToProps = (state, props) => {
  const { entity = {} } = props;
  const currentUserId = getUserId(state);
  return {
    currentUserHiringPreferences: getHiringPreferences(state, { ...props, UserID: currentUserId }),
    ...getBaseUserProfile(state, { ...props, UserID: (entity || {}).ID }),
    currentUser: getUserData(state, props)
  };
};

export default withRouter(
  connect(
    mapStateToProps
  )(UserAvatarDisplay)
);
