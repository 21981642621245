import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from 'antd';
import { debounce } from 'lodash';
import isEmail from 'validator/lib/isEmail';

import { fetchSearch } from 'actions/searchActions';

import './EntitySearch.less';

const { Option, OptGroup } = Select;

/**
 * Component for searching in multiple entitites
 *
 * prop entitiesMapping should be:
 * {
 *  User: {
 *    value: (entity) => entity || 'ID', // the value returned from the selected item.
 *                                       // May be a function or a string representing
 *                                       // a key on the object
 *    label: entity => `${entity.FirstName} ${entity.LastName}` // A function returning a string
 *  },
 * ...
 * }
 */

class EntitySearch extends React.Component {
  state = {
    loading: false,
    error: false,
    value: null,
    values: []
  };

  constructor(props) {
    super(props);

    this.debouncedSearch = debounce(this.handleSearch.bind(this), 300);
    this.handleChange = this.handleChange.bind(this);
  }

  // Search for value in Entities
  handleSearch(value) {
    const { defaultValue, searchScope, searchType } = this.props;
    const { value: stateValue } = this.state;

    if (value === '' || (!value && !stateValue)) {
      this.setState({ value: defaultValue, values: [] });
      return;
    }

    return new Promise((resolve, reject) => {
      if (value.length < process.env.REACT_APP_MIN_SEARCH_LENGTH) {
        resolve([]);
        return;
      }

      const { entitiesMapping, fetchSearch } = this.props;
      const types = Object.keys(entitiesMapping);
      if (!types.length) {
        resolve([]);
        return;
      }

      this.setState({
        loading: true
      });
      // @TODO: add logic from this method into the action so it can be used in other use cases for search
      fetchSearch(searchType, value, Object.keys(entitiesMapping), searchScope).then(res => {
        const values = [];

        if (res.error) {
          this.setState({
            error: res.error,
            loading: false
          }, () => reject(res.error));

          return;
        }

        if (res.payload) {
          types.forEach(type => {
            const options = res.payload[type].map(row => {
              const { value: outputValue } = entitiesMapping[type];
              const output = {
                label: entitiesMapping[type].label(row),
                type
              };

              if (typeof outputValue === 'function') {
                output.value = outputValue(row);
              } else if (typeof outputValue === 'string') {
                output.value = row[outputValue];
              } else {
                reject(new Error('The entityMapping `value` field must be a function or a string'));
              }

              return output;
            });

            // Create optgroups to be clear where the values belong
            values.push({
              label: type,
              options
            });
          });
        }

        if (isEmail(value)) {
          values.push({
            label: 'Email',
            options: [
              {
                label: value,
                type: 'Email',
                value: { roleId: 3, type: 'Email', entity: { ID: value, Email: value } }
              }
            ]
          });
        }

        this.setState({
          error: null,
          loading: false,
          values
        }, () => resolve(values));
      })
        .catch(error => this.setState({ error }, () => reject(error)));
    });
  }

  handleChange(selectedValues) {
    const { clearOnSelect, handleChange } = this.props;
    handleChange({ value: selectedValues });

    this.setState({
      error: null,
      value: selectedValues
    });

    if (clearOnSelect) {
      this.setState({
        value: '',
        values: []
      });
    }
  }

  render() {
    const {
      loading,
      error,
      value,
      values
    } = this.state;
    const {
      className,
      defaultValue,
      isDisabled,
      isMulti,
      placeholder,
      size,
      style
    } = this.props;

    const noOptionsMessage = () => {
      if (!values.length) {
        return <span>Type to search.</span>;
      }

      return <span>{placeholder}</span>;
    };

    /*
     * Warning: This will throw a duplicate key warning when the input values are objects
     * This is because react-select uses the `value` as the key when creating the pills.
     * This is dumb. There is any number of scenarios where we could have identical values.
     * We're choosing to ignore this warning at the moment in the hope that the package is updated.
     * Issue reference: https://github.com/JedWatson/react-select/issues/2844
     */
    return (
      <Select
        className={`entity-search ${className || ''}`}
        disabled={isDisabled}
        showSearch
        mode={isMulti ? 'multiple' : 'default'}
        size={size || 'large'}
        autoClearSearchValue
        labelInValue
        suffixIcon={<FontAwesomeIcon icon={faSearch} />}
        placeholder={placeholder}
        value={!value ? undefined : value}
        filterOption={false}
        notFoundContent={noOptionsMessage()}
        onSearch={this.debouncedSearch}
        onChange={({ key }) => this.handleChange(JSON.parse(key))}
        style={style || { width: '100%' }}
      >
        {values.map(group => (
          group.options.length && (
            <OptGroup key={group.label} label={group.label}>
              {
                group.options.map(option => <Option key={option.value.entity.ID} value={JSON.stringify(option.value)}>{option.label}</Option>)
              }
            </OptGroup>
          )
        ))}
      </Select>
    );
  }
}

EntitySearch.propTypes = {
  className: PropTypes.string,
  clearOnSelect: PropTypes.bool,
  defaultValue: PropTypes.array,
  entitiesMapping: PropTypes.object,
  fetchSearch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  searchType: PropTypes.string,
  searchScope: PropTypes.object
};

EntitySearch.defaultProps = {
  className: '',
  clearOnSelect: true,
  defaultValue: [],
  entitiesMapping: {},
  isDisabled: false,
  isMulti: false,
  placeholder: 'Search...',
  searchScope: null,
  searchType: null
};

export default connect(undefined, {
  fetchSearch
})(EntitySearch);
