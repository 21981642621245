import {
  CLEAR_BANNER_ALERTS,
  PUSH_BANNER_ALERT,
  REMOVE_BANNER_ALERT
} from 'constants/bannerAlertsConstants';

export function removeBannerAlert(id) {
  return {
    type: REMOVE_BANNER_ALERT,
    id
  };
}

export function pushBannerAlert(message) {
  return (dispatch, getState) => {
    dispatch({
      type: PUSH_BANNER_ALERT,
      data: message
    });

    // If timeout (in milliseconds) is set after that time the alert will be dismissed
    if (message.timeout) {
      const alerts = getState().bannerAlerts.items;
      const lastAlert = alerts[alerts.length - 1];

      setTimeout(() => {
        dispatch(removeBannerAlert(lastAlert.id));
      }, message.timeout);
    }
  };
}

export function clearBannerAlerts() {
  return {
    type: CLEAR_BANNER_ALERTS
  };
}
