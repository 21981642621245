import { schema } from 'normalizr';

const Skill = new schema.Entity(
  'Skill',
  {},
  {
    idAttribute: 'ID'
  }
);

const SkillList = [Skill];

export { Skill, SkillList };
