const sizeNames = [
  'blur',
  'thumbnail',
  'extraSmall',
  'small',
  'medium',
  'large',
  'extraLarge'
];

// this will first sort images from small to large
// then match the size according to sizeNames above
// e.g. 16x16 = blur
function convertToBreakpoints(Url = {}, maxWidth = 1603, minWidth = 40) {
  let UrlObject = {};

  if (typeof Url === 'string') {
    UrlObject = {
      [`${minWidth}x${minWidth}`]: Url
    };
  } else {
    UrlObject = { ...Url };
  }

  const sorted = Object.keys(UrlObject).sort((a, b) => {
    const first = parseInt(a.split('x')[0], 10);
    const second = parseInt(b.split('x')[0], 10);
    return first - second;
  });

  return sizeNames.reduce((prev, size, index) => {
    const pluckedSize = sorted[index] || sorted[0];
    const splitSize = pluckedSize.split('x') || [];
    const [firstSize] = splitSize;
    const sizeToInt = parseInt(firstSize, 10);
    if (sizeToInt && (sizeToInt <= maxWidth)) {
      if (pluckedSize) {
        prev[size] = UrlObject[pluckedSize];
        return prev;
      }
      prev[size] = UrlObject[sorted[sorted.length - 1]];
    }
    return prev;
  }, {});
}

export {
  convertToBreakpoints
};
