import mergeWith from 'lodash/mergeWith';

import {
  SET_CURRENT_PROJECT_ID,
  SET_CURRENT_PROJECT_PERMALINK,
  CREATE_PROJECT,
  PROJECT_FETCH,
  PROJECT_DELETE,
  PROJECTS_FETCH,
} from 'constants/projectConstants';

import {
  EVENT_PROJECTS_FETCH
} from 'constants/eventConstants';
import { mergeWithArray } from 'utils/dataUtils';

export function currentProjectID(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_PROJECT_ID:
      return action.payload;
    default:
      return state;
  }
}

export function currentProjectPermalink(state = null, action) {
  switch (action.type) {
    case SET_CURRENT_PROJECT_PERMALINK:
      return action.payload;
    default:
      return state;
  }
}

/**
 * Returns a new 'ProjectsById' state
 * @param  {Object}  [state={}] The project or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function projectById(state = {}, action) {
  const {
    payload, ProjectID, type
  } = action;
  switch (type) {
    case PROJECT_FETCH._START:
    case PROJECT_FETCH._ERROR:
      return {
        [ProjectID]: action
      };
    case PROJECT_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          [payload.result]: action
        };
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'ProjectsByUserId' state
 * @param  {Object}  [state={}] The projects or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function projectsByUserId(state = {}, action) {
  const { payload, type, userId } = action;

  switch (type) {
    case PROJECT_DELETE._SUCCESS: {
      const { projectId } = action;
      const userProjects = Array.from(state[userId].items) || [];

      return {
        ...state,
        [userId]: {
          ...state[userId],
          items: userProjects.filter(id => id !== projectId)
        }
      };
    }
    case PROJECTS_FETCH._START:
    case PROJECTS_FETCH._ERROR:
      return {
        ...state,
        [userId]: {
          ...action,
          items: []
        }
      };
    case PROJECTS_FETCH._SUCCESS:
      if (payload && payload.result) {
        return mergeWith({}, state, {
          [userId]: {
            ...action,
            items: payload.result
          }
        }, mergeWithArray);
      }
      return state;
    case CREATE_PROJECT._SUCCESS:
      if (payload && payload.result) {
        return mergeWith({}, state, {
          [userId]: {
            isLoading: false,
            items: [payload.result]
          }
        }, mergeWithArray);
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'projectsByEventId' state
 * @param  {Object}  [state={}] The event or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with event data
 */
export function projectsByEventId(state = {}, action) {
  const {
    payload, eventId, type, page = false
  } = action;
  switch (type) {
    case EVENT_PROJECTS_FETCH._START:
    case EVENT_PROJECTS_FETCH._ERROR:

      return {
        ...state,
        [eventId]: {
          ...action,
          items: [...state[eventId] ? state[eventId].items : []]
        }
      };
    case EVENT_PROJECTS_FETCH._SUCCESS:
      if (payload && payload.result) {
        if (!page) {
          return {
            ...state,
            [eventId]: {
              ...action,
              items: [...payload.result]
            }
          };
        }
        return {
          ...state,
          [eventId]: {
            ...action,
            items: [
              ...state[eventId].items,
              ...payload.result,
            ]
          }
        };
      }
      return state;
    default:
      return state;
  }
}
