import { difference, isEmpty } from 'lodash';
import merge from 'lodash/merge';

import {
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_SUCCESS,
  LOGIN_ERROR,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  REGISTER_USER_ERROR,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  USER_ADD_MEDIA_SUCCESS,
  USER_DECREMENT_PROFILE_PROGRESS,
  USER_DELETE_MEDIA_SUCCESS,
  USER_INCREMENT_PROFILE_PROGRESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_PREFS_SUCCESS,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS
} from 'constants/userConstants';
import { VERIFY_HASH_SUCCESS } from 'constants/verifyConstants';
import { PROFILE_CHECKLIST_ENUM as PROFILE_CHECKLIST } from 'shared/constants/enumConstants';
import { filterMediaIdsToRemove } from 'reducers/utils/mediaUtils';

const initialState = {
  data: null,
  error: null,
  errorStatus: null,
  isLoading: false
};

export function currentUser(state = initialState, {
  type,
  payload,
  error,
  errorStatus,
  isLoading,
  ...rest
}) {
  switch (type) {
    case LOGIN_START:
      return { ...initialState, isLoading: true };
    case LOGIN_ERROR:
      return {
        ...initialState,
        errorStatus,
        error
      };
    case LOGIN_SUCCESS: {
      if (payload && payload.entities) {
        const { entities, result, Token } = payload;
        const User = entities.User
          ? {
            ...entities.User[result]
          }
          : {};

        return {
          ...initialState,
          data: {
            Token,
            User
          },
          isLoading: false
        };
      }
      return state;
    }
    case LOGOUT_SUCCESS:
    case LOGOUT_ERROR:
      return initialState;
    case USER_UPDATE_START:
      return {
        ...state,
        isLoading,
        error: initialState.error,
        errorStatus: initialState.errorStatus
      };
    case USER_UPDATE_SUCCESS:
    case USER_UPDATE_PREFS_SUCCESS: {
      if (payload && payload.entities) {
        const { entities, result } = payload;
        let { Profile, User } = entities;
        User = User[result];
        if (!isEmpty(Profile)) {
          Profile = Profile[result];
        }

        return {
          ...state,
          data: {
            User: merge(
              state.data.User,
              {
                ...User,
                Profile
              }
            )
          },
          error: initialState.error,
          errorStatus: initialState.errorStatus,
          isLoading: false
        };
      }

      return state;
    }
    case USER_UPDATE_ERROR:
      return {
        ...state,
        error,
        errorStatus
      };
    case USER_ADD_MEDIA_SUCCESS: {
      const { mediaId, mediaType } = rest;
      const updatedUser = { ...state.data.User };
      const userMedia = updatedUser.Media || [];
      const removedMediaIds = filterMediaIdsToRemove(userMedia, state.Media, mediaType);

      updatedUser.Media = difference(userMedia, removedMediaIds).concat(mediaId);

      return {
        ...state,
        data: {
          User: {
            ...state.data.User,
            ...updatedUser
          }
        }
      };
    }
    case USER_DELETE_MEDIA_SUCCESS: {
      const { mediaId } = rest;
      const updatedUser = { ...state.data.User };
      const userMedia = updatedUser.Media || [];
      const updatedMedia = userMedia.filter(id => id !== mediaId);

      return {
        ...state,
        data: {
          User: {
            ...state.data.User,
            Media: updatedMedia
          }
        }
      };
    }
    case USER_DECREMENT_PROFILE_PROGRESS: {
      const newState = {
        ...state
      };
      newState.data.User.ProfileChecklist -= Math.floor(100 / Object.values(PROFILE_CHECKLIST).length);
      return newState;
    }
    case USER_INCREMENT_PROFILE_PROGRESS: {
      const newState = {
        ...state
      };
      newState.data.User.ProfileChecklist += Math.floor(100 / Object.values(PROFILE_CHECKLIST).length);
      return newState;
    }
    case VERIFY_HASH_SUCCESS: {
      if (payload && payload.entities) {
        const newState = {
          ...state
        };
        const { entities, result } = payload;
        let { User } = entities;
        if (User) {
          User = User[result];
          newState.data.User = User;
        }
        return newState;
      }

      return state;
    }
    default:
      return state;
  }
}

/**
 * Returns a new 'UserProfile' state
 * @param  {Array}  [state=[]] The user or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function userProfileByID(state = {}, action) {
  const {
    payload, type, errorStatus
  } = action;

  switch (type) {
    case FETCH_USER_PROFILE_START:
      return {
        ...state
      };
    case FETCH_USER_PROFILE_SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [payload.result]: action
        };
      }
      return state;
    case FETCH_USER_PROFILE_ERROR:
      return {
        ...errorStatus
      };
    default:
      return state;
  }
}

/**
 * Returns a new 'registerUser' state
 * @param  {Array}  [state=[]] The user or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function registerUser(state = { isLoading: false, user: null }, { payload, type, errorStatus }) {
  switch (type) {
    case REGISTER_USER_START:
      return {
        ...state,
        isLoading: true
      };

    case REGISTER_USER_SUCCESS:
      if (payload && payload.entities) {
        const { entities, result, Token } = payload;
        const User = entities.User
          ? {
            ...entities.User[result]
          }
          : {};

        return {
          ...initialState,
          data: {
            Token,
            User
          },
          isLoading: false
        };
      }

      return state;

    case REGISTER_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorStatus
      };
    default:
      return state;
  }
}
