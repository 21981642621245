import randomColor from 'randomcolor';
import {
  fade, adjacent, brightness
} from 'chromatism';

/**
 * Get the colors for making a delightful little gemstone.
 * Oh, also you need a valid Number to make one of these.
 * I prefer createdAt fields via moment.
 * a la moment(timestamp).getValue();
 * @param  {Number} [seed=0] Something that is unique to you
 * @return {Array}  💎
 */
export function getGemColors(seed = 0) {
  // get a color... 🖌️
  const color = randomColor({
    seed: parseInt(seed, 10),
    luminosity: 'bright'
  });

  // get some moore colors... 🌈
  const adjacentColor = adjacent(35, 2, color).hex;

  // get even more colors... 🎨
  const fadeColors = fade(4, color, adjacentColor[1]).hex;

  // put the colors in a box... 🗃️
  const [
    borderColor,
    backgroundColor,
    centerHighlightColor,
    highlightColor
  ] = fadeColors;

  //  ship the box to Jim the diamond cutter for processing... 💠
  return [
    brightness(-15, borderColor).hex,
    backgroundColor,
    brightness(20, centerHighlightColor).hex,
    brightness(20, highlightColor).hex,
  ];
}
