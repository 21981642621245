import { MILESTONE_STATE_ENUM } from 'shared/constants/enumConstants';
import { generateAsyncActions } from 'utils/actionUtils';
import {
  faCalendarAlt,
  faCheck,
  faMinusCircle,
  faClock
} from '@fortawesome/pro-light-svg-icons';

export const MILESTONE_CREATE = generateAsyncActions('MILESTONE_CREATE');

export const MILESTONE_DELETE = generateAsyncActions('MILESTONE_DELETE');

export const MILESTONE_UPDATE = generateAsyncActions('MILESTONE_UPDATE');

export const MILESTONE_ADD_MEDIA = generateAsyncActions('MILESTONE_ADD_MEDIA');

export const MILESTONE_DELETE_MEDIA = generateAsyncActions('MILESTONE_DELETE_MEDIA');

export const MILESTONES_FETCH = generateAsyncActions('MILESTONES_FETCH');

// Colors correspond with rupie-tag--* classNames in antd-overrides.less
export const MILESTONE_STATES = [
  {
    value: MILESTONE_STATE_ENUM.PLANNING,
    display: {
      name: 'Planning',
      color: 'purple',
      icon: faCalendarAlt
    }
  },
  {
    value: MILESTONE_STATE_ENUM.PROGRESS,
    display: {
      name: 'In Progress',
      color: 'blue',
      icon: faClock
    }
  },
  {
    value: MILESTONE_STATE_ENUM.COMPLETE,
    display: {
      name: 'Complete',
      color: 'green',
      icon: faCheck
    }
  },
  {
    value: MILESTONE_STATE_ENUM.CLOSED,
    display: {
      name: 'Closed',
      color: 'gray',
      icon: faMinusCircle
    }
  }
  // {
  //   value: MILESTONE_STATE_ENUM.INACTIVE,
  //   display: {
  //     name: 'Inactive',
  //     color: 'yellow',
  //     icon: faExclamation
  //   }
  // }
];

export const MILESTONE_STATE_DISPLAY = MILESTONE_STATES.reduce((hash, state) => ({
  ...hash,
  [state.value]: state.display
}), {});
