import { schema } from 'normalizr';

import { Beacon, Job, User } from 'schemas/index';

const SuggestedTalent = new schema.Entity(
  'SuggestedTalent',
  {
    User,
    Job
  },
  {
    idAttribute: 'ID'
  }
);

const SuggestedTalentList = [SuggestedTalent];

Job.define({
  SuggestedTalent: SuggestedTalentList
});

User.define({
  SuggestedTalent: SuggestedTalentList
});

Beacon.define({
  SuggestedTalents: SuggestedTalentList
});

export {
  SuggestedTalent,
  SuggestedTalentList
};
