import { normalize } from 'normalizr';

import { Quiz, QuizList } from 'schemas/index';
import { errorState, loadingState, successState } from 'utils/actionUtils';
import { QUIZ_ENDPOINT, QUIZZES_ENDPOINT } from 'constants/global';
import * as quizConstants from 'constants/quizConstants';
import { getUserId } from 'selectors/userSelectors';

import { incrementProfileProgress } from './userActions';

const {
  GET_USER_QUIZ,
  GET_USER_QUIZZES,
  UPDATE_USER_QUIZ
} = quizConstants;

/**
 * Async action that fetches quizzes
 * @return {Function}         The Thunk function to resolve
 */
export function fetchUserQuizzes() {
  return {
    types: {
      loading: () => loadingState(GET_USER_QUIZZES),
      success: quizzes => successState(GET_USER_QUIZZES, normalize(quizzes, QuizList)),
      error: err => errorState(GET_USER_QUIZZES, err, null)
    },
    apiParams: {
      endpoint: QUIZZES_ENDPOINT
    }
  };
}

/**
 * Async action that fetches a quiz
 * @return {Function}         The Thunk function to resolve
 */
export function fetchQuiz(quizId) {
  return {
    types: {
      loading: () => loadingState(GET_USER_QUIZ),
      success: quiz => successState(GET_USER_QUIZ, normalize(quiz, Quiz)),
      error: err => errorState(GET_USER_QUIZ, err, null)
    },
    apiParams: {
      method: 'get',
      endpoint: `${QUIZ_ENDPOINT}/${quizId}`
    }
  };
}

/**
 * Async action that updates quizzes
 * @return {Function}         The Thunk function to resolve
 */
export function updateUserQuiz(quizId, data) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    return dispatch({
      types: {
        loading: () => loadingState(UPDATE_USER_QUIZ),
        success: quiz => {
          const { User: Users = [] } = quiz;

          let complete = false;

          if (Array.isArray(Users)) {
            const [User = {}] = Users;
            const { UserxQuiz = [] } = User;
            const { Complete = false } = UserxQuiz;
            complete = Complete;
          } else {
            const { UserxQuiz = [] } = Users;
            const [result = {}] = UserxQuiz;
            const { Complete = false } = result;
            complete = Complete;
          }

          // if we add more onboarding assessments, uncomment this and it will increment the profileChecklist when they complete all the onboarding assessments
          // const { Quiz } = state;
          // const { numberOfAssessments, numberOfCompleted } = Object.values(Quiz).reduce((accum, next) => {
          //   let { numberOfAssessments, numberOfCompleted } = accum;
          //   const { Category = '', UserxQuiz: { Complete = false } = {} } = next;
          //   if (Category === QUIZ_CATEGORY.ONBOARDING) {
          //     if (Complete) {
          //       numberOfCompleted += 1;
          //     }
          //     numberOfAssessments += 1;
          //   }
          //   return { numberOfAssessments, numberOfCompleted };
          // }, { numberOfAssessments: 0, numberOfCompleted: 0 });
          // increment = numberOfCompleted + (complete ? 1 : 0) === numberOfAssessments;

          if (complete) {
            dispatch(incrementProfileProgress(userId));
          }

          return successState(UPDATE_USER_QUIZ, normalize(quiz, Quiz));
        },
        error: err => errorState(UPDATE_USER_QUIZ, err, null)
      },
      apiParams: {
        method: 'patch',
        endpoint: `${QUIZ_ENDPOINT}/${quizId}`,
        data
      }
    });
  };
}
