import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import ROUTE_PATHS from 'constants/routeConstants';
import * as userConstants from 'constants/userConstants';
import { loadingSelector } from 'selectors/genericSelectors';
import { getBaseUserProfile, getUserId } from 'selectors/userSelectors';

const disableOnboarding = process.env.REACT_APP_DISABLE_ONBOARDING === 'true'; // eslint-disable-line
const withOnboardingRedirect = WrappedComponent => {
  const HOC = class extends PureComponent {
    static propTypes = {
      isLoading: PropTypes.bool,
      isLoginLoading: PropTypes.bool,
      location: PropTypes.object.isRequired,
      User: PropTypes.object.isRequired
    };

    static defaultProps = {
      isLoading: true,
      isLoginLoading: true
    }

    render() {
      const {
        isLoading,
        isLoginLoading,
        User
      } = this.props;

      const { Meta = {} } = User;
      const {
        Onboarding: {
          completed,
          referrer,
          referrerType,
          userType
        } = {}
      } = Meta;

      const { onboardingComplete } = queryString.parse(window.location.search) || {};

      if (Meta.Onboarding
        && !disableOnboarding
        && (!isLoading || !isLoginLoading)
        && (!completed)
        && !onboardingComplete) {
        const search = queryString.stringify({
          referrer,
          referrerType,
          type: userType,
          ...queryString.parse(window.location.search)
        });

        return <Redirect to={{ pathname: ROUTE_PATHS.ONBOARDING, search }} />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

const mapStateToProps = (state, props) => {
  const currentUserId = getUserId(state, props);

  return {
    currentUserId,
    ...getBaseUserProfile(state, { ...props, UserID: currentUserId }),
    isLoading: loadingSelector([userConstants.FETCH_USER_START])(state),
    isLoginLoading: loadingSelector([userConstants.LOGIN_START])(state)
  };
};

const composedWithOnboardingRedirect = compose(
  connect(mapStateToProps),
  withOnboardingRedirect
);

export default composedWithOnboardingRedirect;
