import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Badge, Menu } from 'antd';
import classNames from 'classnames';

import { getMatchedKey, preventMenuSpam } from 'utils/routeUtils';
import { ROUTE_PATHS } from 'constants/global';
import { SIDE_MENU_DATA } from 'constants/layoutConstants';
import IconText from 'components/IconText/IconText';

import './SideMenu.less';

const {
  DASHBOARD, PROJECTS, EVENTS
} = ROUTE_PATHS;

const inverted = [
  DASHBOARD, PROJECTS, EVENTS
];

export default class SideMenu extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    menuDisabled: PropTypes.bool.isRequired,
    menuNotifications: PropTypes.object.isRequired,
    theme: PropTypes.string.isRequired,
    userType: PropTypes.string,
    workMode: PropTypes.string.isRequired
  }

  render() {
    const {
      currentUser,
      location,
      menuDisabled,
      theme,
      menuNotifications,
      workMode,
      userType
    } = this.props;

    return (
      <Menu
        className="side-menu"
        key="Menu"
        mode="inline"
        selectedKeys={[getMatchedKey(SIDE_MENU_DATA, location.pathname)]}
        theme={theme}
      >
        {
          SIDE_MENU_DATA.map(navItem => {
            const {
              className,
              disabled,
              hidden,
              image,
              label,
              link,
              name
            } = navItem;

            const showBadge = !!menuNotifications[link];

            const computedDisabled = typeof disabled === 'function' ? disabled(currentUser) : disabled;
            const computedHidden = typeof hidden === 'function' ? hidden(workMode, userType) : hidden;
            const computedLabel = typeof label === 'function' ? label(workMode) : label;
            const computedName = typeof name === 'function' ? name(workMode) : name;

            return (
              !computedHidden && (
                <Menu.Item
                  className="side-menu__item"
                  disabled={menuDisabled || computedDisabled}
                  key={link}
                  title={computedName}
                >
                  <NavLink
                    activeClassName="side-menu__item--active"
                    className="side-menu__link"
                    onClick={event => {
                      // stop clicking over and over
                      preventMenuSpam(event, location.pathname, link);
                      if (menuDisabled || computedDisabled) {
                        return event.preventDefault();
                      }
                    }}
                    title={computedLabel}
                    to={link}
                  >
                    <IconText
                      image={image}
                      text={computedName}
                      textClassName="side-menu__label"
                      iconClassName={classNames('side-menu__icon', {
                        [className]: className,
                        'side-menu__icon--inverse': inverted.includes(link)
                      })}
                    />
                  </NavLink>
                  {
                    showBadge && (
                      <Badge
                        dot
                        offset={[
                          1, 1
                        ]}
                      />
                    )
                  }
                </Menu.Item>
              )
            );
          })
        }
      </Menu>
    );
  }
}
