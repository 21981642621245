import {
  faCheck, faClock, faGrin,
  faThumbsDown, faTimes
} from '@fortawesome/pro-light-svg-icons';

import { generateAsyncActions } from 'utils/actionUtils';
import {
  JOB_APPLICATION_DECLINE_REASON_ENUM,
  JOB_APPLICATION_STATUS_ENUM,
  JOB_BENEFIT_DEFAULTS,
  JOB_STATUS_ENUM,
  JOB_TYPE_ENUM
} from 'shared/constants/enumConstants';

export const JOB_CLAIM = generateAsyncActions('JOB_CLAIM');
export const JOB_CREATE = generateAsyncActions('JOB_CREATE');
export const JOB_UPDATE = generateAsyncActions('JOB_UPDATE');
export const JOB_DELETE = generateAsyncActions('JOB_DELETE');
export const JOB_ADD_TO_GIG = generateAsyncActions('JOB_ADD_TO_GIG');
export const JOB_REMOVE_FROM_GIG = generateAsyncActions('JOB_REMOVE_FROM_GIG');
export const JOB_APPLICATION_CREATE = generateAsyncActions('JOB_APPLICATION_CREATE');
export const JOB_APPLICATION_UPDATE = generateAsyncActions('JOB_APPLICATION_UPDATE');
export const JOB_APPLICATION_DELETE = generateAsyncActions('JOB_APPLICATION_DELETE');
export const JOB_FETCH = generateAsyncActions('JOB_FETCH');
export const JOB_FETCH_INVITATIONS = generateAsyncActions('JOB_FETCH_INVITATIONS');
export const JOB_FETCH_INVOICE = generateAsyncActions('JOB_FETCH_INVOICE');
export const JOB_FETCH_META = generateAsyncActions('JOB_FETCH_META');
export const JOB_FETCH_INVITES = generateAsyncActions('JOB_FETCH_INVITES');
export const FETCH_PROVIDER_JOBS = generateAsyncActions('FETCH_PROVIDER_JOBS');
export const FETCH_WORKER_JOBS = generateAsyncActions('FETCH_WORKER_JOBS');
export const FETCH_SUGGESTED_TALENT = generateAsyncActions('FETCH_SUGGESTED_TALENT');

export const jobApplicationStatusMap = {
  [JOB_APPLICATION_STATUS_ENUM.NOT_INTERESTED]: {
    message: 'You\'ve declined this job',
    description: 'We\'ll try better to match the right jobs to your in the future ❤️.',
    providerDescription: 'Looks like this candidate wasn\'t interested.',
    title: 'Not interested',
    color: 'red',
    icon: faThumbsDown
  },
  [JOB_APPLICATION_STATUS_ENUM.INTERESTED]: {
    message: 'Nice work!',
    description: 'Hold tight while the job provider reviews your profile.',
    providerDescription: 'This candidate is interested. We\'re waiting for them to apply.',
    title: 'Interested',
    color: 'blue',
    icon: faGrin
  },
  [JOB_APPLICATION_STATUS_ENUM.APPLIED]: {
    message: 'Nice work!',
    description: 'Hold tight while the job provider reviews your profile.',
    providerDescription: 'This candidate is has applied. Now schedule an interview!',
    title: 'Applied',
    color: 'purple',
    icon: faCheck
  },
  [JOB_APPLICATION_STATUS_ENUM.INTERVIEW]: {
    message: 'Nice work!',
    description: 'Hold tight while the job provider reviews your profile.',
    providerDescription: 'This candidate is in the interview stage.',
    title: 'Interviewing',
    color: 'orange',
    icon: faClock
  },
  [JOB_APPLICATION_STATUS_ENUM.PENDING_HIRE]: {
    message: 'Nice work!',
    description: 'Hold tight while the job provider reviews your profile.',
    providerDescription: 'This candidate is in the late stages of being hired.',
    title: 'Pending Hire',
    color: 'yellow',
    icon: faClock
  },
  [JOB_APPLICATION_STATUS_ENUM.HIRED]: {
    message: 'Nice work!',
    description: 'Hold tight while the job provider reviews your profile.',
    providerDescription: 'Nice! You hired this candidate.',
    title: 'Hired',
    color: 'green',
    icon: faCheck
  },
  [JOB_APPLICATION_STATUS_ENUM.REJECTED]: {
    message: 'You weren\'t chosen for this job',
    description: 'Let us try to find something else for you ❤️.',
    providerDescription: 'You rejected this candidate.',
    title: 'Not hired',
    color: 'red',
    icon: faTimes
  }
};

export const jobStatusColorMap = {
  [JOB_STATUS_ENUM.DRAFT]: 'gray',
  [JOB_STATUS_ENUM.OPEN]: 'purple',
  [JOB_STATUS_ENUM.INITIAL_CALL]: 'blue',
  [JOB_STATUS_ENUM.WAITING_FOR_CANDIDATES]: 'purple',
  [JOB_STATUS_ENUM.PENDING_HIRE]: 'green',
  [JOB_STATUS_ENUM.ON_HOLD]: 'orange',
  [JOB_STATUS_ENUM.FILLED]: 'yellow',
  [JOB_STATUS_ENUM.FLAGGED]: 'pink',
  [JOB_STATUS_ENUM.CLOSED]: 'red'
};

export const jobTypeMap = {
  [JOB_TYPE_ENUM.FULL_TIME]: 'Full-time',
  [JOB_TYPE_ENUM.PART_TIME]: 'Part-time'
};

export const FULL_TIME = 'hire';
export const CONTRACT = 'contract';
export const GIG = 'gig';
export const CONTRACT_TO_HIRE = 'c2h';

export const JOB_TYPES = [
  {
    value: JOB_TYPE_ENUM.FULL_TIME,
    title: 'Full Time'
  },
  {
    value: JOB_TYPE_ENUM.PART_TIME,
    title: 'Part Time'
  },
  {
    value: JOB_TYPE_ENUM.CONTRACT,
    title: 'Contract'
  }
];

export const JOB_PAY_SCHEDULES = [
  {
    value: 'Hourly',
    title: 'Hourly'
  },
  {
    value: 'Yearly',
    title: 'Salaried'
  }
];

export const JOB_PAYMENT_CURRENCIES = [
  {
    value: 'USD',
    title: 'Dollar (USD)'
  },
  {
    value: 'AUD',
    title: 'Dollar (AUD)'
  },
  {
    value: 'CAD',
    title: 'Dollar (CAD)'
  },
  {
    value: 'CHF',
    title: 'Swiss Franc'
  },
  {
    value: 'DKK',
    title: 'Krone (DKK)'
  },
  {
    value: 'EUR',
    title: 'Euro'
  },
  {
    value: 'GBP',
    title: 'Great Britain Pound'
  },
  {
    value: 'HKD',
    title: 'Dollar (Hong Kong)'
  },
  {
    value: 'JPY',
    title: 'Yen'
  },
  {
    value: 'NOK',
    title: 'Krone (NOK)'
  },
  {
    value: 'NZD',
    title: 'Dollar (NZD)'
  },
  {
    value: 'SEK',
    title: 'Krone (SEK)'
  },
  {
    value: 'ZAR',
    title: 'South African Rand'
  }
];

export const TALENT = 'talent';
export const PROVIDER = 'provider';

export const JOB_APPLICATION_DECLINE_REASONS = [
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.NOT_RIGHT_TIME,
    title: 'Not the right time to make a move',
    types: [TALENT]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.NOT_INTERESTED_IN_STUDIO,
    title: 'I\'m not interested in the studio',
    types: [TALENT]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.NOT_GOOD_GENRE,
    title: 'The genre of games the studio produces',
    types: [TALENT]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.WORKED_THERE_BEFORE,
    title: 'I\'ve worked there in the past',
    types: [TALENT]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.BAD_STUDIO_REPUTATION,
    title: 'The reputation of the studio/managers',
    types: [TALENT]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.PAY,
    title: 'Pay',
    types: [
      TALENT, PROVIDER
    ]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.LOCATION,
    title: 'Location',
    types: [
      TALENT, PROVIDER
    ]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.NOT_QUALIFIED,
    title: 'Not qualified',
    types: [
      TALENT, PROVIDER
    ]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.OVER_EXPERIENCED,
    title: 'Over-experienced or over-qualified',
    types: [
      TALENT, PROVIDER
    ]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.CAREER_PATH_MISMATCH,
    title: 'This doesn\'t align with my career path',
    types: [TALENT]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.SIZE_OF_STUDIO,
    title: 'Size of studio',
    types: [TALENT]
  },
  {
    value: JOB_APPLICATION_DECLINE_REASON_ENUM.OTHER,
    title: 'Other (please explain)',
    types: [
      TALENT, PROVIDER
    ]
  }
];

export const JOB_BENEFITS = [
  {
    title: 'Health Insurance',
    value: JOB_BENEFIT_DEFAULTS.HEALTH_INSURANCE
  },
  {
    title: 'Bonuses',
    value: JOB_BENEFIT_DEFAULTS.BONUSES
  },
  {
    title: '401k',
    value: JOB_BENEFIT_DEFAULTS['401K']
  },
  {
    title: 'Child care',
    value: JOB_BENEFIT_DEFAULTS.CHILDCARE
  },
  {
    title: 'Relocation Assistance',
    value: JOB_BENEFIT_DEFAULTS.RELOCATION_ASSISTANCE
  },
  {
    title: 'Remote Option',
    value: JOB_BENEFIT_DEFAULTS.REMOTE_OPTION
  },
  {
    title: 'Paid time off',
    value: JOB_BENEFIT_DEFAULTS.PAID_TIME_OFF
  }
];
