import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

const { NODE_ENV, REACT_APP_DEBUG_TRACKING } = process.env;

const shouldTrack = NODE_ENV !== 'production';

// segment.io polyfill
window.analytics = window.analytics || {
  track: () => { },
  page: () => { },
  identify: () => { }
};

if (NODE_ENV !== 'test') {
  ReactGA.initialize([
    {
      trackingId: 'UA-132346629-1',
      debug: REACT_APP_DEBUG_TRACKING,
      alwaysSendToDefaultTracker: true,
      gaOptions: {
        testMode: shouldTrack,
        userId: false
      }
    }
  ]);
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });

    ReactGA.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    static propTypes = {
      location: PropTypes.object.isRequired
    };

    componentDidMount() {
      const { location } = this.props;

      // eslint-disable-next-line
      const page = location.pathname + location.search;
      window.analytics.page(page);

      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const { location } = this.props;
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const nextPage = location.pathname + location.search;

      if (currentPage !== nextPage) {
        window.analytics.page(nextPage);
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export { ReactGA as tracker, withTracker };
