import { schema } from 'normalizr';

import {
  Beacon, BeaconList, Job, JobList, Organization, Proposal, ProposalList, User
} from 'schemas/index';

const Invoice = new schema.Entity(
  'Invoice',
  {
    Job: JobList,
    Beacons: BeaconList,
    Organization,
    Proposal: ProposalList,
    User
  },
  {
    idAttribute: 'ID'
  }
);

const InvoiceList = [Invoice];

Job.define({
  Invoices: InvoiceList
});

Beacon.define({
  Invoices: InvoiceList
});

Proposal.define({
  Invoices: InvoiceList
});

User.define({
  Invoices: InvoiceList
});

Organization.define({
  Invoices: InvoiceList
});

export { Invoice, InvoiceList };
