import { difference } from 'lodash';
import moment from 'moment-business-days';

import {
  MAX_COST,
  MAX_DIFFICULTY,
  MAX_EXPERIENCE_LEVEL,
  MIN_COST,
  RUPIE_MARGIN
} from 'shared/constants/beaconConstants';

export const getBeaconPrice = function getBeaconPrice(difficulty, experienceLevel = 0, overridePrice) {
  const fullPrecision = (MIN_COST + (experienceLevel / MAX_EXPERIENCE_LEVEL) * (MAX_COST - MIN_COST)) * (difficulty / MAX_DIFFICULTY + RUPIE_MARGIN);
  return Number.isInteger(overridePrice) ? overridePrice : Math.round(fullPrecision * 100) / 100;
};

export const beaconSimilarityTest = function beaconSimilarityTest(proposedBeacon, beacons) {
  const { ID: proposedId, ExperienceLevel, Skills } = proposedBeacon;

  if (!ExperienceLevel && !Skills) {
    return true;
  }

  const proposedExperienceLevel = parseInt(ExperienceLevel);
  const proposedSkills = Skills.map(skill => skill.ID);

  return beacons.reduce((accum, beacon) => {
    const { ID, TalentFilter } = beacon;

    if (proposedId === ID) {
      return accum;
    }

    const { ExperienceLevel, Skills } = TalentFilter;
    const skills = Skills.map(skill => skill.ID);
    const diff = difference(skills, proposedSkills);

    // make sure at least one different skill (ie different role) or different experience level (ie different seniority)
    const check = diff.length > 0 || proposedExperienceLevel !== parseInt(ExperienceLevel);
    return accum && check;
  }, true);
};

export const isStaleTalent = createdAt => moment(createdAt).isBefore(moment().businessSubtract(3));
