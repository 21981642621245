import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from 'antd';
import classNames from 'classnames';

import './IconText.less';

const IconText = ({
  avatar,
  className,
  icon,
  iconClassName,
  iconColor,
  isReverse,
  isVertical,
  image,
  size,
  text,
  textClassName,
  textInherit
}) => {
  const wrapperClasses = classNames('icon-text', {
    [className]: className.length,
    'icon-text--has-inherited-text': textInherit,
    'icon-text--is-reverse': isReverse,
    'icon-text--is-vertical': isVertical
  });

  return (
    <span className={wrapperClasses}>
      {
        avatar && (
          <Avatar
            className={classNames('icon-text__avatar', {
              [iconClassName]: iconClassName.length
            })}
            src={avatar}
            size={size}
          />
        )
      }
      {
        icon && (
          <FontAwesomeIcon
            className={classNames('icon-text__icon',
              {
                [`icon-text__icon--${iconColor}`]: iconColor !== null,
                [iconClassName]: iconClassName.length
              })}
            icon={icon}
            size={size}
          />
        )
      }

      {
        !icon && image && (
          <span className="icon-text__image">{image}</span>
        )
      }

      <span
        className={classNames('icon-text__text', {
          [textClassName]: textClassName.length
        })}
      >
        {text}
      </span>
    </span>
  );
};

IconText.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  iconClassName: PropTypes.string,
  iconColor: PropTypes.string,
  image: PropTypes.node,
  isReverse: PropTypes.bool,
  isVertical: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  textClassName: PropTypes.string,
  textInherit: PropTypes.bool
};

IconText.defaultProps = {
  avatar: null,
  className: '',
  icon: null,
  iconClassName: '',
  iconColor: null,
  image: null,
  isReverse: false,
  isVertical: false,
  size: 'lg',
  textClassName: '',
  textInherit: false
};

export default IconText;
