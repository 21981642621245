import { merge } from 'lodash';

import { REMOVE_WORK_EXPERIENCE, UPDATE_WORK_EXPERIENCE } from 'constants/workExperienceConstants';
import { ENTITY_TYPE_ENUM as ENTITY_TYPES } from 'shared/constants/enumConstants';

const { WORK_EXPERIENCE } = ENTITY_TYPES;

export default function entities(state = {}, action) {
  const {
    payload, removedSkills = [], removedGenres = [], type, experienceId
  } = action;

  if (payload && payload.entities) {
    switch (type) {
      case REMOVE_WORK_EXPERIENCE._SUCCESS: {
        const newState = {
          ...state
        };

        delete newState[WORK_EXPERIENCE][experienceId];

        return newState;
      }

      case UPDATE_WORK_EXPERIENCE._SUCCESS: {
        const newState = {
          ...state
        };

        const { Genres = [], Skills = [] } = newState[WORK_EXPERIENCE][experienceId];

        newState[WORK_EXPERIENCE][experienceId].Genres = Genres.filter(genre => {
          const removedGenresIds = removedGenres.map(genre => genre.ID);
          if (!removedGenresIds.includes(genre.ID)) {
            return genre;
          }
          return false;
        });

        newState[WORK_EXPERIENCE][experienceId].Skills = Skills.filter(skill => {
          const removedSkillIds = removedSkills.map(skill => skill.ID);
          if (!removedSkillIds.includes(skill.ID)) {
            return skill;
          }
          return false;
        });

        return newState;
      }

      default:
        // mergeWith/mergeWithArray are only necessary if we're mutating a collection
        // of items on an entity attribute. Otherwise, we'll just use merge to optimize
        return merge({}, state, payload.entities);
    }
  }
  return state;
}
