import { generateAsyncActions } from 'utils/actionUtils';

import { TASK_STATE_ENUM } from 'shared/constants/enumConstants';
import {
  faCheck,
  faMinusCircle,
  faBan,
  faExclamation,
  faHourglassStart,
  faClock
} from '@fortawesome/pro-solid-svg-icons';

export const TASK_CREATE = generateAsyncActions('TASK_CREATE');
export const SUGGESTED_TASK_CREATE = generateAsyncActions('SUGGESTED_TASK_CREATE');

export const TASK_ASSIGN = generateAsyncActions('TASK_ASSIGN');
export const SUGGESTED_TASK_ASSIGN = generateAsyncActions('SUGGESTED_TASK_ASSIGN');

export const TASKS_ASSIGN = generateAsyncActions('TASKS_ASSIGN');
export const SUGGESTED_TASKS_ASSIGN = generateAsyncActions('SUGGESTED_TASKS_ASSIGN');

export const TASK_UPDATE = generateAsyncActions('TASK_UPDATE');
export const SUGGESTED_TASK_UPDATE = generateAsyncActions('SUGGESTED_TASK_UPDATE');

export const TASK_REORDER = generateAsyncActions('TASK_REORDER');
export const SUGGESTED_TASK_REORDER = generateAsyncActions('SUGGESTED_TASK_REORDER');

export const TASK_DELETE = generateAsyncActions('TASK_DELETE');
export const SUGGESTED_TASK_DELETE = generateAsyncActions('SUGGESTED_TASK_DELETE');

export const TASKS_FETCH = generateAsyncActions('TASKS_FETCH');
export const SUGGESTED_TASKS_FETCH = generateAsyncActions('SUGGESTED_TASKS_FETCH');

export const ADD_DELIVERABLE = generateAsyncActions('ADD_DELIVERABLE');

// Colors correspond with rupie-tag--* classNames in antd-overrides.less
export const TASK_STATES = [
  {
    value: TASK_STATE_ENUM.COMPLETE,
    display: {
      name: 'Complete',
      color: 'green',
      icon: faCheck
    }
  },
  {
    value: TASK_STATE_ENUM.PROGRESS,
    display: {
      name: 'In Progress',
      color: 'blue',
      icon: faClock
    }
  },
  {
    value: TASK_STATE_ENUM.OPEN,
    display: {
      name: 'Open',
      color: 'purple',
      icon: faHourglassStart
    }
  },
  {
    value: TASK_STATE_ENUM.BLOCKED,
    display: {
      name: 'Blocked',
      color: 'red',
      icon: faBan
    }
  },
  {
    value: TASK_STATE_ENUM.INACTIVE,
    display: {
      name: 'Inactive',
      color: 'yellow',
      icon: faExclamation
    }
  },
  {
    value: TASK_STATE_ENUM.HIDDEN,
    display: {
      name: 'Hidden',
      color: 'grey',
      icon: faMinusCircle
    }
  }
];

export const TASK_STATE_DISPLAY = TASK_STATES.reduce((prev, state) => ({
  ...prev,
  [state.value]: state.display
}), {});


export const SUGGESTED_TASK_STATE_DISPLAY = TASK_STATES.reduce((prev, state) => {
  if ([
    TASK_STATE_ENUM.COMPLETE, TASK_STATE_ENUM.BLOCKED, TASK_STATE_ENUM.OPEN, TASK_STATE_ENUM.PROGRESS
  ].includes(state.value)) {
    return {
      ...prev,
      [state.value]: state.display
    };
  }
  return prev;
}, {});
