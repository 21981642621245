import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { createResponsiveStateReducer } from 'redux-responsive';
import reduceReducers from 'reduce-reducers';

import {
  BREAKPOINT_EXTRALARGE,
  BREAKPOINT_EXTRASMALL,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL
} from 'constants/layoutConstants';
import { bannerAlertsReducer } from 'reducers/bannerAlertsReducers';
import * as billingReducers from 'reducers/billingReducers';
import { defaultsLoaded } from 'reducers/defaultReducer';
import {
  beaconEntitiesReducers,
  billingProfileEntitiesReducers,
  conversationEntitiesReducers,
  deliverableEntitiesReducers,
  eventEntitiesReducers,
  fileEntitiesReducers,
  genericEntitiesReducers,
  gigEntitiesReducers,
  jobEntitiesReducers,
  milestoneEntitiesReducers,
  organizationEntitiesReducers,
  prismEntitiesReducers,
  projectEntitiesReducers,
  rolodexEntitiesReducers,
  scheduleEventEntitiesReducers,
  skillEntitiesReducers,
  taskEntitiesReducers,
  teamEntitiesReducers,
  userEntitiesReducers,
  workExperienceEntitiesReducers
} from 'reducers/entities';
import { errorReducer } from 'reducers/errorReducer';
import * as eventReducers from 'reducers/eventReducers';
import * as extensionReducers from 'reducers/extensionReducers';
import * as formReducers from 'reducers/formReducer';
import * as gigReducers from 'reducers/gigReducers';
import * as insightReducers from 'reducers/insightReducers';
import * as invitationReducers from 'reducers/invitationReducers';
import { loadingReducer } from 'reducers/loadingReducer';
import * as menuReducers from 'reducers/menuReducers';
import {
  createMilestone,
  milestonesByProjectID,
  updateMilestone
} from 'reducers/milestoneReducers';
import {
  createOrganization,
  organizationById,
  organizationsByUserId
} from 'reducers/organizationReducers';
import {
  currentProjectID,
  currentProjectPermalink,
  projectsByEventId,
  projectsByUserId
} from 'reducers/projectReducers';
import * as rolodexReducers from 'reducers/rolodexReducers';
import * as shardReducers from 'reducers/shardReducers';
import * as signalReducers from 'reducers/signalReducers';
import * as supportReducers from 'reducers/supportReducers';
import {
  createTeam,
  teamById,
  teamsByUserId
} from 'reducers/teamReducers';
import * as userReducers from 'reducers/userReducers';
import * as walletReducers from 'reducers/walletReducers';

// this just makes a huge-ass list of reducers so that
// redux can pull them in and map them to the components
// Use an additional combineReducers to namespace each container's state
const appReducer = combineReducers({
  browser: createResponsiveStateReducer({
    extraSmall: BREAKPOINT_EXTRASMALL,
    small: BREAKPOINT_SMALL,
    medium: BREAKPOINT_MEDIUM,
    large: BREAKPOINT_LARGE,
    extraLarge: BREAKPOINT_EXTRALARGE
  }),

  // https://github.com/redux-utilities/reduce-reducers
  entities: reduceReducers(
    beaconEntitiesReducers,
    billingProfileEntitiesReducers,
    conversationEntitiesReducers,
    deliverableEntitiesReducers,
    eventEntitiesReducers,
    fileEntitiesReducers,
    genericEntitiesReducers,
    gigEntitiesReducers,
    jobEntitiesReducers,
    milestoneEntitiesReducers,
    organizationEntitiesReducers,
    prismEntitiesReducers,
    projectEntitiesReducers,
    rolodexEntitiesReducers,
    scheduleEventEntitiesReducers,
    skillEntitiesReducers,
    taskEntitiesReducers,
    teamEntitiesReducers,
    userEntitiesReducers,
    workExperienceEntitiesReducers
  ),

  Events: combineReducers({ ...eventReducers }),

  form: formReducer,

  insights: combineReducers({ ...insightReducers }),

  user: combineReducers({ ...userReducers }),

  extension: combineReducers({ ...extensionReducers }),

  currentProject: combineReducers({
    ID: currentProjectID,
    Permalink: currentProjectPermalink
  }),

  Gigs: combineReducers({ ...gigReducers }),

  Signal: combineReducers({ ...signalReducers }),

  wallet: combineReducers({ ...walletReducers }),

  Projects: combineReducers({
    byUserID: projectsByUserId,
    byEventID: projectsByEventId
  }),

  Milestones: combineReducers({
    byProjectID: milestonesByProjectID,
    createMilestone,
    updateMilestone
  }),

  Organizations: combineReducers({
    createOrganization,
    byUserID: organizationsByUserId,
    byID: organizationById
  }),

  Teams: combineReducers({
    createTeam,
    byUserID: teamsByUserId,
    byID: teamById
  }),

  Billing: combineReducers({ ...billingReducers }),

  Rolodex: combineReducers({ ...rolodexReducers }),

  Invitations: combineReducers({ ...invitationReducers }),

  Shard: combineReducers({ ...shardReducers }),

  Support: combineReducers({ ...supportReducers }),

  menuReducers: combineReducers({ ...menuReducers }),
  formReducers,
  loadingReducer,
  errorReducer,
  defaultsLoaded,
  bannerAlerts: bannerAlertsReducer
});

// this destroys the entire redux state on logout
export default (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined;  // eslint-disable-line
  }

  return appReducer(state, action);
};
