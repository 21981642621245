import Cookie from 'js-cookie';
import io from 'socket.io-client';

import { getSession } from 'utils/authUtils';
import { setHeaders, setupAxios } from 'shared/web/utils/apiUtils';
import { SERVER_HOST, SERVER_PROTOCOL } from 'constants/global';
import { LOGOUT_SUCCESS } from 'constants/userConstants';

// init the socket, but don't connect until logged in
export const socket = io(`${SERVER_PROTOCOL}://${SERVER_HOST}`, {
  autoConnect: false,
  path: '/ws',
  reconnectionAttempts: 5,
  reconnectionDelay: 2500,
  rememberUpgrade: true,
  transports: ['websocket']
});

// open the current socket
export const openSocket = () => {
  if (!Cookie.get('x-clientid') && getSession()) {
    Cookie.set('x-clientid', getSession().Token.slice(0, 15));
  }
  socket.io.opts.query = {
    token: getSession() && getSession().Token
  };
  socket.connect();
};

// close the current socket
export const closeSocket = () => {
  if (socket.connected || socket.io.readyState === 'connected') {
    socket.close();
  }
};

// Adding our accept header for versioning, eventually we'll prob want this to
// be configured globally, but for now we can do it this way
export const v1 = setupAxios((data, headers) => setHeaders(data, headers, getSession()));

// pass the redux form as a means to log a user out
// based on JWT token expiration/invalidation
export const setupInterceptors = store => {
  // Add a response interceptor
  v1.interceptors.response.use(response => response,
    error => {
      // catches if the session ended!
      if (error.response.status === 401) {
        localStorage.removeItem('SESSION');
        store.dispatch({
          type: LOGOUT_SUCCESS
        });
      }
      return Promise.reject(error);
    });
};
