import { schema } from 'normalizr';

import {
  FileList,
  MediaList,
  SuggestedTaskList,
  TaskList,
} from './index';

// Entities
const Feature = new schema.Entity(
  'Feature',
  {},
  {
    idAttribute: 'ID'
  }
);

const FeatureList = [Feature];

const Milestone = new schema.Entity(
  'Milestone',
  {
    Features: FeatureList,
    Files: FileList,
    Media: MediaList,
    SuggestedTasks: SuggestedTaskList,
    Tasks: TaskList
  },
  {
    idAttribute: 'ID'
  }
);

const MilestoneList = [Milestone];

export { Milestone, MilestoneList };
