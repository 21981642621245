import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { INTERNAL_SERVER_ERROR } from 'http-status-codes';
import Message from 'images/message.svg';
import './UncaughtException.less';
import { Helmet } from 'react-helmet';
import { Row } from 'antd';

export default class UncaughtException extends Component {
  static propTypes = {
    entity: PropTypes.string,
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool
    ]),
    status: PropTypes.number
  };

  static defaultProps = {
    entity: null,
    message: 'Something bad happened :-(',
    status: INTERNAL_SERVER_ERROR
  };

  render() {
    const { entity, message, status } = this.props;
    return (

      <Row className="uncaught">
        <Helmet>
          <title>Not Found</title>
        </Helmet>

        <section className="shrug">
          <div className="flex-container">
            <div className="header-container">
              <h2 className="header-message">
                {
                  message
                }
              </h2>
              <h1>
                {status || INTERNAL_SERVER_ERROR}
              </h1>
            </div>
            <img src={Message} alt="message" />
          </div>

        </section>
      </Row>

    );
  }
}
