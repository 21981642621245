import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Redirect, Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import mobile from 'is-mobile';
import moment from 'moment';
import SimpleReactLightbox from 'simple-react-lightbox';
import configureStore from 'store/configureStore';

import { history } from 'utils/historyUtils';
import appLocale from 'constants/localization/appLocale';
import { fetchAppDefaults } from 'actions/defaultActions';

import * as serviceWorker from './serviceWorker';

const checkExtension = (id, src, callback) => {
  const e = new Image();
  e.src = 'chrome-extension://' + id + '/' + src;
  e.onload = () => callback(1);
  e.onerror = () => callback(0);
};

checkExtension(process.env.REACT_APP_EXTENSION_ID, 'static/media/logo_icon_48.png', ok => {
  if (ok) {
    window.usingRupieExtension = true;
  }
});

checkExtension(process.env.REACT_APP_EXTENSION_INTERNAL_ID, 'static/media/logo_icon_48.png', ok => {
  if (ok) {
    window.usingRupieExtension = true;
  }
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    // debug: true,
    dsn: process.env.REACT_APP_SENTRY_DSN_WEB,
    environment: process.env.NODE_ENV,

    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      }),
      new Sentry.Replay()
    ],

    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.

    // Performance Monitoring
    tracesSampleRate: 1.0 // Capture 100% of the transactions, reduce in production!
    // Session Replay

  });
}

window.isMobile = mobile.isMobile();
window.appLocale = appLocale;

moment.locale(appLocale.moment);

const { store, persistor } = configureStore(); // we can pass an initial state if we want (ssr, etc)

// subscribe to the store and fetch app defaults if we are logged in and haven't already
store.subscribe(() => {
  const state = store.getState();
  if (!state.defaultsLoaded.isLoading && !state.defaultsLoaded.loaded) {
    store.dispatch(fetchAppDefaults());
  }
});

const render = () => {
  // eslint-disable-next-line global-require
  const App = require('./App').default;
  ReactDOM.render(
    // this magical component wraps the whole damn app with the ability to access
    // the global state ("store")
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={appLocale.antd}>
          <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
            <Router history={history}>
              <SimpleReactLightbox>
                <App>
                  {window.location.pathname.includes('index.html') && <Redirect to="/" />}
                </App>
              </SimpleReactLightbox>
            </Router>
          </IntlProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

render();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
