export const errorReducer = (state = {}, action) => {
  const { type, error } = action;
  const matches = /(.*)_(START|ERROR)/.exec(type);

  // not a *_START / *_ERROR action, so we ignore them
  if (!matches) return state;

  // eslint-disable-next-line array-bracket-newline
  const [,
    requestName, requestState
  ] = matches;

  return {
    ...state,
    // Store error response
    // e.g. stores error response when receiving PROJECT_FETCH_ERROR
    //      else clear error when receiving PROJECT_FETCH_SUCCESS
    [requestName]: requestState === 'ERROR' ? error && error.response : null,
  };
};
