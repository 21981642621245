/**
 * Gets a matched key from a set of items that define a `link` prop
 * @param  {Array} items    Array of items that have a link prop
 * @param  {String} pathname the react router pathname value from history.location
 * @return {String}  Either the link of the parent "route" or an empty string
 */
export const getMatchedKey = (items, pathname, exact = false) => items.reduce((nextPath, nextItem) => {
  // We need to remove query params to do the check correctly when using params.
  if (nextPath) return nextPath;

  const link = nextItem.link.split('?')[0];

  const aliases = [
    link, ...nextItem.aliases ? nextItem.aliases.map(link => link.split('?')[0]) : []
  ];

  const matches = aliases.filter(newLink => encodeURIComponent(pathname).indexOf(encodeURIComponent(newLink)) > -1);
  const pathMatches = exact ? aliases[0] === pathname : matches.length;
  if (!nextPath && pathMatches) {
    return link;
  }
  return nextPath;
}, '');

/**
 * Prevents menu items from clicked multiple times if we're on the same route
 * @param  {Event} event   the listener event
 * @param  {String} pathname The path
 * @param  {String} url      the url to check against
 * @return {null}
 */
export const preventMenuSpam = (event, pathname, url) => {
  if (pathname === url) {
    return event.preventDefault();
  }
};
