import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export default class PageMeta extends PureComponent {
  static propTypes = {
    desc: PropTypes.string,
    title: PropTypes.string
  }

  static defaultProps = {
    desc: 'Rupie surfaces the games industry\'s best pre-vetted, full-time hires and flexible contractors prepped and ready to connect with recruiting and hiring professionals.',
    title: ''
  }

  render() {
    const { title, desc } = this.props;

    return (
      <Helmet>
        <title>{title ? `${title} | Rupie` : 'Rupie'}</title>
        <meta name="description" content={desc} />
      </Helmet>
    );
  }
}
