import React, { Fragment, PureComponent } from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Drawer, Layout, Menu
} from 'antd';
import Cookies from 'cookies-js';

import { getClientUrl } from 'shared/utils/urlUtils';
import Logo from 'shared/website/components/Logo/Logo';

import './HeaderPublic.less';

// TODO: clear cookies on app when logging out
// TODO: add Link to webpack and use a single file for both app and website for public header

const { Header } = Layout;
const { Item } = Menu;

let { redirect, loggedIn } = {};

if (typeof window !== `undefined`) {
  const cookies = Cookies.get('loggedIn') ? JSON.parse(Cookies.get('loggedIn')) : {};
  redirect = cookies.redirect;
  loggedIn = cookies.loggedIn;
}

const headerNav = (mode, path) => (
  <nav className="global-header__nav">
    <Menu
      defaultSelectedKeys={path}
      mode={mode}
      role="menu"
      selectedKeys={path}
      theme="dark"
    >
      <Item key="studio">
        <a href="https://rupie.io/studio/beacons" rel="canonical">Talent Search</a>
      </Item>
      <Item key="signal">
        <a href={`${getClientUrl()}/signal`} rel="canonical">Candidate Pipeline</a>
      </Item>
      <Item key="talent">
        <a href="https://rupie.io/talent" rel="canonical">Get Hired</a>
      </Item>
      <Item key="about">
        <a href="https://rupie.io/about" rel="canonical">About Us</a>
      </Item>
      <Item key="pricing" rel="canonical">
        <a href="https://rupie.io/pricing">Pricing</a>
      </Item>
      {/* <Item key="blog">
        <a href="https://blog.rupie.io" target="_blank" rel="noreferrer">Blog</a>
      </Item>
      <Item key="help">
        <a href="https://help.rupie.io" target="_blank" rel="noreferrer">Help</a>
      </Item> */}
    </Menu>
  </nav>
);

const headerAux = btnProps => (
  <div className="global-header__aux">
    {
      loggedIn
        ? (
          <Fragment>
            <Button {...btnProps} size="large" aria-label="Back to App">
              <a href={redirect} rel="noopener">Back to App</a>
            </Button>
            <Button className="rupie-ml-1" {...btnProps} size="large" aria-label="Logout">
              <a href={`${getClientUrl()}/logout`} rel="noopener">Logout</a>
            </Button>
          </Fragment>
        )
        : (
          <Fragment>
            <Button type="primary" size="large" aria-label="Sign Up">
              <Link to="/signup">Sign Up</Link>
            </Button>
            <Button className="rupie-ml-1" {...btnProps} size="large" aria-label="Login">
              <Link to="/login" rel="noopener">Login</Link>
            </Button>
          </Fragment>
        )
    }
  </div>
);

class HeaderPublic extends PureComponent {
  state = {
    visible: false
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  render() {
    const { visible } = this.state;
    const pathName = typeof window !== `undefined` ? window.location.pathname.slice(1) : '';

    return (
      <Header className="global-header global-header--public">
        <Logo />
        {
          headerNav && (
            <Fragment>
              <MediaQuery query="(max-width: 1060px)">
                <Button
                  aria-label="Navigation"
                  className="global-header__button"
                  onClick={this.showDrawer}
                  type="primary"
                >
                  <FontAwesomeIcon icon={faBars} size="lg" />
                </Button>
                <Drawer
                  className="global-header__drawer"
                  closable
                  onClose={this.onClose}
                  placement="right"
                  title={(<Logo />)}
                  visible={visible}
                >
                  {headerNav('inline', pathName)}
                  {headerAux()}
                </Drawer>
              </MediaQuery>

              <MediaQuery query="(min-width: 1061px)">
                {headerNav('horizontal', pathName)}
                {headerAux({
                  ghost: true,
                  type: 'primary'
                })}
              </MediaQuery>
            </Fragment>
          )
        }
      </Header>
    );
  }
}

export default HeaderPublic;
