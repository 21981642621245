import React, { Component } from 'react';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Menu, Popover } from 'antd';

import { withId } from 'utils/dataUtils';
import { GET_CREDITS } from 'constants/creditConstants';
import ROUTES from 'constants/routeConstants';
import { fetchCredits } from 'actions/creditActions';
import { getCredits } from 'selectors/creditSelectors';
import { loadingSelector } from 'selectors/genericSelectors';
import { getUserData } from 'selectors/userSelectors';

import './CreditsDisplay.less';

export class CreditsDisplay extends Component {
  static propTypes = {
    creditFetched: PropTypes.bool,
    fetchCredits: PropTypes.func.isRequired,
    header: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired
  }

  static defaultProps = {
    creditFetched: false,
    header: false
  }

  async componentDidMount() {
    const { fetchCredits, creditFetched } = this.props;

    if (!creditFetched) {
      // call this to get credits
      await fetchCredits();
    }
  }

  render() {
    const { credits, header, isLoading } = this.props;

    if (isLoading) {
      return null;
    }

    const totalCreditAmount = (credits || [])
      .reduce((prev, next) => prev + (Number.parseFloat(next?.Amount) || 0), 0);

    // TODO: accumulate the amount and display
    const totalOrgs = (credits || [])
      .reduce((prev, orgCredit) => {
        if (orgCredit?.OrganizationID) prev[orgCredit?.OrganizationID] = orgCredit?.Organization || { ID: orgCredit.OrganizationID };
        return prev;
      }, {});

    return (
      <div className="credits-display">
        {
          totalCreditAmount > 0
            ? (
              <CountUp prefix="$" end={totalCreditAmount} suffix={header ? ' credits' : ''} decimals={0} delay={0}>
                {({ countUpRef }) => (
                  header
                    ? (
                      <Popover
                        placement="bottom"
                        trigger="click"
                        content={(
                          <Menu style={{ maxWidth: '250px' }}>
                            {
                              (Object.values(totalOrgs)).map(org => (
                                <Menu.Item key={org.ID}>
                                  <Link to={withId(ROUTES.ORGANIZATION_SETTINGS_CREDITS, org.ID)}>
                                    {org.Name || org.ID}
                                  </Link>
                                </Menu.Item>
                              ))
                            }
                          </Menu>
                        )}
                      >
                        <Button className="ant-btn-sm-lc text-white" type="ghost" size="small">
                          <span ref={countUpRef} />
                        </Button>
                      </Popover>
                    )
                    : <span ref={countUpRef} />
                )}
              </CountUp>
            )
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = () => (state, props) => ({
  credits: props.creditFetched ? props.credits : getCredits(state, props),
  currentUser: getUserData(state, props),
  isLoading: loadingSelector([GET_CREDITS._START])(state)
});

const mapDispatchToProps = ({
  fetchCredits
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditsDisplay);
