import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { faLifeRing, faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu, Spin } from 'antd';

import { withId } from 'utils/dataUtils';
import { getMatchedKey } from 'utils/routeUtils';
import { HEADER_MENU_DATA } from 'constants/layoutConstants';
import { WORK_MODE_HIRE, WORK_MODE_WORK } from 'constants/userConstants';
import { updateUserPrefs } from 'actions/userActions';
import { getUserData } from 'selectors/userSelectors';
import CreditsDisplay from 'containers/Credits/CreditsDisplay/CreditsDisplay';
import Notifications from 'containers/Notifications/Notifications';
import AppVersion from 'components/AppVersion/AppVersion';
import UserAvatarDisplay from 'components/EntityDisplay/UserAvatarDisplay';
import IconText from 'components/IconText/IconText';
import Loading from 'components/Loading/Loading';
import WorkMode from 'components/WorkMode/WorkMode';

import './HeaderNav.less';

class HeaderNav extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    history: ReactRouterPropTypes.history.isRequired,
    location: PropTypes.object.isRequired
  }

  static defaultProps = {
    currentUser: {}
  }

  // handle actions from user dropdown
  handleMenuClick = ({ key }) => {
    const { history } = this.props;
    if (key === 'logout') {
      history.replace('/logout');
    }
  };

  render() {
    const {
      currentUser, isMobileOrSmall, location, updateUserPrefs
    } = this.props;

    if (!currentUser) {
      return null;
    }

    const { Username } = currentUser;
    const { Meta: { Preferences, Onboarding } = {} } = currentUser;

    const workMode = Preferences.workMode || (Onboarding.userType === 'talent' ? WORK_MODE_WORK : WORK_MODE_HIRE);
    const otherWorkMode = workMode === WORK_MODE_HIRE ? WORK_MODE_WORK : WORK_MODE_HIRE;

    const links = HEADER_MENU_DATA.map(item => (
      { link: withId(item.link, currentUser.ID) }
    ));

    const menu = (
      <Menu
        className="header-nav-menu"
        onClick={this.handleMenuClick}
        selectedKeys={[getMatchedKey(links, location.pathname, true)]}
      >
        {
          HEADER_MENU_DATA.map(navItem => {
            const {
              disabled,
              hidden,
              icon,
              label,
              link,
              name
            } = navItem;

            const linkWithId = withId(link, currentUser.ID);
            return (
              !hidden && (
                <Menu.Item key={linkWithId} disabled={disabled}>
                  <NavLink
                    to={linkWithId}
                    className={`${(disabled ? ' disabled' : '')}`}
                    title={label}
                    activeClassName="active"
                    disabled={disabled}
                  >
                    <IconText
                      icon={icon}
                      text={name}
                    />
                  </NavLink>
                </Menu.Item>
              )
            );
          })
        }
        {
          process.env.NODE_ENV === 'development'
          && (
            <Menu.Item
              key="workMode"
              onClick={() => {
                window.analytics.track('USER_WORK_MODE_CHANGED', {
                  workMode: otherWorkMode
                });
                updateUserPrefs({
                  workMode: otherWorkMode
                });
              }}
            >
              <IconText
                icon={faSync}
                text={<span>Switch to <strong>{otherWorkMode}</strong> mode</span>}
              />
            </Menu.Item>
          )
        }
      </Menu>
    );

    return (
      <Fragment>
        <div className="global-header-nav">
          {/* <WorkMode mode={workMode} /> */}
          {Username
            ? (
              <Fragment>
                <CreditsDisplay header />
                <a
                  className="global-header-nav__item"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://help.rupie.io"
                  title="Rupie Help Desk"
                >
                  <FontAwesomeIcon
                    icon={faLifeRing}
                    size="lg"
                  />
                </a>
                <React.Suspense fallback={<Loading fill={false} />}>
                  <Notifications />
                </React.Suspense>
                <Dropdown
                  arrow
                  overlay={menu}
                  trigger={['click']}
                >
                  <span>
                    <UserAvatarDisplay
                      avatarOnly
                      entity={currentUser}
                    />
                  </span>
                </Dropdown>
              </Fragment>
            ) : (
              <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
            )
          }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentUser: getUserData(state, props)
});

export default withRouter(connect(mapStateToProps, { updateUserPrefs })(HeaderNav));
