import {
  SEARCH_SIGNAL_ROLES
} from 'constants/signalConstants';

export function signalRoles(state = [], action) {
  const { payload, type } = action;

  switch (type) {
    case SEARCH_SIGNAL_ROLES._SUCCESS:
      const { roles = [] } = payload;
      return [...roles];
    default:
      return state;
  }
}
