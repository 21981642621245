// Regex to get cleaned up linkedin urls
export const LINKEDIN_RECRUITER_URL_REGEX = [
  /http(s)?:\/\/([\w]+\.)?linkedin\.com\/recruiter\/profile\/[A-z0-9_-]+\/?/g,
  /http(s)?:\/\/([\w]+\.)?linkedin\.com\/talent\/search\/profile\/[A-z0-9_-]+\/?/g
];
export const LINKEDIN_URL_REGEX = /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9\p{Emoji_Presentation}_%-]+\/?/gu;

export const NAME_REGEX = /^[a-z ,.'-]+$/i;
export const RUPIE_EMAIL_REGEX = /@rupie.io$/;
export const USERNAME_REGEX = /^$|^[a-zA-Z0-99_@.-]+$/;
