import React from 'react';

export default function() {
  return (
    <a href="https://rupie.io/" className="global-header__logo" aria-label="Rupie Logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 210.3 68.3"
      >
        <g>
          <g className="st1">
            <path d="M71.7,14.1c9,0,15,4.4,15,13.1c0,6.3-3.1,10.2-8,12l10,16.5h-9.9l-9-15.4h-6.8v15.4h-8.8V14.1H71.7z M63,32.9h8.9 c3.8,0,6.4-1.7,6.4-5.5c0-3.7-2.6-5.4-6.4-5.4H63V32.9z" />
            <path d="M101.9,23.2v20.5c0,3.7,2.4,5.2,5.5,5.2c2.2,0,4.3-0.8,5.7-3.9V23.2h8.2v32.6h-7.6v-2.9c-2.3,2.5-5.4,3.7-9.1,3.7 c-6.4,0-10.8-3.7-10.8-11.4V23.2H101.9z" />
            <path d="M137.8,23.2v3.1c2-2.3,4.9-3.9,8.7-3.9c8.7,0,12,8.7,12,17.1c0,8.3-3.3,17-12,17c-3.4,0-6.1-1.3-8.1-3.3v12.3l-8.1,2.8 V23.2H137.8z M138.4,33.5v12c1.3,2.1,3.2,3.7,6.1,3.7c4.8,0,6.4-4.4,6.4-9.6c0-5.2-1.6-9.6-6.4-9.6 C141.6,29.9,139.7,31.4,138.4,33.5z" />
            <path d="M174.8,14.7c0,2.6-1.9,4.5-4.5,4.5s-4.4-1.9-4.4-4.5c0-2.6,1.8-4.5,4.4-4.5S174.8,12.1,174.8,14.7z M174.5,23.2v32.6h-8.1 V23.2H174.5z" />
            <path d="M189.9,42c0.8,5.2,3.9,7.3,7.2,7.3c2.5,0,4.3-1.3,5.6-3.1l5.4,4.7c-1.9,2.5-5.5,5.7-11.5,5.7c-7.9,0-14.4-5.9-14.4-17.1 c0-11.1,5.8-17,14-17c8.2,0,14.1,6,14.1,16.9c0,1.4-0.1,1.9-0.2,2.7H189.9z M189.9,36.1h12.7c-0.8-4.2-3.2-6.3-6.4-6.3 C193,29.8,190.7,32.1,189.9,36.1z" />
          </g>
          <path
            className="st0"
            d="M32.1,9.6l-7.3,0L15.1,0L0,15v38.2l15.1,15.1l15.1-15V39.8h1.9l15-15.1L32.1,9.6z M28.5,31.1h-7v18.6L15.1,56 h0l-6.4-6.4v-31l6.4-6.4h0l6.1,6l7.3,0l6.4,6.4L28.5,31.1z"
          />
          <line className="st1" x1="54.3" y1="24.7" x2="47.2" y2="24.7" />
        </g>
        <path className="st1" d="M31.3,44.9" />
        <path className="st1" d="M31.3,10.9" />
      </svg>
    </a>
  );
}
