import { schema } from 'normalizr';
import { User, UserList } from 'schemas/index';

// Entities
const Rolodex = new schema.Entity(
  'Rolodex',
  {
    User,
    Talent: UserList
  },
  {
    idAttribute: 'ID'
  }
);

const RolodexList = [Rolodex];

User.define({
  Rolodex,
  Rolodexes: RolodexList,
});

export { Rolodex, RolodexList };
