import React from 'react';
import { faPauseCircle, faPlayCircle } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'antd';

import { generateAsyncActions } from 'utils/actionUtils';
import { BEACON_STATUS_ENUM, TALENT_FILTER_ROLE_TITLE_ENUM, TALENT_FILTER_TALENT_CATEGORY_ENUM } from 'shared/constants/enumConstants';
import IconText from 'components/IconText/IconText';

const {
  ANIMATION,
  ART,
  BUSINESS,
  MUSIC,
  TECHNICAL,
  TESTING,
  VFX
} = TALENT_FILTER_TALENT_CATEGORY_ENUM;

const {
  VP,
  DIRECTOR,
  MANAGER,
  SUPERVISOR,
  STAFF,
  LEAD,
  SENIOR,
  ASSOCIATE
} = TALENT_FILTER_ROLE_TITLE_ENUM;

export const GET_BEACON_INTERNAL_RECORDS = generateAsyncActions('GET_BEACON_INTERNAL_RECORDS');
export const GET_BEACON_SUGGESTED_TALENT = generateAsyncActions('GET_BEACON_SUGGESTED_TALENT');
export const GET_BEACON = generateAsyncActions('GET_BEACON');
export const CREATE_BEACON = generateAsyncActions('CREATE_BEACON');
export const UPDATE_BEACON = generateAsyncActions('UPDATE_BEACON');
export const DELETE_BEACON = generateAsyncActions('DELETE_BEACON');
export const ESTIMATE_BEACON = generateAsyncActions('ESTIMATE_BEACON');
export const GET_BEACONS = generateAsyncActions('GET_BEACONS');
export const REVIEW_BEACON_TALENT = generateAsyncActions('REVIEW_BEACON_TALENT');
export const UPDATE_BEACON_META = generateAsyncActions('UPDATE_BEACON_META');
export const GET_BEACON_TRANSACTIONS = generateAsyncActions('GET_BEACON_TRANSACTIONS');
export const ESTIMATE_BEACON_NEW = generateAsyncActions('ESTIMATE_BEACON_NEW');
export const CREATE_BEACON_INTRODUCTION = generateAsyncActions('CREATE_BEACON_INTRODUCTION');

export const BEACON_JOB_TYPES = [
  {
    title: 'Animation/Rigging',
    value: ANIMATION
  },
  {
    title: 'Artistic/Creative',
    value: ART
  },
  {
    title: 'Business/Marketing',
    value: BUSINESS
  },
  {
    title: 'Music/Media',
    value: MUSIC
  },
  {
    title: 'Technical/Software',
    value: TECHNICAL
  },
  {
    title: 'Testing',
    value: TESTING
  },
  {
    title: 'VFX',
    value: VFX
  }
];

export const AGENCY_FEE = {
  [VP]: 0.4,
  [DIRECTOR]: 0.35,
  [MANAGER]: 0.35,
  [STAFF]: 0.3,
  [SUPERVISOR]: 0.3,
  [LEAD]: 0.3,
  [SENIOR]: 0.25,
  [ASSOCIATE]: 0.2
};

// do not sort
export const TALENT_FILTER_TITLES = [
  {
    title: 'VP',
    value: VP
  },
  {
    title: 'Director',
    value: DIRECTOR
  },
  {
    title: 'Manager',
    value: MANAGER
  },
  {
    title: 'Supervisor',
    value: SUPERVISOR
  },
  {
    title: 'Staff',
    value: STAFF
  },
  {
    title: 'Lead',
    value: LEAD
  },
  {
    title: 'Senior',
    value: SENIOR
  },
  {
    title: 'Associate',
    value: ASSOCIATE
  }
];

export const REVIEW_TALENT_REJECT_REASONS = [
  {
    title: 'Salary Too High'
  },
  {
    title: 'Location incorrect'
  },
  {
    title: 'Not AAA talent'
  },
  {
    title: 'Has not shipped a game'
  },
  {
    title: 'Not enough production experience'
  },
  {
    title: 'Too junior'
  },
  {
    title: 'Not enough leadership experience'
  },
  {
    title: 'Outside of time zone'
  },
  {
    title: 'Will not relocate'
  },
  {
    title: 'Missing candidate information'
  },
  {
    title: 'Work experience genre mismatch'
  },
  {
    title: 'Took a position at another company'
  },
  {
    title: 'Role mismatch'
  },
  {
    title: 'Already spoken to this candidate'
  },
  {
    title: 'Not creative enough background'
  },
  {
    title: 'Low quality portfolio'
  },
  {
    title: 'Not technical enough background'
  },
  {
    title: 'Did not respond'
  },
  {
    title: 'Missing required skills'
  },
  {
    title: 'Not D&I talent'
  },
  {
    title: 'Other reason'
  }
];

const { PAUSED, SYSTEM_PAUSED, SOURCING } = BEACON_STATUS_ENUM;
export const STATUS_BUTTON_MAP = {
  [PAUSED]: <IconText icon={faPlayCircle} text="Start Sourcing" />,
  [SOURCING]: <IconText icon={faPauseCircle} text="Pause Sourcing" />,
  [SYSTEM_PAUSED]: (
    <Tooltip title={(
      <span>
        We have paused this Beacon.&nbsp;
        <a
          href="mailto:talent@rupie.io?subject=Beacon%20Paused%20By%20Rupie%20Inquiry"
          rel="noreferrer"
          target="_blank"
          title="Contact us to find out more."
        >Contact us to find out more.
        </a>
      </span>
    )}
    >
      Paused
    </Tooltip>
  )
};

export const STATUS_TAG_MAP = {
  [PAUSED]: 'Paused (by you)',
  [SOURCING]: 'Sourcing...',
  [SYSTEM_PAUSED]: (
    <Tooltip title={(
      <span>
        We have paused this Beacon. &nbsp;
        <a
          href="mailto:talent@rupie.io?subject=Beacon%20Paused%20By%20Rupie%20Inquiry"
          rel="noreferrer"
          target="_blank"
          title="Contact us to find out more."
        >Contact us to find out more.
        </a>
      </span>
    )}
    >
      Paused (by Rupie)
    </Tooltip>
  )
};
