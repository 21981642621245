import { schema } from 'normalizr';

import {
  BillingProfile,
  Conversation,
  FileList,
  Job,
  JobList,
  MediaList,
  MilestoneList,
  ProducerList,
  SuggestedTaskList,
  TaskList,
  Team,
  User
} from 'schemas/index';

const proposalData = {
  Worker: User,
  Provider: User,
  Team,
  SuggestedTasks: SuggestedTaskList,
  Milestones: MilestoneList
};

// Entities
const Proposal = new schema.Entity(
  'Proposal',
  { ...proposalData },
  {
    idAttribute: 'ID',
  }
);

Proposal.define(
  { Proposal }
);

const Gig = new schema.Entity(
  'Gig',
  {
    BillingProfile: [BillingProfile],
    Conversations: [Conversation],
    Files: FileList,
    Jobs: JobList,
    Media: MediaList,
    Producer: ProducerList,
    Proposals: [Proposal],
    Tasks: TaskList
  },
  {
    idAttribute: 'ID'
  }
);

const GigList = [Gig];

Job.define({
  Gigs: GigList
});

const ProposalList = [Proposal];

export {
  Gig, GigList, Proposal, ProposalList
};
