import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { EVENT_TYPE_ENUM, EVENT_MEDIA_TYPE_ENUM } from 'shared/constants/enumConstants';
import { getOrgFilesAndMedia } from 'selectors/organizationSelectors';
import {
  buildProjectContributors,
  getProjectFilesAndMedia,
  getProjectMembers,
  getProjectOrganizations,
  getProjectOwner,
  getProjectTeams
} from 'selectors/projectSelectors';
import {
  getEventByIDState,
  getEventEntities,
  getFileEntities,
  getMediaEntities,
  getMemberEntities,
  getOrganizationByIDState,
  getOrganizationEntities,
  getProjectEntities,
  getProjectsByEventIDState,
  getTeamEntities,
  getUserEntities,
  getEventSubmissionEntities,
  getEventUpdateEntities
} from 'selectors/stateSelectors';
import { getTeamFilesAndMedia } from 'selectors/teamSelectors';
import { getUserFilesAndMedia, getUserId } from 'selectors/userSelectors';

const { AVATAR, BANNER } = EVENT_MEDIA_TYPE_ENUM;

export function getEventFilesAndMedia(event, fileEntities, mediaEntities) {
  const eventMediaFiles = Object.values(fileEntities).filter(file => {
    const { EventxFile, Media } = file;
    if (EventxFile && EventxFile.EventID === event.ID && Media) {
      return file;
    }
  });

  const eventFiles = Object.values(fileEntities).filter(file => {
    const { EventxFile, Media } = file;
    if (EventxFile && EventxFile.EventID === event.ID && !Media) {
      return file;
    }
  }) || [];

  const eventMediaIDs = event.Media || [];
  const eventMedia = compact(eventMediaIDs.reduce((entities, item) => entities.concat(mediaEntities[item]), []));

  const eventAvatar = eventMedia.find(item => {
    const { EventxMedia } = item;

    if (!EventxMedia) {
      return false;
    }

    return EventxMedia.Type === AVATAR;
  }) || eventMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === AVATAR && media.ID === MediaID);
  }) || {};

  const eventBanner = eventMedia.find(item => {
    const { EventxMedia } = item;

    if (!EventxMedia) {
      return false;
    }

    return EventxMedia.Type === BANNER;
  }) || eventMediaFiles.find(item => {
    const { MediaID } = item;

    if (!MediaID) {
      return false;
    }

    return Object.values(mediaEntities).find(media => media.Type === BANNER && media.ID === MediaID);
  }) || {};

  return {
    eventAvatar,
    eventBanner,
    eventFiles
  };
}

const getEventOrganizations = (Event, Organizations, fileEntities, mediaEntities) => {
  const { Organizers } = Event;
  let projectOrgs = [];
  if (Organizers && Organizers.length) {
    projectOrgs = Organizers.reduce((orgs, orgID) => orgs.concat({
      ...Organizations[orgID],
      ...getOrgFilesAndMedia(Organizations[orgID], fileEntities, mediaEntities)
    }), []);
  }

  return projectOrgs;
};

export const getEventsWithFilesAndMedia = createSelector(
  getUserId,
  getEventEntities,
  getFileEntities,
  getMediaEntities,
  (userId, eventEntities, fileEntities, mediaEntities) => ({
    Events: Object.values(eventEntities).map(event => ({
      ...event,
      ...getEventFilesAndMedia(event, fileEntities, mediaEntities)
    }))
  })
);

export const getDiscoverEvents = createSelector(
  getEventsWithFilesAndMedia,
  ({ Events }) => ({
    Events: Events.filter(event => event.State === EVENT_TYPE_ENUM.APPROVED)
  })
);

// if EventID is passed to the connected component, will extract and use this event in the selector
const getEventByIDFilter = (state, props) => {
  const { EventID, match } = props;
  if (props && EventID) {
    return getEventByIDState(state)[EventID];
  } if (match && match.params.id) {
    return getEventByIDState(state)[match.params.id];
  }
  return {};
};

export const getBaseEvent = createSelector(
  getEventByIDFilter,
  getEventEntities,
  (eventFromID, eventEntities) => {
    const eventState = eventFromID || {};
    const { error, isLoading, submissionCount } = eventState;
    const Event = eventEntities[eventState.eventId] || {};

    return {
      error,
      isLoading,
      Event,
      submissionCount
    };
  }
);

export const getEventWithFilesAndMedia = createSelector(
  getBaseEvent,
  getFileEntities,
  getMediaEntities,
  (event, fileEntities, mediaEntities) => ({
    ...event,
    ...getEventFilesAndMedia(event.Event, fileEntities, mediaEntities)
  })
);

export const getEventWithOrganizersAndMedia = createSelector(
  getEventWithFilesAndMedia,
  getOrganizationByIDState,
  getOrganizationEntities,
  getFileEntities,
  getMediaEntities,
  (baseEventWithMedia, orgByIDState, orgEntities, fileEntities, mediaEntities) => {
    const { Event } = baseEventWithMedia;
    const Organization = orgEntities[Event.OrganizationID] || {};
    const Organizers = getEventOrganizations(Event, orgEntities, fileEntities, mediaEntities);
    const submissionCount = parseInt(Event.TotalSubmissions, 10);

    return {
      ...baseEventWithMedia,
      Event,
      Organizers,
      Organization,
      submissionCount
    };
  }
);

export const getEventSubmission = createSelector(
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.id : null;
  },
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.submissionId : null;
  },
  getEventSubmissionEntities,
  getFileEntities,
  (eventID, submissionId, eventSubmissionEntities, fileEntities) => Object.values(eventSubmissionEntities).find(eventSubmission => {
    if (eventSubmission.EventID === eventID && eventSubmission.ID === submissionId) {
      if (eventSubmission.FileID) {
        eventSubmission.File = Object.values(fileEntities).find(file => file.ID === eventSubmission.FileID);
      }
      return eventSubmission;
    }
  })
);

export const getEventTeamSubmissions = createSelector(
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.id : null;
  },
  (state, props) => {
    const { teamId } = props;
    return teamId;
  },
  getUserId,
  getEventSubmissionEntities,
  getUserEntities,
  getMemberEntities,
  getTeamEntities,
  getOrganizationEntities,
  getFileEntities,
  getMediaEntities,
  getProjectsByEventIDState,
  (eventId,
    teamId,
    userId,
    eventSubmissionEntities) => {
    const TeamEventSubmissions = Object.values(eventSubmissionEntities).reduce((submissions, submission) => {
      if (submission.TeamID === teamId && submission.EventID === eventId) {
        return submissions.concat({
          ...submission
        });
      }

      return submissions;
    }, []);

    const UserEventSubmissions = Object.values(eventSubmissionEntities).reduce((submissions, submission) => {
      if (submission.UserID === userId && submission.EventID === eventId && !submission.TeamID) {
        return submissions.concat({
          ...submission
        });
      }

      return submissions;
    }, []);

    return {
      TeamEventSubmissions: TeamEventSubmissions.sort((a, b) => a.Revision - b.Revision),
      UserEventSubmissions: UserEventSubmissions.sort((a, b) => a.Revision - b.Revision)
    };
  }
);

export const getEventSubmissions = createSelector(
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.id : null;
  },
  (state, props) => {
    const { match } = props;
    const { Events: { pagedSubmissionIds = [] } = {} } = state;
    return pagedSubmissionIds[match && match.params ? match.params.id : null];
  },
  getEventSubmissionEntities,
  getUserEntities,
  getMemberEntities,
  getTeamEntities,
  getOrganizationEntities,
  getFileEntities,
  getMediaEntities,
  getProjectsByEventIDState,
  (eventId,
    pagedSubmissionIds = [],
    eventSubmissionEntities,
    userEntities,
    membersEntities,
    teamEntities,
    organizationEntities,
    fileEntities,
    mediaEntities,
    eventProjects) => {
    const Submissions = Object.values(eventSubmissionEntities).reduce((submissions, submission) => {
      if (submission.EventID === eventId && pagedSubmissionIds.includes(submission.ID)) {
        const Team = Object.values(teamEntities).find(team => {
          if (team.ID === submission.TeamID) {
            return { ...team, ...getTeamFilesAndMedia(team, fileEntities, mediaEntities) };
          }
        });

        const User = Object.values(userEntities).find(user => {
          if (user.ID === submission.UserID) {
            return { ...user, ...getUserFilesAndMedia(user, fileEntities, mediaEntities) };
          }
        });

        return submissions.concat({
          ...submission,
          Team,
          User
        });
      }

      return submissions;
    }, []);

    return {
      Submissions
    };
  }
);

export const getEventProjects = createSelector(
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.id : null;
  },
  getProjectEntities,
  getUserEntities,
  getMemberEntities,
  getTeamEntities,
  getOrganizationEntities,
  getFileEntities,
  getMediaEntities,
  getProjectsByEventIDState,
  (eventID,
    projectEntities,
    userEntities,
    membersEntities,
    teamEntities,
    organizationEntities,
    fileEntities,
    mediaEntities,
    eventProjects) => {
    const ProjectIDs = eventProjects[eventID] ? eventProjects[eventID].items : [];

    const Projects = ProjectIDs.reduce((projects, projectID) => {
      const project = projectEntities[projectID];
      const Media = getProjectFilesAndMedia(project, fileEntities, mediaEntities);
      const Members = getProjectMembers(project, membersEntities);
      const MemberOrganizations = getProjectOrganizations(project, organizationEntities, fileEntities, mediaEntities);
      const MemberTeams = getProjectTeams(project, teamEntities, fileEntities, mediaEntities);

      const projectOwner = getProjectOwner(
        project,
        userEntities,
        organizationEntities,
        fileEntities,
        mediaEntities,
        teamEntities
      );

      return projects.concat({
        ...project,
        Media,
        projectOwner,
        Members,
        MemberOrganizations,
        MemberTeams
      });
    }, []);

    return {
      Projects
    };
  }
);

export const getEventContributors = createSelector(
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.id : null;
  },
  getTeamEntities,
  getFileEntities,
  getMediaEntities,
  getUserEntities,
  (eventId, teamEntities, fileEntities, mediaEntities, userEntities) => {
    const teams = Object.values(teamEntities).reduce((accum, team) => {
      if (team.EventxTeam && team.EventxTeam.EventID === eventId) {
        return accum.concat(Object.assign(team, getTeamFilesAndMedia(team, fileEntities, mediaEntities)));
      }
      return accum;
    }, []);

    const users = Object.values(userEntities).reduce((accum, user) => {
      if (user.UserxEvent && user.UserxEvent.EventID === eventId) {
        return accum.concat(Object.assign(user, getUserFilesAndMedia(user, fileEntities, mediaEntities)));
      }
      return accum;
    }, []);

    return {
      TeamParticipants: teams,
      Participants: users
    };
  }
);

export const getEventUpdate = createSelector(
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.updateId : null;
  },
  getEventUpdateEntities,
  (updateId, eventUpdateEntities) => Object.values(eventUpdateEntities).find(eventUpdate => {
    if (eventUpdate.ID === updateId) {
      return eventUpdate;
    }
  }),
);

export function getAuthor(userEntities, UserID, fileEntities, mediaEntities) {
  const authorEntity = userEntities[UserID];

  return authorEntity ? {
    ...authorEntity,
    ...getUserFilesAndMedia(authorEntity, fileEntities, mediaEntities)
  } : {};
}

export const getEventUpdates = createSelector(
  (state, props) => {
    const { match } = props;
    return match && match.params ? match.params.id : null;
  },
  (state, props) => {
    const { match } = props;
    const { Events: { pagedUpdateIds = [] } = {} } = state;
    return pagedUpdateIds[match && match.params ? match.params.id : null];
  },
  getEventUpdateEntities,
  getUserEntities,
  getFileEntities,
  getMediaEntities,
  (eventId,
    pagedUpdateIds = [],
    eventUpdateEntities,
    userEntities,
    fileEntities,
    mediaEntities) => {
    const Updates = Object.values(eventUpdateEntities).reduce((updates, update) => {
      if (update.EventID === eventId) {
        update.Author = getAuthor(userEntities, update.UserID, fileEntities, mediaEntities);
        return updates.concat(update);
      }
      return updates;
    }, []);
    return Updates;
  }
);
