import { createSelector } from 'reselect';

import { ACTIONS, isAuthorized } from 'middleware/acl';
import { getOrgFilesAndMedia } from 'selectors/organizationSelectors';
import {
  getBeaconEntities,
  getFileEntities,
  getInternalRecordEntities,
  getMediaEntities,
  getOrganizationEntities,
  getSkillEntities,
  getSuggestedTalentEntities,
  getTalentFilterEntities,
  getUserEntities
} from 'selectors/stateSelectors';
import { getUserData, getUserId, getUserIdFilter } from 'selectors/userSelectors';

const getBeaconByIDFilter = (state, props) => {
  const { beaconId, match } = props;

  if (beaconId) {
    const beaconEntities = getBeaconEntities(state)[beaconId];
    return beaconEntities;
  }
  if (match && match.params.id) {
    return getBeaconEntities(state)[match.params.id];
  }
  return {};
};

export const getBaseBeacon = createSelector(
  getBeaconByIDFilter,
  beacon => beacon || {}
);

export const getBeaconWithEntities = createSelector(
  getUserId,
  getBaseBeacon,
  getTalentFilterEntities,
  getUserEntities,
  getSkillEntities,
  getSuggestedTalentEntities,
  getFileEntities,
  getMediaEntities,
  getOrganizationEntities,
  getInternalRecordEntities,
  (userId, beacon, talentFilterEntities, userEntities, skillEntities, suggestedTalentEntities, fileEntities, mediaEntities, orgEntities, internalRecordEntities) => {
    const TalentFilter = Object.values(talentFilterEntities).find(talentFilter => talentFilter.ID === beacon.TalentFilterID);
    const { Skills = [], Genres = [] } = TalentFilter || {};

    const Organization = Object.values(orgEntities).reduce((prev, org) => {
      if (beacon.OrganizationID && org.ID === beacon.OrganizationID) {
        return {
          ...org,
          ...getOrgFilesAndMedia(org, fileEntities, mediaEntities)
        };
      }
      return prev;
    }, null);

    const SuggestedTalents = Object.values(suggestedTalentEntities).filter(suggestedTalent => {
      if (suggestedTalent.BeaconID === beacon.ID) {
        const user = Object.values(userEntities).find(user => user.ID === suggestedTalent.UserID);

        if (user) {
          user.InternalRecords = Object.values(internalRecordEntities).filter(internalRecord => {
            if (internalRecord.TalentID === user.ID && internalRecord.BeaconID === beacon.ID) {
              return internalRecord;
            }
          });
        }

        suggestedTalent.User = user;
        return suggestedTalent;
      }
    });

    const InternalRecords = Object.values(internalRecordEntities).filter(internalRecord => {
      if (internalRecord.BeaconID === beacon.ID) {
        return internalRecord;
      }
    });

    return {
      Beacon: beacon,
      Genres,
      Organization,
      Skills,
      TalentFilter,
      InternalRecords,
      SuggestedTalents
    };
  }
);

export const getBeaconsByUserID = createSelector(
  getUserIdFilter,
  getBeaconEntities,
  getSuggestedTalentEntities,
  getUserEntities,
  getTalentFilterEntities,
  getFileEntities,
  getMediaEntities,
  getOrganizationEntities,
  (UserID, beaconEntities, suggestedTalentEntities, userEntities, talentFilterEntities, fileEntities, mediaEntities, orgEntities) => Object.values(beaconEntities)
    .reduce((beacons, beacon) => {
      const beaconObj = { ...beacon };
      beaconObj.TalentFilter = Object.values(talentFilterEntities).find(talentFilter => talentFilter.ID === beacon.TalentFilter);

      beaconObj.Organization = Object.values(orgEntities).reduce((prev, org) => {
        if (beacon.OrganizationID && org.ID === beacon.OrganizationID) {
          return {
            ...org,
            ...getOrgFilesAndMedia(org, fileEntities, mediaEntities)
          };
        }
        return prev;
      }, null);

      beaconObj.SuggestedTalents = Object.values(suggestedTalentEntities).filter(suggestedTalent => {
        if (suggestedTalent.BeaconID === beacon.ID) {
          const user = Object.values(userEntities).find(user => user.ID === suggestedTalent.UserID);
          suggestedTalent.User = user;
          return suggestedTalent;
        }
      });
      return beacons.concat(beaconObj);
    }, [])
    // sort beacons by what needs reviewed
    .sort((a, b) => {
      const aSuggested = (a?.SuggestedTalents || []);
      const bSuggested = (b?.SuggestedTalents || []);
      const ANeedsReviewed = aSuggested.filter(suggestedTalent => (suggestedTalent.ReactionMovingForward === null && suggestedTalent.ReactionShowSimilar === null));
      const BNeedsReviewed = bSuggested.filter(suggestedTalent => (suggestedTalent.ReactionMovingForward === null && suggestedTalent.ReactionShowSimilar === null));

      if (BNeedsReviewed.length === 0 && ANeedsReviewed.length === 0) {
        return bSuggested.length - aSuggested.length;
      }

      return BNeedsReviewed.length - ANeedsReviewed.length;
    })
);

export const getBeaconsByOrganizationID = createSelector(
  (state, props) => props.organizationId,
  getBeaconEntities,
  getSuggestedTalentEntities,
  getUserEntities,
  getTalentFilterEntities,
  getFileEntities,
  getMediaEntities,
  getOrganizationEntities,
  getInternalRecordEntities,
  (organizationId, beaconEntities, suggestedTalentEntities, userEntities, talentFilterEntities, fileEntities, mediaEntities, orgEntities, internalRecordEntities) => Object.values(beaconEntities)
    .reduce((beacons, beacon) => {
      const beaconObj = { ...beacon };
      if (beacon.OrganizationID !== organizationId) {
        return beacons;
      }

      beaconObj.TalentFilter = Object.values(talentFilterEntities).find(talentFilter => talentFilter.ID === beacon.TalentFilter);

      beaconObj.Organization = Object.values(orgEntities).reduce((prev, org) => {
        if (beacon.OrganizationID && org.ID === beacon.OrganizationID) {
          return {
            ...org,
            ...getOrgFilesAndMedia(org, fileEntities, mediaEntities)
          };
        }
        return prev;
      }, null);

      beaconObj.InternalRecords = Object.values(internalRecordEntities).filter(internalRecord => {
        if (internalRecord.BeaconID === beacon.ID) {
          return internalRecord;
        }
      });

      beaconObj.SuggestedTalents = Object.values(suggestedTalentEntities).filter(suggestedTalent => {
        if (suggestedTalent.BeaconID === beacon.ID) {
          const user = Object.values(userEntities).find(user => user.ID === suggestedTalent.UserID);
          suggestedTalent.User = user;
          return suggestedTalent;
        }
      });
      return beacons.concat(beaconObj);
    }, [])
);

export const getBeaconRoles = createSelector([
  state => isAuthorized('User', state),
  getBaseBeacon,
  (state, props) => getUserData(state, props)
], (isUserAuthorized, beacon, currentUser) => {
  const isAdmin = currentUser.ID === beacon.UserID || isUserAuthorized([
    ACTIONS.PATCH, ACTIONS.DELETE
  ], 'Beacon', { ID: beacon.ID });
  const isManager = isUserAuthorized([ACTIONS.PUT], 'Beacon', { ID: beacon.ID });
  const isContributor = isUserAuthorized([ACTIONS.GET], 'Beacon', { ID: beacon.ID });

  return {
    isAdmin,
    isContributor,
    isManager
  };
});
