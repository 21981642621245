import { schema } from 'normalizr';

// Entities
const ScheduleEvent = new schema.Entity(
  'ScheduleEvent',
  {},
  {
    idAttribute: 'ID'
  }
);

const ScheduleEventList = [ScheduleEvent];

export { ScheduleEvent, ScheduleEventList };
