import validator from 'email-validator';
import {
  camelCase,
  has,
  isArray,
  startCase,
  union
} from 'lodash';

import { RUPIE_EMAIL_REGEX } from 'shared/constants/stringConstants';

export function mergeWithArray(objValue, srcValue) {
  if (isArray(objValue)) {
    return union(objValue, srcValue);
  }
}

export function withId(value, id, placeholder = ':id') {
  return value.replace(placeholder, id);
}

/**
 * Modifies a url string with multiple replacements
 * @param  {String} value the url with :id or other selectors
 * @param  {Array}  [valueMap=[{id, placeholder}]] [description]
 * @return {String} the decorated URL
 */
export function withIds(value, valueMap = []) {
  return valueMap.reduce((valueString, { id, placeholder }) => withId(valueString, id, placeholder), value);
}

export function isExperimentEnabled(user = {}, experiment) {
  if (process.env.NODE_ENV !== 'production' && (process.env.DISABLE_EXPERIMENT_CHECK === 'true' || process.env.DISABLE_EXPERIMENT_CHECK === true)) {
    return true;
  }

  if (has(user, `Meta.Experiments.${experiment}`) || has(user, `dataValues.Meta.Experiments.${experiment}`)) {
    return (user.dataValues || user).Meta.Experiments[experiment];
  }

  return false;
}

/*
  rekey an object so all the keys are StartCase
 */
export function rekeyToStartCase(obj) {
  return Object.keys(obj).reduce((accum, next) => {
    if ([
      'createdAt', 'updatedAt', 'deletedAt'
    ].includes(next)) {
      return {
        ...accum,
        [next]: obj[next]
      };
    }

    const key = startCase(next).split(' ').join(''); // eslint-disable-line
    const idCheck = key.slice(-2);

    return {
      ...accum,
      [idCheck.toLowerCase() === 'id'
        ? key.substring(0, key.length - 2) + 'ID'
        : key]: obj[next]
    };
  }, {});
}

/*
  rekey an object so all the keys are camelCase
 */
export function rekeyToCamelCase(obj) {
  return Object.keys(obj).reduce((accum, next) => ({
    ...accum,
    [camelCase(next)]: obj[next]
  }), {});
}

/*
  check to see if an email is a rupie email
 */
export function isRupieEmail(str) {
  const email = str.trim();
  if (!validator.validate(email)) {
    throw new Error(`Expected email however string ${email} isn't an email`);
  }

  return RUPIE_EMAIL_REGEX.test(email);
}

/*
  get or conditions for linkedInUrl, so we check for both cases of linkedin url (with or without slash; preferring with)
 */
export function linkedInUrlConditions(linkedinProfileUrl, profileSearch = false) {
  if (linkedinProfileUrl) {
    if (profileSearch) {
      return [
        {
          [`$Profile.LinkedinUrl$`]: `${linkedinProfileUrl.trim()
            .replace(/\/$/, '')}/`
        },
        {
          [`$Profile.LinkedinUrl$`]: linkedinProfileUrl.trim()
            .replace(/\/$/, '')
        },
        {
          [`$Profile.LinkedinUrl$`]: `${linkedinProfileUrl.toLowerCase().trim()
            .replace(/\/$/, '')}/`
        },
        {
          [`$Profile.LinkedinUrl$`]: linkedinProfileUrl.toLowerCase().trim()
            .replace(/\/$/, '')
        }
      ];
    }

    return [
      {
        LinkedinUrl: `${linkedinProfileUrl.trim()
          .replace(/\/$/, '')}/`
      },
      {
        LinkedinUrl: linkedinProfileUrl.trim()
          .replace(/\/$/, '')
      },
      {
        LinkedinUrl: `${linkedinProfileUrl.toLowerCase().trim()
          .replace(/\/$/, '')}/`
      },
      {
        LinkedinUrl: linkedinProfileUrl.toLowerCase().trim()
          .replace(/\/$/, '')
      }
    ];
  }
  return [];
}
