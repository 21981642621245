import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconText from 'shared/web/components/IconText/IconText';

import './BodyHeader.less';

const BodyHeader = (
  {
    body,
    children,
    className,
    hasDivider,
    image,
    isCentered,
    isField,
    isFieldRequired,
    isLead,
    level,
    title
  }
) => {
  const HeadingType = level;
  const bodyContent = (body || isField) && (
    <Fragment>
      <span className="body-header__title">{title}</span>
      {
        body && (
          <span className="body-header__body">{body}</span>
        )
      }
    </Fragment>
  );

  const imageText = image !== null ? null : (
    <IconText
      image={image}
      text={(<span className="body-header__title">{title}</span>)}
    />
  );

  const childContext = (children || bodyContent) ? (children || bodyContent) : imageText;

  return (
    <div
      className={classNames('body-header', className, {
        'body-header--borderless': !hasDivider,
        'body-header--centered': isCentered,
        'body-header--is-field': isField,
        'body-header--is-field-required': isFieldRequired,
        'body-header--is-lead': isLead,
        [className]: className.length
      })}
    >
      <HeadingType>
        { childContext }
      </HeadingType>
    </div>
  );
};

BodyHeader.defaultProps = {
  body: null,
  children: null,
  className: '',
  hasDivider: true,
  image: null,
  isCentered: false,
  isField: false,
  isFieldRequired: false,
  isLead: false,
  level: 'h3'
};

export default BodyHeader;
