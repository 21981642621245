import { schema } from 'normalizr';
import { FileList, User } from 'schemas/index';

const WorkExperience = new schema.Entity(
  'WorkExperience',
  {
    Files: FileList,
    User
  },
  {
    idAttribute: 'ID'
  }
);

const WorkExperienceList = [WorkExperience];

User.define({
  WorkExperiences: WorkExperienceList
});

export { WorkExperience, WorkExperienceList };
