import React, { Component, Fragment, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { LOGIN_SUCCESS } from 'constants/userConstants';
import { updateUserPrefs } from 'actions/userActions';
import { loadingSelector } from 'selectors/genericSelectors';
import { getUserData, getUserPrefs } from 'selectors/userSelectors';
import RouteHashes from 'containers/Layouts/Handlers/RouteHashes';
import RouteReferrals from 'containers/Layouts/Handlers/RouteReferrals';
import HeaderView from 'containers/Layouts/Header/Header';
import SideView from 'containers/Layouts/Side/Side';
import Loading from 'components/Loading/Loading';

import Logo from 'images/logo_borderless_full_white.svg';
import './DefaultLayout.less';

const { Content } = Layout;

class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    currentUser: PropTypes.object,
    hideNav: PropTypes.bool,
    history: PropTypes.object.isRequired,
    isLessThanLarge: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSmall: PropTypes.bool.isRequired,
    mediaType: PropTypes.string.isRequired,
    updateUserPrefs: PropTypes.func.isRequired,
    userPrefs: PropTypes.object.isRequired
  }

  static defaultProps = {
    currentUser: {},
    hideNav: false
  }

  componentDidUpdate() {
    const { currentUser } = this.props;

    // TODO: migrate to Hubspot chat
    // if (!isEmpty(currentUser)) {
    //   const {
    //     createdAt, Email, FullName, ID
    //   } = currentUser;

    //   window.Intercom('boot', {
    //     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    //     user_id: ID,
    //     email: Email,
    //     name: FullName,
    //     created_at: createdAt
    //   });
    // }
  }

  render() {
    const {
      children,
      hideNav,
      history,
      isLessThanLarge,
      isLoading,
      isSmall,
      mediaType,
      updateUserPrefs,
      userPrefs
    } = this.props;

    if (isEmpty(userPrefs)) {
      return null;
    }

    const {
      SideViewCollapsed,
      Theme
    } = userPrefs;

    const layoutClass = classNames('layout--default', Theme, {
      'wrapper--is-xs': mediaType === 'extraSmall',
      'wrapper--is-sm': mediaType === 'small',
      'wrapper--is-md': mediaType === 'medium',
      'wrapper--is-lg': mediaType === 'large',
      'wrapper--is-xl': mediaType === 'extraLarge',
      'wrapper--is-infinity': mediaType === 'infinity',
      'wrapper--is-lt-large': isLessThanLarge,
      'wrapper--is-mobile': window.isMobile || mediaType === 'extraSmall' || mediaType === 'small',
      'wrapper--is-collapsed': hideNav || SideViewCollapsed
    });

    return (
      <Layout className={layoutClass}>
        <RouteReferrals />
        <RouteHashes />
        {isLoading
          ? (<Loading />)
          : (
            <Fragment>
              <SideView
                menuDisabled={hideNav}
                isMobileOrSmall={window.isMobile || isSmall}
                logo={Logo}
                sideViewCollapsed={hideNav || SideViewCollapsed}
                toggleSide={() => updateUserPrefs({ SideViewCollapsed: true })}
                theme={Theme}
              />
              <Layout>
                <HeaderView
                  changeRoute={url => {
                    history.replace(url);
                  }}
                  isMobileOrSmall={window.isMobile || isSmall}
                  logo={Logo}
                  sideViewCollapsed={hideNav || SideViewCollapsed}
                  toggleSide={() => updateUserPrefs({ SideViewCollapsed: !SideViewCollapsed })}
                />
                <Suspense fallback={<Loading />}>
                  <Content>
                    {children}
                  </Content>
                </Suspense>
              </Layout>
            </Fragment>
          )
        }
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isLessThanLarge: state.browser.lessThan.large,
  isSmall: state.browser.lessThan.medium,
  isLoading: loadingSelector([{ action: LOGIN_SUCCESS, defaultState: false }])(state),
  mediaType: state.browser.mediaType,
  currentUser: getUserData(state, props),
  userPrefs: getUserPrefs(state, props)
});

const mapDispatchToProps = dispatch => ({
  updateUserPrefs: prefs => dispatch(updateUserPrefs(prefs))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultLayout));
