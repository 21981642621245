import { schema } from 'normalizr';

import { Organization, User } from 'schemas/index';

const Credit = new schema.Entity(
  'Credit',
  {
    Organization,
    User
  },
  {
    idAttribute: 'ID'
  }
);

const CreditList = [Credit];

Organization.define({
  Credits: CreditList
});

User.define({
  Credits: CreditList
});

export { Credit, CreditList };
