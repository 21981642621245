import mergeWith from 'lodash/mergeWith';
import { mergeWithArray } from 'utils/dataUtils';

import {
  ORGANIZATION_CREATE,
  ORGANIZATION_FETCH,
  ORGANIZATIONS_FETCH
} from 'constants/organizationConstants';

/**
 * Returns a new 'OrganizationsByUserId' state
 * @param  {Object}  [state={}] The organizations or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with organization data
 */
export function organizationsByUserId(state = {}, action) {
  const { payload, type, userId } = action;

  switch (type) {
    case ORGANIZATIONS_FETCH._START:
    case ORGANIZATIONS_FETCH._ERROR:
      return {
        ...state,
        [userId]: {
          ...action,
          items: []
        }
      };
    case ORGANIZATIONS_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [userId]: {
            ...action,
            items: payload.result
          }
        };
      }
      return state;
    case ORGANIZATION_CREATE._SUCCESS:
      if (payload && payload.result) {
        return mergeWith({}, state, {
          [userId]: {
            isLoading: false,
            items: [payload.result]
          }
        }, mergeWithArray);
      }
      return state;
    default:
      return state;
  }
}

/**
 * Returns a new 'CreateOrganization' state
 * @param  {Object}  [state={}] The created project
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function createOrganization(state = {}, action) {
  const { organizationId, type } = action;

  switch (type) {
    case ORGANIZATION_CREATE._START:
    case ORGANIZATION_CREATE._ERROR: {
      return { ...action };
    }
    case ORGANIZATION_CREATE._SUCCESS:
      return {
        organizationId
      };
    default:
      return state;
  }
}


/**
 * Returns a new 'ProjectsById' state
 * @param  {Object}  [state={}] The project or empty array
 * @param  {Object} action     The actions object
 * @return {Array}             new state with project data
 */
export function organizationById(state = {}, action) {
  const { payload, organizationId, type } = action;

  switch (type) {
    case ORGANIZATION_FETCH._START:
    case ORGANIZATION_FETCH._ERROR:
      return {
        ...state,
        [organizationId]: action
      };
    case ORGANIZATION_FETCH._SUCCESS:
      if (payload && payload.result) {
        return {
          ...state,
          [organizationId]: action
        };
      }
      return state;
    default:
      return state;
  }
}
