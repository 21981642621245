import { schema } from 'normalizr';

import {
  FileList,
  File,
  MediaList,
  Organization,
  OrganizationList,
  ProjectList,
  TeamList,
  UserList,
  User
} from './index';

const Event = new schema.Entity(
  'Event',
  {
    Files: FileList,
    Media: MediaList,
    Organization,
    Organizers: OrganizationList,
    Projects: ProjectList,
    TeamParticipants: TeamList,
    Participants: UserList
  },
  {
    idAttribute: 'ID'
  }
);

const EventSubmissionVote = new schema.Entity(
  'EventSubmissionVote',
  {},
  {
    idAttribute: 'ID'
  }
);

const EventSubmissionVoteList = [EventSubmissionVote];

const EventSubmission = new schema.Entity(
  'Submission',
  {
    File
  },
  {
    idAttribute: 'ID'
  }
);

const EventUpdate = new schema.Entity(
  'EventUpdate',
  { Author: User },
  {
    idAttribute: 'ID'
  }
);

const EventList = [Event];

const EventSubmissionList = [EventSubmission];

const EventUpdateList = [EventUpdate];

export {
  Event, EventList, EventSubmission, EventSubmissionList, EventSubmissionVote, EventSubmissionVoteList, EventUpdate, EventUpdateList
};
