import {
  ROLODEX_TALENT_LIST
} from 'constants/rolodexConstants';

export function rolodexTalent(state = {}, action) {
  const { payload, type } = action;
  switch (type) {
    case ROLODEX_TALENT_LIST:
      const { rolodexId, talent } = payload;
      return {
        ...state,
        [rolodexId]: talent
      };
    default:
      return state;
  }
}
