import { schema } from 'normalizr';
import { DeliverableList } from 'schemas/index';

const Task = new schema.Entity(
  'Task',
  {
    Deliverables: DeliverableList
  },
  {
    idAttribute: 'ID'
  }
);

const TaskList = [Task];

export { Task, TaskList };
