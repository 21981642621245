import { schema } from 'normalizr';

import {
  FileList,
  MediaList,
  WorkPreferencesList,
  Organization
} from './index';

const TeamSkill = new schema.Entity(
  'TeamSkill',
  {},
  {
    idAttribute: 'ID'
  }
);

const TeamSkillList = [TeamSkill];

const Team = new schema.Entity(
  'Team',
  {
    Files: FileList,
    Media: MediaList,
    Skills: TeamSkillList,
    Organization,
    WorkPreferences: WorkPreferencesList
  },
  {
    idAttribute: 'ID'
  }
);

const TeamList = [Team];

Organization.define({
  Teams: TeamList
});

export {
  Team, TeamList, TeamSkill, TeamSkillList
};
